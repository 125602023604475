import React from "react";
import useStyles from "./styles";
import { Button, Grid, Input, Paper, Typography, } from "@mui/material";
import { GetApp, Publish } from "@mui/icons-material";
import Aux from "hoc/auxiliar";
import BackButton from "components/BackButton";
import PermissionsGate from "components/PermissionsGate";


const MassImportTemplate = ({
  title = 'Importar arquivo em massa',
  downloadButtonTitle = 'Download template para importação',
  submitButtonTitle = 'Enviar Arquivo Vínculos em Massa',
  handleClickDownloadTemplate = () => { },
  handleClickMassImport = () => { },
  uppingFile = false,
  permission = ''

}) => {
  const classes = useStyles();

  return (
    <Aux>
      <PermissionsGate scopes={[permission]}>
        <Typography variant="h3" className={classes.title} data-cy="h3Title">{title}</Typography>
        {uppingFile && (
          <Grid justifyContent="center" container spacing={4}>
            <Grid item xs={12} md={6} sm={6} xl={6} lg={6}>
              <span>A importação em massa está ocorrendo, aguarde que estamos criando um a um para você.</span>
            </Grid>
          </Grid>
        )}
        <Paper elevation={0} className={classes.container}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Grid container justifyContent="space-around">
              <Grid item xl={4} lg={4} md={5} sm={6} xs={12}>
                <Button
                  variant="contained"
                  onClick={handleClickDownloadTemplate}
                  startIcon={<GetApp />}
                  className={classes.btItemImport}
                  data-cy="buttonDownloadTemplateImport"
                >
                  {downloadButtonTitle}
                </Button>
              </Grid>
              <Grid item xl={4} lg={4} md={5} sm={6} xs={12}>
                <label htmlFor="contained-button-file">
                  <Input
                    inputProps={{ accept: '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel' }}
                    id="contained-button-file"
                    type="file"
                    onChange={handleClickMassImport}
                    onClick={(event) => {
                      event.target.value = null
                    }}
                    className={classes.inputHidden} />
                  <Button
                    component="span"
                    variant="contained"
                    type="file"
                    startIcon={<Publish />}
                    className={classes.btItemImport}
                    data-cy="spanSendFilesDriversMass"
                  >
                    {submitButtonTitle}
                  </Button>
                </label>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Grid justifyContent="flex-end" container spacing={2} className={classes.buttons}>
          <Grid item xs={12} md={3} sm={4} xl={1} lg={2}>
            <BackButton />
          </Grid>
        </Grid>
      </PermissionsGate>
    </Aux>
  );
};

export default MassImportTemplate;
