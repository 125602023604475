import * as yup from "yup";

export default yup.object().shape({
  /* PERFIL DO VEÍCULO */
  brandId: yup.object().required("Campo obrigatório").nullable(),
  modelId: yup.object().required("Campo obrigatório").nullable(),
  whiteRangeStart: 
    yup.number()
      .required("Campo obrigatório")
      .transform((cv, ov) => ov === "" ? undefined : cv)
      .moreThan(yup.ref("neutralRangeStart"), "O valor deve ser maior que o da faixa lenta")
      .nullable(),
  extraEconomicRangeStart: 
    yup.number()
      .transform((cv, ov) => ov === "" ? undefined : cv)
      .moreThan(yup.ref("whiteRangeStart"), "O valor deve ser maior que o da faixa branca")
      .nullable()
      .when('whiteRangeStart', {
        is: (value) => !!value,
        then: yup.number().required("Campo obrigatório").typeError("Campo obrigatório")
      }),
  economicRangeStart: 
    yup.number()
      .transform((cv, ov) => ov === "" ? undefined : cv)
      .moreThan(yup.ref("extraEconomicRangeStart"), "O valor deve ser maior que o início da faixa verde")
      .nullable()
      .when('extraEconomicRangeStart', {
        is: (value) => !!value,
        then: yup.number().required("Campo obrigatório").typeError("Campo obrigatório")
      }),
  powerRangeStart: 
    yup.number()
      .transform((cv, ov) => ov === "" ? undefined : cv)
      .moreThan(yup.ref("economicRangeStart"), "O valor deve ser maior que o final da faixa verde")
      .nullable()
      .when('economicRangeStart', {
        is: (value) => !!value,
        then: yup.number().required("Campo obrigatório").typeError("Campo obrigatório")
      }),
  engineBrakeRangeStart: 
    yup.number()
      .transform((cv, ov) => ov === "" ? undefined : cv)
      .moreThan(yup.ref("powerRangeStart"), "O valor deve ser maior que a faixa acelerando acima do verde")
      .nullable()
      .when('powerRangeStart', {
        is: (value) => !!value,
        then: yup.number().required("Campo obrigatório").typeError("Campo obrigatório")
      }),
  redRangeStart: 
    yup.number()
      .transform((cv, ov) => ov === "" ? undefined : cv)
      .moreThan(yup.ref("engineBrakeRangeStart"), "O valor deve ser maior que o da faixa freio motor")
      .nullable()
      .when('engineBrakeRangeStart', {
        is: (value) => !!value,
        then: yup.number().required("Campo obrigatório").typeError("Campo obrigatório")
      }),
  redRangeEnd: 
    yup.number()
      .transform((cv, ov) => ov === "" ? undefined : cv)
      .moreThan(yup.ref("redRangeStart"), "O valor deve ser maior que o início da faixa vermelha")
      .nullable()
      .when('redRangeStart', {
        is: (value) => !!value,
        then: yup.number().required("Campo obrigatório").typeError("Campo obrigatório")
      }),
});
