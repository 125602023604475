import React, { useLayoutEffect, useState, useRef, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useWindowSize } from 'hooks/useWindowsSize';
import { useLayoutState } from 'context/LayoutContext';
import { useStyles } from './styles';

const FixedContainer = ({ children }) => {
  const size = useWindowSize();
  const layoutState = useLayoutState();
  const [containerWidth, setContainerWidth] = useState('auto');
  const containerRef = useRef(null); 
  const classes = useStyles();

  const headerHeight = layoutState.headerHeight ?? 80;

  const updateContainerWidth = () => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.offsetWidth);
    }
  };

  useLayoutEffect(() => {
    if (containerRef.current) {
      const resizeObserver = new ResizeObserver(updateContainerWidth);
      resizeObserver.observe(containerRef.current);
      updateContainerWidth();

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, []);

  useEffect(() => {
    updateContainerWidth();
  }, [layoutState.isSidebarOpened, size.width]);

  return (
    <Grid container className={classes.container} style={{ top: headerHeight }}>
      <Grid 
        item 
        xs={12} sm={12} md={12} lg={12} xl={12}
        ref={containerRef}
      >
        <Grid 
          item 
          xs={12} sm={12} md={12} lg={12} xl={12}
          style={{ width: containerWidth }}
        >
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FixedContainer;
