import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

export function Pagination({ page, rowsPerPage, totalItems, onChangePage, classes }) {
  const totalPages = Math.ceil(totalItems / rowsPerPage);

  return (
    <Box className={classes.pagination}>
      <IconButton
        onClick={() => onChangePage(page - 1)}
        disabled={page === 0}
      >
        <NavigateBeforeIcon />
      </IconButton>
      <Typography>
        {`${page * rowsPerPage + 1}-${Math.min((page + 1) * rowsPerPage, totalItems)} de ${totalItems}`}
      </Typography>
      <IconButton
        onClick={() => onChangePage(page + 1)}
        disabled={page >= totalPages - 1}
      >
        <NavigateNextIcon />
      </IconButton>
    </Box>
  );
}