import React from "react";
import PropTypes from "prop-types";
import { Button } from "../../../componentsNew/atoms/button/button";
import Item from "../../../componentsNew/atoms/item/item";
import "./headerDetails.css";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('pt-BR'); 
};

const HeaderDetails = ({ vehicle = { foo: 'bar' }, onBack, showBackButton = true }) => {
  return (
    <div className="HeaderDetails-container">
      <Item icon={'truck_full'} label={vehicle.plate} />
      <Item icon={'date_calendar'} label={formatDate(vehicle.date)} /> 
      {showBackButton && (
        <Button
          size="small"
          type="secondary"
          onClick={onBack}
          label="Voltar"
        />
      )}
    </div>
  );
};

export default HeaderDetails;

HeaderDetails.propTypes = {
  vehicle: PropTypes.shape({
    plate: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired
  }).isRequired,
  onBack: PropTypes.func,
  showBackButton: PropTypes.bool
};
