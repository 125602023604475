const makeDaysOptions = () => {
  let options = [];
  for (let i = 1; i <= 31; i++) {
    options.push({
      label: String(i),
      value: i,
    });
  }
  return options;
};
const makeDaysOptionsWithLabel = () => {
  let options = [];
  for (let i = 1; i <= 31; i++) {
    options.push({
      label: `Dia ${i} do mês seguinte`,
      value: i,
    });
  }
  return options;
};

export const startingDaysOptions = makeDaysOptions();
export const finishingDaysOptions = makeDaysOptionsWithLabel();

export const requirementLevelOptions = [
  { value: 1, label: "Baixa", type: "low" },
  { value: 2, label: "Média", type: "medium" },
  { value: 3, label: "Alta", type: "high" },
];

export const allRequirementLevelOptions = [
  { value: 1, label: "Baixa", type: "low" },
  { value: 2, label: "Média", type: "medium" },
  { value: 3, label: "Alta", type: "high" },
  { value: 4, label: "Personalizada", type: "custom" },
];

export const periodOptions = [
  { value: 1, label: "Mesmo mês" },
  { value: 2, label: "Mês seguinte" },
];

export const rewardTypeOptions = [
  { value: 1, label: "PADRÃO" },
  { value: 2, label: "PERSONALIZADO" },
];

export const rewardSelectOptions = [
  { value: 1, label: "PADRÃO" },
  { value: 2, label: "PERSONALIZADO" },
];

export const brandListOptions = [
  {
    id: "opt",
    name: "OPT",
  },
  {
    id: "rp4",
    name: "RP4",
  },
  {
    id: "rp5",
    name: "RP5",
  },
  {
    id: "sat_lite2",
    name: "Satlite3",
  },
  {
    id: "teltonika",
    name: "Teltonika",
  },
  {
    id: "queclink",
    name: "Queclink",
  },
  {
    id: "bce",
    name: "BCE",
  },
  {
    id: "virtual",
    name: "Virtual",
  },
];

// id = 0 -> Deleted
export const deviceStatusOptions = [
  {
    id: 1,
    name: "Disponível para instalação",
  },
  {
    id: 2,
    name: "Em uso",
  },
  {
    id: 3,
    name: "Em teste",
  },
  {
    id: 4,
    name: "Em manutenção",
  },
];

export const distanceCalcMethodOptions = [
  {
    id: "can",
    name: "CAN",
  },
  {
    id: "gps",
    name: "GPS",
  },
];

export const fuelCalcMethodOptions = [
  {
    id: "can",
    name: "CAN",
  },
  {
    id: "sensor",
    name: "SENSOR",
  },
  {
    id: "hrfc",
    name: "HRFC",
  },
];

export const accelerationIdentificationMethodOptions = [
  {
    id: "pedal",
    name: "PEDAL",
  },
  {
    id: "hrfc",
    name: "HRFC",
  },
];

export const statusTypeOptions = [
  {
    id: 1,
    name: "EM TESTE",
  },
  {
    id: 2,
    name: "ATIVO",
  },
];

const arrayOptions = [
  {
    value: 1,
    label: "Total motor ligado parado",
    type: "idle",
  },
  {
    value: 2,
    label: "Excesso de velocidade",
    type: "speeding",
  },
  {
    value: 3,
    label: "Início faixa verde",
    type: "extraEconomicRange",
  },
  {
    value: 4,
    label: "Aproveitamento de embalo",
    type: "leverage",
  },
  {
    value: 5,
    label: "Piloto automático",
    type: "cruiseControl",
  },
  {
    value: 6,
    label: "Acelerando acima do verde",
    type: "powerRange",
  },
  {
    value: 7,
    label: "Total faixa verde",
    type: "greenRange",
  },
  {
    value: 8,
    label: "Final faixa verde",
    type: "economicRange",
  },
  {
    value: 9,
    label: "Freio motor",
    type: "engineBrake",
  },
  {
    value: 10,
    label: "Eco-Roll/I-Roll",
    type: "ecoRoll",
  },
  {
    value: 11,
    label: "Tempo em movimento",
    type: "movement",
  },
  {
    value: 15,
    label: "Motor ligado parado",
    type: "lowIdle",
  }
];

export const penalizingOptions = [
  {
    value: 12,
    label: "Uso do pedal do acelerador - Ideal ",
    type: "lowPressure",
  },
  {
    value: 13,
    label: "Uso do pedal do acelerador - Atenção ",
    type: "medPressure",
  },
  {
    value: 14,
    label: "Uso do pedal do acelerador - Crítico ",
    type: "higPressure",
  },
];

export const indicatorsOptions = arrayOptions.sort((x, y) =>
  x.label.localeCompare(y.label),
);

export const indicatorsAndPenalizingOptionsConcat =
  indicatorsOptions.concat(penalizingOptions);

export const odomTypeOptions = [
  { value: 1, label: "Físico" },
  { value: 2, label: "Virtual" },
];

export const typeVizualizationOptions = [
  {
    value: 1,
    label: "Por dia",
    label2: "Dia : ",
    visualization: "daily",
    formatDate: "MMMM/yyyy",
  },
  {
    value: 2,
    label: "Por semana",
    label2: "Semana : ",
    visualization: "weekly",
    formatDate: "MMMM/yyyy",
  },
  {
    value: 3,
    label: "Por mês",
    label2: "Mês : ",
    visualization: "monthly",
    formatDate: "yyyy",
  },
];