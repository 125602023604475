import React, { useEffect, useState, useCallback } from 'react';
import { Grid, Paper, Typography } from '@mui/material';

// themes
import colors from 'themes/gobrax';

// helpers
import { getNameIndicator } from 'helpers/indicators';

// components
import HalfMoonChart from 'components/HalfMoonChart';

// styles
import useStyles from './styles';

const ConsolidatedIndicators = ({ indicators }) => {
  const classes = useStyles();

  const [data, setData] = useState([]);

  const getColor = (value) => {
    let color = '';

    if (value <= 40) {
      color = colors.palette.error.main;
    } else if (value <= 80) {
      color = colors.palette.warning.main;
    } else {
      color = colors.palette.success.main;
    }

    return color;
  }

  const ordernateIndicators = (indicatorList) => {
    let ordenateArray = [];

    const ordenateIndicators = [
      "Início faixa verde",
      "Aproveitamento de embalo",
      "Pressão do acelerador",
      "Motor ligado parado",
      "Acelerando acima do verde",
      "Freio motor",
      "Piloto automático",
      "Excesso de velocidade",
    ];

    for (const label of ordenateIndicators) {
      for (const indicator of indicatorList) {
        if (indicator.label === label) {
          ordenateArray.push(indicator);
        }
      }
    }

    return ordenateArray;
  };

  const handleData = useCallback(() => {
    const formatedData = Object.keys(indicators).map(key => {
      return {
        key: key,
        label: getNameIndicator(key),
        color: getColor(indicators[key].score),
        value: indicators[key].score,
      }
    });

    const orderedList = ordernateIndicators(formatedData);

    setData(orderedList);
  }, [indicators])

  useEffect(() => {
    indicators && handleData();
  }, [handleData, indicators])

  return (
    <Grid item container spacing={2} xl={12} lg={12} md={12} sm={12} xs={12}>
      {(data.length > 0 && indicators) && data.map(item => (
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={item.key}>
          <Paper elevation={0} className={classes.card}>
            <Grid container direction='row' justifyContent='center'>
              <HalfMoonChart data={item} />
              <Typography variant='h6' className={classes.itemTitle}>{item.label}</Typography>
            </Grid>
          </Paper>
        </Grid>
      ))}
    </Grid>
  )
}

export default ConsolidatedIndicators;