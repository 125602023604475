import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  textBold: {
    fontWeight: 700,
  },
  statusText: {
    fontWeight: 700,
    marginTop: "-4px !important",
    marginLeft: "8px !important"
  },
  success: {
    color: theme.palette.success.main
  },
  danger: {
    color: theme.palette.error.main
  },
  btAction: {
    textTransform: "none",
    color: theme.palette.primary.main
  },
  titlePage: {
    fontWeight: 600,
    cursor: "default",
    fontSize: "24px",
    color: theme.palette.text.title2,
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      marginBottom: "8px"
    }
  },
  header: {
    paddingTop: 16,
    paddingBottom: 16,
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    width: '100%',
    overflow: 'visible',
    '@media (min-width: 768px)': {
      flexDirection: 'row',
      alignItems: 'flex-start'
    }
  },
  headerAlt: {
    paddingTop: 16,
    paddingBottom: 16,
    width: '100%',
  },
  operationsSelect: {
    maxWidth: 420,
  },
  datesSelect: {
    borderRadius: 20,
    display: 'flex',
    alignItems: 'center',
    maxWidth: 420,
    backgroundColor: 'white',
    boxShadow: '3px 5px 4px #DADADA'
  }
}));
