import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
  Box
} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';

const DeleteUserDialog = ({ open, onClose, onConfirm }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      aria-labelledby="delete-dialog-title"
      aria-describedby="delete-dialog-description"
      PaperProps={{
        sx: {
          maxWidth: '400px'
        }
      }}
    >
      <DialogTitle
        id="delete-dialog-title"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 1,
          bgcolor: '#ef5350',
          color: 'white',
          textAlign: 'center',
          padding: '16px'
        }}
      >
        <WarningIcon sx={{ color: 'white' }} fontSize="large" />
        <Typography variant="h6" component="span">
          Confirmar exclusão
        </Typography>
      </DialogTitle>

      <DialogContent sx={{ bgcolor: '#ffebee' }}>
        <Box sx={{
          py: 2,
          textAlign: 'center'
        }}>
          <DialogContentText id="delete-dialog-description">
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                fontWeight: 'bold',
                color: '#d32f2f'
              }}
            >
              Atenção: Esta ação é irreversível.
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: '#d32f2f'
              }}
            >
              Ao confirmar, o usuário será excluído definitivamente do sistema e todos os dados associados serão perdidos.
            </Typography>
          </DialogContentText>
        </Box>
      </DialogContent>

      <DialogActions
        sx={{
          px: 3,
          pb: 2,
          bgcolor: '#ffebee',
          justifyContent: 'center', 
          gap: 1 
        }}
      >
        <Button
          onClick={onClose}
          variant="outlined"
          sx={{
            borderRadius: 2,
            background: '#fff',
            borderColor: 'black',
            '&:hover': {
              borderColor: 'black',
              backgroundColor: '#e2dada'
            }
          }}
        >
          Cancelar
        </Button>
        <Button
          onClick={onConfirm}
          variant="contained"
          color="error"
          sx={{
            borderRadius: 2,
            bgcolor: '#d32f2f',
            '&:hover': {
              bgcolor: '#b71c1c'
            }
          }}
          autoFocus
        >
          Confirmar Exclusão
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteUserDialog;