import React from "react";
import { Grid, Skeleton } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

import Aux from "hoc/auxiliar";

const EvolutiveSkeleton = () => {
  const classes = useStyles();

  return (
    <Aux>
      <Grid container>
        <Grid item xs={12} className={classes.wrapperContent}>
          <Grid className={classes.dropzone} id="charts">
            {[1, 2, 3, 4].map((_, index) => (
              <Grid key={`${index}-evolutive-skeleton`} display="flex" flexDirection={{ xs: 'column', md: 'row' }} gap={2}>
                <Grid item flex={1}>
                  <Skeleton variant="rectangular" height={280} className={classes.bordered} />
                </Grid>
                <Grid item flex={1}>
                  <Skeleton variant="rectangular" height={280} className={classes.bordered} />
                </Grid>
                <Grid item flex={1}>
                  <Skeleton variant="rectangular" height={280} className={classes.bordered} />
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Aux>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapperContent: {
    marginTop: theme.spacing(3),
  },
  dropzone: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2)
  },
  bordered: {
    borderRadius: "22px",
  },
}));

export default EvolutiveSkeleton;
