import React from "react";
import { Link } from "react-router-dom";
import { IconButton, Grid } from "@mui/material";
import { Delete as DeleteIcon, Edit as EditIcon, RemoveRedEyeOutlined as EyeIcon, History as HistoryIcon } from "@mui/icons-material";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";

export const makeColumns = ({ onDelete, onEdit, size }) => {
  let columns = size.mobile ? [
    {
      name: "perfil",
      label: "Perfis",
    },
    {
      name: "modelName",
      label: ' ',
    }
  ] : [
    {
      name: "modelName",
      label: "Perfis",
      options: {
        filterOptions: {
          fullWidth: true,
        }
      }
    }
  ];

  if (!size.mobile && hasPermission({ scopes: ['can_edit_profiles', 'can_remove_profiles', 'can_view_profiles_without_edit', 'can_view_history_profile'] })) {
    columns.push({
      name: "id",
      label: "Ações",
      options: {
        sort: false,
        filter: false,
        viewColumns: false,
        print: false,
        setCellProps: () => ({
          style: {
            width: 200
          }
        }),
        customBodyRender: (value) => {
          return (
            <Grid container>
              <PermissionsGate scopes={['can_edit_profiles']}>
                <Grid container item xs={3}>
                  <IconButton
                    onClick={() => onEdit(value)}
                    aria-label="edit"
                    title="Editar"
                    size="large">
                    <EditIcon />
                  </IconButton>
                </Grid>
              </PermissionsGate>
              <PermissionsGate scopes={['can_view_history_profile']}>
                <Grid item xs={3}>
                  <IconButton
                    component={Link}
                    to={`/gofurther/profile/historic-profiles/${value}`}
                    aria-label="history"
                    title="Histórico"
                    size="large">
                    <HistoryIcon />
                  </IconButton>
                </Grid>
              </PermissionsGate>
              <PermissionsGate scopes={['can_remove_profiles']}>
                <Grid container item xs={3}>
                  <IconButton
                    onClick={() => onDelete(value)}
                    aria-label="delete"
                    title="Excluir"
                    size="large">
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </PermissionsGate>
              <PermissionsGate scopes={['can_view_users_without_edit']}>
                <Grid container item xs={3}>
                  <IconButton
                    aria-label="view-profile"
                    title="Visualizar perfil"
                    component={Link}
                    to={`/gofurther/profile/view-profile/${value}`}
                    size="large">
                    <EyeIcon />
                  </IconButton>
                </Grid>
              </PermissionsGate>
            </Grid>
          );
        },
      },
    });
  }
  return columns;
};
