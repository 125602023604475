import React from "react";
import { Grid, Skeleton } from "@mui/material";
import { styled } from '@mui/system';

import Aux from "hoc/auxiliar";

import { useWindowSize } from "hooks/useWindowsSize";

const DriversMonitoringSkeleton = () => {
  const size = useWindowSize();

  return (
    <Aux>
      <Grid container spacing={1} marginTop={.125} direction={size.mobile ? "column" : "row"}>
        <Grid item xl={size.grid6} lg={size.grid6} md={size.grid6} sm={size.grid6} xs={size.grid6}>
          <BorderedSkeleton variant="rectangular" height={50} />
        </Grid>
        <Grid item xl={size.grid6} lg={size.grid6} md={size.grid6} sm={size.grid6} xs={size.grid6}>
          <BorderedSkeleton variant="rectangular" height={50} />
        </Grid>
      </Grid>
      <Grid container spacing={1} marginTop={.125} direction={size.mobile ? "column" : "row"}>
        <Grid item xl={size.grid6} lg={size.grid6} md={size.grid6} sm={size.grid6} xs={size.grid6}>
          <BorderedSkeleton variant="rectangular" height={50} />
        </Grid>
        <Grid item xl={size.grid6} lg={size.grid6} md={size.grid6} sm={size.grid6} xs={size.grid6}>
          <BorderedSkeleton variant="rectangular" height={50} />
        </Grid>
      </Grid>
      <Grid container spacing={1} marginTop={.125}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <BorderedSkeleton variant="rectangular" width={"100%"} height={550} />
        </Grid>
      </Grid>
    </Aux>
  );
};

const BorderedSkeleton = styled(Skeleton)({
  borderRadius: "10px",
});

export default DriversMonitoringSkeleton;
