export function StatusVehicle(data) {
  const vehiclesStatus = [];
  if (Array.isArray(data)) {
    data.map((vehicle, i) => {
      vehiclesStatus.push({
        vehicleId: vehicle?.vehicleId,
        date: vehicle?.date,
        color: TypeStatus(vehicle?.status),
        title: vehicle?.plate ?? `Sem identificação ${i}`,
        lng: vehicle?.point?.lon,
        lat: vehicle?.point?.lat,
        uuid: vehicle?.vehicleId,
      });
    });
  }
  return vehiclesStatus;
}

export function TypeStatus(value) {
  switch (value) {
    case "STOPPED_MOTOR_ON":
      return "blue";
      case "COMMUNICATION_OFF":
        return "white";
    case "IN_MOVEMENT":
      return "green";
    case "DANGER_BREAKDOWN":
      return "yellow";
    case "STOPPED_MOTOR_OFF":
      return "gray";
    case "UNAVAILABLE":
      return "red";

    default:
      return "red";
  }
}

export function getVehicleStatus(value) {
  switch (value) {
    case "STOPPED_MOTOR_ON":
      return "Motor ligado parado";
    case "COMMUNICATION_OFF":
      return "Sem comunicação";
    case "IN_MOVEMENT":
      return "Em movimento";
    case "DANGER_BREAKDOWN":
      return "Risco de pane seca";
    case "STOPPED_MOTOR_OFF":
      return "Motor desligado";
    case "UNAVAILABLE":
      return "Dados insuficientes";

    default:
      return "Sem comunicação";
  }
}
