import { createSlice } from '@reduxjs/toolkit';

const TODAY = new Date()

const initialState = {
  filters : {
    operation: null,
    year: {
      value: TODAY.getFullYear(),
      label: TODAY.getFullYear(),
    },
    cutoffDate: null,
  },
};

export const consolidatedSlice = createSlice({
  name: 'consolidated',
  initialState,
  reducers: {
    setFilters: (state, action) => {
      const { name, selectedOption } = action.payload;
      if (name === 'cutoffDate') {
        if(selectedOption === null) {
          state.filters[name] = selectedOption;
        } else {
          state.filters[name] = JSON.stringify(selectedOption);
        }
      } else {
        state.filters[name] = selectedOption;
      }
    },
    clearConsolidatedFilters: (state, action) => {
      state.filters = initialState.filters
    },
  }
});

// Action creators are generated for each case reducer function
export const { 
  clearConsolidatedFilters, 
  resetStep, 
  setFilters, 
  setPaginationReducer,
  setPerformancesReducer,
  setStepAdd, 
  setStepDeduct, 
} = consolidatedSlice.actions;

export default consolidatedSlice.reducer;
