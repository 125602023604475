import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { makeStyles } from '@mui/styles';

const secondary = "#F7DF36"; // Cor secundária

const useStyles = makeStyles((theme) => ({
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40vh',
    backgroundColor: theme.palette.background.default,
  },
  circleWrapper: {
    position: 'relative',
    width: '120px',
    height: '120px',
  },
  animatedCircle: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    animation: '$spin 2s linear infinite',
    zIndex: 1,
  },
  iconWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 2,
  },
  textWrapper: {
    marginTop: theme.spacing(2),
    fontSize: '16px',
    fontWeight: '500',
    color: 'black',
    display: 'flex',
    alignItems: 'center',
  },
  dots: {
    display: 'inline-block',
    marginLeft: '5px',
    '&::after': {
      content: '"."',
      animation: '$dots 2s steps(4, end) infinite',
    },
  },
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  '@keyframes dots': {
    '0%': {
      content: '"."',
    },
    '33%': {
      content: '".."',
    },
    '66%': {
      content: '"..."',
    },
    '100%': {
      content: '""',
    },
  },
}));

function TruckLoader() {
  const classes = useStyles();

  return (
    <Box className={classes.loadingContainer}>
      <div className={classes.circleWrapper}>
        <CircularProgress
          size={120}
          thickness={2}
          className={classes.animatedCircle}
          style={{ color: secondary }}
        />
        <div className={classes.iconWrapper}>
          <LocalShippingIcon style={{ fontSize: 60, color: '#000000' }} />
        </div>
      </div>
      <Typography className={classes.textWrapper}>
        Estamos carregando as informações
        <span className={classes.dots}></span>
      </Typography>
    </Box>
  );
}

export default TruckLoader;
