import { emptyOperation } from "../helpers/follow";
import React, { createContext, useCallback, useState, useContext } from "react";

const SelectContext = createContext();

const SelectDriversProvider = ({ children }) => {
  const TODAY = new Date();
  const [customersDates, setCustomersDates] = useState(null);
  const [cutOffDatesOptions, setCutOffDatesOptions] = useState(null);
  const [details, setDetails] = useState(null);
  const [drivers, setDrivers] = useState(null);
  const [historyGoBack, setHistoryGoBack] = useState(false);
  const [vehiclesByOperation, setVehiclesByOperation] = useState(null);
  const [selectedCutOffDate, setSelectedCutOffDate] = useState(null);
  const [selectedOperation, setSelectedOperation] = useState(emptyOperation);
  const [selectedYear, setSelectedYear] = useState({
    value: TODAY.getFullYear(),
    label: TODAY.getFullYear(),
  });
  const [tripListDetails, setTripListDetails] = useState(null);

  const [filters, setFilters] = useState({
    operation: null,
    year: {
      value: TODAY.getFullYear(),
      label: TODAY.getFullYear(),
    },
    cutoffDate: null,
  });

  const onChangeCutOffDate = (cutOffDate) => {
    setSelectedCutOffDate(cutOffDate);
  };
  const onChangeOperation = (list, index) => {
    setSelectedOperation(list);
  };
  const resetContext = useCallback(() => {
    setCustomersDates(null);
    setCutOffDatesOptions(null);
    setDetails(null);
    setDrivers(null);
    setHistoryGoBack(false);
    setVehiclesByOperation(null);
    setSelectedCutOffDate(null);
    setSelectedOperation(emptyOperation);
    setSelectedYear({
      value: TODAY.getFullYear(),
      label: TODAY.getFullYear(),
    });
    setTripListDetails(null);
  }, []);

  const contexts = {
    customersDates,
    cutOffDatesOptions,
    details,
    drivers,
    historyGoBack,
    selectedOperation,
    selectedCutOffDate,
    selectedYear,
    tripListDetails,
    vehiclesByOperation,
    filters,
    setFilters,
    setCustomersDates,
    setCutOffDatesOptions,
    setDetails,
    setDrivers,
    setHistoryGoBack,
    setSelectedCutOffDate,
    setSelectedYear,
    setTripListDetails,
    setVehiclesByOperation,
    onChangeOperation,
    onChangeCutOffDate,
    resetContext,
  };
  return (
    <SelectContext.Provider value={contexts}>{children}</SelectContext.Provider>
  );
};

function useSelectDrivers() {
  const context = useContext(SelectContext);
  return context;
}

export { SelectDriversProvider, useSelectDrivers };
