import React, { useCallback, useEffect, useState } from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import DragIndicator from "@mui/icons-material/DragIndicator";
import Select from "react-select";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  AreaChart,
  Area,
  Tooltip,
} from "recharts";
import DatePicker, { registerLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from "react-grid-dnd";
import { Today } from "@mui/icons-material";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/pt-br';
import { toast } from "react-toastify";

// store
import { useAppSelector } from "store";

// services
import { getVehiclesByOperation } from "services/vehicle";
import { getFleetEvolutive, postFleetEvolutive } from "services/evolutive";
import { handleAmplitudeEvent } from "services/amplitude";

// helpers
import { extractIds } from "helpers/extractIds";
import { objectComparisonCallback } from "helpers/objectComparisonCallback";
import { translationsMonths } from "helpers/translationMonths";

// components
import Aux from "hoc/auxiliar";
import PermissionsGate from "components/PermissionsGate";
import Infobox from "components/Infobox";
import EvolutiveSkeleton from "components/Skeletons/EvolutiveSkeleton";
import { TitlePage } from "components/TitlePage";
import { OperationsSelect } from "components/OperationsSelect";

import { typeVizualizationOptions } from "domain/selectOptions";

// hooks
import { useWindowSize } from "hooks/useWindowsSize";

// styles
import { colourStyles } from "./colourStyles";
import useStyles from "./styles";

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.locale('pt-br');

export default function Evolutive() {
  registerLocale("pt-BR", ptBR);

  const classes = useStyles();

  const size = useWindowSize();

  const { currentCustomer } = useAppSelector((state) => state.global.user);

  const [loading, setLoading] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [typeVisualization, setTypeVisualization] = useState(typeVizualizationOptions[0]);
  const [charts, setCharts] = useState([]);

  async function onChange(_, sourceIndex, targetIndex) {
    const result = swap(charts, sourceIndex, targetIndex);

    let model = [];

    result.forEach(function (element, index) {
      model.push({
        id: element.id,
        order: index + 1,
        title: element.title,
        customer_id: Number(currentCustomer),
      });
      element.order = index + 1;
    });

    setCharts(result);

    const response = await postFleetEvolutive(model);

    if (response.status !== 200) {
      fetchData(selectedVehicles, startDate, typeVisualization);
    }
  };

  const capitalizeFirstLetter = useCallback((string) => string.charAt(0).toUpperCase() + string.slice(1).toLowerCase(), []);

  const sanitizeVehicles = useCallback(vehicles => vehicles.map((vehicle) => {
    const vehicleLabel = `${vehicle.identification} - ${vehicle.plate}`;
    const driverLabel = vehicle.currentDriver?.driverName ? ` - (Motorista atual: ${vehicle.currentDriver.driverName})` : '';

    return {
      id: vehicle.id,
      label: `${vehicleLabel}${driverLabel}`,
      operation: {
        id: vehicle.operationId,
        label: vehicle.operation_name
      }
    };
  }), []);

  const handleVehiclesChange = useCallback((selected) => {
    setSelectedVehicles(selected);

    handleAmplitudeEvent(
      'Filter Updated',
      { 
        label: "Frota",
        value: selected.map(item => item?.label).join(", ")
      }
    );
  }, [setSelectedVehicles, handleAmplitudeEvent]);

  const handleOperationSelect = useCallback((operation) => {
    const selectedWithoutOperationVehicles = selectedVehicles.filter(selectedVehicle => selectedVehicle.operation?.id !== operation?.id);
    const operationVehicles = sanitizeVehicles(vehicles).filter(vehicle => vehicle.operation.id === operation.id);

    setSelectedVehicles([...selectedWithoutOperationVehicles, ...operationVehicles]);
  }, [sanitizeVehicles, selectedVehicles, setSelectedVehicles]);

  const handleOperationDelete = useCallback((operationId) => {
    const updatedSelectedVehicles = selectedVehicles.filter(selectedVehicle => selectedVehicle.operation?.id !== Number(operationId));

    setSelectedVehicles(updatedSelectedVehicles);
  }, [selectedVehicles, setSelectedVehicles]);

  const fetchData = useCallback(async (vehicles, date, type) => {
    if(!vehicles.length || !date || !type) {
      setCharts([]);
      return;
    };
    
    const data = {
      ids: extractIds(vehicles),
      startDate: dayjs(startDate).format("YYYY-MM-DDT00:00:00-0300"),
      dateType: typeVisualization.visualization,
    };

    const formattedDate = dayjs(startDate).format("MMM/YYYY");

    setLoading(true);

    try {
      const response = await getFleetEvolutive(
        data.ids,
        data.startDate,
        data.dateType,
        currentCustomer,
      );

      if(response.status !== 200) {
        throw new Error("Erro ao carregar dados");
      }

      const chartsData = response.data.data;

      const orderedChartsData = chartsData.sort((a, b) => objectComparisonCallback(a.order, b.order));

      const translatedChartsData = orderedChartsData.map((item) => ({
        ...item,
        evolutive: item.evolutive.map((evo) => ({
          ...evo,
          name: translationsMonths[evo.name],
        })),
      }));

      const updatedChartsData = type.value === 3 ? translatedChartsData : orderedChartsData;

      const finalChartsData = updatedChartsData.map(item => ({
        ...item,
        title: capitalizeFirstLetter(item.title)?.replace('Inicio', 'Início')
      }));

      setCharts(finalChartsData);

      handleAmplitudeEvent(
        'Evolutive Loaded',
        { 
          total_vehicle: vehicles.length,
          group: data.dateType,
          period: formattedDate
        }
      );
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [currentCustomer]);

  const fetchOperations = useCallback(async () => {
    try {
      const response = await getVehiclesByOperation(currentCustomer);

      const operations = response.data.customers;

      const orderedOperations = operations.sort(
        (a, b) => (a.name > b.name) - (a.name < b.name)
      );

      // Removes operations without valid vehicles
      const filteredOperations = orderedOperations.filter(orderedOperation => !!orderedOperation.vehicles.length);

      const operationsVehicles = filteredOperations.reduce((acc, curr) => ([...acc, ...curr.vehicles]), []);

      setVehicles(operationsVehicles);
    } catch (err) {
      toast.error(
        "Erro ao carregar a lista de veículos. Entre em contato com o suporte.",
      );
    }
  }, [currentCustomer]);

  const handleSelectDate = (date) => {
    const formattedDate = dayjs(date).format("MMM/yyyy");

    handleAmplitudeEvent('Filter Updated', { label: "Periodo", value: formattedDate });
    
    setStartDate(date);
  };

  const getDataCyTag = (title) => {
    const tags = {
      "nota geral": "h4TituloNotaGeral",
      "média consumida(km/l)": "h4TituloMediaConsumidaKmL",
      "litros consumidos": "h4TituloLitrosConsumidos",
      "início da faixa verde": "h4TituloInicioFaixaVerde",
      "piloto automático": "h4TituloPilotoAutomático",
      "aproveitamento de embalo": "h4TituloAproveitamentoEmbalo",
      "total de km rodados": "h4TituloTotalKmRodados",
      "acelerando acima do verde": "h4TituloAcelerandoAcimaVerde",
      "motor ligado parado": "h4TituloMotorLigadoParado",
      "excesso de velocidade": "h4TituloExcessoVelocidade",
    };

    return tags[title.toLowerCase()] || "h4Titulo";
  }

  const boxDataCy = (title, prefix = "div") => {
    const formattedTitle = title
      .toLowerCase()
      .normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      .replace(/\b\w/g, (match) => match.toUpperCase())
      .replace(/ /g, "");
      
    return `${prefix}${formattedTitle}Dados`;
  };

  useEffect(() => {
    handleAmplitudeEvent('Evolutive Screen Viewed');

    if(currentCustomer) {
      fetchOperations();
    }
  }, []);

  useEffect(() => {
    fetchData(selectedVehicles, startDate, typeVisualization);
  }, [selectedVehicles, startDate, typeVisualization]);

  return (
    <Aux>
      <PermissionsGate scopes={["can_view_evolutive"]}>
        <Grid container>
          <Grid container item xs={12} className={classes.header}>
            <Grid item xs={12} mb={1}>
              <TitlePage title={"Evolutivo"} />
            </Grid>
            <Grid item xl={size.grid4} lg={size.grid4} md={size.grid4} sm={size.grid4} xs={size.grid4}>
              <OperationsSelect
                vehicles={sanitizeVehicles(vehicles).filter(vehicle => !selectedVehicles.some(selectedVehicle => selectedVehicle.id === vehicle.id))}
                value={selectedVehicles}
                onChange={handleVehiclesChange}
                onOperationSelect={handleOperationSelect}
                onOperationDelete={handleOperationDelete}
                onAllSelect={() => setSelectedVehicles(sanitizeVehicles(vehicles))}
                onAllDelete={() => setSelectedVehicles([])}
              />
            </Grid>
            <Grid item xl={size.grid4} lg={size.grid4} md={size.grid4} sm={size.grid4} xs={size.grid4} data-cy="divSelectDataCorte">
              <Paper elevation={0} className={classes.paper}>
                <Grid container>
                  <Grid item xs={1} style={{ alignSelf: "center" }}>
                    <Today />
                  </Grid>
                  <Grid item xs={11}>
                    <Select
                      styles={colourStyles}
                      placeholder="Tipo de visualização"
                      value={typeVisualization}
                      options={typeVizualizationOptions}
                      onChange={(selectedOption) => {
                        handleAmplitudeEvent('Filter Updated', { label: "Agrupamento", value: selectedOption.label })
                        setTypeVisualization(selectedOption)
                      }}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xl={size.grid4} lg={size.grid4} md={size.grid4} sm={size.grid4} xs={size.grid4} data-cy="divSelectDataCalendario">
              <Paper elevation={0} className={classes.paper}>
                <Grid container>
                  <Grid item xs={1} style={{ alignSelf: "center" }}>
                    <Today />
                  </Grid>
                  <Grid item xs={11}>
                    <DatePicker
                      locale="pt-BR"
                      label
                      placeholderText="Escolha a data"
                      selected={startDate}
                      value={startDate}
                      onChange={(date) => handleSelectDate(date)}
                      dateFormat={typeVisualization.formatDate}
                      showMonthYearPicker={typeVisualization.value !== 3}
                      showYearPicker={typeVisualization.value === 3}
                      showFullMonthYearPicker
                      showFourColumnMonthYearPicker
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.wrapperContent}>
            <GridContextProvider onChange={onChange}>
              {loading ? (
                <EvolutiveSkeleton />
              ) : (
                <>
                  {charts.length ? (
                    <GridDropZone id="charts" className={classes.dropzone} boxesPerRow={size.mobile ? 1 : 3} rowHeight={300} style={{ height: "1200px" }}>
                      {charts?.map((chart, index) => (
                        <GridItem key={chart.id}>
                          <Box
                            bgcolor="white"
                            borderRadius="12px"
                            width="96%"
                            height={280}
                            padding={2}
                            display="flex"
                            flexDirection="column"
                            data-cy={boxDataCy(chart?.title)}
                          >
                            <Box
                              display="flex"
                              flexDirection="row"
                              justifyContent="space-between"
                            >
                              <Typography
                                variant="h4"
                                className={classes.chartTitle}
                                data-cy={getDataCyTag(chart?.title)}
                              >
                                {chart?.title}
                              </Typography>
                              <DragIndicator />
                            </Box>
                            <Box mt="22px" borderRadius="12px">
                              <ResponsiveContainer width="100%" height={200}>
                                <AreaChart
                                  width={500}
                                  height={400}
                                  data={charts[index].evolutive}
                                  margin={{
                                    top: 10,
                                    right: 30,
                                    left: 0,
                                    bottom: 0,
                                  }}
                                >
                                  <CartesianGrid
                                    strokeDasharray="1 3"
                                    vertical={false}
                                  />
                                  <XAxis dataKey="name" />
                                  <YAxis />
                                  <defs>
                                    <linearGradient
                                      id="splitColor"
                                      x1="1"
                                      y1="0"
                                      x2="1"
                                      y2="1"
                                    >
                                      <stop
                                        offset={"30%"}
                                        stopColor="green"
                                        stopOpacity={1}
                                      />
                                      <stop
                                        offset={"50%"}
                                        stopColor="yellow"
                                        stopOpacity={1}
                                      />
                                      <stop
                                        offset={"100%"}
                                        stopColor="red"
                                        stopOpacity={1}
                                      />
                                    </linearGradient>
                                  </defs>
                                  <Tooltip
                                    labelFormatter={(value) => (`${typeVisualization.label2}${value}`)}
                                    formatter={(value) => ([value, chart.title])}
                                  />
                                  <Area
                                    type="monotone"
                                    dataKey="value"
                                    stroke="gray"
                                    fill="url(#splitColor)"
                                  />
                                </AreaChart>
                              </ResponsiveContainer>
                            </Box>
                          </Box>
                        </GridItem>
                      ))}
                    </GridDropZone>
                  ) : (
                    <Infobox />
                  )}
                </>
              )}
            </GridContextProvider>
          </Grid>
        </Grid>
      </PermissionsGate>
    </Aux>
  );
};
