import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Grid } from '@mui/material';
import { toast } from 'react-toastify';
import dayjs from "dayjs";

// store
import { useAppSelector } from 'store';

// components
import Aux from 'hoc/auxiliar';
import BackButton from 'components/BackButton';
import Calendar from 'components/Calendar';
import ModalRecalculation from 'components/ModalRecalculation';
import Table from 'components/Table';
import Widget from 'components/Widget/Widget';
import PermissionsGate, { hasPermission } from "components/PermissionsGate";
import { makeColumns } from './tableColumns';

// helpers
import { orderVehiclesByCrescent } from 'helpers/vehicles';
import { orderArrayOfObjectsByDate } from 'helpers/functions';

// services
import { getAllRecalculations } from 'services/recalculation';
import { getVehiclesByOperation } from 'services/vehicle';

// styles
import useStyles from './styles';

const ListRecalculation = () => {
  const { currentCustomer } = useAppSelector((state) => state.global.user);
  const classes = useStyles();
  const history = useHistory();

  const dbDateTemplateFormat = "YYYY-MM-DDTHH:mm:ssZZ";
  const humanDateTemplateFormat = "DD/MM/YYYY HH:mm";
  const humanPeriodDateTemplateFormat = "DD/MM/YYYY";
  const [selectedDates, setSelectedDates] = useState({
    initialDate: dayjs().format("YYYY-MM-DDT00:00:00ZZ"),
    finalDate: dayjs().format("YYYY-MM-DDT23:59:59ZZ"),
  });

  const [openModal, setOpenModal] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [recalculations, setRecalculations] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const fetchRecalculation = async () => {
    try {
      const response = await getAllRecalculations(selectedDates.initialDate, selectedDates.finalDate);
      if (response.data.data) {
        let data = response.data.data;
        data = orderArrayOfObjectsByDate(data, 'process_date');
        const responseData = data.map((item) => ({
          ...item,
          process_date: dayjs(item.process_date).format(humanDateTemplateFormat),
          period: `${dayjs(item.start_date).format(humanPeriodDateTemplateFormat)} até ${dayjs(item.end_date).format(humanPeriodDateTemplateFormat)}`,
        }));
        setRecalculations(responseData);
      } else {
        setRecalculations([]);
      }
    } catch (err) {
      setRecalculations([]);
      toast.error(
        'Erro ao carregar lista de recálculos. Entre em contato com o suporte',
      );
    }
  };

  const fetchVehicles = useCallback(async () => {
    try {
      const response = await getVehiclesByOperation(currentCustomer);

      let orderedVehiclesByCustomer = response.data.customers.sort(
        (a, b) => (a.name > b.name) - (a.name < b.name)
      );

      orderedVehiclesByCustomer.map((option, i) => {
        const optionVehicles = option.vehicles
          .filter(item => item?.devicesHistory !== null)
          .filter(item => item?.currentDevice?.identification !== '');

        orderedVehiclesByCustomer[i].vehicles = orderVehiclesByCrescent(optionVehicles);
      });

      setVehicles(orderedVehiclesByCustomer);
    } catch (err) {
      console.log('err', err);
      setVehicles([]);
      toast.error(
        'Erro ao carregar lista de Veículos. Entre em contato com o suporte',
      );
    }
  }, [currentCustomer])

  const handleSelectDate = (initialDate, finalDate) => {
    setSelectedDates({
      initialDate: dayjs(initialDate).format(dbDateTemplateFormat),
      finalDate: dayjs(finalDate).format(dbDateTemplateFormat),
    });
  };

  const handleRecalculation = (value) => history.push(`/gofurther/vehicle/recalculation/${value}`);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    fetchRecalculation();
    fetchVehicles();
  };

  const columns = makeColumns({
    showRecalculation: handleRecalculation,
  });

  useEffect(() => {
    if (currentCustomer && hasPermission({ scopes: ['can_recalculate_vehicles'] })) {
      fetchVehicles();
    }
  }, [currentCustomer, fetchVehicles]);

  useEffect(() => {
    fetchRecalculation();
  }, [selectedDates]);

  const renderTable = useCallback(() => (
    <Table
      columns={columns}
      data={recalculations}
      tableName='list-recalculations'
      options={{ rowsPerPage }}
      setRowsPerPage={setRowsPerPage}
    />
  ), [columns, recalculations, rowsPerPage]);

  return (
    <Aux>
      <PermissionsGate scopes={['can_recalculate_vehicles']}>
        <Grid container spacing={4}>
          <ModalRecalculation
            open={openModal}
            handleClose={handleCloseModal}
            vehicles={vehicles}
          />
          <Grid item xs={12} className={classes.tableContent}>
            <Grid container justifyContent='flex-end' className={classes.toolbar}>
              <Grid item xl={8} lg={8} md={6} sm={12} xs={12}>
                <Calendar
                  selectedDates={selectedDates}
                  handleCalendar={handleSelectDate}
                />
              </Grid>
              <Grid item xl={2} lg={2} md={3} sm={12} xs={12} className={classes.btItem}>
                <BackButton className={classes.backButton} />
              </Grid>
              <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                <Button
                  fullWidth
                  variant='contained'
                  className={classes.btnNew}
                  onClick={handleOpenModal}
                >
                  Novo Recálculo
                </Button>
              </Grid>
            </Grid>
            <Widget
              disableWidgetMenu
              title='Recalcular dados dos veículos'
              subtitle='Selecione um período diferente caso queira ver recálculos mais antigos'
              subtitleClass={classes.subtitle}
            >
              {recalculations && renderTable()}
            </Widget>
          </Grid>
        </Grid>
      </PermissionsGate>
    </Aux>
  );
};

export default ListRecalculation;
