import React from 'react';
import PropTypes from 'prop-types';

import './modalTitle.css';

export const ModalTitle = ({ title = "Título", ...props }) => {
  return (
    <div
      className={`ModalTitle-container`}
      {...props}
    >
      {title}
    </div>
  );
};

ModalTitle.propTypes = {
  /**
    * Título
    */
  title: PropTypes.string,
};
