import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { useTheme } from "@mui/material";
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { useDebouncedCallback } from 'use-debounce';
import MuiDataTable from 'mui-datatables';

// config
import locale from './config/mui-datatable-locale';

// store
import { useAppSelector } from 'store';

// services
import { insertUserSelection, updateUserSelection } from 'services/userSelection';
import { handleAmplitudeEvent } from 'services/amplitude';

// components
import CustomToolbar from 'components/TableResponsive/components/CustomToolbar';
import { CustomCheckbox } from './components/CustomCheckbox';
import CustomTableViewCol from './components/CustomTableViewCol';

// hooks
import useUserTableColumns from 'hooks/useUserTableColumns';

// styles
import useStyles from './styles';

export default function Table({
  columns,
  options,
  pointer,
  tableName,
  tableHeight = "460",
  setRowsPerPage,
  toolbarNone = false,
  ...props
}) {
  const theme = useTheme();
  const { userId } = useAppSelector((state) => state.global.user);

  const getMuiTheme = () => createTheme({
    components: {
      MuiToolbar: {
        styleOverrides: {
          root: {
            display: toolbarNone ? 'none' : 'flex',
            backgroundColor: !props.tableWhite ? theme.palette.background.default : 'white !important',
            justifyContent: "center",
            minHeight: "44px !important",
            
            "& h6": {
              fontSize: "16px",
              color: theme.palette.text.primary,
            }
          },
        }
      },

      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: '#F7DF36',
            '&.Mui-checked': {
              color: '#F7DF36'
            }
          }
        }
      },

      MuiButton: {
        styleOverrides: {
          root: {
            margin: 0,
            padding: 0,
          }
        }
      },

      MuiIconButton: {
        styleOverrides: {
          root: {
            padding: "10px",
            color: theme.palette.text.primary,
          }
        }
      },

      MUIDataTable: {
        styleOverrides: {
          paper: {
            borderBottomLeftRadius: "20px",
            borderBottomRightRadius: "20px",
            overflow: "hidden",
          },
          responsiveBase: {
            backgroundColor: !props.tableWhite ? theme.palette.background.default : 'white',
            paddingBottom: props.paddingBottom ? props.paddingBottom : "16px",
            paddingRight: props.paddingRight ? props.paddingRight : "8px",
            "&::-webkit-scrollbar": {
              borderRadius: "10px",
              height: "16px",
              width: "16px",
            },
            "&::-webkit-scrollbar-track": {
              marginTop: "40px",
              WebkitBoxShadow: theme.customShadows.scrollBar,
              borderRadius: "10px",
              backgroundColor: theme.palette.scrollBarBack,
            },
            "&::-webkit-scrollbar-thumb ": {
              borderRadius: "10px",
              border: `4px solid ${theme.palette.scrollBarBack}`,
              backgroundColor: theme.palette.scrollBar,
            },
          },
          root: {
            backgroundColor: theme.palette.background.default,
          },
        }
      },

      MUIDataTableBodyRow: {
        styleOverrides: {
          root: {
            width: "100%",
            "&:nth-of-type(odd)": {
              backgroundColor: theme.palette.white
            },
            "&:nth-of-type(even)": {
              backgroundColor: theme.palette.background.background2
            }
          }
        }
      },

      MuiTableHead: {
        styleOverrides: {
          root: {
            height: "37.5px",
          },
        }
      },

      MUIDataTableHeadCell: {
        styleOverrides: {
          toolButton: {
            marginLeft: 0,
            marginRight: 0,
          },
          contentWrapper: {
            justifyContent: "center",
            width: "100%",
          }
        }
      },

      MuiTableRow: {
        styleOverrides: {
          root: {
            "&:hover": {
              cursor: pointer ? "pointer" : "default"
            },
          }
        }
      },

      MUIDataTableFooter: {
        styleOverrides: {
          root: {
            backgroundColor: !props.tableWhite ? theme.palette.background.default : 'white',
            border: "none",
            "& td": {
              border: "none",
            },
          },
        }
      },

      MUIDataTablePagination: {
        styleOverrides: {
          navContainer: {
            justifyContent: "center",
          }
        }
      },

      MuiTablePagination: {
        styleOverrides: {
          selectLabel: {
            margin: 0,
          },
          displayedRows: {
            margin: 0,
          }
        }
      }
    }
  });

  const classes = useStyles();

  const { loadingColumns, userCustomizationColumns, fetchData } = useUserTableColumns(tableName);
  const [customFields, setCustomFields] = useState([]);
  const [customColumns, setCustomColumns] = useState([]);
  const [missingColumns, setMissingColumns] = useState(false);
  const userColumns = useRef([]);
  const selectAll = useRef([]);

  const insertUserColumns = useCallback(async () => {
    const columnsNewFields = columns.map((item) => {
      const newFieldsOptions = {
        name: item.name,
        display: item.options && typeof item.options.display === 'boolean' ? item.options.display : true
      };
      if (item.options
        && typeof item.options.hideColumn === 'boolean') {
        newFieldsOptions.hideColumn = item.options.hideColumn;
      }
      return newFieldsOptions;
    });

    try {
      if (columnsNewFields.length > 0) {
        const response = await insertUserSelection({
          tableName: tableName,
          fields: columnsNewFields
        }, userId);
        
        if (response && response.data.data?.success) {
          userColumns.current = columnsNewFields;
          setCustomFields(columnsNewFields);
          setMissingColumns(false);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }, [columns, tableName]);

  const handleNewColumns = useCallback(() => {
    let newColumns = [];
    columns.forEach((item) => {
      let foundedColumn = customFields.find((i) => i.name === item.name);
      if (foundedColumn) {
        newColumns.push({
          ...item,
          options: {
            ...item.options,
            display: foundedColumn.display
          }
        })
      }
    });
    setCustomColumns(newColumns);
  }, [columns, customFields]);

  const debounced = useDebouncedCallback(async (newFields) => {
    try {
      if (newFields.length > 0) {
        await updateUserSelection({
          tableName: tableName,
          fields: newFields
        }, userId);
      }
    } catch (err) {
      console.log(err);
    } finally {
      fetchData()
    }
  }, 1000);

  const handleChangeColumns = useCallback((changedColumn, action) => {
    handleAmplitudeEvent("Table Columns Updated", { table_name: tableName, column_name: changedColumn });
    let newFields = [];
    if (!changedColumn) {
      newFields = userColumns.current.map((item) => {
        return {
          ...item,
          name: item.name,
          display: !item.hideColumn ? selectAll.current : false
        }
      });
    } else {
      newFields = userColumns.current.map((item) => {
        return item.name !== changedColumn ? item : {
          ...item,
          display: !item.hideColumn ? action === 'add' : false
        }
      });
    }
    userColumns.current = newFields;
    debounced(newFields);
  }, [debounced]);

  const memorizedOptions = useMemo(() => ({
    ...locale,
    selectableRows: "none",
    enableNestedDataAccess: ".",
    elevation: 0,
    fixedHeader: true,
    tableBodyMaxHeight: "535px",
    download: false,
    customToolbar: () => <CustomToolbar download={props?.download ?? {}} email={props?.email || false} tableName={tableName} />,
    onColumnViewChange: handleChangeColumns,
    onChangeRowsPerPage: (rowsPerPage) => setRowsPerPage && setRowsPerPage(rowsPerPage),
    ...options,
    rowsPerPage: options?.rowsPerPage ?? 15,
  }), [handleChangeColumns, options, props.download, setRowsPerPage]);

  const handleSelectAll = (checked) => {
    selectAll.current = checked;
  };

  const checkUserColumns = useCallback(() => {
    const { fields, success } = userCustomizationColumns.data;
    // Check for new columns
    let notFound = false;
    columns.forEach(field => {
      if (fields && !fields.find(o => o.name === field.name)) {
        notFound = true;
      }
    });
    setMissingColumns(notFound);

    if (!success) {
      setCustomColumns(columns);
      return;
    }
    if (!fields) {
      insertUserColumns();
    } else {
      userColumns.current = fields;
      setCustomFields(fields);
    }
  }, [columns, insertUserColumns, userCustomizationColumns]);

  useEffect(() => {
    if (!loadingColumns) {
      checkUserColumns();
    }
  }, [checkUserColumns, loadingColumns]);

  useEffect(() => {
    if (customFields.length > 0) {
      handleNewColumns();
    }
  }, [customFields, handleNewColumns]);

  useEffect(() => {
    if (missingColumns) {
      insertUserColumns();
    }
  }, [insertUserColumns, missingColumns]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={getMuiTheme()}>
        {customColumns.length > 0 && (
          <MuiDataTable
            {...props}
            className={classes.root}
            columns={customColumns}
            options={memorizedOptions}
            components={{
              TableViewCol: (propsTableView) => (
                <CustomTableViewCol
                  {...propsTableView}
                  handleSelectAll={handleSelectAll}
                />
              ),
              Checkbox: CustomCheckbox,
            }}
          />
        )}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}