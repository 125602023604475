export default class Indicator {
  constructor({ id, lowerLimit, upperLimit, weight, requirementLevel, penalizing }) {
    this.indicator_id = id;
    this.lower_limit = +lowerLimit;
    this.upper_limit = +upperLimit;
    this.weight = +weight;
    this.requirement_level = requirementLevel;
    this.penalizing = penalizing;
  }
}
