import { Fragment, useCallback } from 'react';
import { Box, FormHelperText, Grid, Paper, TextField, Typography, Autocomplete } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { useWatch, useFormContext, Controller } from 'react-hook-form';
import { useTheme } from '@mui/styles';
import { styled } from "@mui/material/styles";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

// context
import { useInputsProfile } from 'context/useInputsProfile';

// services
import { createModel } from 'services/vehicle';

// components
import Aux from 'hoc/auxiliar';
import { Input } from 'components/react-hook-form';
import FormVehicleProfileSkeleton from "components/Skeletons/FormVehicleProfileSkeleton";


import useStyles from './styles';
import 'react-datepicker/dist/react-datepicker.css';

const StyledAutocomplete = styled(Autocomplete)(() => ({
  '.MuiInputBase-root': {
    borderRadius: 20,
  }
}));

const VehicleProfileForm = () => {
  const theme = useTheme();
  const classes = useStyles();

  const { models, brands, inputs, fetchModelsList, setModels } = useInputsProfile();

  const { control, getValues, setValue, resetField } = useFormContext();

  const filter = createFilterOptions();

  const confirmAlertConfig = (title, text, confirmBt) => ({
    title: title,
    text: text,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: theme.palette.primary.main,
    cancelButtonColor: theme.palette.error.main,
    confirmButtonText: confirmBt,
  });

  const handleCreateModel = useCallback(async (newModel) => {
    try {
      const response = await createModel(newModel);

      if (response.status === 200) {
        toast.success('Modelo criado com sucesso!');

        const newModelId = response.data.data.id;
        
        await fetchModelsList(newModel.brandId);

        setValue('modelId', { value: newModelId, label: newModel.name });
      } else {
        throw new Error();
      }
    } catch (error) {
      toast.error('Erro ao criar modelo. Verifique com o suporte');
    }
  }, [createModel, toast, fetchModelsList]);

  if (!brands) {
    return <FormVehicleProfileSkeleton />;
  }

  return (
    <Aux>
      <Paper elevation={2} className={classes.container}>
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid container>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Typography className={classes.title}>Vínculo marca/perfil</Typography>
              </Grid>
              <Grid container>
                <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                  <Box sx={{ m: 2 }}>
                    <Controller control={control} name="brandId" render={({ field: { ref, value = '', onChange, ...rest }, fieldState: { error } }) => (
                      <>
                        <StyledAutocomplete
                          {...rest}
                          options={brands}
                          renderInput={(params) => <TextField {...params} label="Marca *" />}
                          value={value}
                          onChange={(_, value) => {
                            onChange(value);

                            if(value) {
                              const selectedBrandId = value?.value;
  
                              fetchModelsList(selectedBrandId);
                              resetField('modelId');
                            } else {
                              setModels([]);
                            }
                          }}
                          isOptionEqualToValue={(option, value) => value ? option.label === value?.label || value === '' : false}
                          getOptionLabel={(option) => option?.label ?? ''}
                          renderOption={({ key, ...props }, option) => (
                            <Box key={option.value} component="li" {...props}>
                              <Typography>{option.label}</Typography>
                            </Box>
                          )}
                          disablePortal
                        />
                        <FormHelperText className={classes.formHelperText} error={true}>
                          {error?.message}
                        </FormHelperText>
                      </>
                    )}
                    />
                  </Box>
                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                  <Box sx={{ m: 2 }}>
                    <Controller control={control} name="modelId" render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
                    <>
                      <StyledAutocomplete
                        {...rest}
                        options={models}
                        onChange={(_, value) => {
                          onChange(value);

                          const isNewModel = value?.label.includes('Adicionar');

                          if(isNewModel) {
                            const stringsBetweenQuotes = value.label.match(/"([^"]+)"/g);
                            const newModelLabel = stringsBetweenQuotes[0].replace(/"([^"]+)"/g, (match, word) => word);

                            const selectedBrandId = getValues('brandId').value;

                            const alertConfig = confirmAlertConfig(
                              '',
                              'Confirma a criação de um novo Modelo?',
                              'Sim, inserir!',
                            );

                            Swal.fire(alertConfig).then((result) => {
                              if (result.isConfirmed) {
                                handleCreateModel({ brandId: selectedBrandId, name: newModelLabel });
                              }
                            });
                          }
                        }}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          const { inputValue } = params;

                          const isExisting = options.some((option) => inputValue === option.label);

                          if(inputValue !== '' && !isExisting) {
                            filtered.push({
                              value: 0,
                              label: `Adicionar "${inputValue}"`
                            });
                          }

                          return filtered;
                        }}
                        noOptionsText="Sem dados"
                        isOptionEqualToValue={(option, value) => value ? value === "" || option.value === value?.value : false}
                        getOptionLabel={(option) => option?.label ?? ""}
                        renderOption={({ key, ...props }, option) => (
                          <Box key={option.value} component="li" {...props}>
                            <Typography>{option.label}</Typography>
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Perfil *"
                          />
                        )}
                        disabled={!models.length}
                        // clearOnEscape
                        // clearOnBlur
                        handleHomeEndKeys
                        freeSolo
                      />
                      <FormHelperText className={classes.formHelperText} error={true}>
                        {error?.message}
                      </FormHelperText>
                    </>
                    )} />
                  </Box>
                </Grid>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Box sx={{ mt: 2 }}>
                  <Typography className={classes.title}>Faixas de RPM</Typography>
                </Box>
              </Grid>
              <Box sx={{ m: 2 }}>
                <Grid container>
                  {inputs.map((input, index) => (
                    <Fragment key={input.name}>
                      {!!index && <ArrowRightAltIcon className={classes.iconRight} />}
                      <RpmInput input={input} />
                    </Fragment>
                  ))}
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Box sx={{ mt: 2 }}>
              <Typography className={classes.explanation}>
                *Os campos marcados com o asterisco (*) são aqueles que precisam obrigatoriamente ser preenchidos.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Aux>
  );
};

const RpmInput = ({ input }) => {
  const classes = useStyles();

  const { control, resetField, formState } = useFormContext();

  const { inputs } = useInputsProfile();

  const inputIndex = inputs.findIndex(item => item.name === input.name);
  const previousInputName = inputs[inputIndex - 1]?.name;
  const previousInput = inputs[inputIndex - 1] ?? input;

  const previousInputValue = useWatch({ control, name: previousInput.name });
  const previousInputError = Object.keys(formState.errors).includes(previousInputName);

  const isDisabled = !previousInputValue && previousInputValue !== 0 || !inputIndex || previousInputError;

  const handleRpmInputChange = useCallback((event) => {
    const { name, value } = event.currentTarget;

    if(!value) {
      const currentIndex = inputs.findIndex(input => input.name === name);

      if(currentIndex !== -1) {
        const nextInputs = inputs.slice(currentIndex + 1);

        nextInputs.forEach(nextInput => {
          resetField(nextInput.name);
        });
      }
    }
  }, [inputs]);

  return (
    <Grid item xl={1} lg={1} md={1} sm={3} xs={12} className={classes.item}>
      <Typography className={classes.subtitle}>{input.title} *</Typography>
      <Controller control={control} name={input.name} render={({ field: { ref, onChange, ...rest }, fieldState: { error } }) => (
        <>
          <Input
            {...rest}
            variant="outlined"
            type="number"
            className={classes.rpmInput}
            error={!!error}
            disabled={isDisabled}
            onChange={(event) => {
              handleRpmInputChange(event);
              onChange(event);
            }}
          />
          {!!error && (
            <FormHelperText className={classes.formHelperText} error={true}>
              {error?.message}
            </FormHelperText>
          )}
        </>
      )}
      />
    </Grid>
  );
}

export default VehicleProfileForm;
