import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Divider, Grid, Typography, Paper } from "@mui/material";
import { toast } from "react-toastify";

// components
import Aux from "hoc/auxiliar";
import BackButton from "components/BackButton";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";

// helpers
import { indicatorsOptions, penalizingOptions } from "domain/selectOptions";

// services
import { getOperationTypeById } from "services/operationType";

// style
import useStyles from "./styles";
import "react-datepicker/dist/react-datepicker.css";

const ViewRewardGroup = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [ rewardGroup, setRewardGroup ] = useState(null);
 
  const fetchOperationById = async () => {
    try {
      const response = await getOperationTypeById(id);
      if (response?.code !== 200) {
        throw new Error();
      }

      const { operationTypes } = response.data;

      let customIndicators = {
        indicators: [],
        penalizing: []
      };
      let defaultIndicators = {
        high: {
          indicators: [],
          penalizing: []
        },
        medium: {
          indicators: [],
          penalizing: []
        },
        low: {
          indicators: [],
          penalizing: []
        },

      }
      if(operationTypes.type === 2) {
        operationTypes.indicators.map(item => {
          if (!item.penalizing) {
            customIndicators.indicators.push({
              label: indicatorsOptions.find(i => i.value === item.indicator_id).label,
              ...item
            })
          } else {
            customIndicators.penalizing.push({
              label: penalizingOptions.find(i => i.value === item.indicator_id).label,
              ...item
            })
          }
        })
      } else {
        operationTypes.indicators.map(item => {
          if (!item.penalizing) {
            defaultIndicators[item.requirement_level].indicators.push({
              label: indicatorsOptions.find(i => i.value === item.indicator_id).label,
              ...item
            })
          } else {
            defaultIndicators[item.requirement_level].penalizing.push({
              label: penalizingOptions.find(i => i.value === item.indicator_id).label,
              ...item
            })
          }
        })
      }

      setRewardGroup({
        operationType: operationTypes.type,
        type: operationTypes.type === 1 ? 'Padrão' : 'Personalizado',
        name: operationTypes.name,
        penalty: operationTypes.penalty_score,
        custom: customIndicators,
        default: defaultIndicators
      })
    } catch (error) {
      toast.error(
        "Erro ao buscar dados do grupo de bonificação. Verifique com o suporte",
      );
    };
  };

  useEffect(() => {
    if (hasPermission({ scopes: ['can_view_rewardgroup_without_edit'] })) {
      fetchOperationById();
    }
  }, []);

  const Indicator = ({ind}) => {
    return (
      <Grid item xs={12}>
        <Box sx={{ m: 2 }}>
          <Grid container>
            <Grid item xs={3}><Typography><strong>Indicador: </strong> {ind?.label || ' - '}</Typography></Grid>
            <Grid item xs={3}><Typography><strong>Percentagem aceitável: </strong> {`${ind?.lower_limit} %` || ' - '}</Typography></Grid>
            <Grid item xs={3}><Typography><strong>Percentagem ideal: </strong> {`${ind?.upper_limit} %` || ' - '}</Typography></Grid>
            <Grid item xs={3}><Typography><strong>Peso: </strong> {`${ind?.weight}` || ' - '}</Typography></Grid>
          </Grid>
        </Box>
        <Divider />
      </Grid>
    )
  };

  const Penalizer = ({ind}) => {
    return (
      <Grid item xs={12}>
        <Box sx={{ m: 2 }}>
          <Grid container>
            <Grid item xs={3}><Typography><strong>Penalizador: </strong> {ind?.label || ' - '}</Typography></Grid>
            <Grid item xs={3}><Typography><strong>Percentagem aceitável: </strong> {`${ind?.lower_limit} %` || ' - '}</Typography></Grid>
            <Grid item xs={3}><Typography><strong>Percentagem ideal: </strong> {`${ind?.upper_limit} %` || ' - '}</Typography></Grid>
            <Grid item xs={3}><Typography><strong>Peso: </strong> {`${ind?.weight}` || ' - '}</Typography></Grid>
          </Grid>
        </Box>
        <Divider />
      </Grid>
      );
  };

  return (
    <Aux>
      <PermissionsGate scopes={['can_view_rewardgroup_without_edit']}>
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box>
              <Grid item xl={12} xs={12}>
                <Typography variant="h1" className={classes.titleMain}>Visualizar grupo de bonificação</Typography>
                <Paper elevation={0} className={classes.container}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box sx={{ m: 2 }}>
                        <Typography><strong>Nome: </strong> {rewardGroup?.name || ' - '}</Typography><br/>
                        <Typography><strong>Tipo: </strong> {rewardGroup?.type || ' - '}</Typography><br />
                        <Typography><strong>Nota a ser retirada do penalizador: </strong> {rewardGroup?.penalty || ' - '}</Typography>
                      </Box>
                      <Divider />
                    </Grid>
                    {/* Customizado */}
                    {rewardGroup && rewardGroup?.operationType === 2 && (
                      <Grid container>
                        <Grid item container xs={12}>
                          <Grid item xs={12}>
                            <Typography className={classes.title}>Indicador</Typography>
                          </Grid>
                          {rewardGroup.custom?.indicators && rewardGroup.custom?.indicators.map((ind, i) => (
                            <Indicator ind={ind} key={`indicator_${i}`} />
                          ))}
                        </Grid>
                        <Grid item container xs={12}>
                          <Grid item xs={12}>
                            <Typography className={classes.title}>Penalizador</Typography>
                          </Grid>
                          {rewardGroup.custom?.penalizing && rewardGroup.custom?.penalizing.map((ind, i) => (
                            <Penalizer ind={ind} key={`penalizer_${i}`} />
                          ))}
                        </Grid>
                      </Grid>
                    )}
                    {/* Padrão */}
                    {rewardGroup && rewardGroup?.operationType === 1 && (
                      <Grid container>
                        <Typography variant="h2" className={classes.titleMain}>Exigência alta</Typography>
                        <Grid item container xs={12}>
                          <Grid item xs={12}>
                            <Typography className={classes.title}>Indicador</Typography>
                          </Grid>
                          {rewardGroup.default?.high?.indicators && rewardGroup.default?.high?.indicators.map((ind, i) => (
                            <Indicator ind={ind} key={`indicator_high_${i}`} />
                          ))}
                        </Grid>
                        <Grid item container xs={12}>
                          <Grid item xs={12}>
                            <Typography className={classes.title}>Penalizador</Typography>
                          </Grid>
                          {rewardGroup.default?.high?.penalizing && rewardGroup.default?.high?.penalizing.map((ind, i) => (
                            <Penalizer ind={ind} key={`penalizer_high_${i}`} />
                          ))}
                        </Grid>
                        <Typography variant="h2" className={classes.titleMain}>Exigência média</Typography>
                        <Grid item container xs={12}>
                          <Grid item xs={12}>
                            <Typography className={classes.title}>Indicador</Typography>
                          </Grid>
                          {rewardGroup.default?.medium?.indicators && rewardGroup.default?.medium?.indicators.map((ind, i) => (
                            <Indicator ind={ind} key={`indicator_medium_${i}`} />
                          ))}
                        </Grid>
                        <Grid item container xs={12}>
                          <Grid item xs={12}>
                            <Typography className={classes.title}>Penalizador</Typography>
                          </Grid>
                          {rewardGroup.default?.medium?.penalizing && rewardGroup.default?.medium?.penalizing.map((ind, i) => (
                            <Penalizer ind={ind} key={`penalizer_medium_${i}`} />
                          ))}
                        </Grid>
                        <Typography variant="h2" className={classes.titleMain}>Exigência baixa</Typography>
                        <Grid item container xs={12}>
                          <Grid item xs={12}>
                            <Typography className={classes.title}>Indicador</Typography>
                          </Grid>
                          {rewardGroup.default?.low?.indicators && rewardGroup.default?.low?.indicators.map((ind, i) => (
                            <Indicator ind={ind} key={`indicator_low_${i}`} />
                          ))}
                        </Grid>
                        <Grid item container xs={12}>
                          <Grid item xs={12}>
                            <Typography className={classes.title}>Penalizador</Typography>
                          </Grid>
                          {rewardGroup.default?.low?.penalizing && rewardGroup.default?.low?.penalizing.map((ind, i) => (
                            <Penalizer ind={ind} key={`penalizer_low_${i}`} />
                          ))}
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Paper>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Grid container justifyContent="flex-end" spacing={2}>
                    <Grid item xs={12} md={3} sm={4} lg={2}>
                      <Box sx={{ m: 2 }}>
                        <BackButton />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </PermissionsGate>
    </Aux>
  );
};

export default ViewRewardGroup;
