export const titleTranslations = {
  communicating: "Comunicação",
  gps: "GPS",
  rpm: "RPM",
  time: "Hora do GPS",
  canFuelLevel: "Nível de combustível CAN",
  totalFuelConsumption: "Consumo total de combustível",
  totalFuelConsumptionHighResolution: "Consumo de combustível alta resolução",
  totalMileage: "Odômetro"
};

export const descriptionTranslations = {
  "Device is communicating": "Dispositivo está se comunicando",
  "GPS is normal": "GPS está normal",
  "RPM is normal": "RPM está normal",
  "Gps time is normal": "Hora do GPS está normal",
  "Can Fuel Level is normal": "Nível de combustível CAN está normal",
  "Total Fuel Consumption is normal": "Consumo total de combustível está normal",
  "Total Fuel Consumption High Resolution is normal": "Consumo de combustível alta resolução está normal",
  "Odometer is normal": "Odômetro está normal",
  "Device is not communicating": "Dispositivo não está se comunicando",
  "GPS is not normal": "GPS não está normal",
  "RPM is not normal": "RPM não está normal",
  "Gps time is not  normal": "Hora do GPS não está normal",
  "Can Fuel Level is not normal": "Nível de combustível CAN não está normal",
  "Total Fuel Consumption is not normal": "Consumo total de combustível não está normal",
  "Total Fuel Consumption High Resolution is not normal": "Consumo de combustível alta resolução não está normal",
  "Odometer is not normal": "Odômetro não está normal",
  "Motor desligado": "Motor desligado",
  "No communication in the last 30 minutes": "Sem comunicação nos últimos 30 minutos",
  "GPS status is not valid": "Status do GPS não é válido",
  "RPM values are not within the expected range": "Valores de RPM não estão dentro do intervalo esperado",
  "Gps time is over than 12 hours past": "Hora do GPS está mais de 12 horas atrasada",
  "Can Fuel Level is not present": "Nível de combustível CAN não está presente",
  "Total Fuel Consumption is not present": "Consumo total de combustível não está presente",
  "Total Fuel Consumption High Resolution is not present": "Consumo de combustível alta resolução não está presente",
  "Odometer is not present": "Odômetro não está presente"
};

export const translateTitle = (key) => titleTranslations[key] || key;
export const translateDescription = (desc) => descriptionTranslations[desc] || desc;
