import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  container: {
    borderRadius: 20,
    paddingLeft: 16,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    backgroundColor: 'white',
    boxShadow: '3px 5px 4px #DADADA'
  },
  chip: {
    display: 'flex',
    alignItems: 'center',
    gap: 6,
  },
  chipAmount: {
    fontWeight: 500,
  },
  operation: {
    border: 'none',
    padding: '6px 16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    }
  },
  operationName: {
    borderRadius: 6,
    padding: '3px 6px',
    backgroundColor: theme.palette.gray.extraLight,
    fontSize: 12,    
    fontWeight: '500',
  },
  operationVehicles: {
    borderRadius: 6,
    padding: '3px 6px',
    backgroundColor: theme.palette.gray.extraLight,
    fontSize: 12,    
    fontWeight: '500',
  },
}));
