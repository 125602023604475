import axios from "axios";
import api, { apiWS } from "./api";

export const getMapDateLocation = async (id) => {
  try {
    const response = await apiWS.get(`/web/v2/location/vehicle/route/day?vehicle_id=${id.toString()}`,);
    const responseData = response?.data?.data ?? [];
    return responseData;
  } catch (err) {
    console.log('err', err)
    throw err;
  }
};

export const getVehicleStatus = async (id) => {
  try {
    const response = await apiWS.get(`/web/v2/location/vehicle/status?vehicle_id=${id.toString()}`,);
    const responseData = response?.data?.data ?? [];
    return responseData;
  } catch (err) {
    console.log('err', err)
    throw err;
  }
};

export const getRouteDay = async (vehicleId) => {
  try {
    const response = await apiWS.get(`/api/v1/location/vehicles/route/day?vehicle_id=${vehicleId.toString()}`)
    return response;
  } catch (err) {
    throw err;
  }
}