import React from "react";
import BackdropMaterial from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import useStyles from "./styles";

export default function Backdrop(props) {
  const classes = useStyles();

  return (
    <BackdropMaterial className={classes.backdrop} open={props.open}>
      <CircularProgress color="inherit" />
    </BackdropMaterial>
  );
}
