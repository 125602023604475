import React from "react";
import Grid from "@mui/material/Grid";
import FormHelperText from "@mui/material/FormHelperText";
import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import { useFormState, useFormContext } from "react-hook-form";

// config
import { makeColumns } from "../config/makeColumns";

// components
import RequirementLevel from "../requirementLevel";
import { ptBR } from "components/Table/config/locale";

const RequirementContainer = (props) => {
  const { control } = useFormContext();
  const { errors } = useFormState({ control });
  
  return (
    <Grid container spacing={2}>
      <RequirementLevel
        penalizing={props.penalizing}
        title={props.title}
        name={props.name}
        controllArrayName={props.controllArrayName}
        requirementLevelField={props.requirementLevelField}
      />
      <Grid item xl={8} lg={8} md={8} sm={7} xs={12}>
        <Grid container>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <StyledDataGrid
              localeText={ptBR}
              style={{ minHeight: "380px" }}
              rows={props.dataTable ?? []}
              columns={makeColumns(props.onDelete)}
              pageSize={6}
              disableSelectionOnClick
            />
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Grid item xl={12}>
              <FormHelperText
                style={{ marginLeft: 15, marginBottom: 20 }}
                error={true}
              >
                {errors[props.controllArrayName]?.message}
              </FormHelperText>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const StyledDataGrid = styled(DataGrid)(() => ({
  backgroundColor: 'white',
  '& .MuiDataGrid-row--borderBottom': {
    backgroundColor: 'white !important',
  },
  '& .MuiDataGrid-overlay': {
    backgroundColor: 'white',
  },
  '& .MuiTablePagination-selectLabel': {
    margin: 0,
  },
  '& .MuiTablePagination-displayedRows': {
    margin: 0,
  }
}));

export default RequirementContainer;
