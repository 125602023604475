import { Grid, Typography } from "@mui/material";
import React from "react";
import { useTheme } from "@mui/styles";

export function TitlePage({ title }) {
  const theme = useTheme();
  return (
    <Grid>
      <Typography
        variant="h2"
        data-cy="h2Titulo"
        style={{ 
          fontWeight: 600, 
          cursor: "default", 
          fontSize: "24px", 
          color: theme.palette.text.title2 
        }}
      >
        {title}
      </Typography>
    </Grid>
  );
}
