import React, { useState, useEffect } from "react";
import { Grid, Typography, Box, FormHelperText, Paper, Autocomplete, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useFormContext, Controller } from "react-hook-form";

// helpers
import { phoneMask } from "helpers/masks";
import { makeSelectOptions } from "helpers/makeSelectOptions";
import { orderByName } from "helpers/functions";
import { getRoleDeviceStatus, statusOptions } from "helpers/deviceStatus";

// domain
import { brandListOptions } from "domain/selectOptions";

// components
import Aux from "hoc/auxiliar";
import { Input } from "components/react-hook-form";
import FormDeviceSkeleton from "components/Skeletons/FormDeviceSkeleton";

import useStyles from "./styles";

const StyledAutocomplete = styled(Autocomplete)(() => ({
  maxWidth: '540px',
  '.MuiInputBase-root': {
    borderRadius: 20,
  }
}));

const DeviceForm = (props) => {
  const { status } = props;

  const classes = useStyles();

  const { control, setValue } = useFormContext();

  const [listBrands, setListBrands] = useState(null);

  const fetchBrandsList = () => {
    const options = makeSelectOptions({
      data: brandListOptions,
      keyLabel: "name",
      keyValue: "id",
    });

    const brandsOptions = orderByName(options);

    setListBrands(brandsOptions);
  };

  const getSelectDisabled = () => {
    if (props.isEdit && status === 5) return true;
  };

  useEffect(() => {
    fetchBrandsList();
  }, []);

  if (!listBrands) return <FormDeviceSkeleton />;

  return (
    <Aux>
      <Paper elevation={0} className={classes.container}>
        <Typography className={classes.title}>Dados gerais:</Typography>
        <Grid container spacing={2}>
          <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
            <Box sx={{ m: 2 }}>
              <Controller control={control} name="brand" render={({ field: { value = '', onChange, ...rest }, fieldState: { error } }) => (
                <>
                  <StyledAutocomplete
                    {...rest}
                    options={listBrands}
                    renderInput={(params) => <TextField {...params} label="Marca *" />}
                    value={value}
                    onChange={(_, value) => onChange(value)}
                    isOptionEqualToValue={(option, value) => value ? option.label === value?.label || value === '' : false}
                    getOptionLabel={(option) => option?.label ?? ''}
                    renderOption={({ key, ...props }, option) => (
                      <Box key={option.value} component="li" {...props}>
                        <Typography>{option.label}</Typography>
                      </Box>
                    )}
                    disablePortal
                    noOptionsText="Nenhuma marca cadastrada"
                  />
                  <FormHelperText className={classes.formHelperText} error={true}>
                    {error?.message}
                  </FormHelperText>
                </>
              )} />
            </Box>
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
            <Box sx={{ m: 2 }}>
              <Controller control={control} name="identification" render={({ field, fieldState: { error } }) => (
                <Input
                  {...field}
                  variant="outlined"
                  fullWidth
                  label="ID*"
                  helperText={error?.message}
                  error={!!error}
                />
              )} />
            </Box>
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
            <Box sx={{ m: 2 }}>
              <Controller control={control} name="simcard" render={({ field, fieldState: { error } }) => (
                <Input
                  {...field}
                  variant="outlined"
                  fullWidth
                  label="SIM card*"
                  helperText={error?.message}
                  error={!!error}
                  onChange={(event) => setValue("simcard", phoneMask(event.target.value), { shouldValidate: true })}
                />
              )} />
            </Box>
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
            <Box sx={{ m: 2 }}>
              <Controller control={control} name="status" render={({ field: { value = '', onChange, ...rest }, fieldState: { error } }) => (
                <>
                  <StyledAutocomplete
                    {...rest}
                    options={props.isEdit ? getRoleDeviceStatus(status) : statusOptions.register}
                    renderInput={(params) => <TextField {...params} label="Selecione o status *" />}
                    value={value}
                    onChange={(_, value) => onChange(value)}
                    isOptionEqualToValue={(option, value) => value ? option.label === value?.label || value === '' : false}
                    getOptionLabel={(option) => option?.label ?? ''}
                    renderOption={({ key, ...props }, option) => (
                      <Box key={option.value} component="li" {...props}>
                        <Typography>{option.label}</Typography>
                      </Box>
                    )}
                    disablePortal
                    noOptionsText="Nenhum status cadastrado"
                    disabled={getSelectDisabled()}
                  />
                  <FormHelperText className={classes.formHelperText} error={true}>
                    {error?.message}
                  </FormHelperText>
                </>
              )} />
            </Box>
          </Grid>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Box sx={{ mt: 4 }}>
            <Typography className={classes.explanation}>*Os campos marcados com o asterisco (*) são aqueles que precisam obrigatoriamente ser preenchidos.</Typography>
          </Box>
        </Grid>
      </Paper>
    </Aux>
  );
};

export default DeviceForm;
