import React from 'react';
import Lottie from 'lottie-react';

function LottieComponent({ animationData, width = 40, height = 40, loop = true, autoplay = true }) {
  const defaultOptions = {
    loop,
    autoplay,
    animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  
  return (
    <div>
      <Lottie
        {...defaultOptions}
        style={{
          maxWidth: width,
          maxHeight: height
        }}
        controls={false}
      />
    </div>
  );
}

export default LottieComponent;
