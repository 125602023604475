export function NumberFormatBR(value){
  const numberBR = value?.toLocaleString("pt-br") ?? ''
  return numberBR
}

export function NumberFormatBRCurrency(value){
  const numberBR = value?.toLocaleString("pt-br", {
    style: "currency",
    currency: "BRL",
  }) ?? ''
  return numberBR
}