import * as yup from "yup";

export default yup.object().shape({
  label: yup.string().required("Campo obrigatório"),
  permissions: yup.array().test({
    name: 'min',
    exclusive: true,
    message: 'Selecione ao menos uma permissão',
    test: (items) => {
      return items.length > 0;
    },
  }),
});
