import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";
import { Grid } from "@mui/material";
import HistoryNotificationCard from "./history-notification-card";

export default function HistoryModal({
  id,
  histories,
  setOpen,
  open,
}) {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ zIndex: 9999 }}
        PaperProps={{
          style: { borderRadius: 16 }
        }}
        maxWidth={'md'}
        onClose={handleClose}
      >
        <DialogContent style={{ backgroundColor: '#F6F7F9' }}>
          <Grid style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', flexWrap: 'nowrap' }}>
            <DialogContentText id="alert-dialog-description" style={{
              color: '#181814',
              fontSize: '16px', fontFamily: 'Roboto', lineHeight: '140%', fontWeight: 700
            }}>
              Histórico de alterações:
            </DialogContentText>
            <Icon path={mdiClose} size={1} onClick={() => setOpen(false)} style={{ padding: 4, cursor: 'pointer' }} />
          </Grid>
          <Grid container spacing={2} style={{ marginTop: 16, marginBottom: 16 }}>
          </Grid>
          <Grid container>
            {histories.length && histories.map((history) => (
              <HistoryNotificationCard
                key={history.id}
                type={history.type}
                status={history.status}
                id={history.id}
                statusType={history.status_type}
                platform={history.platforms}
                createdAt={history.publish_at}
                updatedAt={history.updated_at}
                createdBy={history.user}
                title={history.title}
                subtitle={history.subtitle}
                body={history.body}
                username={history.username}
              />
            ))}
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
