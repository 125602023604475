import React from "react";
import Button from "@mui/material/Button";

// components
import { hasPermission } from 'components/PermissionsGate';

export const makeColumns = (handleDelete) => [
  {
    field: "conduction",
    headerName: "Indicador",
    headerAlign: "center",
    description: "This column has a value getter and is not sortable.",
    width: 200,
    valueGetter: (params) => params?.label,
  },
  {
    field: "acceptablePercentage",
    headerName: "Percentagem aceitável  ",
    type: "number",
    width: 230,
    align: "center",
    headerAlign: "center",
    editable: true,
  },
  {
    field: "idealPercentage",
    headerName: "Percentagem ideal",
    width: 230,
    align: "center",
    headerAlign: "center",
    editable: true,
  },
  {
    field: "weight",
    headerName: "Peso do indicador",
    description: "This column has a value getter and is not sortable.",
    type: "number",
    align: "center",
    headerAlign: "center",
    width: 230,
    editable: true,
  },
  {
    field: "id",
    headerName: "Remover",
    align: "center",
    headerAlign: "center",
    width: 200,
    renderCell: (params) => (
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={handleDelete.bind(null, params.value)}
        disabled={hasPermission({ scopes: ['can_view_operations_without_edit'] })}
        style={{ color: 'white' }}
      >
        Remover
      </Button>
    ),
  },
];
