import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  textField: {
    '& input': {
      borderRadius: '20px !important',
    },
    [`& fieldset`]: {
      borderRadius: 20,
    },
  },
  borderBottom: {
    borderBottom: "1px solid #F0F0F0",
  }
}));
