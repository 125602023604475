import * as yup from "yup";

export default yup.object().shape({
  name: yup
    .string()
    .max(50, "Máximo de 50 caracteres excedido.")
    .required("Campo obrigatório"),
  targetMileage: yup
    .string()
    .max(14, "Kilometragem máxima excedida.")
    .required("Campo obrigatório"),
  maxAmount: yup
    .string()
    .when('rewardFormat', {
      is: 'range',
      then: yup
        .string()
        .notRequired(),
      otherwise: yup
        .string()
        .required("Campo obrigatório")
        .max(14, "Limite máximo excedido."),
    }),
  operationType: yup
    .number()
    .typeError('Campo obrigatório')
    .nullable()
    .required("Campo obrigatório"),
  requirementLevel: yup
    .number()
    .typeError('Campo obrigatório')
    .nullable()
    .required("Campo obrigatório"),
  enabledExtraReward: yup
    .bool(),
  extraTargetMileage: yup
    .number()
    .when('enabledExtraReward', {
      is: true,
      then: yup.number().typeError('Campo obrigatório').required('Campo obrigatório'),
      otherwise: yup.number().typeError('Campo obrigatório')
    }),
  extraRewardMileage: yup
    .number()
    .when('enabledExtraReward', {
      is: true,
      then: yup.number().typeError('Campo obrigatório').required('Campo obrigatório'),
      otherwise: yup.number().typeError('Campo obrigatório')
    }),
  extraIntervalMileage: yup
    .number()
    .when('enabledExtraReward', {
      is: true,
      then: yup.number().typeError('Campo obrigatório').required('Campo obrigatório'),
      otherwise: yup.number().typeError('Campo obrigatório')
    }),
  considerScore: yup
    .bool(),
});
