import api from "./api";
import { convertStringDate } from "helpers/functions";

const itensUrl = (itens) => {
  let partialUrl = "";
  if (itens) {
    for (let i = 0; i < itens.length; i++) {
      if (i + 1 === itens.length) {
        partialUrl += `${itens[i]}`;
      } else {
        partialUrl += `${itens[i]},`;
      }
    }
  }
  return partialUrl;
};

export const getAllRecalculations = async (startDate, endDate, customerId) => {
  try {
    const convertedDates = convertStringDate(startDate, endDate);
    const response = await api.get(`/web/v2/performance/recalc/drivers?customerID=${customerId}&endDate=${convertedDates.endDate}&startDate=${convertedDates.startDate}`);
    return response;
  } catch (err) {
    return err;
  }
};

export const getRecalculationById = async (recalcIds, customerId) => {
  try {
    if (!Array.isArray(recalcIds)) {
      recalcIds = [recalcIds];
    }
    const response = await api.get(`/web/v2/performance/recalc/drivers?recalcID=${itensUrl(recalcIds)}&customerID=${customerId}`);
    return response;
  } catch (err) {
    return err;
  }
};

export const createRecalculation = async (driverList, email, startDate, endDate, customerId) => {
  try {
    const convertedDates = convertStringDate(startDate, endDate);
    const response = await api.post(`/web/v2/performance/recalc/drivers`, {
      drivers: itensUrl(driverList),
      user: email,
      startDate: convertedDates.startDate,
      endDate: convertedDates.endDate,
      customerID: parseInt(customerId)
    });
    return response;
  } catch (err) {
    throw err;
  }
};