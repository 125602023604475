
import * as amplitude from "@amplitude/analytics-browser";

export const handleAmplitudeEvent = async (eventType, eventProperties) => {
  const isDevelop = 
    window.location.href.includes('fa-qa.gobrax.com.br') ||
    window.location.href.includes('localhost') ||
    window.location.href.includes('127.0.0.1');

  if(isDevelop) return;

  const eventPropertiesWithLocation = {
    ...eventProperties,
    current_url: window.location.href
  }

  amplitude.track(
    eventType,
    eventPropertiesWithLocation
  )

  await amplitude.flush().promise;
}