import React from 'react';
import { FormControl, Select, MenuItem } from '@mui/material';

export const RowsPerPageSelector = ({
  rowsPerPage,
  handleRowsPerPageChange,
  totalNumberOfItems,
  rowsPerPagebaseOptions
}) => {

  const generateRowsPerPageOptions = (total) => {
    const additionalOptions = new Set(rowsPerPagebaseOptions);
  
    if (total > 100) {
      additionalOptions.add(100);
      additionalOptions.add(200);
      additionalOptions.add(500);
      additionalOptions.add(1000);
    }
    if (total > 1000) {
      additionalOptions.add(5000);
    }
  
    additionalOptions.add(total);
  
    return Array.from(additionalOptions).sort((a, b) => a - b); 
  };
  
  const rowsPerPageOptions = generateRowsPerPageOptions(totalNumberOfItems);

  return (
    <FormControl variant="outlined" size="small" style={{ minWidth: 120 }}>
      <Select
        labelId="rows-per-page-label"
        value={rowsPerPage}
        onChange={handleRowsPerPageChange}
        displayEmpty
        renderValue={(value) => `${value} linhas`}
        style={{ fontSize: '0.875rem' }}
      >
        {rowsPerPageOptions.map((option) => (
          <MenuItem
            key={option}
            value={option}
            style={{ fontSize: '0.875rem' }}
          >
            {option === totalNumberOfItems ? 'Todas as linhas' : `${option} linhas`}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
