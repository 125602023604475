import { makeStyles } from "@mui/styles";

export default makeStyles(theme => ({
  paper: {
    borderRadius: 20,
    maxWidth: "100%",
    margin: "0 auto",
    padding: "4px 8px 4px 20px",
  },
  groupStyles: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  labelStyles: {
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.gray.extraLight,
    padding: 2,
    paddingLeft: 6,
    paddingRight: 6,
    borderRadius: 6,
    fontSize: 12,
  },
  labelCustomStyles: {
    color: theme.palette.text.main,
    backgroundColor: theme.palette.gray.extraLight,
    padding: 2,
    paddingLeft: 6,
    paddingRight: 6,
    borderRadius: 6,
    fontSize: 12,    
    fontWeight: '500',
  },
  groupBadgeStyles: {
    backgroundColor: theme.palette.gray.extraLight,
    borderRadius: '2em',
    color: theme.palette.secondary.contrastText,
    display: 'inline-block',
    fontSize: 12,
    fontWeight: '400',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.15em 0.5em',
    textAlign: 'center',
  }
}));
  