import { alpha } from "@mui/material";
import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  tableContent: {
    marginTop: theme.spacing(2)
  },
  btnNew: {
    padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
    borderRadius: 10,
    backgroundColor: theme.palette.secondary.main,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: alpha(theme.palette.secondary.main, 0.8),
      fontWeight: 600
    },
  },
  container: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(5),
    borderRadius: 28
  },
  pageTitleContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  pageTitle: {
    fontWeight: "bold",
  },
  title: {
    fontSize: 24,
    fontWeight: 500,
    color: theme.palette.text.title,
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(1),
  },
  buttonDetail: {
    textTransform: "inherit",
    fontSize: 16,
    fontWeight: "bold",
    borderRadius: 10,
    boxShadow: "none",
  },
  buttonDelete:{
    color: 'red',
    borderColor: 'red',
  },
}));
