import React from "react";
import { Grid, Typography } from "@mui/material";
import makeStyles from "./styles";
import classnames from "classnames";

export const makeColumns = () => {
  const classes = makeStyles();

  const getCell = (data, textBold, success, error) => {
    let itemData = typeof data === "string" ? data.trim() : data;
    return (
      <Typography
        variant="body2"
        className={classnames(classes.text, {
          [classes.textBold]: textBold,
          [classes.success]: success,
          [classes.error]: error,
        })}
        noWrap={false}
      >
        {itemData}
      </Typography>
    );
  };

  return [
    {
      name: "line",
      label: "Linha",
      options: {
        customBodyRender: (value) => getCell(value, true),
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value) => value ? getCell(value, true, true) : '',
      },
    },
    {
      name: "errors",
      label: "Erros",
      options: {
        customBodyRender: (value) => {
          if (value) {
            return value.map((item, index) => {
              let indexItem = index+1;
              return (
                <Grid container key={index}>
                  <Grid item xs={6}>
                    {getCell(indexItem + ' - ' + item.column, true, false, true)}
                  </Grid>
                  <Grid item xs={6}>
                    {getCell(indexItem + ' - ' + item.message, true, false, true)}
                  </Grid>
                </Grid>
              );
            });
          }
        },
      },
    },
  ];
};
