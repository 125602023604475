import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  formControl: {
    marginLeft: theme.spacing(3),
    minWidth: 180,
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(1),
      minWidth: 160,
    },
  },
  item: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paperSelectCalendario: {
    borderRadius: 20,
    margin: '0 auto',
    padding: '4px 4px 4px 4px',
  },
}));
