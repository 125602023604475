import React from 'react';
import { Box, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import PrintIcon from '@mui/icons-material/Print';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

export function TopActionButtons({ onSearchClick, isSearchOpen, onPrint, onExcelExport, onColumnSelectorClick, allColumnsHidden }) {
  return (
    <Box className="flex justify-end space-x-2 bg-gray-50 border-b" >
      <IconButton
        className={`p-2 hover:bg-gray-200 ${isSearchOpen ? 'bg-gray-200' : ''}`}
        title="Buscar"
        onClick={onSearchClick}
        disabled={allColumnsHidden}
      >
        <SearchIcon />
      </IconButton>
      <IconButton
        className="p-2 hover:bg-gray-200"
        title="Imprimir"
        onClick={onPrint}
        disabled={allColumnsHidden}
      >
        <PrintIcon />
      </IconButton>
      <IconButton
        className="p-2 hover:bg-gray-200"
        title="Colunas"
        onClick={onColumnSelectorClick}
      >
        <ViewColumnIcon />
      </IconButton>
      <IconButton
        className="p-2 hover:bg-gray-200"
        title="Exportar para Excel"
        onClick={onExcelExport}
        disabled={allColumnsHidden}
      >
        <CloudDownloadIcon />
      </IconButton>
    </Box >
  );
}