import { convertDateBr }  from "helpers/dates";

export const makeColumns = ({size}) => {
  return [
    {
      name: "plate",
      label: size.mobile? ' ': "Placa",
    },
    {
      name: "startDate",
      label: "Início do vínculo",
      options: {
        customBodyRender: (value) => {
          return convertDateBr(value)
        },
      },
    },
    {
      name: "endDate",
      label: "Fim do vínculo",
      options: {
        customBodyRender: (value) => {
          return value ? convertDateBr(value) : 'Vínculo atual'
        },
      }
    },
  ];
};
