export const cnpjMask = (value) => {
  return value
    .replace(/\D/g, "")
    .replace(/^(\d{2})(\d)/, "$1.$2")
    .replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
    .replace(/\.(\d{3})(\d)/, ".$1/$2")
    .replace(/(\d{4})(\d)/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1");
};

export const dateMask = (value) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "$1/$2")
    .replace(/(\d{2})(\d{1,4})/, "$1/$2")
    .replace(/(\d{4})\d+?$/, "$1");
};

export const phoneMask = (value) => {
  if (!value) {
    return "";
  }
  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "($1) $2")
    .replace(/(\d{4})(\d)/, "$1-$2")
    .replace(/(\d{4})-(\d)(\d{4})/, "$1$2-$3")
    .replace(/(-\d{4})\d+?$/, "$1");
};

export const cpfMask = (value) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1");
};

export const onlyNumberMask = (value) => {
  if (!value) {
    return "";
  }
  return value.replace(/\D/g, "");
};

export const floatMask = (value) => {
  if (!value) {
    return "";
  }
  return value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
};

export const removeSpecialCharactersMask = (value) => {
  return value.replace(/[&@/\\#,+()$~%.!'":;*?<>{}[\]]/g, "");
};

export const removeSpecialCharactersAndNumbersMask = (value) => {
  return value.replace(/[^a-záàâãéèêíïóôõöúçñ' ]+$/gi, "");
};

export const removeCountryCode = (value) => {
  return value.replace(/\D/g, "").replace(/^(\d{2})(\d)/, "$2");
};

export const removeAllSpecialCharacters = (value) => {
  if (!value) {
    return "";
  }
  return value.replace(/[^0-9]/g, "");
};
