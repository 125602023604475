import React, { createContext, useContext, useState } from 'react';

const ModalContext = createContext();

export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({ children }) => {
  const [visibleModals, setVisibleModals] = useState({});

  const showModal = (id) => {
    setVisibleModals(prev => ({ ...prev, [id]: true }));
  };

  const hideModal = (id) => {
    setVisibleModals(prev => ({ ...prev, [id]: false }));
  };

  return (
    <ModalContext.Provider value={{ visibleModals, showModal, hideModal }}>
      {children}
    </ModalContext.Provider>
  );
};
