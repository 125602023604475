import React from "react";
import { Typography, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';

// helpers
import { makeValueStars, valueToPositiveFloat } from "helpers/functions";

import makeStyles from "./styles";

const StyledEmptyStar = styled(StarIcon)(() => ({
  color: '#00000042',
  fontSize: '18px !important'
}));

export const makeColumns = (size, isPenalizer, data) => {
  const classes = makeStyles();

  const returnOptions = [
    {
      name: "vehicleIdentification",
      label: "Frota",
      options: {
        customBodyRender: (value) => {
          return <Typography variant="body2" data-cy="thFrota">{value}</Typography>;
        },
      },
    },
    {
      name: "achievedMileage",
      label: "Dist. percorrida",
      options: {
        customBodyRender: (value) => {
          return <Typography variant="body2" data-cy="thDistanciaPercorrida">{value}</Typography>;
        },
      },
    },
    {
      name: "score",
      label: "Nota geral",
      options: {
        customBodyRender: (value) => {
          return <Typography variant="body2" data-cy="thNotaGeral">{valueToPositiveFloat(value, 2)}</Typography>;
        },
      },
    },
    {
      name: "extraEconomicDrivingScore",
      label: "Início da faixa verde",
      options: {
        customBodyRender: (value) => {
          return (
            <Tooltip title={`Nota: ${value}`} data-cy="thInicioFaixaVerde">
              <Box>
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                  emptyIcon={<StyledEmptyStar />}
                />
              </Box>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "leveragingScore",
      label: "Aproveitamento de embalo",
      options: {
        customBodyRender: (value) => {
          return (
            <Tooltip title={`Nota: ${value}`} data-cy="thAproveitamentoEmbalo">
              <Box>
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                  emptyIcon={<StyledEmptyStar />}
                />
              </Box>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "idleScore", 
      label: "Motor ligado parado",
      options: {
        customBodyRender: (value, tableMeta) => {
          const rowData = data[tableMeta.rowIndex];
          const lowIdleScore = rowData.lowIdleScore; 
          const scoreToDisplay = lowIdleScore !== undefined && lowIdleScore !== null ? lowIdleScore : value;

          return (
            <Tooltip title={`Nota: ${scoreToDisplay}`} data-cy="thMotorLigadoParado">
              <Box>
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(scoreToDisplay)}
                  precision={0.1}
                  readOnly
                  size="small"
                  emptyIcon={<StyledEmptyStar />}
                />
              </Box>
            </Tooltip>
          )
        },
      },
    },
    {
      name: "powerScore",
      label: "Acelerando acima do verde",
      options: {
        customBodyRender: (value) => {
          return (
            <Tooltip title={`Nota: ${value}`} data-cy="thAcelerandoAcimaVerde">
              <Box>
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                  emptyIcon={<StyledEmptyStar />}
                />
              </Box>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "cruiseControlScore",
      label: "Piloto automático",
      options: {
        customBodyRender: (value) => {
          return (
            <Tooltip title={`Nota: ${value}`} data-cy="thPilotoAutomatico">
              <Box>
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                  emptyIcon={<StyledEmptyStar />}
                />
              </Box>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "defensiveDrivingScore",
      label: "Excesso de velocidade",
      options: {
        customBodyRender: (value) => {
          return (
            <Tooltip title={`Nota: ${value}`} data-cy="thExcessoDeVelocidade">
              <Box>
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                  emptyIcon={<StyledEmptyStar />}
                />
              </Box>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "engineBrakeScore",
      label: "Freio motor",
      options: {
        filter: isPenalizer.engineBrakeScore,
        customBodyRender: (value) => {
          return isPenalizer.engineBrakeScore ? (
            <Tooltip title={`Nota: ${value}`}>
              <Box>
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                  emptyIcon={<StyledEmptyStar />}
                />
              </Box>
            </Tooltip>
          ) : (
            <Typography variant="body2" data-cy="thFreioMotor">Não avaliado</Typography>
          );
        },
      },
    },
    {
      name: "throttlePressureScore",
      label: "Pressão do acelerador",
      options: {
        filter: isPenalizer.throttlePressureScore,
        customBodyRender: (value) => {
          return isPenalizer.throttlePressureScore ? (
            <Tooltip title={`Nota: ${value}`}>
              <Box>
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                  emptyIcon={<StyledEmptyStar />}
                />
              </Box>
            </Tooltip>
          ) : (
            <Typography variant="body2" data-cy="thPressaoDoAcelerador">Não avaliado</Typography>
          );
        },
      },
    },
    {
      name: "id",
      label: "id",
      options: {
        display: false,
        searchable: false,
        filter: false,
        viewColumns: false,
        hideColumn: true,
      },
    }
  ];
  
  return [
    {
      name: "origin",
      label: size.mobile ? ' ' : "Origem",
      options: {
        customBodyRender: (value) => {
          if (!!value) {
            return (
              <Typography
                variant="body2"
                className={classes.textBold}
                noWrap={true}
                data-cy="thOrigem" 
              >
                {value}
              </Typography>
            );
          }
        },
      },
    },
    {
      name: "destination",
      label: "Destino",
      options: {
        customBodyRender: (value) => {
          return (
            <Typography
              variant="body2"
              className={classes.textBold}
              noWrap={true}
              data-cy="thDestino"
            >
              {value}
            </Typography>
          );
        },
      },
    },
    ...returnOptions,
  ];
};
