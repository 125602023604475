import "./statusEmpty.css";

const StatusEmpty = ({ title = '', description }) => {
  return (
    <div className={`StatusEmpty-container`}>
      <span className={`StatusEmpty-title`}>{title}</span>
      <span className={`StatusEmpty-description`}>{description}</span>
    </div>
  );
};

export default StatusEmpty;
