export const getDeviceStatus = (deviceId) => {
    switch (deviceId) {
      case 1:
        return 'Disponível para instalação'
      case 2:
        return 'Em estoque'
      case 3:
        return 'Enviado ao cliente'
      case 4:
        return 'Aguardando devolutiva'
      case 5:
        return 'Em uso'
      case 6:
        return 'Em revisão'
      default:
        return 
    }
  };

export const getRoleDeviceStatus = (deviceId) => {
    switch (deviceId) {
      case 1:
        return statusOptions.roleAvailable
      case 2:
        return statusOptions.roleInventory
      case 3:
        return statusOptions.roleSendClient
      case 4:
        return statusOptions.roleWaitingFeedback
      case 5:
        return 
      case 6:
        return statusOptions.roleRevision
      default:
        return statusOptions.roleRevision
    }
  };
  
  export const statusOptions = {
    allStatus: [{
        value: 1, 
        label: 'Disponível para instalação' 
      },{
        value: 2, 
        label: 'Em estoque' 
      },{
        value: 3, 
        label: 'Enviado ao cliente' 
      },{
        value: 4, 
        label: 'Aguardando devolutiva' 
      },{
        value: 5, 
        label: 'Em uso' 
      },{
        value: 6, 
        label: 'Em revisão' 
      }],
    updateOldDevice: [{
        value: 1, 
        label: 'Disponível para instalação' 
      },{
        value: 2, 
        label: 'Em estoque' 
      },{
        value: 4, 
        label: 'Aguardando devolutiva' 
      },{
        value: 6, 
        label: 'Em revisão' 
      }],
    register: [{
      value: 1,
      label: "Disponível para instalação",
      },{
        value: 2,
        label: "Em estoque",
      },{
        value: 3,
        label: "Enviado ao cliente"
    }],
    roleInventory: [{
        value: 1, 
        label: 'Disponível para instalação' 
      },{
        value: 3, 
        label: 'Enviado ao cliente' 
      },{
        value: 6, 
        label: 'Em revisão' 
      }],
     roleSendClient: [{
      value: 1,
      label: "Disponível para instalação",
      },{
        value: 2,
        label: "Em estoque",
     }],
     roleAvailable: [{
        value: 2,
        label: "Em estoque",
     }],
     roleWaitingFeedback: [{
        value: 1,
        label: "Disponível para instalação",
        },{
          value: 2,
          label: "Em estoque",
       },{
        value: 6, 
        label: 'Em revisão' 
      }],
      roleRevision: [{
        value: 1,
        label: "Disponível para instalação",
        },{
          value: 2,
          label: "Em estoque",
       },{
        value: 3, 
        label: 'Enviado ao cliente' 
      }]
  }
