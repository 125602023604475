import { useMemo } from "react";
import { Box, Paper, Typography } from "@mui/material";
import { Skeleton } from "@material-ui/lab";

import PerformanceIndicator from '../PerformanceIndicator';
import HalfMoonChart from '../HalfMoonChart';

import useStyles from './styles';

const ConsolidatedIndicators = ({ indicators = [], loading, generalScore }) => {
  const classes = useStyles();

  const displayGeneralScore = useMemo(() => !loading && !!generalScore, [loading, generalScore]);

  const displayIndicators = useMemo(() => !loading && !!indicators.length, [loading, indicators]);

  return (
    <Box className={classes.indicators}>
      {displayGeneralScore ? (
        <PerformanceIndicator data={{ globalScore: generalScore }} />
      ) : (
        <IndicatorSkeleton />
      )}
      
      {displayIndicators ? (
        <>
          {indicators?.map((indicator) => (
            <Paper key={indicator.key} elevation={1} className={classes.card}>
              <HalfMoonChart data={indicator} />
              <Typography variant="h6" className={classes.cardTitle}>
                {indicator.label}
              </Typography>
            </Paper>
          ))}
        </>
      ) : (
        <>
          {Array.from(new Array(8)).map((_, index) => (
            <IndicatorSkeleton key={index} />
          ))}
        </>
      )}
    </Box>
  );
}

const IndicatorSkeleton = () => <Skeleton variant="rect" width="100%" height={160} style={{ borderRadius: 28, minWidth: 140 }} />;

export default ConsolidatedIndicators;
