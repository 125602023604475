import { LinearProgress } from '@mui/material'
import withStyles from '@mui/styles/withStyles';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 8,
    borderRadius: 5,
    marginTop: theme.spacing(1),
    boxShadow: `-2px 3px 4px ${theme.palette.background.hoverMultiMenu}`,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.white,
    margin: '2px'
  },
}))(LinearProgress);


export default BorderLinearProgress;
