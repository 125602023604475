import * as yup from "yup";

export default yup.object().shape({
  rewardRange: yup.array().of(
    yup.object().shape({
      range: yup
        .string()
        .matches(/^\d+-\d+$/, "A faixa deve conter dois números separados por um hífen (Ex: 0-69).")
        .required("A faixa é obrigatória."),
      reward: yup
        .number()
        .typeError("O valor deve ser numérico.")
        .required("O valor é obrigatório."),
    })
  ).min(1, "Deve haver pelo menos um item na lista de faixas.")
});
