import React, { memo } from "react";
import Table from "../../components/Table";
import { makeColumns } from "./tableColumns";

const ErrorsTable = ({ data, tableName }) => {
  const columns = makeColumns();
  return (
    <Table
      columns={columns}
      data={data}
      options={{
        search: false,
        print: false,
        download: false,
        viewColumns: false,
        filter: false,
        pagination: false,
      }}
      tableName={tableName}
    />
  );
};

export default memo(ErrorsTable);
