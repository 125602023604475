import { Box, Skeleton } from "@mui/material";

const OperationListHistorySkeleton = () => {
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Skeleton variant="rectangular" height={35} />
      <Skeleton variant="rectangular" height={300} style={{ borderRadius: "10px" }} />
    </Box>
  );
};

export default OperationListHistorySkeleton;
