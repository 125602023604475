import React from 'react';
import {
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Typography,
  Skeleton,
  useMediaQuery,
  useTheme
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Map from "components/Map";
import FleetChart from "components/FleetChart";
import { ModalTitle } from '../../atoms/modalTitle/modalTitle';
import { handleAmplitudeEvent } from 'services/amplitude';

import './modalDieselControl.css';

export const ModalDieselControl = ({
  chartData,
  handleModal,
  locations,
  loadingModalChart,
  loadingModalMap,
  showModal,
  showMapOnly,
}) => {
  const handleClose = () => {
    handleAmplitudeEvent('Chart Modal Closed');
    handleModal(false);
  };
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Dialog
      className={`ModalDieselControl-container`}
      PaperProps={isMobile ? {
        style: {
          padding: 4,
          maxWidth: "100%"
        }
      } : {
        style: {
          borderRadius: "50px",
          overflowY: "unset",
        }
      }}
      open={showModal}
      onClose={handleClose}
      fullWidth={true}
      fullScreen={isMobile}
      scroll={"body"}
      maxWidth="lg"
      aria-labelledby="Gráfico"
      aria-describedby="Combustível, RPM, Altitude e Velocidade"
    >
      <IconButton
        className={`ModalDieselControl-closeButton`}
        onClick={handleClose}
        size="large"
        style={{
          zIndex: 1000, 
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent className={`ModalDieselControl-content`}>
        <Grid container spacing={3} data-cy="allElements">
          {!showMapOnly && !loadingModalMap && !loadingModalChart && (
            <ModalTitle title="Detalhes sobre o abastecimento" />
          )}
          
          {!showMapOnly && (
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              {loadingModalChart || !chartData ? (
                <Grid container spacing={2}>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Typography className={`ModalDieselControl-loadingText`}>
                      Carregando gráfico...
                    </Typography>
                    <Skeleton width="100%" height={250} />
                  </Grid>
                </Grid>
              ) : (
                <FleetChart
                  loading={loadingModalChart}
                  data={chartData}
                  tooltip={true}
                  margin={20}
                  chartId="fuel-chart"
                />
              )}
            </Grid>
          )}
          
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            {loadingModalMap || !locations ? (
              <Grid container spacing={2}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Typography className={`ModalDieselControl-loadingText`}>
                    Carregando mapa...
                  </Typography>
                  <Skeleton width="100%" height={300} />
                </Grid>
              </Grid>
            ) : (
              <Map data={locations} singlePoint={true} />
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
