import api from "./api";

const arrayUrl = (items = "") => {
  let partialUrl = "";
  if (items) {
    partialUrl = Array.isArray(items) ? items.join() : items;
  }
  return partialUrl;
};

export const getRoles = async () => {
  try {
    const response = await api.get(`/get-all-roles`);
    return response;
  } catch (err) {
    return err;
  }
};

export const getRolesByContext = async (context = '') => {
  try {
    const response = await api.get(`/web/v2/permissions/roles-context-view/${context}`);
    return response;
  } catch (err) {
    return err;
  }
};

export const getRoleById = async (roleId) => {
  try {
    const response = await api.get(`/get-all-roles?role=${roleId}`)
    return response
  } catch (error) {
    return error;
  }
};

export const getRolePermissionById = async (roleId) => {
  try {
    const response = await api.get(`/web/v2/permissions/permissionRoles/${roleId}`)
    return response
  } catch (error) {
    return error;
  }
};

export const createRole = async (data) => {
  try {
    const response = await api.post(`/add-new-role`, data);
    return response;
  } catch (err) {
    return err;
  } 
};

export const updateRole = async (data) => {
  try {
    const response = await api.put(`/update-role`, data);
    return response;
  } catch (err) {
    return err;
  }
}

export const createPermissionRoles = async (data) => {
  try {
    const response = await api.post(`/web/v2/permissions/permissionRoles`, data);
    return response;
  } catch (err) {
    return err;
  }
};

export const updatePermissionRoles = async (data) => {
  try {
    const response = await api.put(`/web/v2/permissions/permissionRoles`, data);
    return response;
  } catch (err) {
    return err;
  }
};

export const deleteRole = async (roleId) => {
  try {
    const response = await api.delete(`delete-role?role=${roleId}`);
    return response;
  } catch (err) {
    return err;
  }
};

export const getUserPermissions = async (email) => {
  try {
    const response = await api.get(`/list-permissions?user=${email}&relation=view`);
    return response;
  } catch (err) {
    return err;
  }
};

export const getUsersRoles = async () => {
  try {
    const response = await api.get(`/get-users-roles`);
    return response;
  } catch (err) {
    return err;
  }
};

export const setUserRole = async (data) => {
  try {
    const response = await api.post(`/add-user-permission`, data);
    return response;
  } catch (err) {
    return err;
  }
};

export const updateUserRole = async (data) => {
  try {
    const response = await api.post(`/update-user-permission`, data);
    return response;
  } catch (err) {
    return err;
  }
};

export const deleteUserPermissions = async (email) => {
  try {
    const response = await api.delete(`/delete-user-permission?user=${email}`);
    return response;
  } catch (err) {
    return err;
  }
};

export const getAllPermissionRoles = async () => {
  try {
    const response = await api.get(`/web/v2/permissions/permissionRoles/all`);
    return response;
  } catch (err) {
    return err;
  }
};


export const getAllPermissionByContext = async (context) => {
  try {
    const response = await api.get(`/web/v2/permissions/permissionModule/GetPermissionTreeByContext/${context}`);
    return response;
  } catch (err) {
    return err;
  }
};
