import React, { useCallback, useEffect, useState, useRef } from 'react'
import { useFormContext, useFormState, useWatch, Controller } from "react-hook-form";
import { Box, FormHelperText, Grid, Paper, Typography, Autocomplete, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTheme } from '@mui/styles';
import { toast } from "react-toastify";
import Swal from "sweetalert2";

// domain
import { finishingDaysOptions, startingDaysOptions } from 'domain/selectOptions';

// store
import { useAppSelector } from 'store';

// services
import { getOperations } from "services/operations";

// helpers
import { periodTypeOptions } from 'helpers/functions';

// components
import Aux from "hoc/auxiliar";
import Input from 'components/react-hook-form/input';
import FormCustomerSkeleton from "components/Skeletons/FormCustomerSkeleton";

// styles
import useStyles from "./styles";

const StyledAutocomplete = styled(Autocomplete)(() => ({
  '.MuiInputBase-root': {
    borderRadius: 20,
  }
}));

export const CustomerForm = ({ defaultValues, operationTypeOptions, isEdit = false, loading = false }) => {
  const { currentCustomer } = useAppSelector((state) => state.global.user);

  const classes = useStyles();
  const theme = useTheme();
  const { control, setValue } = useFormContext();
  const { errors } = useFormState({ control });

  const startingDay = useWatch({ name: 'startingDay', control });
  const finishingDay = useWatch({ name: 'finishingDay', control });
  const period = useWatch({ name: 'period', control });
  const operations = useWatch({ name: 'operations', control });

  const [disabledfinishingDayDay, setDisabledfinishingDayDay] = useState(true);
  const [daysOptions, setDaysOptions] = useState(startingDaysOptions);
  const [message, setMessage] = useState(false);
  const [operationsList, setOperationsList] = useState([]);

  let totalOperations = useRef(0);

  useEffect(() => {
    if (isEdit && defaultValues) {
      const filteredOperations = defaultValues?.operations.filter((operation) => !!operation);

      setValue("id", defaultValues?.id);
      setValue("name", defaultValues?.name ? defaultValues?.name : "");
      setValue("document", defaultValues?.document ? defaultValues?.document : "");
      setValue("period", defaultValues?.period ? defaultValues?.period[0] : "");
      setValue("startingDay", defaultValues?.startingDay ? defaultValues?.startingDay : "");
      setValue("finishingDay", defaultValues?.finishingDay ? defaultValues?.finishingDay : "");
      setValue("operations", filteredOperations ?? []);
      setValue("note", defaultValues?.note ?? "");
    }
  }, []);

  useEffect(() => {
    if (startingDay) {
      setDisabledfinishingDayDay(false);

      if (startingDay && finishingDay) {
        if (startingDay > finishingDay && period === 0) {
          return setMessage(true);
        } else {
          return setMessage(false);
        }
      }
    } else {
      setDisabledfinishingDayDay(true);
    }
  }, [startingDay, finishingDay]);

  useEffect(() => {
    if (period?.value === 1) {
      setDaysOptions(finishingDaysOptions);
    } else {
      setDaysOptions(startingDaysOptions);
    }
  }, [period]);

  const fetchOperations = useCallback(async () => {
    try {
      const response = await getOperations(currentCustomer);

      if (response.status === 200 || response.status === 201) {
        let listOperation = [];

        if (response.data.data.operation) {
          response.data.data.operation.forEach(item => {
            listOperation.push({
              ...item,
              name: item?.name,
              vehiclesAmount: item.vehicles ? item.vehicles.length : 0,
            });
          })
        } else {
          return null;
        }

        setOperationsList(listOperation);
      } else {
        return toast.error(
          "Erro estranho aconteceu. Entre em contato com o suporte.",
        );
      }
    } catch (error) {
      toast.error(
        "Erro ao buscar operações. Entre em contato com o suporte.",
      );
    }
  }, [])

  useEffect(() => {
    const total = operations?.length || 0;

    if (totalOperations.current !== total) {
      totalOperations.current = total;
    }
  }, [operations, operationsList]);

  useEffect(() => {
    fetchOperations();
  }, [fetchOperations]);

  const handleOperationsChange = (selectedOperationValue, selectedOperations) => {
    if (isEdit && selectedOperations.length < totalOperations.current) {
      const founded = operationsList.filter((item) => (item.operationType === selectedOperationValue && item.vehicles.length > 0));

      if (founded.length > 0) {
        Swal.fire({
          title: 'Atenção! Você está prestes a remover um tipo de bonificação com veículos vinculados.',
          text: 'Isso poderá causar inconsistência nos dados destes veículos. Tem certeza que deseja continuar?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: theme.palette.error.main,
          cancelButtonColor: theme.palette.primary.main,
          confirmButtonText: 'Sim, remover!',
          cancelButtonText: 'Cancelar',
          customClass: {
            container: 'swalAlert'
          }
        }).then(async (result) => {
          if (result.isConfirmed) {
            setValue("operations", selectedOperations);
          }
        });

        return;
      }
    }
  }

  if (loading) {
    return <FormCustomerSkeleton />;
  }

  return (
    <Aux>
      <Paper elevation={2} className={classes.container}>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid container>
              <Typography className={classes.title}>Dados do cliente</Typography>
              <Grid container>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Box sx={{ m: 2 }}>
                    <Input
                      name="name"
                      variant="outlined"
                      fullWidth
                      label="Nome do cliente *"
                      helperText={errors.name?.message}
                      error={!!errors.name}
                    />
                  </Box>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Box sx={{ m: 2 }}>
                    <Input
                      name={'document'}
                      variant="outlined"
                      fullWidth
                      useMask="99.999.999/9999-99"
                      label="CNPJ *"
                      helperText={errors.document?.message}
                      error={!!errors.document}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid container>
              <Typography className={classes.title}>Data de corte/pagamento</Typography>
              <Grid container>
                <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                  <Box sx={{ m: 2 }}>
                    <Controller control={control} name="period" render={({ field: { value = '', onChange, ...rest }, fieldState: { error } }) => (
                      <>
                        <StyledAutocomplete
                          {...rest}
                          options={periodTypeOptions}
                          renderInput={(params) => <TextField {...params} label="Selecione um período" />}
                          value={value}
                          onChange={(_, selectedPeriod) => {
                            onChange(selectedPeriod);

                            if (period !== selectedPeriod?.value) {
                              setMessage(false);
                              setValue('startingDay', '');
                              setValue('finishingDay', '');
                            }
                          }}
                          isOptionEqualToValue={(option, value) => value ? option.label === value?.label || value === '' : false}
                          getOptionLabel={(option) => option?.label ?? ''}
                          renderOption={({ key, ...props }, option) => (
                            <Box key={option.value} component="li" {...props}>
                              <Typography>{option.label}</Typography>
                            </Box>
                          )}
                          disablePortal
                          noOptionsText="Nenhum período cadastrado"
                        />
                        <FormHelperText style={{ marginLeft: 15 }} error={true}>
                          {error?.message}
                        </FormHelperText>
                      </>
                    )} />
                  </Box>
                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                  <Box sx={{ m: 2 }}>
                    <Controller control={control} name="startingDay" render={({ field: { value = '', onChange, ...rest }, fieldState: { error } }) => (
                      <>
                        <StyledAutocomplete
                          {...rest}
                          options={startingDaysOptions}
                          renderInput={(params) => <TextField {...params} label="Selecione a data inicial" />}
                          value={value}
                          onChange={(_, value) => onChange(value)}
                          isOptionEqualToValue={(option, value) => value ? option.label === value?.label || value === '' : false}
                          getOptionLabel={(option) => option?.label ?? ''}
                          renderOption={({ key, ...props }, option) => (
                            <Box key={option.value} component="li" {...props}>
                              <Typography>{option.label}</Typography>
                            </Box>
                          )}
                          disablePortal
                          noOptionsText="Nenhuma data cadastrada"
                        />
                        <FormHelperText style={{ marginLeft: 15 }} error={true}>
                          {error?.message}
                        </FormHelperText>
                      </>
                    )} />
                  </Box>
                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                  <Box sx={{ m: 2 }}>
                    <Controller control={control} name="finishingDay" render={({ field: { value = '', onChange, ...rest }, fieldState: { error } }) => (
                      <>
                        <StyledAutocomplete
                          {...rest}
                          options={daysOptions}
                          renderInput={(params) => <TextField {...params} label="Selecione a data final" />}
                          value={value}
                          onChange={(_, value) => onChange(value)}
                          isOptionEqualToValue={(option, value) => value ? option.label === value?.label || value === '' : false}
                          getOptionLabel={(option) => option?.label ?? ''}
                          renderOption={({ key, ...props }, option) => (
                            <Box key={option.value} component="li" {...props}>
                              <Typography>{option.label}</Typography>
                            </Box>
                          )}
                          disablePortal
                          noOptionsText="Nenhuma data cadastrada"
                          disabled={disabledfinishingDayDay}
                          required
                        />
                        <FormHelperText style={{ marginLeft: 15 }} error={true}>
                          {error?.message}
                        </FormHelperText>
                        {message && (
                          <FormHelperText style={{ marginLeft: 15 }} error={true}>
                            A data final não pode ser menor que a data inicial
                          </FormHelperText>
                        )}
                      </>
                    )} />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <Typography className={classes.title}>Tipos de bonificação para ser liberado</Typography>
              <Grid container>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Box sx={{ m: 2 }}>
                    <Controller control={control} name="operations" render={({ field: { value = '', onChange, ...rest }, fieldState: { error } }) => (
                      <>
                        <StyledAutocomplete
                          {...rest}
                          options={operationTypeOptions}
                          renderInput={(params) => <TextField {...params} label="Selecione um grupo de bonificação" />}
                          value={value}
                          onChange={(event, selectedOperations, action) => {
                            onChange(selectedOperations);

                            if(action === 'selectOption') {
                              handleOperationsChange(event.currentTarget.value, selectedOperations);
                            } else {
                              // difference must also be ONE item (that has been removed)
                              const difference = operations.filter(operation => selectedOperations.every(selectedOperation => selectedOperation.value !== operation.value));
                              
                              handleOperationsChange(difference[0]?.value, selectedOperations);
                            }
                          }}
                          isOptionEqualToValue={(option, value) => value ? option.label === value?.label || value === '' : false}
                          getOptionLabel={(option) => option?.label ?? ''}
                          renderOption={({ key, ...props }, option) => (
                            <Box key={option.value} component="li" {...props} value={option.value}>
                              <Typography>{option.label}</Typography>
                            </Box>
                          )}
                          disablePortal
                          noOptionsText="Nenhum motorista cadastrado"
                          multiple
                        />
                        <FormHelperText style={{ marginLeft: 15 }} error={true}>
                          {error?.message}
                        </FormHelperText>
                      </>
                    )} />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <Typography className={classes.title}>Anotações sobre o cliente</Typography>
              <Grid container>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Box sx={{ m: 2 }}>
                    <Input
                      fullWidth
                      label="Observações..."
                      multiline
                      minRows={2}
                      maxRows={4}
                      name="note"
                      variant='outlined'
                      className={classes.textField}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Box sx={{ mt: 4 }}>
              <Typography className={classes.explanation}>*Os campos marcados com o asterisco (*) são aqueles que precisam obrigatoriamente ser preenchidos.</Typography>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Aux>
  );
};
