import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  paperForm: {
    padding: theme.spacing(2),
  },
  inputTitle: {
    fontSize: 16,
    fontWeight: "bold",
    textAlign: "center",
  },
  formControl: {
    "& .MuiInputLabel-outlined": {
      fontSize: "0.8rem",
      maxWidth: "90%",
      lineHeight: 1.2
    },
  },
}));
