import React from "react";
import { Typography, Tooltip } from "@mui/material";
import { History, LinkOff } from "@mui/icons-material";

import PermissionsGate, { hasPermission } from "components/PermissionsGate";

import makeStyles from "./styles";

export const makeColumns = ({ handleUnlink, handleHistory, size }) => {
  const classes = makeStyles();
  let columns = [
    {
      name: "identification",
      label: size.mobile ? ' ' : "Veículo",
      options: {
        customBodyRender: (value) => {
          return (
            <Typography variant="body2" className={classes.textBold} data-cy="thVehicle">
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "currentDriverName",
      label: "Motorista atual vinculado",
      options: {
        customBodyRender: (value) => {
          return !!value ? (
            <Typography variant="body2" className={classes.textBold} data-cy="thCurrentLinkedDriver">
              {value}
            </Typography>
          ) : (
            <Typography variant="body2" className={classes.textInfo} data-cy="thNoDriverLinked">
              Sem motorista vinculado
            </Typography>
          );
        },
      },
    },
    {
      name: "plate",
      label: "Placa",
      options: {
        customBodyRender: (value) => {
          return (
            <Typography data-cy="thPlate">
              {value}
            </Typography>
          );
        }
      }
    },
    {
      name: "lastestLinkDate",
      label: "Data do último vínculo",
      options: {
        customBodyRender: (value) => {
          return !!value ? (
            <Typography data-cy="thLastBondDate">
              {value}
            </Typography>
          ) : (
            <Typography variant="body2"
              className={classes.textInfo}
              data-cy="thLastBondDate"
            >
              Sem vínculo atual
            </Typography>
          )
        }
      },
    }
  ];
  if (!size.mobile && hasPermission({ scopes: ['can_view_linkdrivershistory', 'can_edit_linkdrivers'] })) {
    columns.push({
      name: "entryData",
      label: "Ações",
      options: {
        sort: false,
        filter: false,
        viewColumns: false,
        print: false,
        customBodyRender: (_, metaData) => {
          return (
            <div className={classes.actionContent} data-cy="thActions">
              <PermissionsGate scopes={['can_view_linkdrivershistory']}>
                <Tooltip title="Histórico">
                  <History size={22}
                    onClick={() => handleHistory(metaData.rowData)}
                    className={classes.actionButton}
                    data-cy="svgHistory"
                  />
                </Tooltip>
              </PermissionsGate>
              <PermissionsGate scopes={['can_edit_linkdrivers']}>
                {metaData.rowData[1] && (
                  <Tooltip title="Desvincular motorista">
                    <LinkOff size={22}
                      onClick={() => handleUnlink(metaData.rowData)}
                      className={classes.actionButton}
                      data-cy="thUnlinkDriver"
                    />
                  </Tooltip>
                )}
              </PermissionsGate>
            </div>
          );
        },
      },
    });
  }

  return columns;
};
