import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useLocation, useHistory } from 'react-router-dom';
import dayjs from 'dayjs';

import { handleAmplitudeEvent } from "services/amplitude";
import { useAppSelector } from "store";

import { useVehicleWebSocket } from "pages/liveMap/useVehicleWebsocket";

import Map from "componentsNew/molecules/map/map";
import Title from "componentsNew/atoms/title/title";
import HeaderDetails from "componentsNew/molecules/headerDetails/headerDetails";
import StatusCar from "componentsNew/molecules/statusCar/statusCar";

import "./liveMapDetails.css";
import { getRouteDay } from "services/map";
import FleetChart from "components/FleetChart";
import { getVehicleProfile } from "services/fleetPerformance";
import { format } from "date-fns";
import { parseChartData } from "helpers/fleetChart";

import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

const LiveMapDetails = () => {
  const history = useHistory()
  const location = useLocation();
  const { vehicle } = location.state || {};
  const { token } = useAppSelector((state) => state.global.user);
  const [chart, setChart] = useState(null);

  const { message, points } = useVehicleWebSocket({
    selectedVehicles: { id: vehicle?.vehicleId },
    sessionId: token,
    singleVehicle: true
  });

  const fetchVehicleProfile = async (vehicleId) => {
    try {
      return await getVehicleProfile(vehicleId);
    } catch (error) {
      console.error("Erro ao obter perfil do veículo:", error);
      return {};
    }
  };

  const transformToRequiredFormat = (apiData) => {
    return apiData.map((data) => ({
      acceleration_pedal_position: 0,
      can_fuel_level: data.graphic.fuel.percent,
      gps_speed: 0,
      can_speed: data.graphic.speed,
      height: data.graphic.altitude || data.graphic.height,
      imei: data.deviceId,
      rpm: data.graphic.rpm,
      time: new Date(data.date).toISOString(),
      total_fuel_consumption_high_resolution: 0
    }));
  };

  const getRouteDayByVehicle = async () => {
    try {
      const response = await getRouteDay(vehicle?.vehicleId);

      const vehicleProfile = await fetchVehicleProfile(vehicle?.vehicleId);
      const vehicleModel = {
        maxFuelCapacity: vehicle.graphic.fuel.maxFuelCapacity,
      }
      if (response.status === 200) {
        const responseData = response?.data?.data ?? [];
        const chartDataNormalized = transformToRequiredFormat(responseData);

        const startOfToday = dayjs().tz("America/Sao_Paulo").startOf('day').format('YYYY-MM-DDTHH:mm:ssZ');
        const now = dayjs().tz("America/Sao_Paulo").format('YYYY-MM-DDTHH:mm:ssZ');

        const chartData = parseChartData(chartDataNormalized, vehicleProfile.data, vehicleModel, vehicleProfile.data, startOfToday, now);

        setChart(chartData);
      }

    } catch (error) {
      console.error("Erro ao buscar rota do veículo:", error);
    }
  };

  useEffect(() => {
    getRouteDayByVehicle(vehicle?.vehicleId);
  }, [])

  return (
    <>
      {message && message.length > 0 && (
        <div className={`LiveMap-container`}>
          <Grid container className={`LiveMap-container-top`}>
            <Grid item className={`LiveMap-title`}>
              <Title value={"Mapa ao vivo"} />
            </Grid>
            <Grid item className={`LiveMap-header`}>
              <HeaderDetails
                vehicle={message[0]}
                onBack={() => {
                  history.push('/gofurther/liveMap', { wasDetail: true });
                  handleAmplitudeEvent('Backward Button Clicked')
                }}
              />
            </Grid>
          </Grid>

          <Grid container className={`LiveMap-container-medium`}>
            <Grid item className={`LiveMap-map`}>
              <Map
                points={points}
                initZoom={15}
                loading={false}
                height={"390px"}
                message={message}
                disableMarkerClick={true}
              />
            </Grid>
            <Grid item className={`LiveMap-description`}>
              <StatusCar
                vehicle={message[0]}
              />
            </Grid>
          </Grid>
          <Grid className={`LiveMap-chart`}>
            <FleetChart
              data={{
                ...chart,
                limits: {
                  maxFuelCapacity: vehicle?.maxFuelCapacity ?? 0,
                  maxSpeedAllowed: 0,
                }
              }}
              tooltip={true}
              seconds={true}
              headerShow={false}
              header={null}
              chartId="live-map-details-chart"
            />
          </Grid>
        </div>
      )}
    </>
  );
};

export default LiveMapDetails;
LiveMapDetails.displayName = "LiveMapDetails";
