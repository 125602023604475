import React, { useState } from 'react';
import {
  List as MUIList,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
} from '@mui/material';
import { virtualizedTable } from '../styles';

export function ColumnSelector({ columns, visibleColumns, onColumnToggle }) {
  const classes = virtualizedTable();

  const allSelected = visibleColumns.every(col => col.display);
  const [selectAll, setSelectAll] = useState(allSelected);

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    onColumnToggle(
      visibleColumns.map(col => col.name),
      newSelectAll
    );
  };

  const handleColumnToggle = (columnName) => {
    const targetColumn = visibleColumns.find(col => col.name === columnName);
    if (targetColumn) {
      onColumnToggle([columnName], !targetColumn.display);
    }
  };

  return (
    <MUIList dense>
      <ListItem onClick={handleSelectAll}>
        <ListItemIcon>
          <Checkbox
            edge="start"
            checked={selectAll}
            tabIndex={-1}
            disableRipple
            className={classes.checkBox}
          />
        </ListItemIcon>
        <ListItemText primary="Selecionar todos" />
      </ListItem>
      {visibleColumns.map((column) => (
        <ListItem
          key={column.name}
          onClick={() => handleColumnToggle(column.name)}
        >
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={column.display}
              tabIndex={-1}
              disableRipple
              className={classes.checkBox}
            />
          </ListItemIcon>
          <ListItemText primary={columns.find(col => col.name === column.name)?.label || column.name} />
        </ListItem>
      ))}
    </MUIList>
  );
}
