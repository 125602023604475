import { makeStyles } from "@mui/styles";
import { alpha } from '@mui/material/styles';

export default makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(4),
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
  },
  btConfirm: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
    "&:hover": {
      backgroundColor: alpha(theme.palette.success.main, 0.8),
    },
  },
  formHelperText: {
    marginLeft: theme.spacing(2)
  },
  bottomForm: {
    marginTop: theme.spacing(2)
  },
  divider: {
    marginTop: theme.spacing(2)
  }
}));
