import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useForm, FormProvider, useFormState } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DoneAll } from "@mui/icons-material";
import { toast } from "react-toastify";

// components
import Aux from "hoc/auxiliar";
import UserForm from "components/UserForm";
import BackButton from "components/BackButton";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";
import DeleteUserDialog from "./DeleteUserDialog";

// helpers
import makeDefaultValues from "helpers/makeDefaultValues";

// domain
import fields from "domain/forms/formUserInitialValues";
import schemaValidation from "domain/validation/yup/formEditUserValidation";

// services
import { getUserById, updateUser, deleteUserEntirely } from "services/user";
import { getUserPermissions, updateUserRole } from "services/roles";

// models
import UserModel from "models/User";
import UserRoleModel from "models/UserRole";

// style
import useStyles from "./styles";
import "react-datepicker/dist/react-datepicker.css";
import { useState } from "react";

const FormUser = () => {
  const classes = useStyles();
  const { userID } = useParams();
  const history = useHistory()
  const defaultValues = makeDefaultValues({ fields });
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schemaValidation),
  });
  const { isSubmitting } = useFormState({ control: methods.control });

  const [isAllCustomers, setIsAllCustomers] = useState(null)
  const [openDialog, setOpenDialog] = useState(false);

  const resetForm = () => {
    toast.success("Usuário atualizado com sucesso")
    methods.reset(defaultValues);
    history.push("/gofurther/user/list-users")
  }

  const onSubmit = async (data) => {
    try {
      const userModel = new UserModel(
        data.customer.map(item => item.value),
        data.name,
        data.email,
        data.password,
        data.isAllCustomers
      );
      const response = await updateUser(userID, userModel);
      if (response.status === 200) {
        if (!hasPermission({ scopes: ['can_update_users_roles'] })) {
          resetForm()
          return
        }
        const roleModel = new UserRoleModel(
          data.role.value,
          data.email
        );
        const responseRole = await updateUserRole(roleModel);
        if (responseRole.status === 200 && !responseRole.err) {
          resetForm()
        }
      }
    } catch (error) {
      toast.error("Erro ao atualizar usuário. Verifique com o suporte");
    }
  };

  const fetchUserById = async () => {
    try {
      const response = await getUserById(userID);

      const fetchedUser = response.data.data.user;

      setIsAllCustomers(fetchedUser.isAllCustomers);

      methods.setValue("customerOption", fetchedUser.customerId);
      methods.setValue("name", fetchedUser.name);
      methods.setValue("email", fetchedUser.email);

      fetchUserRole(fetchedUser.email);
    } catch (error) {
      toast.error(
        "Erro ao buscar dados do usuário. Verifique com o suporte",
      );
    };
  };

  const fetchUserRole = async (email) => {
    try {
      const response = await getUserPermissions(email);

      const userPermissions = response.data?.data;
      const latestUserPermission = userPermissions?.[0];
      const latestUserRole = {
        value: latestUserPermission?.role,
        label: latestUserPermission?.name
      };

      if (userPermissions) {
        methods.setValue("role", latestUserRole);
      }
    } catch (error) {
      toast.error(
        "Erro ao buscar dados do usuário. Verifique com o suporte",
      );
    };
  };

  const fetchDeleteById = async () => {
    try {
      const response = await deleteUserEntirely(userID);
      if (response.status === 200) {
        toast.success("Usuário excluído com sucesso");
        history.push("/gofurther/user/list-users");
      }
    } catch (error) {
      toast.error("Erro ao excluir permanentemente o usuário. Verifique com o suporte");
    } finally {
      handleCloseDialog();
    }
  };

  useEffect(() => {
    if (hasPermission({ scopes: ['can_edit_users'] })) {
      fetchUserById();
    }
  }, []);

  if (!defaultValues) {
    return "...carregando";
  }

  const handleClickLinkChassisToLogin = () => history.push(`/gofurther/user/form-user/mass-link-chassis-to-login/${methods.getValues('email')}`);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmDelete = () => {
    fetchDeleteById();
  };

  return (
    <Aux>
      <PermissionsGate scopes={['can_edit_users']}>
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box>
              <Grid item xl={12} xs={12}>
                <FormProvider {...methods}>
                  <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Grid container className={classes.btImport} justifyContent="space-between">
                      <Typography variant="h1" className={classes.title}>Edição de usuário</Typography>
                      {/* <PermissionsGate scopes={["can_import_link_chassis_to_login"]}>
                        <Grid
                          item xl={2} lg={3} md={4} sm={12} xs={12}
                          className={classes.btImport}
                        >
                          <Button
                            fullWidth
                            variant="contained"
                            className={classes.btnNew}
                            onClick={handleClickLinkChassisToLogin}
                            data-cy="buttonImportarMassa"
                          >
                            Anexar os chassis ao login
                          </Button>
                        </Grid>
                      </PermissionsGate> */}
                    </Grid>
                    <UserForm isEdit isAllCustomers={isAllCustomers} />
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Grid container justifyContent="flex-end" spacing={2} my={2}>
                        <Grid item xs={12} md={3} sm={4} xl={1} lg={2}>
                          <BackButton />
                        </Grid>
                        <PermissionsGate scopes={['can_delete_user_entirely']}>
                          <Grid item xs={12} md={3} sm={3} xl={3} lg={3}>
                            <Button
                              fullWidth
                              variant="contained"
                              className={classes.btDelete}
                              type="button"
                              disabled={isSubmitting}
                              onClick={handleOpenDialog}
                            >
                              Excluir definitivamente
                            </Button>
                          </Grid>
                        </PermissionsGate>
                        <Grid item xs={12} md={3} sm={4} xl={1} lg={2}>
                          <Button
                            fullWidth
                            variant="contained"
                            type="submit"
                            startIcon={<DoneAll />}
                            className={classes.btConfirm}
                            disabled={isSubmitting}
                          >
                            Atualizar
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                </FormProvider>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <DeleteUserDialog
          open={openDialog}
          onClose={handleCloseDialog}
          onConfirm={handleConfirmDelete}
        />
      </PermissionsGate>
    </Aux>
  );
};

export default FormUser;
