import React from "react";
import PropTypes from "prop-types";

import { svgBack, svgBackSmall, svgNext, svgNextSmall } from "./variables";

import "./button.css";

export const Button = ({
  type = "primary",
  size = "medium",
  label,
  loading = false,
  onClick = undefined,
  labelLoading = "Carregando...",
  icon = "none",
  ...props
}) => {
  return (
    <button
      type="button"
      className={[
        "button",
        `button-${type}`,
        `button-${size}`,
        `button-${loading}`,
        `button-icon-${icon}`,
      ].join(" ")}
      onClick={!loading ? onClick : null}
      {...props}
    >
      {!loading && icon === "back" && (
        <span className={"button-arrow-back"}>
          {type === "small" ? svgBackSmall : svgBack}
        </span>
      )}
      {loading ? labelLoading : label}
      {!loading && icon === "next" && (
        <span className={"button-arrow-next"}>
          {type === "small" ? svgNextSmall : svgNext}
        </span>
      )}
    </button>
  );
};

Button.propTypes = {
  /**
   * Qual tipo?
   */
  type: PropTypes.oneOf(["primary", "secondary", "disable"]),
  /**
   * Qual tamanho?
   */
  size: PropTypes.oneOf(["small", "medium", "large", "xlarge"]),
  /**
   * Label
   */
  label: PropTypes.string.isRequired,
  /**
   * Ação
   */
  onClick: PropTypes.func,
  /**
   * Loading?
   */
  loading: PropTypes.bool,
  /**
   * Label Loading
   */
  labelLoading: PropTypes.string,
  /**
   * Seta?
   */
  icon: PropTypes.oneOf(["back", "next", "none"]),
};