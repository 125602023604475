import { useState } from "react";
import { Box, Dialog, DialogTitle, IconButton, Typography, Button, DialogContent } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Icon from "@mdi/react";
import { mdiChevronLeft } from "@mdi/js";

import { useWindowSize } from "hooks/useWindowsSize";

import useStyles from "./styles";

export default function ModalCreateOperations({
  onClose,
  open,
  onSubmit,
  loading,
}) {
  const classes = useStyles();
  const size = useWindowSize();
  
  const [saveWithRecalc, setSaveWithRecalc] = useState(false);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="new-feature-dialog-title"
      aria-describedby="new-feature-dialog-description"
      maxWidth={"md"}
      fullWidth
      fullScreen={size.mobile}
      PaperProps={{ classes: { root: classes.dialogBase } }}
    >
      <div className={classes.upperButtons}>
        <Button
          onClick={() => {
            if (saveWithRecalc) {
              setSaveWithRecalc(false);
            } else {
              onClose();
            }
          }}
          className={classes.backButton}
        >
          <Icon path={mdiChevronLeft} className={classes.icon} size={1} />{" "}Voltar
        </Button>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <Box className={classes.contentBox}>
        <DialogTitle>
          {saveWithRecalc ? (
            <Typography className={classes.textBold}>Escolha o período que deseja realizar o recálculo:</Typography>
          ) : (
            <Typography className={classes.textBold} style={{ color: '#ca1e19' }}>Atenção para as alterações</Typography>
          )}
        </DialogTitle>
        <DialogContent>
          <div className={classes.recalcOptionContainer}>
            {saveWithRecalc ? (
              <div className={classes.textContainer}>
                <Typography className={classes.description}>
                  Selecione uma das opções para recalcular as viagens dos motoristas e caminhões. As alterações realizadas serão refletidas nas notas e premiações dos motoristas.
                </Typography>
                <Typography className={classes.description}>
                  <strong>Recalcular mês atual:</strong> Recomendado para quando o mês ainda está em andamento ou quando for o primeiro dia do novo mês (dessa forma, os motoristas começam a rodar com as novas alterações do zero).
                </Typography>
                <Typography className={classes.description}>
                  <strong>Recalcular mês passado e atual:</strong> Ideal para aplicar mudanças no início do mês, caso sejam necessárias correções também no mês anterior.
                </Typography>
                <Typography className={classes.description}>
                  <strong>Ao clicar em uma das ações abaixo, esse processo é irreversível. Tenha certeza das alterações realizadas.</strong>
                </Typography>
                <Typography className={classes.description}>
                  Caso você precise recalcular períodos ainda maiores, entre em contato com nosso suporte.
                </Typography>
              </div>
            ) : (
              <div className={classes.textContainer}>
                <Typography className={classes.description}>
                  Você deseja realmente aplicar as alterações realizadas? Caso optar por PROSSEGUIR, leia com atenção o próximo passo e seus impactos.
                </Typography>
              </div>
            )}
            <>
              {saveWithRecalc ? (
                <div className={classes.actionButtons}>
                  <Button
                    fullWidth
                    variant="outlined"
                    color="secondary"
                    className={classes.actionButton}
                    disabled={loading}
                    onClick={() => onSubmit({ recalculate_past: true, startDate: true })}
                  >
                    Recalcular mês passado e atual
                  </Button>
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    disabled={loading}
                    className={classes.actionButton}
                    onClick={() => onSubmit({ recalculate_past: true })}
                  >
                    Recalcular mês atual
                  </Button>
                </div>
              ) : (
                <div className={classes.actionButtons}>
                  <Button
                    fullWidth
                    variant="outlined"
                    color="secondary"
                    disabled={loading}
                    className={classes.actionButton}
                    onClick={onClose}
                  >
                    Cancelar
                  </Button>
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    disabled={loading}
                    className={classes.actionButton}
                    onClick={() => setSaveWithRecalc(true)}
                  >
                    Prosseguir
                  </Button>
                </div>
              )}
            </>
          </div>
        </DialogContent>
      </Box>
    </Dialog>
  );
}
