import { makeStyles } from "@mui/styles";

export const virtualizedTable = makeStyles((theme) => ({
  tableRoot: {
    borderRadius: '4px',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    textAlign: 'center',
    marginTop: '16px',
  },
  header: {
    backgroundColor: theme.palette.secondary.main,
    borderBottom: '1px solid #E0E0E0',
    borderRadius: '8px 8px 0 0',
    padding: '8px',
    display: 'flex',
    '& .MuiTypography-root': {
      fontWeight: 'bold',
    },
    '& svg': {
      marginLeft: '4px'
    }
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#FFFFFF',
    },
    '&:nth-of-type(even)': {
      backgroundColor: '#F5F5F5',
    },
    '&:hover': {
      backgroundColor: '#FFFFD1',
    },
  },
  cell: {
    padding: '8px',
    fontSize: '14px',
  },
  footer: {
    padding: '8px',
    display: 'flex',
    margin: 'auto',
    alignItems: 'center',
  },
  pagination: {
    display: 'flex',
    alignItems: 'center',
  },
  listContainer: {
    flex: 1,
    overflow: 'hidden',
  },
  searchBar: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
    borderBottom: '1px solid rgb(204, 204, 204)',
    boxShadow: 'none',
    margin: '0px auto 24px',
    width: '20%',
  },
  scrollBox: {
    overflowY: 'auto',
    overflowX: 'hidden',
    scrollbarWidth: 'thin',
    scrollbarColor: '#888 #f1f1f1',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  },
  checkBox: {
    color: `${theme.palette.secondary.main} !important`
  },
  primaryButton: {
    background: `${theme.palette.secondary.main} !important`
  },
}));