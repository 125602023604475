
export const convertLastBondPointToChart = (datesLastBondPoint) => {
  if (!Array.isArray(datesLastBondPoint) || datesLastBondPoint.length === 0) {
    return [];
  }

  const xaxisMapped = datesLastBondPoint.map(date => {
    const timestamp = new Date(date).getTime();
    if (isNaN(timestamp)) {
      return [];
    }
    return {
      x: timestamp,
      strokeDashArray: 0,
      borderColor: '#f55d20',
      label: {
        borderColor: '#f55d20',
        style: {
          fontSize: 8,
          color: '#fff',
          background: '#f55d20',
        },
        text: 'Último ponto do vínculo',
      }
    };
  }).filter(item => item !== null);

  if (xaxisMapped.length === 0) {
    return [];
  }

  return xaxisMapped.length > 0 ? { xaxis: xaxisMapped } : {};
};