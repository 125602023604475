import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: 'AIzaSyBBUlLwKSfy1AFnf9b4kowhbgWr9SdDD7I',
  authDomain: 'gobrax-notifications-fa16a.firebaseapp.com',
  projectId: 'gobrax-notifications-fa16a',
  storageBucket: 'gobrax-notifications-fa16a.appspot.com',
  messagingSenderId: '460525560783',
  appId: '1:460525560783:web:a3f7a5913494ffa39fe8ba',
  measurementId: 'G-D80S1P2JTS',
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const getFirebaseToken = async () => {
  return getToken(messaging, {vapidKey: 'BH0rF4Ik2EnoY3trWwhLq0InxonF4SjsXvkARQQnfVCsOVsrqp_ozsgb6wvgrz9f-igBv9CLv4YBG1p8uHAvBKo'  }).then((currentToken) => {
    if (currentToken) {
      return currentToken
    } else {
      console.log('No registration token available. Request permission to generate one.');
      return null
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
  });
}

export const onMessageListener = () => {
  return new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
}