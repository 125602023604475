import { alpha } from "@mui/material";
import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(3),
  },
  tableContent: {
    marginTop: theme.spacing(2)
  },
  pageTitleContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  pageTitle: {
    fontWeight: "bold",
  },
  btnNew: {
    padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
    borderRadius: 10,
    backgroundColor: theme.palette.secondary.main,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: alpha(theme.palette.secondary.main, 0.8),
      fontWeight: 600
    },
  },
  buttonDetail: {
    textTransform: "inherit",
    fontSize: 16,
    fontWeight: "bold",
    borderRadius: 10,
    boxShadow: "none",
  },
  btItem: {
    [theme.breakpoints.up("md")]: {
      paddingRight: theme.spacing(2)
    },
    [theme.breakpoints.down('md')]: {
      paddingBottom: theme.spacing(2),
    },
  },
  widget:{
    minHeight: '85%',
  },
  actionColumn: {
    minWidth: 200,
  }
}));
