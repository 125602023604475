import React from 'react';
import PropTypes from "prop-types";

import { CardValue } from '../../atoms/cardValue/cardValue';
import { CardTitle } from '../../atoms/cardTitle/cardTitle';
import { CardDescription } from '../../atoms/cardDescription/cardDescription';

import './card.css';

const Card = ({ props, value = 0, title = "", description = "", dataCy = "card", style, valueStyle, titleStyle, cardDescriptionStyle }) => {
  return (
    <div
      className={`Card-container`}
      {...props}
      data-cy={dataCy}
      style={style}
    >
      <CardValue value={value} valueStyle={valueStyle} />
      <CardTitle title={title} titleStyle={titleStyle} />
      <CardDescription description={description} cardDescriptionStyle={cardDescriptionStyle} />
    </div>
  );
};

export default Card;

Card.propTypes = {
  props: PropTypes.any,
  value: PropTypes.any,
  title: PropTypes.any,
  description: PropTypes.any,
  dataCy: PropTypes.any,
  style: PropTypes.any,
  valueStyle: PropTypes.any,
  titleStyle: PropTypes.any,
  cardDescriptionStyle: PropTypes.any,
};
