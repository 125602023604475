import { makeStyles } from "@mui/styles";
import { alpha } from '@mui/material/styles';
import colors from '../../themes/gobrax'

export default makeStyles(theme => ({
  logotype: {
    color: theme.palette.black,
    marginLeft: theme.spacing(2.5),
    marginRight: theme.spacing(2.5),
    fontWeight: 500,
    fontSize: 18,
    whiteSpace: "nowrap",
    [theme.breakpoints.down('sm')]: {
      display: "none",
    },
  },
  logoIconButton: {
    padding: theme.spacing(1),
    borderRadius: 0
  },
  logoHeader: {
    width: "145px",
    [theme.breakpoints.down('sm')]: {
      width: "135px"
    },
  },
  wrapperSelect: { 
    display: "flex", 
    flexDirection: "row", 
    alignItems: "center", 
    width: "100%" 
  },
  wrapperIcon: {
    paddingRight: theme.spacing(2), 
    paddingLeft: theme.spacing(2),
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    height: 40,
    display: 'flex'
  },
  appBar: {
    paddingTop: theme.spacing(.5),
    paddingBottom: theme.spacing(.5),
    backgroundColor: theme.palette.white,
    width: "100vw",
    maxWidth: "100%",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  grow: {
    flexGrow: 1,
  },
  search: {
    position: "relative",
    borderRadius: 25,
    paddingLeft: theme.spacing(2.5),
    width: 36,
    backgroundColor: alpha(theme.palette.common.black, 0),
    transition: theme.transitions.create(["background-color", "width"]),
    "&:hover": {
      cursor: "pointer",
      backgroundColor: alpha(theme.palette.common.black, 0.08),
    },
  },
  searchFocused: {
    backgroundColor: alpha(theme.palette.common.black, 0.08),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 250,
    },
  },
  searchIcon: {
    width: 36,
    right: 0,
    height: "100%",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: theme.transitions.create("right"),
    "&:hover": {
      cursor: "pointer",
    },
  },
  searchIconOpened: {
    right: theme.spacing(1.25),
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    height: 36,
    padding: 0,
    paddingRight: 36 + theme.spacing(1.25),
    width: "100%",
  },
  messageContent: {
    display: "flex",
    flexDirection: "column",
  },
  headerMenu: {
    marginTop: theme.spacing(2),
    borderRadius: 20,
  },
  headerMenuList: {
    display: "flex",
    flexDirection: "column",
  },
  headerMenuItem: {
    "&:hover, &:focus": {
      backgroundColor: theme.palette.background.light,
      // color: "white",
    },
  },
  headerContent: {
    display: 'flex',
    justifyContent: 'end'
  },
  headerMenuButton: {
    marginTop: 15,
    padding: theme.spacing(0.5),
    width: "40px",
    height: "40px",
    alignSelf: "center"
  },
  formControl: {
    [`& fieldset`]: {
      borderRadius: 20,
    },
  },
  headerMenuButtonSandwich: {
    marginLeft: 9,
    [theme.breakpoints.down('md')]: {
      marginLeft: 0
    },
    padding: theme.spacing(0.5),
  },
  headerMenuButtonCollapse: {
    marginRight: theme.spacing(2),
  },
  headerIcon: {
    fontSize: 28,
    color: "rgba(0, 0, 0, 0.35)",
  },
  notificationIcon: {
    fontSize: 28,
    color: "#181814",
  },
  closeIcon: {
    fontSize: 28,
    color: "#595959",
  },
  notificationCollapse: {
    position: 'fixed',
    top: 0,
    width: '100%',
    maxWidth: 460,
    height: '100vh',
    backgroundColor: colors.palette.white,
    transition: 'right 0.5s',
    zIndex: 999,
    padding: '10px 20px',
    display: 'flex',
    flexWrap: 'inherit',
    flexDirection: 'column',
    boxShadow: '0px 1px 6px 0px #0000000F'
  },
  notificationsGrid: {
    overflow: 'auto',

    [`&::-webkit-scrollbar`]: {
      width: '7px',
      height: '36px',
    },

    [`&::-webkit-scrollbar-track`]: {
      background: '#DADADA',
      borderRadius: '4px',
    },

    [`&::-webkit-scrollbar-thumb`]: {
      background: '#939393',
      borderRadius: '4px',
      height: 'auto',
    },
  },
  notificationSvg: {
    margin: 'auto'
  },
  notificationTitle: {
    maxWidth: '50%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  notificationCollapseTitle: {
    fontWeight: 600,
    fontSize: 24,
    color: colors.palette.text.title
  },
  notificationCollapseEmptyStateTitle: {
    fontWeight: 700,
    fontSize: 20,
    color: colors.palette.text.title
  },
  notificationCollapseEmptyStateDescription: {
    fontWeight: 400,
    fontSize: 16,
    color: colors.palette.text.title
  },
  notificationCollapseSubTitle: {
    fontWeight: 500,
    fontSize: 16,
    color: colors.palette.text.title,
    display: 'flex',
    maxWidth: '220px',
    justifyContent: 'space-between',
    cursor: 'pointer',
    flexWrap: 'inherit',
    margin: '5px 0 20px'
  },
  backdrop: {
    transition: 'opacity 0.4s',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: colors.palette.black,
  },
  headerIconCollapse: {
    color: "black",
  },
  profileMenu: {
    minWidth: 265,
    overflow: "visible",
    zIndex: 1,
  },
  profileMenuUser: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
  },
  profileMenuItem: {
    color: theme.palette.text.hint,
  },
  profileMenuIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.text.hint,
    '&:hover': {
      color: theme.palette.primary.main,
    }
  },
  profileMenuLink: {
    fontSize: 16,
    textDecoration: "none",
    "&:hover": {
      cursor: "pointer",
    },
  },
  messageNotification: {
    height: "auto",
    display: "flex",
    alignItems: "center",
    "&:hover, &:focus": {
      backgroundColor: theme.palette.background.light,
    },
  },
  messageNotificationSide: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginRight: theme.spacing(2),
  },
  messageNotificationBodySide: {
    alignItems: "flex-start",
    marginRight: 0,
  },
  sendMessageButton: {
    margin: theme.spacing(4),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textTransform: "none",
  },
  sendButtonIcon: {
    marginLeft: theme.spacing(2),
  },
  purchaseBtn: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    },
    marginRight: theme.spacing(3)
  },
  profile: {
    flex: 1
  },
  paper: {
    borderRadius: 20,
    maxWidth: "100%",
    margin: "0 auto",
    paddingRight: 5,
  },
  titleABC: {
    fontWeight: '400',
    fontSize: 12,
    color: theme.palette.gray.default,
    letterSpacing: '0.5px',
    marginBottom: 1
  },
  businessIcon: {
    display: 'flex',
    height: '40px',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'center',
  }
}));
