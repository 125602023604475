import React, { useState } from "react";
import { Grid, List, ListItem, Paper, Typography } from "@mui/material";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import { useTheme } from "@mui/styles";
import classnames from "classnames";

// styles
import useStyles from "./styles";

const GForce = (props) => {
  const { data, print } = props;
  const { low_force, mid_force, high_force } = data;

  const theme = useTheme();
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  return (
    <Paper onClick={() => {
      if (open) setOpen(false)
    }} elevation={0} className={classnames(classes.root, props.className)}>
      <Grid
        container
        spacing={2}
        className={classes.containerRelative}
        alignItems={!print ? "flex-start" : "center"}
      >
        {/* low_force */}
        <Grid item xs={4} data-cy="divForcaGFracaValor">
          {low_force && (
            <Grid
              container
              spacing={0}
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              className={classes.barContainer}
            >
              <Grid
                item
                xs={12}
                style={{
                  backgroundColor: theme.palette.success.main,
                  opacity: 0.2,
                  height: `${low_force.percentage > 0 && low_force.percentage < 15
                      ? 85
                      : 100 - low_force.percentage
                    }%`,
                }}
              ></Grid>
              <Grid
                item
                xs={12}
                style={{
                  backgroundColor: theme.palette.success.main,
                  height: `${low_force.percentage > 0 && low_force.percentage < 15
                      ? 15
                      : low_force.percentage
                    }%`,
                }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  className={classes.legendText}
                >
                  <Typography variant="h5" className={classes.textBold}>
                    {low_force.percentage}%
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        {/* mid_force */}
        <Grid item xs={4} data-cy="divForcaGMediaValor">
          {mid_force && (
            <Grid
              container
              spacing={0}
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              className={classes.barContainer}
            >
              <Grid
                item
                xs={12}
                style={{
                  backgroundColor: theme.palette.warning.main,
                  opacity: 0.2,
                  height: `${mid_force.percentage > 0 && mid_force.percentage < 15
                      ? 85
                      : 100 - mid_force.percentage
                    }%`,
                }}
              ></Grid>
              <Grid
                item
                xs={12}
                style={{
                  backgroundColor: theme.palette.warning.main,
                  height: `${mid_force.percentage > 0 && mid_force.percentage < 15
                      ? 15
                      : mid_force.percentage
                    }%`,
                }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  className={classes.legendText}
                >
                  <Typography variant="h5" className={classes.textBold}>
                    {mid_force.percentage}%
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        {/* high_force */}
        <Grid item xs={4} data-cy="divForcaGForteValor">
          {high_force && (
            <Grid
              container
              spacing={0}
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              className={classes.barContainer}
            >
              <Grid
                item
                xs={12}
                style={{
                  backgroundColor: theme.palette.error.main,
                  opacity: 0.2,
                  height: `${high_force.percentage > 0 && high_force.percentage < 15
                      ? 85
                      : 100 - high_force.percentage
                    }%`,
                }}
              ></Grid>
              <Grid
                item
                xs={12}
                style={{
                  backgroundColor: theme.palette.error.main,
                  height: `${high_force.percentage > 0 && high_force.percentage < 15
                      ? 15
                      : high_force.percentage
                    }%`,
                }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  className={classes.legendText}
                >
                  <Typography variant="h5" className={classes.textBold}>
                    {high_force.percentage}%
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>

        {/* Legend */}
        <Grid item xs={12}>
          <List className={classes.list}>
            <ListItem className={classes.listItem} data-cy="liFraca">
              <Brightness1Icon
                style={{ color: theme.palette.success.main }}
                className={classes.listItemIcon}
              />
              <Typography variant="caption" className={classes.listItemTitle}>
                Fraca
              </Typography>
            </ListItem>
            <ListItem className={classes.listItem} data-cy="liMedia">
              <Brightness1Icon
                style={{ color: theme.palette.warning.main }}
                className={classes.listItemIcon}
              />
              <Typography variant="caption" className={classes.listItemTitle}>
                Média
              </Typography>
            </ListItem>
            <ListItem className={classes.listItem} data-cy="Forte">
              <Brightness1Icon
                style={{ color: theme.palette.error.main }}
                className={classes.listItemIcon}
              />
              <Typography variant="caption" className={classes.listItemTitle}>
                Forte
              </Typography>
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default GForce;
