import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  container: {
    display: "flex",
    flexGrow: "1",
    height: "170px",
    minWidth: "130px",
  },
  print: {
    height: "170px !important",
  },
  printCard: {
    padding: `15px !important`,
  },
  card: {
    padding: `20px 10px`,
    borderRadius: "20px",
  },
  responsiveContainer: {
    "& .recharts-wrapper": {
      cursor: "pointer !important"
    },
  },
  title: {
    fontSize: "1rem",
    color: theme.palette.text.primary,
    textAlign: "center",
    fontWeight: "bold",
    paddingTop: theme.spacing(1),
    lineHeight: "1.2",
  },
  percentage: {
    fontFamily: theme.typography.fontFamilySecondary,
    fontSize: "2.5rem",
    lineHeight: "1",
    fontWeight: "600",
    textAlign: "center",
  },
  subtitle: {
    textAlign: "center",
    color: theme.palette.text.hint,
    fontFamily: theme.typography.fontFamilySecondary,
    fontSize: theme.typography.h2.body2,
  },
  stars: {
    textAlign: "center",
    paddingTop: theme.spacing(1),
  },
}));
