import { makeStyles } from '@mui/styles';
import { darken } from 'polished';
import gobrax from 'themes/gobrax';

export default makeStyles((theme) => ({
  title: {
    fontWeight: 'bold',
    fontSize: theme.typography.h4.fontSize,
    color: gobrax.palette.text.title2,
    marginBottom: '8px'
  },
  subTitle: {
    fontWeight: '400',
    fontSize: '16px',
    color: gobrax.palette.text.title2,
    marginBottom: '16px'
  },
  accordion: {
    color: gobrax.palette.text.title2,
    boxShadow: '0px 4px 10px 0px #3535350F',
    width: '100%',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    marginBottom: '16px',
    "& p": {
      fontSize: '16px',
      maxWidth: '440px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      padding: '16px 0',
      cursor: 'pointer'
    },
    "& .Mui-expanded": {
      margin: '0',
      minHeight: '48px',
    },
    "& .MuiAccordionSummary-content": {
      margin: '0',
      height: '48px',
      alignItems: 'center'
    }
  },
  accordionDrop: {
    display: 'flex',
    flexDirection: 'column',
  },
  form: {
    width: '648px',
    "& .ql-editor": {
      "& span": {
        color: theme.palette.error.main
      },
      height: '266px',
      overflow: 'auto',
      border: "1px solid #F0F0F0",
      borderRadius: '8px',
      "&:focus, &:visited": {
        border: "2px solid #2265b7 !important",
      },
      "&:hover": {
        border: "1px solid #424448",
      },
      [`&::-webkit-scrollbar`]: {
        width: '7px',
        height: '36px',
      },

      [`&::-webkit-scrollbar-track`]: {
        background: '#DADADA',
        borderRadius: '4px',
      },

      [`&::-webkit-scrollbar-thumb`]: {
        background: '#939393',
        borderRadius: '4px',
        height: 'auto',
      },
    },
    "& .ql-container.ql-snow": {
      marginTop: '12px',
      border: "none",
    },
    "& .ql-toolbar.ql-snow": {
      border: 'none',
      display: 'flex',
      justifyContent: 'space-between'
    },
    "& .ql-font.ql-picker": {
      display: 'none'
    },
    "& .quill": {
      display: 'flex',
      flexDirection: 'column-reverse'
    },
    "& .MuiAccordion-rounded:first-child": {
      borderRadius: '32px'
    },
    "& .ql-formats": {
      marginRight: 0,
    }

  },
  editor: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #F0F0F0",
      borderRadius: "8px !important"
    }
  },
  editorTitle: {
    fontSize: 16,
    fontWeight: 700,
    margin: '0 0 16px',
  },
  inputSubTitle: {
    fontSize: 14,
    fontWeight: 400,
    margin: '4px 0 -10px',
    color: gobrax.palette.text.hint
  },
  buttonBack: {
    padding: '12px 32px',
     borderRadius: '32px',
     borderColor: '#FFD31C',
     textTransform: 'capitalize',
     fontSize: '16px',
     fontWeight: 'bold'
  },
  controlButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '16px',
    "& p": {
      cursor: 'pointer',
      fontSize: '16px',
      display: 'flex',
      alignItems: 'center',
      padding: '10px',
      height: '48px',
    },
    "& p:hover": {
      transition: '0.3s'
    }
  },
  deleteDisabled: {
    textTransform: 'none',
    fontSize: 16,
    color: '#A6A6A6',
    width: '100px',
    borderRadius: '32px',
    fontWeight: '400',
    justifyContent: 'space-around',
    '&:hover': {
      backgroundColor: darken(0.1, '#fff'),
      color: darken(0.2, '#A6A6A6'),
    },
  },
  delete: {
    textTransform: 'none',
    color: '#E2211C',
    fontSize: 16,
    width: '100px',
    borderRadius: '32px',
    fontWeight: '400',
    justifyContent: 'space-around',
    '&:hover': {
      backgroundColor: darken(0.1, '#fff'),
    },
  },
  iconColor: {
    color: '#E2211C',
  },
  iconColorDisabled: {
    color: '#A6A6A6',
  },
  publish: {
    textTransform: 'none',
    fontSize: 18,
    color: '#000000',
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '32px',
    width: '149px',
    fontWeight: '700',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: darken(0.2, theme.palette.secondary.main),
    },
  },
  preNotification: {
    maxWidth: '48%',
    marginLeft: '10%',
    "& p": {
      fontWeight: '600',
      fontSize: '18px',
      color: gobrax.palette.text.title2,
      marginBottom: '16px'
    },
    "& div": {
      borderRadius: '8px',
      padding: '20px',
      backgroundColor: 'white',
      height: '537px',
      overflow: 'auto',
      [`&::-webkit-scrollbar`]: {
        width: '7px',
        height: '36px',
      },

      [`&::-webkit-scrollbar-track`]: {
        background: '#DADADA',
        borderRadius: '4px',
      },

      [`&::-webkit-scrollbar-thumb`]: {
        background: '#939393',
        borderRadius: '4px',
        height: 'auto',
      },

      [`&::-webkit-scrollbar-thumb:horizontal`]: {
        width: '7px'
      },
    },
  },
  inputs: {
    marginBottom: '16px'
  },
  boxInput: {
    width: '48%',
    position: 'relative',
    minWidth: '224px',
    '& .MuiInput-underline:before': {
      border: 'none !important'
    },
    '& .MuiInput-underline:after': {
      border: 'none !important'
    },
    '& .MuiSelect-select': {
      backgroundColor: 'transparent',
      paddingRight: '0'
    },
    '& .MuiSelect-icon': {
      display: 'none'
    },
    '& .MuiMenu-paper': {
      top: '300px'
    },
    "& #custom-menu-item": {
      top: '50px',
      left: '20px'
    },
  },
  calendarIcon: {
    position: 'absolute',
    zIndex: 2,
    color: gobrax.palette.text.title2,
    top: '25%',
    left: '15px'
  },
  arrowIcon: {
    position: 'absolute',
    right: '10px',
    display: 'flex',
    top: '7px',
    zIndex: 2,
    background: gobrax.palette.white,
    alignItems: 'center',
    "& hr": {
      width: '20px',
      borderTop: '1px solid #595959',
      transform: 'rotate(90deg)',
      marginRight: '-5px'
    }
  },
  inputCalendar: {
    fontSize: theme.typography.h4.fontSize,
    color: gobrax.palette.text.title2,
    height: '48px',
    width: '100%',
    '& .MuiInputBase-input': {
      textAlign: 'left',
      borderRadius: '32px',
      padding: '15px 15px 15px 45px',
      boxShadow: '0px 4px 10px 0px #3535350F',
      backgroundColor: 'white',
      fontWeight: 400,
      fontSize: '16px'
    },
    '&:before': {
      transition: 'none',
      border: 'transparent'
    },
    '&:after': {
      transition: 'none',
      border: 'transparent'
    },
    '& input': {
      cursor: 'pointer',
    },
    '&:hover:not(.Mui-disabled):before': {
      border: 'transparent'
    }
  },
  selectUser: {
    fontSize: theme.typography.h5.fontSize,
    color: gobrax.palette.text.title2,
    width: '100%',
    height: '48px',
    backgroundColor: 'transparent',
    webkitAppearance: 'none',
    mozAppearance: 'none',
    appearance: 'none',
    background: 'transparent',
    border: 'none',
    outline: 'none',
    zIndex: 1,
    cursor: 'pointer',
    backgroundColor: 'white',
    borderRadius: '32px',
    padding: '0 48px',
    '&:focus': {
      backgroundColor: 'inherit', // Altera a cor de fundo do input durante o foco
      border: 'none !important', // Remove a borda durante o foco
    },
    "& .MuiInput-formControl": {
      margin: 'auto 0'
    }
  },
  checkboxes: {
    padding: 0,
    margin: '16px 0 -15px 10px',
    "& h6": {
      fontWeight: 500,
      fontSize: '16px'
    }
  },
  error: {
    color: theme.palette.error.main
  }
}));
