import React from "react";
import { Typography } from "@mui/material";
import RecalculationStatus from "components/RecalculationStatus";

import makeStyles from "./styles";

export const makeColumns = () => {
  const classes = makeStyles();

  const getValue = (value, withoutValue, dataCyTag) => {
    return value ? (
      <Typography variant="body2" data-cy={dataCyTag}>
        {value}
      </Typography>
    ) : (
      <Typography variant="body2" className={classes.textInfo} data-cy={dataCyTag}>
        {withoutValue}
      </Typography>
    );
  };

  return [
    {
      name: "vehicle",
      label: "Frota/placa",
      options: {
        customBodyRender: (value) => getValue(value, "Sem dados", "thMotorista"),
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value) => (
          <div data-cy="thStatus">
            <RecalculationStatus value={value} />
          </div>
        
      ),
      },
    },
  ];
};
