import React, { useEffect, useState } from 'react';
import { Grid, Typography, Paper, Button } from '@mui/material';
import axios from 'axios';

// assets
import lottieLoading from 'assets/lottie/lottieLoading.json';
import JoaoImage from "../../assets/images/joao.png";

// store
import { useAppSelector } from 'store';

// components
import LottieComponent from 'components/LottieComponent';
import SelectQuestion from './components/select-question';

export const InsightCardComponent = ({ title, subTitle, description }) => {
  return (
    <Paper elevation={1} style={{ minHeight: 158, maxWidth: 316, borderRadius: 16, padding: 16, textAlign: 'center', marginTop: 8 }}>
      <Grid container style={{ gap: 8, justifyContent: 'center' }}>
        <Grid style={{ gap: '8px' }}>
          <Grid item style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography style={{ fontSize: 32, fontWeight: 600, textAlign: 'center' }}>
              {title}
            </Typography>
          </Grid>
          <Grid item >
            <Typography style={{ fontSize: 18, fontWeight: 400, color: '#181814', textAlign: 'center' }}>
              {subTitle}
            </Typography>
          </Grid>
          <Grid item>
            <Typography style={{ fontSize: 14, fontWeight: 400, color: '#595959', textAlign: 'center' }}>
              {description}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
};

function GeneralInsight() {
  const [data, setData] = useState([]);
  const [infoCard, setInfoCard] = useState(null);
  const [assistant, setAssistant] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState([])
  const { currentCustomer } = useAppSelector((state) => state.global.user);

  const handleChat = async (id, question) => {
    try {
      setIsLoading(true);
      setAssistant([...assistant, { id, question }]);
      const response = await axios.get(`https://hml-virtual-assistant.gobrax.com.br/web/openai/gpt?customer_id=${currentCustomer}&question_id=${id}&temperature=0.6`);
      if (response.status === 200) {
        setAssistant([...assistant, { id, answer: response.data.message, question }]);
      } else {
        setAssistant([...assistant, { id, answer: 'Não houve resposta pra essa pergunta.', question }]);
      }
    } catch (error) {
      console.log(error)
      setAssistant([...assistant, { id, answer: 'Não houve resposta pra essa pergunta.', question }]);
    } finally {
      setIsLoading(false);
    }
  }

  const handleQuestions = async () => {
    try {
      const response = await axios.get('https://hml-virtual-assistant.gobrax.com.br/app/questions/list');
      if (response.status === 200) {
        const data = [
          {
            id: 1,
            name: 'Performance Geral',
            questions: response.data.general_performance
          },
          {
            id: 2,
            name: 'Outros',
            questions: response.data.others
          },
          {
            id: 3,
            name: 'Dicas',
            questions: response.data.tips
          },
          {
            id: 4,
            name: 'Trechos',
            questions: response.data.trips_stretches
          },
        ];
        setData(data);
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleSelectedQuestion = (selected, isFrequently = false) => {
    const question = isFrequently ? selected : selected[0];
    
    const questionModel = {
      id: question.id,
      value: isFrequently ? question.label : question.value
    }

    setSelectedQuestion(questionModel);
    if (questionModel.id && questionModel.value) {
      handleChat(questionModel.id, questionModel.value)
    }
  }

  const handleInfoCard = async () => {
    try {
      const response = await axios.get(`https://hml-virtual-assistant.gobrax.com.br/web/insights?customer_id=${currentCustomer}&start_date=2024-01-01T00:00:00-0300&end_date=2024-01-31T23:59:59-0300`);
      if (response.status === 200) {
        setInfoCard(response.data);
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    handleQuestions();
    handleInfoCard();
  }, [])

  return (
    <Grid container item style={{ flex: 1, flexDirection: 'column' }}>
      <Grid>
        <Typography style={{ fontSize: 24, fontWeight: 600, color: '#595959', marginBottom: 16 }}>
          Insights Gerais
        </Typography>
      </Grid>
      <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} style={{ alignItems: "center" }}>
        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <InsightCardComponent title={infoCard?.general_score ? `Nota ${infoCard?.general_score}` : 'Não há nota disponível'} subTitle={'Essa é a nota geral da sua frota'} description={''} />
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <InsightCardComponent title={infoCard?.best_operation || 'Não há operação no momento'} subTitle={'Sua melhor operação no momento'} description={'Veja detalhes dos trajetos e veja como melhorar as outras operações.'} />
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <InsightCardComponent title={infoCard?.drivers_low_score || 'Não há dados'} subTitle={'Motoristas com notas abaixo de 60'} description={'Veja os planos de ação.'} />
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <InsightCardComponent title={infoCard?.worst_operation || 'Não há operação no momento'} subTitle={'Sua pior operação no momento'} description={'Veja detalhes dos trajetos e veja como melhorar os resultados e economizar mais.'} />
        </Grid>
      </Grid>
      <Grid style={{ marginBottom: 12, display: 'flex', justifyContent: 'space-between' }}>
        <Grid>
          <Typography style={{ fontSize: 20, fontWeight: 600, color: '#181714', marginTop: 16 }}>
            Pergunte ao João, o amigo do gestor!
          </Typography>
          <Typography style={{ fontSize: 16, fontWeight: 400, color: '#181714' }}>
            Selecione abaixo a categoria e tenha informações analisadas, selecionadas e relevantes para sua frota.
          </Typography>
        </Grid>
        <Grid>
          <img src={JoaoImage} alt="illustration" style={{ width: 80, height: 'auto', marginBottom: '-25px', position: 'relative', zIndex: 10000 }} />
        </Grid>
      </Grid>
      <Paper elevation={1} style={{ width: '100%', borderRadius: 16, padding: 16 }}>
        <Grid>
          <Grid style={{ height: 488, overflowY: 'auto', marginBottom: 16 }}>
            {assistant.length > 0 && assistant.map((assistant, index) => {
              return (
                <Grid container key={index} style={{ flexDirection: 'column', gap: 16, padding: 16, width: '60%', marginLeft: 'auto' }}>
                  <Grid style={{ color: '#595959', fontSize: 16, fontWeight: 400, padding: 16, borderTopRightRadius: 24, borderTopLeftRadius: 24, borderBottomLeftRadius: 24, backgroundColor: '#F6F7F9', maxWidth: '70%', alignSelf: 'flex-end' }}>
                    {assistant.question}
                  </Grid>
                  <Grid style={{ color: '#181814', fontSize: 16, fontWeight: 400, padding: 16, borderTopRightRadius: 24, borderTopLeftRadius: 24, borderBottomRightRadius: 24, backgroundColor: '#FFFAD9', maxWidth: '70%', alignSelf: 'flex-start' }}>
                    {isLoading && !assistant.answer
                      ? <LottieComponent
                        animationData={lottieLoading}
                      />
                      : <>{assistant.answer}</>}
                  </Grid>
                </Grid>
              )
            })}
          </Grid>
        </Grid>
        <Grid container>

          <Typography style={{ fontSize: 14, fontWeight: 600, color: '#181814', marginBottom: 8 }}>
            Perguntas relacionadas:
          </Typography>
          <Grid item style={{ marginRight: 8 }} xl={12} lg={12} md={12} sm={12} xs={12}>
            {(data.length && data[0].questions.length) && data[0].questions.map((item, index) => {
              if (index < 6) {
                return (
                  <Button
                    key={index}
                    variant="outlined"
                    style={{ borderRadius: 8, padding: 8, marginBottom: 8, borderColor: '#F7DF36', margin: 4, borderRadius: 20 }}
                    onClick={() => handleSelectedQuestion(item, true)}
                  >
                    {item.label}
                  </Button>
                )
              }
            })}
          </Grid>
          <SelectQuestion
            listData={data}
            handleSelectedListData={handleSelectedQuestion}
            selectedData={selectedQuestion}
            isSingleMode
          />
        </Grid>
      </Paper>
    </Grid>
  )
}

export default GeneralInsight;
