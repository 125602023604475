import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Button, Grid, Typography, Paper, Box } from "@mui/material";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { toast } from "react-toastify";
import { useTheme } from "@mui/styles";
import { useHistory, useLocation } from "react-router-dom";
import classNames from "classnames";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';

// assets
import notFound from "assets/lottie/sad.json";

// store
import { useAppDispatch, useAppSelector, store } from "store";
import { saveCustomerProfile } from "store/features/customerProfileSlice";
import { useSelect } from "context/useSelect";
import {
  clearBonusFilters,
  clearCalendar,
  setBonusFilters,
  setStepAdd,
  setTotalPercentageReducer,
  setGoalReducer,
  setDriversReducer,
  resetTotalPercentage,
  setIsCalendarFilter,
  resetYear,
  resetCutoffDate,
  setDownloadFollow,
} from "store/features/bonusFollowSlice";

// config
import makeColumns from "./config/tableColumns";

// helpers
import { downloadFile, valueToPositiveFloat, getCurrentMonth } from "helpers/functions";
import {
  formatVehicleGroups,
  generateRange,
  getYearsOptions,
} from "helpers/operations";
import { defaultOperation, selectFilterOptions } from "helpers/follow";

//services
import { getDrivers, getDriversByOperation } from "services/driver";
import {
  getReward,
  getRewardXLSByDriver,
  getRewardXLS,
  getRewardV2,
  getRewardV2OnDemand,
} from "services/reward";
import { getCustomersById } from "services/customers";
import { getOperations } from "services/operations";

// components
import Aux from "hoc/auxiliar";
import RewardSkeleton from "components/Skeletons/RewardSkeleton";
import FollowComponent from "components/FollowComponent";
import NotFoundWithLottie from "components/NotFoundWithLottie";
import Calendar from "components/Calendar";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";
import CircularProgress from "@mui/material/CircularProgress";
import GetAppIcon from "@mui/icons-material/GetApp";
import Modal from "components/FollowModal";
import TableResponsive from "components/TableResponsive";
import { handleAmplitudeEvent } from "services/amplitude";

// hooks
import { useWindowSize } from "hooks/useWindowsSize";

// styles
import { colourStyles } from "./colourStyles";
import useStyles from "./styles";

dayjs.extend(customParseFormat);
dayjs.extend(utc);

export default function Follow() {
  const { currentCustomer } = useAppSelector((state) => state.global.user);
  const dispatch = useAppDispatch();
  const { profile } = useAppSelector((state) => state.customerProfile);
  const {
    filters,
    totalPercentage,
    goal,
    drivers,
    download: downloadFollow,
  } = useAppSelector((state) => state.bonusFollow);
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const animatedComponents = makeAnimated();
  const yearsOptions = getYearsOptions();
  const dateFormatTemplate = "YYYY-MM-DDTHH:mm:ssZZ";
  const size = useWindowSize();

  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [allDrivers, setAllDrivers] = useState(null);
  const [operationsOptions, setOperationsOptions] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState();
  const [id, setId] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { cutOffDatesOptions, setCutOffDatesOptions } = useSelect();
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [modalOpen, setModalOpen] = useState(false)
  const search = useLocation().search;
  const [realTimeData, setRealTimeData] = useState({
    achievedMileage: 0,
    monthScore: 0,
    totalTrips: 0,
    totalReward: 0
  })
  const [reward, setReward] = useState({
    performances: null,
  });

  const cutoffDate = useMemo(() => {
    const date = JSON.parse(filters.cutoffDate);

    if (!date) return null;

    const result = {
      ...date,
      startDate: dayjs(date?.startDate).toDate(),
      finishDate: dayjs(date?.finishDate).toDate()
    };

    return result;
  }, [filters.cutoffDate]);

  const handleCalendarFilterType = (filterType) => {
    handleAmplitudeEvent("Bonus Filter Updated", { label: 'Tipo', value: filterType.name });

    if (filterType.id === 0) {
      setReward({
        performances: null
      });
      dispatch(resetTotalPercentage());
    } else {
      const cutoffDates = store.getState().bonusFollow.filters.cutoffDate;

      if (cutoffDates) {
        const { startDate, finishDate } = JSON.parse(cutoffDates);

        const currentDates = {
          startDate: new Date(startDate),
          finishDate: new Date(finishDate)
        };

        dispatch(setBonusFilters({ selectedOption: currentDates, name: 'calendar' }));
      } else {
        dispatch(clearCalendar());
      }
    }

    dispatch(resetCutoffDate());
    dispatch(resetYear());
    dispatch(setIsCalendarFilter(filterType));
  };

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);

      const driversResponse = await getDrivers(currentCustomer);
      const operationsResponse = await getOperations(currentCustomer);
      const customersResponse = await getCustomersById(currentCustomer);

      if (driversResponse.status !== 200) {
        throw new Error("Error fetching drivers");
      }

      if (operationsResponse.status !== 200) {
        throw new Error("Error fetching operations");
      }

      if (customersResponse.status !== 200) {
        throw new Error("Error fetching customers");
      }

      setAllDrivers(driversResponse.data.drivers);

      const operationData = operationsResponse?.data?.data?.operation;

      if (operationData) {
        const flatArrayAllVehicles = formatVehicleGroups(operationData).flatMap(
          (item) => item.vehicles,
        );

        const newOperation = {
          ...defaultOperation,
          vehicles: flatArrayAllVehicles,
        };

        const newArrayGroups = [
          newOperation,
          ...formatVehicleGroups(operationData),
        ];

        setOperationsOptions(newArrayGroups);
      } else {
        dispatch(clearBonusFilters());

        setOperationsOptions([]);
      }
      const customersOptions = customersResponse.data.customers[0];

      dispatch(saveCustomerProfile(customersOptions));

      handleInitialDates(customersOptions, store.getState().bonusFollow.filters.year);

      const selectedDate = store.getState().bonusFollow.filters.isCalendarFilter?.id > 0 ?
        store.getState().bonusFollow.filters.calendar :
        store.getState().bonusFollow.filters.cutoffDate;

      const operations = store.getState().bonusFollow.filters.operation?.id === 0 ?
        defaultOperation :
        store.getState().bonusFollow.filters.operation;

      handleDataByOperation(
        operations,
        selectedDate,
        driversResponse.data.drivers,
      );
    } catch (err) {
      toast.error("Erro ao buscar dados. Contate o suporte.");
    } finally {
      setLoading(false);
    }
  }, [currentCustomer]);

  const fetchBonus = useCallback(async (
    driversItens,
    listVehicleByOperation,
    modelFormattedCutoffDates,
    operation
  ) => {
    try {
      const { startDate, finishDate } = modelFormattedCutoffDates;
      setLoadingData(true);
      if (driversItens && driversItens.length && modelFormattedCutoffDates) {
        let driverList = driversItens.map(
          (driver) => driver.id || driver.driverId,
        );
        driverList = [...new Set(driverList)];
        listVehicleByOperation = [...new Set(listVehicleByOperation)];
        let response = null;
        // Novo endpoint para com acesso direto ao banco DT-79
        if (!hasPermission({ scopes: ["can_access_beta"] })) {
          response = await getReward(
            driverList,
            listVehicleByOperation,
            startDate,
            finishDate,
            10000,
          );
        } else {
          response = await getRewardV2(
            driverList,
            listVehicleByOperation,
            startDate,
            finishDate,
            10000,
          );
        }

        if (response.status !== 200) {
          throw new Error("Error fetching reward");
        }

        const performance = response.data.data;
        const totalAchievedMileage = performance.totalAchievedMileage;
        const totalMonthTargetMileage = performance.totalMonthTargetMileage;
        dispatch(
          setGoalReducer({
            total: totalAchievedMileage,
            monthly: totalMonthTargetMileage,
          }),
        );

        let percentage = valueToPositiveFloat(
          Math.round((totalAchievedMileage / totalMonthTargetMileage) * 100),
        );
        percentage = percentage > 100 ? 100 : percentage;
        dispatch(setTotalPercentageReducer(percentage));
        const missingAchieved = 100 - percentage;
        const pieChartData = [
          { value: percentage, color: theme.palette.secondary.main },
          { value: missingAchieved, color: theme.palette.gray.extraLight },
        ];

        if (performance.performances !== null) {
          const performances = performance.performances.map((driver) => ({
            ...driver,
            id: driver.driverId,
          }));

          const hasOperationSelected = !!operation?.id;

          const updatedPerformances = performances?.map((performance) => ({
            ...performance,
            totalReward: hasOperationSelected
              ? "Os valores são exibidos quando selecionar toda a frota"
              : performance?.totalReward,
          }));

          setReward({ ...performance, performances: updatedPerformances, pieChartData })
        }

        const downloadLinkmodel = {
          link: `/web/v2/performance/xls/multidriver`,
          fileName: `Bonificação_${dayjs(startDate).format("DD-MM-YYYY-HH-mm")}_${dayjs(finishDate).format("DD-MM-YYYY-HH-mm")}.xlsx`,
          params: {
            drivers: driverList,
            startDate: startDate,
            endDate: finishDate,
            limit: 10000,
          },
        };

        dispatch(setDownloadFollow(downloadLinkmodel));
      } else {
        setReward({
          performances: null
        })
      }
    } catch (error) {
      toast.error("Erro ao buscar dados. Contate o suporte.");
    } finally {
      setLoadingData(false);
    }
  }, [dispatch, theme]);

  const translateForAmplitudeEvent = (name) => {
    if (name === "cutoffDate") {
      return "Data de corte"
    }
    if (name === "calendar") {
      return "Calendário"
    }
  }

  const onChangeFilters = (selectedOption, name) => {
    try {
      setLoading(true);

      if (name === "operation") {
        handleAmplitudeEvent('Bonus Filter Updated', { label: 'Grupo de veículo', value: selectedOption.label })
      }

      if (name === "year") {
        handleAmplitudeEvent('Bonus Filter Updated', { label: 'Ano', value: selectedOption.label })
      }

      if (name === "cutoffDate" || name === "calendar") {
        const startDate = dayjs(new Date(selectedOption.startDate)).format(dateFormatTemplate);
        const finishDate = dayjs(new Date(selectedOption.startDate)).format(dateFormatTemplate);

        handleAmplitudeEvent('Bonus Filter Updated', { label: translateForAmplitudeEvent(name), value: startDate + ' - ' + finishDate })
      }

      dispatch(setBonusFilters({ selectedOption, name }));

      const updatedFilters = store.getState().bonusFollow.filters;

      if (name === "year") {
        handleChangeYear(profile, updatedFilters.year);
        setReward({
          performances: null
        })
      } else if (
        !!updatedFilters.operation &&
        (!!updatedFilters.cutoffDate || !!updatedFilters.calendar)
      ) {
        const filtersData = !!filters.isCalendarFilter.id
          ? updatedFilters.calendar
          : updatedFilters.cutoffDate;
        const operations = updatedFilters.operation.id === 0 ? defaultOperation : updatedFilters.operation;
        handleDataByOperation(operations, filtersData);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleChangeYear = (customerOptions, year) => {
    const { starting_day, period, finishing_day } = customerOptions;
    const currentYear = year.value;
    if (starting_day && finishing_day && !isNaN(period) && currentYear) {
      const cutOffDate = generateRange(
        starting_day,
        finishing_day,
        period,
        currentYear,
      );
      setCutOffDatesOptions(cutOffDate);
      dispatch(setBonusFilters({ selectedOption: null, name: "cutoffDate" }));
    } else {
      setCutOffDatesOptions(null);
      dispatch(setBonusFilters({ selectedOption: null, name: "cutoffDate" }));
    }
  };

  const handleInitialDates = (customerOptions) => {
    const { starting_day, period, finishing_day } = customerOptions;

    const currentYear = store.getState().bonusFollow.filters.year.value;

    if (starting_day && finishing_day && !isNaN(period) && currentYear) {
      const cutOffDate = generateRange(
        starting_day,
        finishing_day,
        period,
        currentYear,
      );

      setCutOffDatesOptions(cutOffDate);

      if (store.getState().bonusFollow.fields.step === 0) {
        return;
      } else {
        const selectedMonth = getCurrentMonth(finishing_day);

        dispatch(
          setBonusFilters({
            selectedOption: cutOffDate[selectedMonth],
            cutoffDate: cutOffDate[selectedMonth],
            name: "cutoffDate",
          }),
        );
      }
    } else {
      setCutOffDatesOptions(null);
      dispatch(setBonusFilters({ selectedOption: null, name: "cutoffDate" }));
    }
  };

  const handleSelectRow = (data, data2) => {
    setId(data2.dataIndex);
    const driverId = data[Object.keys(data).length - 1];
    const driverTripList = reward.performances.find(
      (item) => item.id === driverId,
    );
    const driverName = driverTripList.driverName;
    if (
      !driverTripList.tripsScores ||
      driverTripList.tripsScores.lenght === 0
    ) {
      toast.error("Motorista sem viagens registradas.");
      return;
    }
    const dataTable = driverTripList.tripsScores.map((trip) => ({
      ...trip,
      id: trip.vehicleId,
      resumeMobile: {
        origin: trip.origin.date,
        destination: trip.destination.date,
      },
    }));

    handleAmplitudeEvent('Bonus Driver Selected', {
      driverId: driverId,
      driverName: driverName,
      achievedMileage: driverTripList.achievedMileage,
      score: driverTripList.monthScore,
      trips: driverTripList.totalTrips,
      reward: driverTripList.totalReward
    });

    setSelectedRowData({
      data: dataTable,
      name: driverName,
      isCalendarFilter: filters.isCalendarFilter,
      driverId: driverId,
    });
  };

  const handleClickDetail = () => {
    handleAmplitudeEvent('View Bonus Trip Report Clicked', { driverName: selectedRowData?.name || '', driverId: selectedRowData?.driverId || '' })
    dispatch(setStepAdd());
    history.push({
      pathname: "/gofurther/bonus/follow/details",
      state: selectedRowData,
    });
  };

  const handleDataByOperation = useCallback(async (operation, date, drivers) => {
    setLoading(true);
    try {
      if (date) {
        const { id: operationId, value } = operation;
        const { startDate, finishDate } = JSON.parse(date);
        const formattedStartDate = dayjs(startDate).format(dateFormatTemplate);
        const formattedFinishDate = dayjs(finishDate).format(dateFormatTemplate);

        let driversItens = [];

        if (value === 0) {
          driversItens = drivers || allDrivers;
        } else {
          const response = await getDriversByOperation(
            operationId,
            formattedStartDate,
            formattedFinishDate,
          );

          if (response.status === 200 || response.status === 201) {
            driversItens = response.data.drivers || [];
          } else {
            throw new Error("Error fetching drivers");
          }
        }
        let listVehicleByOperation = operation?.vehicles ? operation.vehicles?.map(
          (elm) => {
            if (elm?.vehicleId) {
              return elm.vehicleId
            }
          },
        ) : '';
        if (listVehicleByOperation) {
          listVehicleByOperation = listVehicleByOperation.filter(item => item !== undefined)
        }
        const modelFormattedCutoffDates = {
          startDate: formattedStartDate,
          finishDate: formattedFinishDate,
        };
        dispatch(setDriversReducer(driversItens));
        fetchBonus(
          driversItens,
          listVehicleByOperation,
          modelFormattedCutoffDates,
          operation
        );
      }
    } catch (error) {
      toast.error(
        "Erro ao carregar motoristas. Entre em contato com o suporte.",
      );
    } finally {
      setLoading(false);
    }
  }, [allDrivers, dispatch, fetchBonus])

  const handleRealTimeData = async () => {
    try {
      handleAmplitudeEvent('Realtime Bonus Clicked', { driverName: selectedRowData?.name || '' })
      const selectedDate = !!filters.isCalendarFilter.id ? store.getState().bonusFollow.filters.calendar : store.getState().bonusFollow.filters.cutoffDate;
      const { startDate, finishDate } = JSON.parse(selectedDate);
      const formattedStartDate = dayjs(startDate).format(dateFormatTemplate);
      const formattedFinishDate = dayjs(finishDate).format(dateFormatTemplate);
      const { operation } = store.getState().bonusFollow.filters;
      const operations = operation?.id === 0 ? defaultOperation : operation;

      let flatVehicles = operations.vehicles ? operations.vehicles.flatMap((item) => item?.vehicleId && item.vehicleId) : '';

      if (flatVehicles) {
        flatVehicles = flatVehicles.filter(item => item !== undefined)
        flatVehicles = [...new Set(flatVehicles)];
      }

      const response = await getRewardV2OnDemand(
        [selectedRowData.driverId],
        flatVehicles,
        formattedStartDate,
        formattedFinishDate,
        10000,
      );

      const { performances } = response.data.data;

      if (!performances || performances?.lenght === 0) {
        toast.warn("Sem dados para exibir no momento.")
        setModalOpen(false)
        return
      }
      setModalOpen(true)
      setModalLoading(true)
      const performance = performances[0]
      setRealTimeData({
        achievedMileage: performance?.achievedMileage || 0,
        monthScore: performance?.monthScore || 0,
        totalReward: performance?.totalReward || 0,
        totalTrips: performance?.totalTrips || 0
      })
    } catch (error) {
      toast.error("Erro ao carregar dados da visão do motorista no aplicativo.");
      setModalOpen(false)
    } finally {
      setModalLoading(false)
    }
  }

  const handleExportByDriver = async (driverId, driverName) => {
    handleAmplitudeEvent('Bonus Table Excel Download Clicked')
    const name = driverName ? driverName.split(" ")[0] : "Bonificação";
    const fileName = `${name}_${dayjs(filters.startDate).format("YYYY-MM-DD-HH")}_${dayjs(filters.finishDate).format("YYYY-MM-DD-HH")}.xlsx`;
    try {
      const response = await getRewardXLSByDriver(
        driverId,
        filters.startDate,
        filters.finishDate,
      );
      downloadFile(
        response.data.XLS,
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        fileName,
      );
    } catch (err) {
      toast.error("Erro ao carregar relatório. Entre em contato com o suporte.");
    }
  };

  const handleExport = async () => {
    handleAmplitudeEvent('Download Bonus Report Clicked')
    setLoadingDownload(true);
    const dates = filters.cutoffDate || filters.calendar;
    const { startDate, finishDate } = JSON.parse(dates);
    const fileName = `Bonificação_${dayjs(startDate).format("DD-MM-YYYY-HH-mm")}_${dayjs(finishDate).format("DD-MM-YYYY-HH-mm")}.xlsx`;

    try {
      let driverList = drivers.map((driver) => driver.id || driver.driverId);

      driverList = [...new Set(driverList)];

      const response = await getRewardXLS(
        driverList,
        dayjs(startDate).format(dateFormatTemplate),
        dayjs(finishDate).format(dateFormatTemplate),
        10000,
      );
      downloadFile(
        response.data?.data?.XLS,
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        fileName,
      );
    } catch (err) {
      toast.error("Erro ao carregar relatório. Entre em contato com o suporte.");
    } finally {
      setLoadingDownload(false);
    }
  };

  useEffect(() => {
    if (hasPermission({ scopes: ["can_view_bonus"] }) && currentCustomer) {
      if (store.getState().bonusFollow.fields.step > 0) {
        dispatch(clearBonusFilters());
      }

      handleAmplitudeEvent('Bonus Screen Viewed');

      fetchData();
    }
  }, [fetchData, currentCustomer]);

  useEffect(() => {
    const origin = new URLSearchParams(search).get("origin")
    const login_redirect = localStorage.getItem('login_redirect')
    login_redirect && login_redirect !== 'gofurther/bonus/follow' && origin === 'login' && history.replace(`/${login_redirect}`)
  }, [history]);

  const columns = makeColumns(handleExportByDriver, size);

  const styleFormat = filters.isCalendarFilter?.label === "Calendário";

  if ((loading || loadingData) && filters.operation?.id >= 0) {
    return <RewardSkeleton />;
  }

  return (
    <Aux>
      <PermissionsGate scopes={["can_view_bonus"]}>
        <Modal
          handleClose={() => setModalOpen(false)}
          isOpen={modalOpen}
        >
          <Grid container>
            {modalLoading ?
              <Grid item container xs={12} justifyContent="center"><CircularProgress color="inherit" /></Grid> :
              <Grid item xs={12}>
                <Typography className={classes.modalTitle}>Informações visualizadas no aplicativo pelo motorista</Typography>
                <Typography className={classes.modalText} style={{ paddingBottom: 24 }}>({selectedRowData?.name || ''})</Typography>
                <Grid container>
                  <Grid item container xs={12} display="flex" justifyContent="space-between" style={{ paddingBottom: 16 }}>
                    <Typography className={classes.modalText} display="inline">Km rodado</Typography>
                    <Typography className={classes.modalValue} display="inline">{valueToPositiveFloat(realTimeData.achievedMileage, 0)}</Typography>
                  </Grid>
                  <Grid item container xs={12} display="flex" justifyContent="space-between" style={{ paddingBottom: 16 }}>
                    <Typography className={classes.modalText} display="inline">Nota geral</Typography>
                    <Typography className={classes.modalValue} display="inline">{valueToPositiveFloat(realTimeData.monthScore)}</Typography>
                  </Grid>
                  <Grid item container xs={12} display="flex" justifyContent="space-between" style={{ paddingBottom: 16 }}>
                    <Typography className={classes.modalText} display="inline">Quantidade de viagens</Typography>
                    <Typography className={classes.modalValue} display="inline">{realTimeData.totalTrips}</Typography>
                  </Grid>
                  <Grid item container xs={12} display="flex" justifyContent="space-between">
                    <Typography className={classes.modalText} display="inline">Valor da premiação</Typography>
                    <Typography className={classes.modalValue} display="inline">{realTimeData.totalReward.toLocaleString("pt-br", { style: "currency", currency: "BRL" })}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            }
          </Grid>
        </Modal>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ paddingTop: 16 }}>
          <Grid container justifyContent="space-around" data-cy="divGrupoSelects">
            {operationsOptions.lenght === 0 ? (
              <Grid
                item
                xl={styleFormat ? size.grid4 : size.grid3}
                lg={styleFormat ? size.grid4 : size.grid3}
                md={styleFormat ? size.grid4 : size.grid3}
                sm={styleFormat ? size.grid4 : size.grid3}
                xs={styleFormat ? size.grid4 : size.grid3}
                style={size.mobile ? {} : { paddingLeft: 5, paddingRight: 5 }}
              >
                <Paper elevation={0} className={classes.center}>
                  <Typography>
                    Não foram encontradas operações disponíveis.
                  </Typography>
                </Paper>
              </Grid>
            ) : (
              <Grid
                item
                xl={styleFormat ? size.grid4 : size.grid3}
                lg={styleFormat ? size.grid4 : size.grid3}
                md={styleFormat ? size.grid4 : size.grid3}
                sm={styleFormat ? size.grid4 : size.grid3}
                xs={styleFormat ? size.grid4 : size.grid3}
                style={size.mobile ? {} : { paddingLeft: 5, paddingRight: 5 }}
                data-cy="divSelectFrota"
              >
                <Paper elevation={0} className={classes.paper}>
                  <Select
                    placeholder="Operação"
                    options={operationsOptions}
                    styles={{
                      ...colourStyles,
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    onChange={(selectedOption) =>
                      onChangeFilters(selectedOption, "operation")
                    }
                    value={filters.operation}
                    defaultValue={filters.operation}
                    components={animatedComponents}
                    noOptionsMessage={() => "Nenhum resultado encontrado!"}
                  />
                </Paper>
              </Grid>
            )}
            <Grid
              item
              xl={styleFormat ? size.grid4 : size.grid3}
              lg={styleFormat ? size.grid4 : size.grid3}
              md={styleFormat ? size.grid4 : size.grid3}
              sm={styleFormat ? size.grid4 : size.grid3}
              xs={styleFormat ? size.grid4 : size.grid3}
              style={size.mobile ? {} : { paddingLeft: 5, paddingRight: 5 }}
              data-cy="divSelectTipoDeCorte"
            >
              <Paper elevation={0} className={classes.paper}>
                <Select
                  placeholder="Tipo de filtro de data"
                  options={selectFilterOptions}
                  onChange={(item) => handleCalendarFilterType(item)}
                  value={filters.isCalendarFilter}
                  defaultValue={selectFilterOptions[0]}
                  components={animatedComponents}
                  menuPortalTarget={document.body}
                  styles={{
                    ...colourStyles,
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                />
              </Paper>
            </Grid>
            {filters.isCalendarFilter?.label !== "Calendário" && (
              <Grid
                item
                xl={styleFormat ? size.grid4 : size.grid3}
                lg={styleFormat ? size.grid4 : size.grid3}
                md={styleFormat ? size.grid4 : size.grid3}
                sm={styleFormat ? size.grid4 : size.grid3}
                xs={styleFormat ? size.grid4 : size.grid3}
                style={size.mobile ? {} : { paddingLeft: 5, paddingRight: 5 }}
                data-cy="divSelectAnoOuCalendario"
              >
                <Paper elevation={0} className={classes.paperSelect}>
                  <Select
                    name="year"
                    placeholder="Ano"
                    options={yearsOptions.reverse()}
                    menuPortalTarget={document.body}
                    styles={{
                      ...colourStyles,
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    onChange={(selectedOption) =>
                      onChangeFilters(selectedOption, "year")
                    }
                    value={store.getState().bonusFollow.filters.year}
                    defaultValue={store.getState().bonusFollow.filters.year}
                    components={animatedComponents}
                  />
                </Paper>
              </Grid>
            )}
            {filters.isCalendarFilter?.label === "Calendário" ? (
              <Grid
                item
                xl={styleFormat ? size.grid4 : size.grid3}
                lg={styleFormat ? size.grid4 : size.grid3}
                md={styleFormat ? size.grid4 : size.grid3}
                sm={styleFormat ? size.grid4 : size.grid3}
                xs={styleFormat ? size.grid4 : size.grid3}
                style={size.mobile ? {} : { paddingLeft: 5, paddingRight: 5 }}
              >
                <Calendar
                  selectedDates={{
                    initialDate: JSON.parse(filters.calendar)?.startDate,
                    finalDate: JSON.parse(filters.calendar)?.finishDate,
                  }}
                  handleCalendar={(startDate, endDate) =>
                    onChangeFilters(
                      {
                        startDate: startDate,
                        finishDate: endDate,
                      },
                      "calendar",
                    )
                  }
                />
              </Grid>
            ) : (
              <>
                {cutOffDatesOptions === null ? (
                  <Grid
                    item
                    xl={styleFormat ? size.grid4 : size.grid3}
                    lg={styleFormat ? size.grid4 : size.grid3}
                    md={styleFormat ? size.grid4 : size.grid3}
                    sm={styleFormat ? size.grid4 : size.grid3}
                    xs={styleFormat ? size.grid4 : size.grid3}
                    style={
                      size.mobile ? {} : { paddingLeft: 5, paddingRight: 5 }
                    }
                  >
                    <Paper elevation={0} className={classes.center}>
                      <Typography>
                        Não foram encontradas data de corte.
                      </Typography>
                    </Paper>
                  </Grid>
                ) : (
                  <Grid
                    item
                    xl={styleFormat ? size.grid4 : size.grid3}
                    lg={styleFormat ? size.grid4 : size.grid3}
                    md={styleFormat ? size.grid4 : size.grid3}
                    sm={styleFormat ? size.grid4 : size.grid3}
                    xs={styleFormat ? size.grid4 : size.grid3}
                    style={
                      size.mobile ? {} : { paddingLeft: 5, paddingRight: 5 }
                    }
                    data-cy="divSelectDataDeCorte"
                  >
                    <Paper elevation={0} className={classes.paper}>
                      <Select
                        placeholder="Selecione a data de corte"
                        options={cutOffDatesOptions}
                        menuPortalTarget={document.body}
                        styles={{
                          ...colourStyles,
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        components={animatedComponents}
                        onChange={(selectedOption) => {
                          onChangeFilters(selectedOption, "cutoffDate");
                        }}
                        value={cutoffDate}
                      />
                    </Paper>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Grid>
        {!loading &&
          !loadingData &&
          (!reward?.performances || reward?.performances?.length === 0) ? (
          <NotFoundWithLottie
            lottieFiles={notFound}
            description="Não foram encontradas premiações."
            strongDescription="Verifique os filtros!"
          />
        ) : (
          <Grid container>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography className={classes.followTitle}>
                Acompanhamento da premiação
              </Typography>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid container direction="row" justifyContent="space-between">
                {/*Barras de info*/}
                <Grid container item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Grid
                    container
                    direction={size.mobile ? "row" : "column"}
                    justifyContent="space-between"
                  >
                    <Grid
                      item
                      xl={size.grid12Mobile6}
                      lg={size.grid12Mobile6}
                      md={size.grid12Mobile6}
                      sm={size.grid12Mobile6}
                      xs={size.grid12Mobile6}
                      style={size.mobile ? {} : { marginRight: 15 }}
                      className={classNames(
                        classes.roundedContainer,
                        classes.itemContent,
                      )}
                      component={Paper}
                      elevation={0}
                    >
                      <Grid
                        container
                        alignItems="center"
                        className={classes.infoBarItens}
                        justifyContent="space-between"
                      >
                        <Typography className={classes.itemTitle} data-cy="pPremiacaoTotal">
                          Premiação total
                        </Typography>
                        <Typography
                          align={size.mobile ? "left" : "right"}
                          className={classes.reward}
                          data-cy="pValorPremiacaoTotal"
                        >
                          {reward?.totalReward
                            ? reward.totalReward.toLocaleString("pt-br", {
                              style: "currency",
                              currency: "BRL",
                            })
                            : 0}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xl={size.grid12Mobile5}
                      lg={size.grid12Mobile5}
                      md={size.grid12Mobile5}
                      sm={size.grid12Mobile5}
                      xs={size.grid12Mobile5}
                      style={size.mobile ? {} : { marginRight: 15 }}
                      className={classNames(
                        classes.roundedContainer,
                        classes.itemContent,
                      )}
                      component={Paper}
                      elevation={0}
                    >
                      <Grid
                        container
                        alignItems="center"
                        className={classes.infoBarItens}
                        justifyContent="space-between"
                      >
                        <Typography className={classes.itemTitle} data-cy="pPerformance">
                          Performance
                        </Typography>
                        <Typography
                          align={size.mobile ? "left" : "right"}
                          className={classes.goal}
                          data-cy="pValorPerformance"
                        >
                          {reward?.generalScore}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {/*grafico*/}
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Grid container alignItems="center">
                    <Grid
                      item
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      component={Paper}
                      elevation={0}
                      className={classes.roundedContainer}
                    >
                      <Grid container alignItems="center">
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                          <Grid container>
                            <FollowComponent
                              data={reward?.pieChartData || 0}
                              percentage={totalPercentage || 0}
                            />
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                              <Typography
                                align="center"
                                className={classes.meta}
                                data-cy="pEmRelacaoMeta"
                              >
                                Em relação à meta {reward?.lastTimeRun ? `(atualizado em ${reward?.lastTimeRun})` : ''}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xl={6}
                          lg={6}
                          md={5}
                          sm={6}
                          xs={6}
                          className={classes.legendRight}
                        >
                          <Grid
                            container
                            spacing={2}
                            className={classes.textItensContainer}
                          >
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                              <Grid container>
                                <Grid
                                  item
                                  xl={12}
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  xs={12}
                                >
                                  <Typography className={classes.titleMeta} data-cy="pKmRodados">
                                    Km rodados
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xl={12}
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  xs={12}
                                >
                                  <Typography className={classes.valueMeta} data-cy="pValorKmRodados">
                                    {valueToPositiveFloat(
                                      goal.total,
                                      2,
                                    ).toLocaleString("pt-BR")}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                              <Grid container justifyContent="flex-end">
                                <Grid
                                  item
                                  xl={12}
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  xs={12}
                                >
                                  <Typography className={classes.titleMeta} data-cy="pMeta">
                                    Meta
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xl={12}
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  xs={12}
                                >
                                  <Typography className={classes.valueMeta} data-cy="pValorMeta">
                                    {goal?.monthly.toLocaleString("pt-BR") ?? 0}
                                  </Typography>

                                  {/* <Typography className={classes.valueMeta} data-cy="pLastTimeRUn">
                                    {reward?.lastTimeRun ?? 0}
                                  </Typography> */}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* table */}
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} data-cy="Tabela">
              <TableResponsive
                columns={columns}
                data={reward?.performances}
                options={{
                  onRowClick: handleSelectRow,
                  rowsPerPage,
                  onChangeRowsPerPage: (amount) => setRowsPerPage(amount),
                  setRowProps: (row, index) => {
                    return index === id
                      ? {
                        style: {
                          backgroundColor: "#FFF5CB",
                          cursor: "pointer",
                          width: "100%",
                        },
                      }
                      : null;
                  },
                }}
                download={downloadFollow}
                pointer={true}
                tableName="bonus"
              />
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid container justifyContent="flex-end" spacing={2}>
                <PermissionsGate scopes={["can_view_real_time_data"]}>
                  <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                    <Box marginTop={size.mobile ? "25px" : 0}>
                      <Button
                        fullWidth
                        variant="outlined"
                        color="secondary"
                        onClick={handleRealTimeData}
                        disabled={!selectedRowData}
                        className={classes.buttonRealTime}
                        data-cy="buttonTempoReal"
                      >
                        Visão do motorista no aplicativo
                      </Button>
                    </Box>
                  </Grid>
                </PermissionsGate>
                <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                  <Box>
                    <Button
                      fullWidth={true}
                      variant="outlined"
                      color="secondary"
                      className={classes.buttonDownload}
                      onClick={handleExport}
                      disabled={loadingDownload}
                      data-cy="buttonDownload"
                      startIcon={
                        !loadingDownload ? (
                          <GetAppIcon />
                        ) : (
                          <CircularProgress
                            color="inherit"
                            size={20}
                            thickness={6}
                          />
                        )
                      }
                    >
                      Download (exportar)
                    </Button>
                  </Box>
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                  <Box marginTop={size.mobile ? "25px" : 0}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="secondary"
                      data-cy="buttonVerRelatioViagem"
                      onClick={handleClickDetail}
                      disabled={!selectedRowData}
                      className={classes.buttonDetail}
                    >
                      Ver relatório da viagem
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </PermissionsGate>
    </Aux>
  );
}
