import * as yup from "yup";

export default yup.object().shape({
  document: yup.string().test("document", "CNPJ incompleto", function (value) {
    if (value) {
      const schema = yup.string().min(18, "CNPJ inválido");
      return schema.isValidSync(value);
    }
    return true;
  }).required("Campo obrigatório"),
  name: yup.string().required("Campo obrigatório"),
  note: yup.string().max(254, "Máximo de 254 caracteres"),
  operations: yup
    .array()
    .of(yup
      .object()
      .shape({
        value: yup.number().required("Campo obrigatório"),
        label: yup.string().required(),
      })
      .typeError("Campo obrigatório")
      .nullable()
      .required("Campo obrigatório")
    ).required("Campo obrigatório")
    .min(1, "Selecione ao menos uma opção"),
  period: yup
    .object()
    .shape({
      value: yup.number().required("Campo obrigatório"),
      label: yup.string().required(),
    })
    .typeError("Campo obrigatório")
    .nullable()
    .required("Campo obrigatório"),
  startingDay: yup
    .object()
    .shape({
      value: yup.number().required("Campo obrigatório"),
      label: yup.string().required(),
    })
    .typeError("Campo obrigatório")
    .nullable()
    .required("Campo obrigatório"),
  finishingDay: yup
    .object()
    .shape({
      value: yup.number().required("Campo obrigatório"),
      label: yup.string().required(),
    })
    .typeError("Campo obrigatório")
    .nullable()
    .required("Campo obrigatório"),
});
