import React from "react";
import { IconButton, Grid } from "@mui/material";
import { DeleteOutline, EditOutlined, HistoryOutlined } from "@mui/icons-material";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";

export const makeColumns = ({ onDelete, onEdit, onHistory, size }) => {
  let columns = [
    {
      name: "customerName",
      label: size.mobile ? ' ' : "Empresa",
    },
    {
      name: "identification",
      label: "ID",
    },
    {
      name: "brand",
      label: "Marca",
    },
    {
      name: "simcard",
      label: "SIM card",
    },
    {
      name: "status",
      label: "Status",
    },
    {
      name: "created_at",
      label: "Data de criação",
    }
  ];

  if (!size.mobile && hasPermission({ scopes: ['can_view_history_devices', 'can_edit_devices', 'can_remove_devices'] })) {
    columns.push({
      name: "id",
      label: "Ações",
      options: {
        sort: false,
        filter: false,
        viewColumns: false,
        print: false,
        customBodyRender: (value, data) => {
          return (
            <Grid container>
              <PermissionsGate scopes={['can_view_history_devices']}>
                <Grid
                  item
                  container
                  justifyContent="center"
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                >
                  <IconButton
                    onClick={() => onHistory(value)}
                    aria-label="history"
                    title="Histórico"
                    size="large">
                    <HistoryOutlined />
                  </IconButton>
                </Grid>
              </PermissionsGate>
              <PermissionsGate scopes={['can_edit_devices']}>
                <Grid
                  container
                  justifyContent="center"
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                >
                  <IconButton
                    onClick={() => onEdit(value)}
                    aria-label="edit"
                    title="Editar"
                    size="large">
                    <EditOutlined />
                  </IconButton>
                </Grid>
              </PermissionsGate>
              <PermissionsGate scopes={['can_remove_devices']}>
                <Grid
                  container
                  justifyContent="center"
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                >
                  <IconButton
                    onClick={() => onDelete(data.tableData[data.rowIndex])}
                    aria-label="delete"
                    title="Excluir"
                    size="large">
                    <DeleteOutline />
                  </IconButton>
                </Grid>
              </PermissionsGate>
            </Grid>
          );
        },
      },
    });
  }

  return columns;
};
