import { makeStyles } from "@mui/styles";
import { alpha } from '@mui/material/styles';

export default makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  btConfirm: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    "&:hover": {
      backgroundColor: alpha(theme.palette.secondary.main, 0.8),
      fontWeight: 700
    },
  },
  btCancel: {
    "&:hover": {
      backgroundColor: alpha(theme.palette.gray.extraLight, 0.8),
      fontWeight: 700
    },
  },
  textContainer: {
    padding: theme.spacing(2),
  },
  title: {
    fontWeight: 'bold'
  }
}));
