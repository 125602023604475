import React from "react";
import { Box, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/styles";
import { useWatch, useFormContext, useFormState } from "react-hook-form";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

// helpers
import { removeSpecialCharactersNumberMask } from "helpers/functions";

// components
import Input from "components/react-hook-form/input";
import RequirementContainer from "../requirementContainer";

const Standard = () => {
  const theme = useTheme();
  const { control, setValue } = useFormContext();

  const { errors } = useFormState({ control });

  const dataTableHighRequirementList = useWatch({
    control,
    name: "highControll",
  });

  const dataTableMediumRequirementList = useWatch({
    control,
    name: "mediumControll",
  });

  const dataTableLowRequirementList = useWatch({
    control,
    name: "lowControll",
  });

  const dataTablePenalizingHighRequirementList = useWatch({
    control,
    name: "penalizingHighControll",
  });

  const dataTablePenalizingMediumRequirementList = useWatch({
    control,
    name: "penalizingMediumControll",
  });
  
  const dataTablePenalizingLowRequirementList = useWatch({
    control,
    name: "penalizingLowControll",
  });

  const handleDeleteIndicator = async (dataTable, control, id) => {
    const { isConfirmed } = await Swal.fire({
      title: `Tem certeza que deseja excluir este indicador?`,
      text: "Essa ação é irreversível!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: theme.palette.primary.main,
      cancelButtonColor: theme.palette.error.main,
      confirmButtonText: "Sim, excluir!",
      cancelButtonText: "Cancelar",
    });

    if (isConfirmed) {
      try {
        const updateIndicators = dataTable.filter(
          (item) => item.id !== id,
        );
        setValue(control, updateIndicators);

        Swal.fire(
          "Excluído!",
          "O registro foi excluído com sucesso",
          "success",
        );
      } catch (err) {
        console.log(err);
        toast.error("Erro ao indicador. Entre em contato com o suporte.");
      }
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xl={3} lg={8} md={8} sm={6} xs={12}>
        <Box sx={{ m: 2 }}>
          <Input
            name="name"
            variant="outlined"
            fullWidth
            label="Nome *"
            helperText={errors.name?.message}
            error={errors.name && true}
          />
        </Box>
      </Grid>
      <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Grid style={{ padding: 4 }} item xl={6} lg={6} md={6} sm={6} xs={12}>
          <RequirementContainer
            title="Exigência alta"
            name="highRequirementList"
            controllArrayName="highControll"
            onDelete={(id) => handleDeleteIndicator(dataTableHighRequirementList, "highControll", id)}
            dataTable={dataTableHighRequirementList}
            requirementLevelField="high"
          />
        </Grid>
        <Grid style={{ padding: 4 }} item xl={6} lg={6} md={6} sm={6} xs={12}>
          <RequirementContainer
            title="Penalizadores"
            penalizing
            name="highPenalizingRequirementList"
            controllArrayName="penalizingHighControll"
            onDelete={(id) => handleDeleteIndicator(dataTablePenalizingHighRequirementList, "penalizingHighControll", id)}
            dataTable={dataTablePenalizingHighRequirementList}
            requirementLevelField="high"
          />
        </Grid>
      </Grid>

      <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Grid style={{ padding: 4 }} item xl={6} lg={6} md={6} sm={6} xs={12}>
          <RequirementContainer
            title="Exigência média"
            name="mediumRequirementList"
            controllArrayName="mediumControll"
            onDelete={(id) => handleDeleteIndicator(dataTableMediumRequirementList, "mediumControll", id)}
            dataTable={dataTableMediumRequirementList}
            requirementLevelField="medium"
          />
        </Grid>
        <Grid style={{ padding: 4 }} item xl={6} lg={6} md={6} sm={6} xs={12}>
          <RequirementContainer
            title="Penalizadores"
            penalizing
            name="mediumPenalizingRequirementList"
            controllArrayName="penalizingMediumControll"
            onDelete={(id) => handleDeleteIndicator(dataTablePenalizingMediumRequirementList, "penalizingMediumControll", id)}
            dataTable={dataTablePenalizingMediumRequirementList}
            requirementLevelField="medium"
          />
        </Grid>
      </Grid>

      <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Grid style={{ padding: 4 }} item xl={6} lg={6} md={6} sm={6} xs={12}>
          <RequirementContainer
            title="Exigência baixa"
            name="lowRequirementList"
            controllArrayName="lowControll"
            onDelete={(id) => handleDeleteIndicator(dataTableLowRequirementList, "lowControll", id)}
            dataTable={dataTableLowRequirementList}
            requirementLevelField="low"
          />
        </Grid>
        <Grid style={{ padding: 4 }} item xl={6} lg={6} md={6} sm={6} xs={12}>
          <RequirementContainer
            title="Penalizadores"
            penalizing
            name="lowPenalizingRequirementList"
            controllArrayName="penalizingLowControll"
            onDelete={(id) => handleDeleteIndicator(dataTablePenalizingLowRequirementList, "penalizingLowControll", id)}
            dataTable={dataTablePenalizingLowRequirementList}
            requirementLevelField="low"
          />
        </Grid>
      </Grid>
      <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Grid container justifyContent='flex-end' alignItems="center" item xl={11} lg={11} md={11} sm={11} xs={12}>
          <Typography variant="h4" style={{ margin: 4 }}>Defina a nota a ser retirada do penalizador:</Typography>
        </Grid>
        <Grid item xl={1} lg={1} md={1} sm={1} xs={12}>
          <Input
            onKeyPress={removeSpecialCharactersNumberMask}
            type='number'
            name="penalty_score"
            variant="outlined"
            fullWidth
            label="Nota *"
            helperText={errors.penalty_score?.message}
            error={errors.penalty_score && true}
            inputProps={{
              min: 0
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Standard;
