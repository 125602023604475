import React from "react";
import { Box, Skeleton } from "@mui/material";

const ListUsersSkeleton = () => {
  return (
    <Box>
      <Skeleton variant="rectangular" height={500} style={{ borderRadius: '10px' }} />
    </Box>
  );
};

export default ListUsersSkeleton;
