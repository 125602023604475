import { create } from "apisauce";
import { clearCustomerProfile } from "../store/features/customerProfileSlice";
import { logout } from "../store/globalSlice";
import { store } from "store";

const baseURL = `${process.env.REACT_APP_LINK_API}`;
const baseURLWS = `${process.env.REACT_APP_LINK_API_WS}`;

// define the api
const api = create({
  baseURL: baseURL,
  timeout: 240000,
});

export const apiExt = create({
  baseURL: baseURL,
  timeout: 240000,
});

export const apiWS = create({
  baseURL: baseURLWS,
  timeout: 240000,
});

api.axiosInstance.interceptors.request.use(
  (config) => {

    // caso específico em que o token é diferente. Card aberto para correção. Remover da linha 36 até 39
    const { token, email, credentials } = store.getState().global.user;
    
    config.headers.OriginVersion = "WEB 3.1";

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    if (config.url.startsWith('api/internal/devices')) {
      config.headers.Authorization = `Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjIwNDI3MTU2NzYsImlhdCI6MTcyNzM1NTY3NiwibmJmIjoxNzI3MzU1Njc2LCJzdWIiOiI5OTkifQ.w0LwY4We2bZrqYcZI9bo9kt97rhH5aW91fUXIZ2jmVdfPbnfv9gFGwrwt1_9pAmHE1za0MOnSbfassduSvKhtg`;
    }

    if (credentials) {
      config.headers.Credentials = credentials;
    }

    if (config.method !== 'get') {
      if (!config.data) {
        config.data = {};
      }

      config.data.sourceId = 1;

      if (email) {
        config.data.execEmail = email;
      }
    } else if (config.params?.removeCredentials) {
      delete config.headers.Credentials;
    }
    return config;
  },
  (error) => {
    console.log("error", error);
    return Promise.reject(error);
  },
);

api.axiosInstance.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this  to => trigger
    // Do something with response data
    return response;
  },
  (error) => {
    if (error.response.data.code === 401) {
      localStorage.clear();
      store.dispatch(logout());
      store.dispatch(clearCustomerProfile());
      window.location.href = `${process.env.REACT_APP_LINK_API_LOGIN}/self-service/browser/flows/logout`;
    }
    return Promise.reject(error);
  },
);

api.addResponseTransform((response) => {
  if (!response.ok) {
    throw response;
  }
});

export default api;
