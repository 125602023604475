import React, { useCallback, useEffect, memo, useState } from "react";
import ApexCharts from "apexcharts";
import Chart from "react-apexcharts";
import { Grid } from "@mui/material";
import { useTheme } from "@mui/styles";
import { useDebouncedCallback } from 'use-debounce';
import CheckboxFullChart from "../CheckboxFullChart";
import { getColor } from "../../helpers/fleetChart";
import { convertDateBr } from "helpers/dates";

import { handleAmplitudeEvent } from "services/amplitude";

// styles
import useStyles from "./styles";
import getChartSettings from "./getChartSettings";

const FullChart = ({ chartId, data, getTooltipData, series, seconds }) => {
  const classes = useStyles();
  const theme = useTheme();
  const seriesColors = getColor(theme);
  const { chartData, dates, limits, rpmrange, datesLastBondPoint } = data;
  const { maxFuelCapacity, maxSpeedAllowed, hasGNV } = limits;
  const [chartOptions, setChartOptions] = useState(null);

  const debouncedTooltip = useDebouncedCallback((tootltipData) => {
    getTooltipData(tootltipData);
  }, 300);

  const handleTooltipData = (item, index) => {
    const tootltipData = {
      acceleration_pedal: chartData[index].acceleration_pedal,
      altitude: chartData[index].altitude,
      brake_pedal: chartData[index].brake_pedal,
      date: convertDateBr(chartData[index].date, true),
      fuel: chartData[index].fuel,
      fuel_level: chartData[index].fuel_level,
      gear: chartData[index].gear,
      id: chartData[index].id,
      rpm: chartData[index].rpm,
      rpmStatus: chartData[index].rpmStatus,
      speed: chartData[index].speed,
      imei: data.imei,
      hasGnv: hasGNV
    };

    debouncedTooltip(tootltipData);
  };

  const fetchData = useCallback(() => {
    const initialDate = new Date(dates.initialDate).getTime();
    const finalDate = new Date(dates.finalDate).getTime();
    const greenRpmRangeBegin = rpmrange.extra[1];
    const greenRpmRangeEnd = rpmrange.economy[1];

    setChartOptions(getChartSettings(
      chartId,
      initialDate,
      finalDate,
      greenRpmRangeBegin,
      greenRpmRangeEnd,
      handleTooltipData,
      maxFuelCapacity,
      maxSpeedAllowed,
      seconds,
      seriesColors,
      theme,
      datesLastBondPoint,
      hasGNV
    ));
  }, [])

  const handleChartChange = useCallback((item, value) => {
    handleAmplitudeEvent("Chart Subtitle Updated", { label: item, display: value });
    const eventType = value ? "showSeries" : "hideSeries";
    ApexCharts.exec(
      chartId,
      eventType,
      [item],
    );
  }, [])

  useEffect(() => {
    series && fetchData();
  }, [series])

  return (
    series && (
      <Grid container alignContent="center" justifyContent="center">
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.chart}
        >
          {chartOptions && <Chart
            options={chartOptions}
            series={series}
            type="line"
            height={350}
          />}
        </Grid>
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={classes.legendContent}
        >
          <CheckboxFullChart handleClick={handleChartChange} />
        </Grid>
      </Grid>
    )
  );
};

export default memo(FullChart);
