export default [
  "customer",
  "customerOption",
  "operation",
  "identification",
  "plate",
  "fueltype",
  "wheeldriver",
  "odomType",
  "initialKm",
  "maxFuelCapacity",
  "maxAcceleration",
  "chassi",
  "distanceCalcMethod",
  "fuelCalcMethod",
  "accelerationIdentificationMethod",
  "hasEcoRoll",
  "hasDafEcoRoll",
  "maxSpeedAllowed",
  "maxSpeedAllowedWithRain",
  "leveragePeak",
  "brand",
  "model",
  "observation",
  "motor",
  "truckModel",
  "fabricationYear"
];
