import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  outlinedInput: {
    borderRadius: 22,
  },
  label: {
    '& p': {
      fontSize: theme.typography.body2.fontSize,
    }
  }
}));
  