import { useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { getFirebaseToken, onMessageListener } from "../../firebase";

// store
import { useAppDispatch, useAppSelector } from "store";
import { setGlobalUser } from "store/globalSlice";

import { useAmplitudeScore } from "context/useAmplitudeScore";

// services
import api from "services/api";
import { getNotifications } from "services/notifications";
import { getUserByEmail } from "services/user";
import { getUserPermissions } from "services/roles";
import { handleAmplitudeEvent } from "services/amplitude";

import { formatIdToFiveCharMinimum } from "helpers/functions";

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const history = useHistory();

  const dispatch = useAppDispatch();

  const { identifyUser } = useAmplitudeScore();

  const user = useAppSelector(state => state.global.user);
  const isAuthenticated = !!user.token;

  useEffect(() => {
    const unlisten = history.listen(async (location, action) => {
      const response = await fetch(`${process.env.REACT_APP_LINK_API_LOGIN}/sessions/whoami`, {
        credentials: "include",
      });

      const authenticatedSession = await response.json();

      if(authenticatedSession.error) {
        history.push("/login");
      }
    });

    return () => unlisten();
  }, []);

  useEffect(() => {
    const registerNotifications = async (userEmail) => {
      try {
        const responseNotifications = await getNotifications(userEmail)
  
        if (responseNotifications.status === 200) {
          const allNotifications = responseNotifications.data.data.filter(notification => notification.status === 'published' || notification.status === 'viewed')
          const newsNotifications = responseNotifications.data.data.filter(notification => notification.status === 'published')
  
          let orderNewsNotifications = [];

          if (newsNotifications.length > 0) {
            orderNewsNotifications = newsNotifications.sort((a, b) => {
              return new Date(b.publish_at) - new Date(a.publish_at);
            })
          }
  
  
          const notificationModel = {
            notifications: {
              allNotifications,
              newsNotifications: orderNewsNotifications
            }
          }
          
          dispatch(setGlobalUser({ notifications: notificationModel.notifications }))
        }
      } catch (error) {
  
      }
    }

    const authenticateUser = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_LINK_API_LOGIN}/sessions/whoami`, {
          credentials: "include",
        });
  
        const authenticatedSession = await response.json();
  
        if(authenticatedSession.error) {
          history.push("/login");
          return false;
        }
  
        const encodedAuthenticatedSession = Buffer.from(JSON.stringify(authenticatedSession)).toString("base64");
        const authenticatedSessionUserName = authenticatedSession.identity.traits.name?.first;
        const authenticatedSessionUserEmail = authenticatedSession.identity.traits.email;
  
        dispatch(setGlobalUser({ token: encodedAuthenticatedSession }));
  
        const userResponse = await getUserByEmail(authenticatedSessionUserEmail);
  
        if (userResponse.status === 200 && userResponse?.data) {
          const userId = userResponse.data.data.userid;
          const credentials = userResponse.data.data?.token || '';
  
          localStorage.setItem("userId", userId);
  
          dispatch(setGlobalUser({ userId, credentials }));
        };
  
        const permissionsResponse = await getUserPermissions(authenticatedSessionUserEmail);
  
        if (permissionsResponse.status === 200 && permissionsResponse.data && permissionsResponse.data.data) {
          const loginRedirect = permissionsResponse.data.data[0].login_redirect || "";
  
          const permissions = permissionsResponse.data.data;
          const encodedPermissions = Buffer.from(JSON.stringify(permissions)).toString("base64");
  
          localStorage.setItem("login_redirect", loginRedirect);
          localStorage.setItem("roles", encodedPermissions);
  
          dispatch(setGlobalUser({ roles: encodedPermissions }));
        };
  
        const globalUser = {
          name: authenticatedSessionUserName,
          email: authenticatedSessionUserEmail,
          token: encodedAuthenticatedSession
        };
  
        dispatch(setGlobalUser(globalUser));
  
        if (userResponse.data?.data?.userid) {
          identifyUser(
            formatIdToFiveCharMinimum(userResponse.data?.data?.userid),
            authenticatedSessionUserName,
            authenticatedSessionUserEmail
          );
        }

        if (!user.token) {
          handleAmplitudeEvent('Login');
        }
  
        getFirebaseToken().then(async (firebaseToken) => {
          if (firebaseToken) {
            try {
              await api.post('/api/v1/notification/credential', {
                "platform": "web",
                "credential": authenticatedSessionUserEmail,
                "token": firebaseToken
              })
            } catch (err) {
  
            }
  
            registerNotifications(authenticatedSessionUserEmail);
  
            onMessageListener().then(payload => {  
              registerNotifications(authenticatedSessionUserEmail);
  
            }).catch(err => console.log('failed: ', err));
          }
        });
      } catch (error) {
        console.log('authenticateUser error', error);
      }
    };

    authenticateUser();
  }, []);

  return (
    <Route {...rest} render={(props) => (
      isAuthenticated ? <Component {...props} /> : <></>
    )} />
  );
}