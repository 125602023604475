import React from 'react';

import './cardDescription.css';

export const CardDescription = ({ description = 'Descrição', cardDescriptionStyle, props }) => {
  return (
    <div
      className={`CardDescription-container`}
      style={cardDescriptionStyle}
      {...props}
    >
      {description}
    </div>
  );
};
