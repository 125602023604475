import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: {
    name: null,
    email: null,
    userId: null,
    currentCustomer: null,
    listCustomers: null,
    token: null,
    login_redirect: null,
    credentials: null,
    notifications: {
      allNotifications: [],
      newsNotifications: []
    }
  },
};

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setGlobalUser: (state, action) => {
      state.user = { ...state.user, ...action.payload }
    },
    logout: (state) => {
      state.user = initialState.user;
    }
  },
});

// Action creators are generated for each case reducer function
export const { setGlobalUser, logout } = globalSlice.actions;

export default globalSlice.reducer;
