import React from 'react'

import { LayoutProvider } from './LayoutContext'
import { SelectProvider } from './useSelect'
import { SelectDriversProvider } from './useSelectDrivers'
import { SelectComparisonProvider } from './useSelectComparison'
import { SelectCustomersProvider } from './selectCustomersContext'
import { AmplitudeScoreProvider } from './useAmplitudeScore'
import { InputsProfileProvider } from './useInputsProfile'
import { ModalProvider } from 'hooks/useModal'

const AppProvider = ({ children }) => (
  <LayoutProvider>
    <AmplitudeScoreProvider>
      <SelectCustomersProvider>
        <InputsProfileProvider>
          <SelectProvider>
            <SelectDriversProvider>
              <SelectComparisonProvider>
                  <ModalProvider>
                    {children}
                  </ModalProvider>
              </SelectComparisonProvider>
            </SelectDriversProvider>
          </SelectProvider>
        </InputsProfileProvider>
      </SelectCustomersProvider>
    </AmplitudeScoreProvider>
  </LayoutProvider>
)

export default AppProvider
