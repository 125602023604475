import { Grid, Skeleton } from "@mui/material";
import { styled } from '@mui/system';

import Aux from "hoc/auxiliar";

import { useWindowSize } from "hooks/useWindowsSize";

const RewardSkeleton = () => {
  const size = useWindowSize();

  return (
    <Aux>
      <Container container spacing={1} direction="row" justifyContent="space-around" alignItems="center">
        <Grid item xl={size.grid3} lg={size.grid3} md={size.grid3}sm={size.grid3} xs={size.grid3}>
          <BorderedSkeleton variant="rectangular" width="100%" height={50} />
        </Grid>
        <Grid item xl={size.grid3} lg={size.grid3} md={size.grid3} sm={size.grid3} xs={size.grid3}>
          <BorderedSkeleton variant="rectangular" width="100%" height={50} />
        </Grid>
        <Grid item xl={size.grid3} lg={size.grid3} md={size.grid3} sm={size.grid3} xs={size.grid3}>
          <BorderedSkeleton variant="rectangular" width="100%" height={50} />
        </Grid>
        <Grid item xl={size.grid3} lg={size.grid3} md={size.grid3} sm={size.grid3} xs={size.grid3}>
          <BorderedSkeleton variant="rectangular" width="100%" height={50} />
        </Grid>
      </Container>
      
      <Container container spacing={1} direction="row" justifyContent="space-between" alignItems="stretch">
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid container direction="row" justifyContent="space-between" alignItems="stretch" spacing={1}>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Grid container direction="column" justifyContent="space-between" alignItems="stretch">
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <BorderedSkeleton variant="rectangular" width="100%" height={80} />
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} marginTop={1} >
                  <BorderedSkeleton variant="rectangular" width="100%" height={80} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Grid container alignItems="center">
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <BorderedSkeleton variant="rectangular" width="100%" height={180} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Grid container spacing={1} marginTop={.125}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <BorderedSkeleton variant="rectangular" width="100%" height={550} />
        </Grid>
      </Grid>
    </Aux>
  );
};

const Container = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(.125),
}));

const BorderedSkeleton = styled(Skeleton)({
  borderRadius: "22px",
});

export default RewardSkeleton;
