import React, { useEffect, useState } from "react";
import { Box, FormHelperText, Grid, Typography, Paper, Autocomplete, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useFormContext, useFormState, useWatch, Controller } from "react-hook-form";
import { toast } from "react-toastify";

// store
import { useAppSelector } from "store";

// services
import { getCustomersById } from "services/customers";
import { getRoles } from 'services/roles';

// helpers
import { makeSelectOptions } from "helpers/makeSelectOptions";
import { orderByName } from "helpers/functions";
import { getGroupedRoles } from 'helpers/permissions';
import { getFilteredPermissions } from "helpers/getFilteredPermissions";

// hooks
import { useWindowSize } from "hooks/useWindowsSize";

// components
import Aux from "hoc/auxiliar";
import FormUserSkeleton from "components/Skeletons/FormUserSkeleton";
import { Input, Select } from "components/react-hook-form";
import { hasPermission } from "components/PermissionsGate";
import Table from "components/Table/Table";
import TableResponsive from "components/TableResponsive/Table";
import MultiSelectUsers from "components/MultiSelectUsers";
import { makeColumns } from "./tableColumns";

import useStyles from "./styles";
import "react-datepicker/dist/react-datepicker.css";

const StyledAutocomplete = styled(Autocomplete)(() => ({
  maxWidth: '540px',
  '.MuiInputBase-root': {
    borderRadius: 20,
  }
}));

const UserForm = (props) => {
  const { listCustomers } = useAppSelector((state) => state.global.user);
  const classes = useStyles();
  const size = useWindowSize();

  const [isLoading, setIsLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [tableData, setTableData] = useState([]);
  const [selectedVehicles, setSelectedVehicles] = useState([]);

  const { control, setValue } = useFormContext();
  const { errors } = useFormState({ control });

  const fetchCustomerList = async () => {
    setIsLoading(true);
    try {
      const response = await getCustomersById(listCustomers);
      const customerOptions = makeSelectOptions({
        data: response.data?.customers ?? [],
        keyLabel: "name",
        keyValue: "id",
      });
      const orderOptions = orderByName(customerOptions);
      setCustomers(orderOptions);
    } catch (err) {
      toast.error(
        "Erro ao carregar lista de empresas. Entre em contato com o suporte.",
      );
    } finally {
      setIsLoading(false);
    }
  }

  const fetchRoles = async () => {
    setIsLoading(true);
    try {
      const response = await getRoles();
      const permissions = getGroupedRoles(response.data.data);
      const hasViewPermission = hasPermission({ scopes: ["view_super_admin_option"] });
      const { permissionsTableData, accessTypes } = getFilteredPermissions(permissions, hasViewPermission);

      setRoles(accessTypes);
      setTableData(permissionsTableData);
    } catch (err) {
      toast.error(
        "Erro ao carregar lista de perfis. Entre em contato com o suporte.",
      );
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (listCustomers) {
      fetchCustomerList()
      fetchRoles();
    }
  }, [listCustomers]);

  // whatchers
  const customerOption = useWatch({ control, name: "customerOption" });
  const roleOption = useWatch({ control, name: "roleOption" });
  const columns = makeColumns();

  const handleCustomers = async () => {
    await new Promise(resolve => setTimeout(resolve, 1000));
    let customersEdit = customers.filter(elm => customerOption.includes(elm.value))
    setValue("customer", customersEdit);
    setSelectedVehicles(customersEdit)
  }

  useEffect(() => {
    if (customerOption && customers && customers.length > 0) {
      handleCustomers();
    }
  }, [customers]);

  useEffect(() => {
    if (roleOption && roles && roles.length > 0) {
      setValue(
        "role",
        roles.find((elm) => elm.value === roleOption),
      );
    }
  }, [roleOption, roles]);

  const handleSelectedVehicles = async (selected, isAllCustomers) => {
    setValue("customer", selected)
    setValue("isAllCustomers", isAllCustomers)
  };

  if (isLoading) {
    return <FormUserSkeleton />;
  }

  return (
    <Aux>
      <Paper elevation={0} className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
            <Box sx={{ m: 2 }}>
              <MultiSelectUsers
                isDetail={false}
                isSingleMode={false}
                listData={customers}
                handleSelectedListData={handleSelectedVehicles}
                selectedData={selectedVehicles}
                isAllCustomers={props.isAllCustomers}
              />
              <FormHelperText className={classes.formHelperText} error={true}>
                {errors.customer?.message}
              </FormHelperText>
            </Box>
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
            <Box sx={{ m: 2 }}>
              <Input
                name="name"
                variant="outlined"
                fullWidth
                label="Nome completo *"
                helperText={errors.name?.message}
                error={errors.name && true}
              />
            </Box>
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
            <Box sx={{ m: 2 }}>
              <Input
                name="email"
                variant="outlined"
                fullWidth
                helperText={errors.email?.message}
                error={errors.email && true}
                label="E-mail"
                autoComplete={'new-email'}
              />
            </Box>
          </Grid>
          {!props.isEdit && (<Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
            <Box sx={{ m: 2 }}>
              <Input
                type="password"
                name="password"
                variant="outlined"
                fullWidth
                label="Senha *"
                helperText={errors.password?.message}
                error={errors.password && true}
                autoComplete={'new-password'}
              />
            </Box>
          </Grid>)}
          {/* MM-195 */}
          {(props.isAdding || hasPermission({ scopes: ["can_update_users_roles"] })) &&
            <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
              <Box sx={{ m: 2 }}>
                <Controller
                  control={control}
                  name="role"
                  render={({ field: { value = '', onChange, ...rest }, fieldState: { error } }) => (
                    <>
                      <StyledAutocomplete
                        {...rest}
                        options={roles}
                        renderInput={(params) => <TextField {...params} label="Tipo de acesso *" />}
                        value={value}
                        onChange={(_, value) => onChange(value)}
                        isOptionEqualToValue={(option, value) => value ? option.label === value?.label || value === '' : false}
                        getOptionLabel={(option) => option?.label ?? ''}
                        renderOption={({ key, ...props }, option) => (
                          <Box key={option.value} component="li" {...props}>
                            <Typography>{option.label}</Typography>
                          </Box>
                        )}
                        disablePortal
                        noOptionsText="Nenhum motorista cadastrado"
                      />
                      <FormHelperText className={classes.formHelperText} error={true}>
                        {error?.message}
                      </FormHelperText>
                    </>
                  )}
                />
              </Box>
            </Grid>
          }
        </Grid>
        {tableData.length > 0 && (
          <>
            {
              size.mobile ? <TableResponsive
                columns={columns}
                data={tableData}
                tableName="list-permissions-create-user"
                options={{ rowsPerPage }}
                setRowsPerPage={setRowsPerPage}
                tableWhite={true}
              /> : <Table
                columns={columns}
                data={tableData}
                tableName="list-permissions-create-user"
                options={{ rowsPerPage }}
                setRowsPerPage={setRowsPerPage}
                tableWhite={true}
              />
            }
          </>
        )}
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Box sx={{ mt: 4 }}>
            <Typography className={classes.explanation}>*Os campos marcados com o asterisco (*) são aqueles que precisam obrigatoriamente ser preenchidos.</Typography>
          </Box>
        </Grid>
      </Paper>
    </Aux>
  );
};

export default UserForm;
