import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";

import { Grid } from "@mui/material";
import { toast } from "react-toastify";

import { useAppSelector } from "store";

import { getVehiclesByOperation } from "services/vehicle";
import { handleAmplitudeEvent } from "services/amplitude";

import Title from "componentsNew/atoms/title/title";
import FilterStatus from "componentsNew/molecules/filterStatus/filterStatus";
import Map from "componentsNew/molecules/map/map";
import StatusEmpty from "componentsNew/atoms/statusEmpty/statusEmpty";
import { ModalReloadWebSocket } from "./modal";
import Aux from "hoc/auxiliar";
import PermissionsGate from "components/PermissionsGate";

import { orderVehiclesByCrescent } from "helpers/vehicles";

import { useVehicleWebSocket } from "./useVehicleWebsocket";
import { TypeStatus } from "helpers/statusVehicle";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setLiveMapSelectedVehicles } from "store/features/liveMapSlice";

import "./liveMap.css";

const zoom = 5

import VehicleSelect from "./VehicleSelect";

const LOCATION_CENTER = {
  lat: -15.823438,
  lng: -47.727483,
}
const LiveMap = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();

  const { currentCustomer, token } = useAppSelector((state) => state.global.user);
  const { liveMapSelectedVehicles } = useAppSelector((state) => state.liveMap);

  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [modal, setModal] = useState(false);

  const [vehicles, setVehicles] = useState([]);

  const { status, message, points } = useVehicleWebSocket({
    selectedVehicles: selectedVehicles || [],
    sessionId: token,
    singleVehicle: false
  });

  const statusCounts = useMemo(() => {
    const statusMap = { STOPPED_MOTOR_ON: 0, IN_MOVEMENT: 0, DANGER_BREAKDOWN: 0, STOPPED_MOTOR_OFF: 0 };

    message?.forEach((vehicle) => {
      const vehicleStatus = TypeStatus(vehicle.status);
      if (vehicleStatus === 'blue') statusMap.STOPPED_MOTOR_ON += 1;
      if (vehicleStatus === 'green') statusMap.IN_MOVEMENT += 1;
      if (vehicleStatus === 'yellow') statusMap.DANGER_BREAKDOWN += 1;
      if (vehicleStatus === 'gray') statusMap.STOPPED_MOTOR_OFF += 1;
    });

    return [
      { label: 'Motor ligado parado', count: statusMap.STOPPED_MOTOR_ON, color: 'blue' },
      { label: 'Em movimento', count: statusMap.IN_MOVEMENT, color: 'green' },
      { label: 'Risco de pane seca', count: statusMap.DANGER_BREAKDOWN, color: 'yellow' },
      { label: 'Motor desligado', count: statusMap.STOPPED_MOTOR_OFF, color: 'gray' },
    ];
  }, [message]);

  const fetchVehicles = useCallback(async () => {
    try {
      const response = await getVehiclesByOperation(currentCustomer);
      if (response.data.customers) {
        let orderedVehiclesByCustomer = response.data.customers.sort(
          (a, b) => (a.name > b.name) - (a.name < b.name)
        );
        const groupedOptions = orderedVehiclesByCustomer.map((option) => {
          const optionVehicles = option.vehicles
            .filter(item => item?.devicesHistory?.length)
            .filter(item => item?.currentDevice?.identification !== '');

          return {
            label: option.name,
            options: orderVehiclesByCrescent(optionVehicles).map(vehicle => ({
              ...vehicle,
              value: vehicle.id,
              label: vehicle.plate,
              brand: vehicle.brand,
              model: vehicle.model,
              fabricationYear: vehicle.fabricationYear,
            })),
          };
        });

        setVehicles(groupedOptions);
      }
    } catch (err) {
      toast.error("Erro ao carregar lista de veículos. Entre em contato com o suporte.");
    }
  }, [currentCustomer]);


  useLayoutEffect(() => {
    handleAmplitudeEvent('Live Map Screen Viewed');
    fetchVehicles();
  }, []);

  const handleSelectedData = (selected) => {
    setSelectedVehicles(selected);
    dispatch(setLiveMapSelectedVehicles(selected));
  };

  useEffect(() => {
    if (state?.wasDetail) {
      handleSelectedData(liveMapSelectedVehicles);
    }
  }, [state?.wasDetail]);

  return (
    <Aux>
      <PermissionsGate scopes={['can_view_liveMap']}>
        <div className={`LiveMap-container`}>
          <ModalReloadWebSocket modal={modal} setModal={setModal} />
          <Grid container className={`LiveMap-container-top`}>
            <Grid item className={`LiveMap-title`}>
              <Title value={"Mapa ao vivo"} />
              <span className={`LiveMap-description1`}>
                Visualize a sua frota em tempo real e tenha mais <br />
                controle sobre os status de cada veículo
              </span>
            </Grid>
            <Grid item className={`LiveMap-select`}>
              {/* <MultiSelect
            listData={vehicles}
            handleSelectedListData={handleSelectedData}
            selectedData={selectedVehicles}
            initDebounce={false}
          /> */}
              <VehicleSelect
                options={vehicles}
                onChange={handleSelectedData}
                selectedValues={selectedVehicles}
              />
            </Grid>
          </Grid>
          {selectedVehicles?.length > 0 ? (
            status === "error" ? (
              <StatusEmpty
                title={"Ocorreu um erro ao realizar a busca."}
                description="Nenhum resultado para exibir, entre em contato com o suporte ou tente novamente mais tarde."
              />
            ) : status === "empty" || status === 'disconnected' ? (
              <StatusEmpty
                title={"Não há resultados para mostrar."}
                description="Nenhum resultado para exibir com esses filtros, verifique-o ou entre em contato com o suporte caso tenha certeza que era para exibir informações."
              />
            ) : (
              <>
                {statusCounts?.length > 0 && (
                  <>
                    <span className={`LiveMap-description1`}>
                      Ative ou desative selecionando abaixo o tipo de status que
                      deseja visualizar
                    </span>
                    <FilterStatus statusCounts={statusCounts} />
                  </>
                )}
                {message && message.length > 0 ? <Map
                  message={message}
                  points={points}
                  initLocationCenter={LOCATION_CENTER}
                  initZoom={zoom}
                  height={"75vh"}
                /> : <StatusEmpty
                  title={"Não há resultados para mostrar."}
                  description="Nenhum resultado para exibir com esses filtros, verifique-o ou entre em contato com o suporte caso tenha certeza que era para exibir informações."
                />}
              </>
            )
          ) : (
            <StatusEmpty
              title="Selecione o filtro acima."
              description="Para realizar a busca, é necessário selecionar uma ou mais frota."
            />
          )}
        </div>
      </PermissionsGate>
    </Aux>

  );
}


export default LiveMap;