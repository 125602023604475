import React, { memo, useCallback, useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { Grid, Box } from "@mui/material";
import { toast } from "react-toastify";
import dayjs from "dayjs";

// services
import { getOperationHistory } from "services/operations";
import { handleAmplitudeEvent } from "services/amplitude";

// components
import Aux from 'hoc/auxiliar';
import BackButton from 'components/BackButton';
import Table from 'components/Table';
import Widget from 'components/Widget/Widget';
import OperationListHistorySkeleton from "components/Skeletons/OperationListHistorySkeleton";
import TableResponsive from "components/TableResponsive";

import { makeColumns } from "./tableColumns";

import { useWindowSize } from "hooks/useWindowsSize";

const OperationListHistory = () => {
  const { state } = useLocation();
  const [loading, setLoading] = useState(true);
  const [groupHistories, setGroupHistories] = useState([]);

  useEffect(() => {
    if (state.operationHistoryId) {
      fetchOperationHistory(state?.operationHistoryId);
    }
  }, [state]);

  const fetchOperationHistory = async (historyId) => {
    setLoading(true);
    try {
      const response = await getOperationHistory(historyId);

      if (response.status === 200 || response.status === 201) {

        const { operationHistories } = response.data?.data
        if (!operationHistories?.length) {
          setLoading(false);
          return;
        }

        const onMapHistoriesData = operationHistories?.map((history) => {
          if (history?.historyType === 'CREATION') {
            return {
              modification_type: 'Criação do grupo',
              user: `${history?.execName} ${history?.execEmail ? `- ${history?.execEmail}` : ''}`,
              datetime: dayjs(history.createdAt).format('DD/MM/YYYY HH:mm'),
            };
          }

          return history?.changes?.map((change) => {
            const changeKey = Object.keys(change)[0];
            const mapChangeNameToText = {
              rewardTargetMileage: 'Meta de Km rodado por mês',
              enabled: 'Ativo',
              name: 'Nome do grupo',
              operationTypeId: 'Tipo de bonificação',
              rewardMaxAmount: 'Bonificação máx por usuário',
              vehicles: 'Veículos do grupo',
              deleted_at: null,
              minScore: 'Nota mínima',
              rewardFormat: 'Formato da bonificação',
            }

            if (!mapChangeNameToText[changeKey]) {
              return false
            }

            const parsedChange = {
              modification_type: mapChangeNameToText[changeKey],
              modificationData: { modification: change[changeKey], changeKey: changeKey },
              user: `${history?.execName} ${history?.execEmail ? `- ${history?.execEmail}` : ''}`,
              datetime: dayjs(history.createdAt).format('DD/MM/YYYY HH:mm'),
            };

            return parsedChange;
          });
        }).flat().filter(item => item);

        const handleHistoriesModifications = onMapHistoriesData.map((operationHistoric) => {
          let modificationText = '';
          const { modificationData: { changeKey, modification } = {} } = operationHistoric;
        
          if (!changeKey || !modification) return { ...operationHistoric, modification: 'Grupo de veículos criado com sucesso.' };
        
          if (changeKey === 'rewardFormat') {
            modificationText = `Era: ${modification.was || 'Não definido'} | Alterado para: ${modification.is}`;
            if (modification.recalc_info) {
              modificationText += ` | ${modification.recalc_info}`;
            }
            return { ...operationHistoric, modification: modificationText };
          }
          
          if (changeKey === 'vehicles') {
            if (modification?.removed) {
              const removedVehicles = modification?.removed?.filter(vehicle => vehicle?.vName)
                .reduce((prev, curr) => [...prev, curr.vName], []).join(', ');
              modificationText += ` ${removedVehicles ? `Removidos: ${removedVehicles}` : ''}`;
            }
        
            if (modification?.inserted) {
              const insertedVehicles = modification?.inserted?.filter(vehicle => vehicle?.vName)
                .reduce((prev, curr) => [...prev, curr.vName], []).join(', ');
              modificationText += ` ${insertedVehicles ? 'Inseridos:' + insertedVehicles : ''}`;
            }
        
            modificationText = modificationText || 'Sem alterações';
            return { ...operationHistoric, modification: modificationText };
          }
        
          let wasValue = typeof modification.was === 'boolean' ? modification.was === true ? 'Ativo' : 'Inativo' : modification.was;
          if (changeKey === 'rewardMaxAmount') {
            wasValue = wasValue.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            });
          }
          modificationText += ` ${`Era: ${wasValue}`}`;
        
          let isValue = typeof modification.is === 'boolean' ? modification.is === true ? 'Ativo' : 'Inativo' : modification.is;
          if (changeKey === 'rewardMaxAmount') {
            isValue = isValue.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            });
          }
          modificationText += ` ${isValue ? `| Alterado para: ${isValue}` : ''}`;
        
          // Adicionando o recalc_info, se estiver presente
          if (modification.recalc_info) {
            modificationText += ` | ${modification.recalc_info}`;
          }

          
        
          return { ...operationHistoric, modification: modificationText };
        });
        setGroupHistories(handleHistoriesModifications);
      }

    } catch (error) {
      toast.error('Erro ao buscar histórico do grupo. Contate o suporte')
    } finally {
      setLoading(false);

    }

  };

  const size = useWindowSize()

  const columns = makeColumns(size);

  const renderModal = useCallback(
    () => (
      size.mobile ?
        <TableResponsive
          columns={columns}
          data={groupHistories}
          options={{
            download: false,
            setRowProps: (row, index) => {
              return { style: { backgroundColor: "white", cursor: 'default' } }
            },
          }}
          paddingBottom="0px"
          tableName="operation-list-history"
        /> :
        <Table
          columns={columns}
          data={groupHistories}
          options={{
            download: false,
          }}
          paddingBottom="0px"
          tableName="operation-list-history"
        />
    ),
    [columns, groupHistories],
  );

  useEffect(() => {
    handleAmplitudeEvent('Vehicle Group Logs Screen Viewed');
  }, []);

  return (
    <Aux>
      <Grid container spacing={4}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Widget disableWidgetMenu title="Histórico do grupo de veículos">
            {loading ? (
              <OperationListHistorySkeleton />
            ) : (
              <Grid container spacing={2} justifyContent="space-between">
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  {renderModal()}
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  {size.mobile ?
                    <Grid sx={{ paddingBottom: 12 }}>
                      <BackButton />
                    </Grid> :
                    <Box sx={{ width: 160, float: 'right', paddingBottom: 12 }}>
                      <BackButton />
                    </Box>}
                </Grid>
              </Grid>
            )}
          </Widget>
        </Grid>
      </Grid>
    </Aux>
  );
}


export default memo(OperationListHistory);
