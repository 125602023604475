import React, { useState } from "react";

import { Paper, Grid } from "@mui/material";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

// styles
import { colourStyles } from './styles'
import useStyles from "./useStyles";
import Typography from "@mui/material/Typography";

export default function OperationFilters({ handleCutOffDate, handleOperation, cutOffDateOptions, operationsOptions }) {
  const classes = useStyles();
  const today = new Date()
  const animatedComponents = makeAnimated();
  const [selectedOperation, setSelectedOperation] = useState([])
  const [selectedCutOffDate, setSelectedCutOffDate] = useState(cutOffDateOptions ? cutOffDateOptions[today.getMonth()] : [])

  const onChangeOperation = (list, index) => {
    handleOperation(list, index)
    setSelectedOperation(list)
  }

  const onChangeCutOffDate = (cutOffDate) => {
    handleCutOffDate(cutOffDate)
    setSelectedCutOffDate(cutOffDate)
  }

  return (
    <Grid container justifyContent="space-around">
      {!isNaN(operationsOptions) ? (
        <Paper elevation={0} className={classes.center}>
          <Typography>Não foram encontradas operações.</Typography>
        </Paper>
      ) : (
        <Paper elevation={0} className={classes.paper}>
          <Select
            placeholder="Rodoviário"
            options={operationsOptions}
            styles={colourStyles}
            onChange={(list, index) => onChangeOperation(list, index)}
            value={selectedOperation}
            components={animatedComponents}
            noOptionsMessage={() => "Nenhum resultado encontrado!"}
          />
        </Paper>
      )}
      {cutOffDateOptions === null ? (
        <Paper elevation={0} className={classes.center}>
          <Typography>Não foram encontradas data de corte.</Typography>
        </Paper>
      ) : (
        <Paper elevation={0} className={classes.paper}>
          <Select
            placeholder="Data de corte"
            options={cutOffDateOptions}
            styles={colourStyles}
            components={animatedComponents}
            onChange={(date) => onChangeCutOffDate(date)}
            value={selectedCutOffDate}
            defaultValue={cutOffDateOptions ? cutOffDateOptions[today.getMonth()] : []}
          />
        </Paper>
      )}
    </Grid>
  )
}
