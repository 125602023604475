import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router-dom';
import { Box, Button, Grid, Typography } from '@mui/material';
import { toast } from 'react-toastify';

// domain
import schemaValidation from 'domain/validation/yup/standardRewardGroup';

// services
import { createOperationType } from 'services/operationType';
import { createIndicators } from 'services/indicators';

// helpers
import { checkAndWarnWeights } from 'helpers/checkAndWarnWeights';

// models
import IndicatorModel from 'models/Indicator';
import RewardGroupModel from 'models/RewardGroup';

// components
import Aux from 'hoc/auxiliar';
import BackButton from 'components/BackButton';
import Standard from '../components/standard';
import { defaultValues } from './defaultValues'

// styles
import useStyles from './styles';

const CreateStandard = () => {
  const classes = useStyles();
  const history = useHistory();
  const customer = localStorage.getItem('customers');

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schemaValidation),
    mode: 'onChange',
  });

  const resetForm = () => {
    methods.setValue("name", "");
    methods.setValue("highControll", []);
    methods.setValue("mediumControll", []);
    methods.setValue("lowControll", []);
    methods.setValue("penalizingHighControll", []);
    methods.setValue("penalizingMediumControll", []);
    methods.setValue("penalizingLowControll", []);
    methods.setValue("penalty_score", '');
  };

  const onSubmit = async () => {
    const result = await methods.trigger([
      "name",
      "rewardType",
      "customControll",
      "highControll",
      "mediumControll",
      "lowControll",
      "penalizingHighControll",
      "penalizingMediumControll",
      "penalizingLowControll",
      "penalty_score"
    ]);

    if (result) {
      try {
        const data = methods.getValues();

        if (checkAndWarnWeights(data.highControll, 'indicators') ||
          checkAndWarnWeights(data.mediumControll, 'indicators') ||
          checkAndWarnWeights(data.lowControll, 'indicators')) {
          toast.warning('A soma dos pesos dos indicadores de cada quadro deve ser 100.');

          return;
        } else if (checkAndWarnWeights(data.penalizingLowControll, 'penalizers') ||
          checkAndWarnWeights(data.penalizingMediumControll, 'penalizers') ||
          checkAndWarnWeights(data.penalizingHighControll, 'penalizers')) {
          toast.warning('A soma dos pesos dos penalizadores de cada quadro deve ser 100.');

          return;
        }

        const rewardGroup = new RewardGroupModel({
          name: data.name,
          type: 1,
          description: "",
          penalty_score: Number(data.penalty_score),
          customer_id: Number(customer)
        });

        const responseOperationType = await createOperationType(rewardGroup);
        if (responseOperationType?.code !== 200) {
          throw new Error("Erro ao criar a operação");
        }

        let indicatorsList = [
          ...data.highControll,
          ...data.mediumControll,
          ...data.lowControll,
          ...data.penalizingHighControll,
          ...data.penalizingMediumControll,
          ...data.penalizingLowControll,
        ];

        const indicators = indicatorsList.map(
          (ind) => {
            return new IndicatorModel({
              id: ind.id,
              lowerLimit: ind.acceptablePercentage,
              upperLimit: ind.idealPercentage,
              weight: ind.weight,
              requirementLevel: ind.requirement_level,
              penalizing: ind.penalizing || false
            })
          }
        );

        const responseCreateIndicators = await createIndicators({
          operation_type_id: responseOperationType.data.operation_type_id,
          indicators,
        });


        if (responseCreateIndicators?.code !== 200) {
          throw new Error("Erro ao criar os indicadores");
        }
        toast.success("Grupo criado com sucesso!");
        history.push("/gofurther/rewardGroup/list-rewardGroup");
        resetForm();
      } catch (err) {
        toast.error(err.message);
        console.log(err);
      }
    }
  };

  return (
    <Aux>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Box sx={{ mt: 4 }}>
                  <Typography className={classes.explanation}>
                    * Os campos marcados com o asterisco (*) são aqueles que precisam obrigatoriamente ser preenchidos.
                  </Typography>
                </Box>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.reward}>
                <Standard />
              </Grid>
              <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                <Box sx={{ m: 2 }}>
                  <BackButton className={classes.backButton} />
                </Box>
              </Grid>
              <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                <Box sx={{ m: 2 }}>
                  <Button
                    fullWidth
                    variant="contained"
                    type="submit"
                    className={classes.btConfirm}
                  >
                    Salvar
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Aux>
  );
};

export default CreateStandard;
