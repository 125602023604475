import React, { memo } from "react";
import {
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Skeleton } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import FleetChart from "../FleetChart";

// styles
import useStyles from "./styles";
import { handleAmplitudeEvent } from "services/amplitude";

const ModalFleetChart = ({
  showFleetChart,
  handleFleetChart,
  loading,
  chartData,
  selectedVehicles,
  selectedDates,
}) => {
  const classes = useStyles();
  const handleClose = () => {
    handleAmplitudeEvent('Chart Modal Closed');
    handleFleetChart(false);
  };

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={showFleetChart}
      onClose={handleClose}
      fullWidth={true}
      scroll={"body"}
      maxWidth="lg"
      aria-labelledby="Gráfico"
      aria-describedby="Combustível, RPM, Altitude e Velocidade"
    >
      <IconButton className={classes.customizedButton} onClick={handleClose} size="large">
        <CloseIcon />
      </IconButton>
      <DialogContent style={{ padding: '12px 24px' }}>
        <Grid
          container
          spacing={3}
          data-cy="allElements"
          className={classes.container}
        >
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            {loading || !chartData ? (
              <Grid container spacing={2}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Typography className={classes.loading}>
                    Carregando...
                  </Typography>
                  <Skeleton width="100%" height={250} />
                </Grid>
              </Grid>
            ) : (
              <FleetChart
                loading={loading}
                data={chartData}
                selectedVehicles={selectedVehicles}
                selectedDates={selectedDates}
                tooltip={true}
                chartId="dashboard-chart"
              />
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default memo(ModalFleetChart);
