import { makeStyles } from "@mui/styles";
import { alpha } from '@mui/material/styles';

export default makeStyles((theme) => ({
  btnBack: {
    backgroundColor: theme.palette.background.backgroundButton,
    boxShadow: "none",
    borderRadius: "10px",
    padding: "8px 24px",
    textTransform: "none",
    color: theme.palette.text.title,
    "&:hover": {
      backgroundColor: alpha(theme.palette.background.backgroundButton, 0.8),
    },
  },
}));
