/* eslint-disable camelcase */
export const color_neutral_0 = '#FFFFFF'
export const color_neutral_100 = '#FAFAFA'
export const color_neutral_200 = '#F4F4F6'
export const color_neutral_300 = '#F0F0F0'
export const color_neutral_400 = '#DFDFE2'
export const color_neutral_500 = '#C5C6C9'
export const color_neutral_600 = '#AAABB1'
export const color_neutral_700 = '#909198'
export const color_neutral_800 = '#6B6C76'
export const color_neutral_900 = '#575860'
export const color_neutral_1000 = '#44444B'
export const color_neutral_1100 = '#313135'
export const color_neutral_1200 = '#0B0B0E'

export const color_yellow_50 = '#FFFBF2'
export const color_yellow_100 = '#FFF7E4'
export const color_yellow_200 = '#FFEEC6'
export const color_yellow_300 = '#FFE6A3'
export const color_yellow_400 = '#FFDD75'
export const color_yellow_500 = '#FFD31C'
export const color_yellow_600 = '#E4BD19'
export const color_yellow_700 = '#C6A316'
export const color_yellow_800 = '#A18512'
export const color_yellow_900 = '#725E0D'

export const color_blue_50 = '#F2F4FB'
export const color_blue_100 = '#E4E9F8'
export const color_blue_200 = '#C6D0F0'
export const color_blue_300 = '#A2B5E8'
export const color_blue_400 = '#7394E0'
export const color_blue_500 = '#0D69D7'
export const color_blue_600 = '#0C5EC0'
export const color_blue_700 = '#0A51A7'
export const color_blue_800 = '#084288'
export const color_blue_900 = '#062F60'

export const color_green_50 = '#F1FEF1'
export const color_green_100 = '#E5FDE4'
export const color_green_200 = '#C8FAC6'
export const color_green_300 = '#A6F8A1'
export const color_green_400 = '#7AF572'
export const color_green_500 = '#40D81B'
export const color_green_600 = '#2BD900'
export const color_green_700 = '#25BC00'
export const color_green_800 = '#1E9A00'
export const color_green_900 = '#156D00'

export const color_orange_50 = '#FFF4E5'
export const color_orange_100 = '#FFECD1'
export const color_orange_200 = '#FFD9A3'
export const color_orange_300 = '#FFC576'
export const color_orange_400 = '#FFB248'
export const color_orange_500 = '#FF9F1A'
export const color_orange_600 = '#E18300'
export const color_orange_700 = '#A96200'
export const color_orange_800 = '#704100'
export const color_orange_900 = '#382100'

export const color_red_50 = '#FCF2F2'
export const color_red_100 = '#F9E5E4'
export const color_red_200 = '#F4C7C6'
export const color_red_300 = '#EEA3A3'
export const color_red_400 = '#E87675'
export const color_red_500 = '#E2211C'
export const color_red_600 = '#CA1E19'
export const color_red_700 = '#AF1A16'
export const color_red_800 = '#8F1512'
export const color_red_900 = '#650F0D'

export const color_purple_50 = '#F5F2FF'
export const color_purple_100 = '#EBE4FE'
export const color_purple_200 = '#D5C6FD'
export const color_purple_300 = '#BCA2FD'
export const color_purple_400 = '#9F74FC'
export const color_purple_500 = '#7C19FB'
export const color_purple_600 = '#6F16E1'
export const color_purple_700 = '#6013C2'
export const color_purple_800 = '#4E109F'
export const color_purple_900 = '#370B70'
