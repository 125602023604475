export const getAssignType = (value) => {
    switch (value) {
      case 1:
        return 'Tela'
      case 2:
        return 'Aplicativo'
      case 3:  
      case 4:
        return 'Integração API'
      default:
        return 'Desconhecido'
    }
}