import { alpha } from "@mui/material";
import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  textBold: {
    fontWeight: "bold",
  },
  textInfo: {
    fontStyle: "italic",
    color: theme.palette.text.secondary,
  },
  actionContent: {
    display: "flex",
    justifyContent: "space-around",
  },
  buttonDetail: {
    textTransform: "inherit",
    fontSize: 16,
    fontWeight: "bold",
    borderRadius: 10,
    boxShadow: "none",
  },
  actionButton: {
    transition: "0.3s",
    "&:hover": {
      cursor: "pointer",
      fontSize: 28,
    },
  },
  btnNew: {
    padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
    borderRadius: 10,
    backgroundColor: theme.palette.secondary.main,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: alpha(theme.palette.secondary.main, 0.8),
      fontWeight: 600
    },
  },
  btItem: {
    marginLeft: 'auto',
  },
  container: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(3),
  },
  title: {
    fontSize: 24,
    fontWeight: 500,
    color: theme.palette.text.title,
    marginLeft: theme.spacing(1),
    paddingTop: theme.spacing(4)
  },
  inputHidden: {
    display: "none",
  }
}));
