import React, { useState } from "react";
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Inbox as InboxIcon } from "@mui/icons-material";
import Icon from "@mdi/react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { handleAmplitudeEvent } from "services/amplitude";

import useStyles from "./styles";

export default function SidebarLink({
  link,
  icon,
  label,
  children,
  location,
  isSidebarOpened,
  nested,
  isChildren,
  onClose,
  bagde,
  isMenuAdministrative,
}) {
  var classes = useStyles();

  // local
  var [isOpen, setIsOpen] = useState(false);
  var isLinkActive =
    link &&
    (location.pathname === link || location.pathname.indexOf(link) !== -1);

  if (!children)
    return (
      <ListItem
        onClick={(e) => handleAmplitudeEvent('Menu Sidebar Clicked', { type: 'click', label: isMenuAdministrative ? `Área administrativa > ${label}` : label, isChildren: !!isChildren, description: 'User clicked on a link in the sidebar', })}
        button
        component={link && Link}
        to={link}
        className={classes.link}
        classes={{
          root: classnames(classes.linkRoot, {
            [classes.linkActive]: isLinkActive && !nested,
            [classes.linkNested]: nested,
          }),
        }}
        disableRipple
      >
        <ListItemIcon data-cy="svgLink"
          className={classnames(classes.linkIcon, {
            [classes.linkIconActive]: isLinkActive,
            [classes.linkIconChildren]: isChildren && !isLinkActive,
          })}
        >
          <Icon path={icon} size={1} />
          {bagde && <span className={classnames({ [classes.linkTextHidden]: isSidebarOpened }, classes.badgeMini)} />}
        </ListItemIcon>
        <ListItemText data-cy="textLink"
          classes={{
            primary: classnames(classes.linkTextTitle, {
              [classes.linkTextActive]: isLinkActive,
              [classes.linkTextHidden]: !isSidebarOpened,
              [classes.linkTextChildren]: isChildren,
              [classes.linkTextParent]: !isChildren
            }),
          }}
          primary={label}
        />
        {bagde && <span className={classnames({ [classes.linkTextHidden]: !isSidebarOpened }, classes.badgeNew)}>Novo</span>}

      </ListItem>
    );

  return (
    <>
      <ListItem
        button
        component={link && Link}
        onClick={toggleCollapse}
        className={classes.link}
        classes={{
          root: classnames(classes.linkRoot, {
            [classes.linkActive]: isLinkActive && !nested,
            [classes.linkNested]: nested,
          }),
        }}
        to={link}
        disableRipple
      >
        <ListItemIcon data-cy="svgLink2"
          className={classnames(classes.linkIcon, {
            [classes.linkIconActive]: isLinkActive,
          })}
        >
          {icon ? <Icon path={icon} size={1} /> : <InboxIcon />}
        </ListItemIcon>
        <ListItemText data-cy="textLink2"
          classes={{
            primary: classnames(classes.linkTextTitle, {
              [classes.linkTextActive]: isLinkActive,
              [classes.linkTextHidden]: !isSidebarOpened,
              [classes.linkTextParent]: !isChildren
            }),
          }}
          primary={label}
        />
      </ListItem>
      {children && (
        <Collapse data-cy="linkItemsLeftMenu"
          in={isOpen && isSidebarOpened}
          timeout="auto"
          unmountOnExit
          className={classes.nestedList}
        >
          <List component="div" disablePadding>
            {children.map(childrenLink => {
              return (
                <SidebarLink
                  onClose={onClose}
                  key={childrenLink && childrenLink.link}
                  location={location}
                  isSidebarOpened={isSidebarOpened}
                  classes={classes}
                  nested
                  {...childrenLink}
                />
              );
            })}
          </List>
        </Collapse>
      )}
    </>
  );

  // ###########################################################

  function toggleCollapse(e) {
    if (isSidebarOpened) {
      e.preventDefault();
      setIsOpen(!isOpen);
    }
  }
}
