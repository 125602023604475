import React from "react";
import PropTypes from "prop-types";
import { Checkbox, Grid } from "@mui/material";
import { colorMaps } from "componentsNew/atoms/clusterSingle/clusterSingle";
import { trunkSVG } from "./variables";

import "./filterStatus.css";

const FilterStatus = ({ statusCounts }) => {
  return (
    <div className="FilterStatus-container">
      <Grid className="FilterStatus-grid-container">
        {statusCounts?.map((status) => (
          <Grid key={status.id} className="FilterStatus-item-container">
            <span
              className="FilterStatus-item-icon"
              style={{ backgroundColor: colorMaps[status.color] }}
            >
              {trunkSVG}
            </span>
            <span className="FilterStatus-item-typography">
              {`(${status.count}) ${status.label}`}
            </span>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

FilterStatus.propTypes = {
  statusCounts: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      count: PropTypes.number,
      color: PropTypes.string,
      id: PropTypes.string
    })
  ),
  onFilterChange: PropTypes.func,
};

export default FilterStatus;
