import React from "react";
import PropTypes from "prop-types";
import { Skeleton } from '@mui/material';

import IconComponent from "components/IconComponent";

import "./item.css";

const Item = ({ icon = 0, label, size = "medium", labelStrong, loading = false }) => {
  return (
    <div className={"Item-container"}>
      {loading ?
        <Skeleton variant="rectangular"
          width={'100%'}
          height={20}
          style={{ borderRadius: "8px", margin: "2px", minWidth: 100 }}
        /> :
        <div className="Item-container-div">
          <IconComponent name={icon} size={size} />
          <div className="Item-container-span">
            {label && <span className={"Item-label"}>{label}</span>}
            {labelStrong && <span className={"Item-labelStrong"}>{labelStrong}</span>}
          </div>
        </div>
      }
    </div>
  );
};

export default Item;

Item.propTypes = {
  icon: PropTypes.any,
  label: PropTypes.any,
  size: PropTypes.any,
  labelStrong: PropTypes.any,
  loading: PropTypes.any,
};
