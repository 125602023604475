import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useFormContext, Controller } from "react-hook-form";
import classNames from 'classnames';
import useStyles from '../styles';
import { getAllPermissionByContext } from 'services/roles';
import { useHistory } from 'react-router';

const PermissionFormDriver = ({ formData }) => {
  const classes = useStyles();
  const { control, formState: { errors }, setValue } = useFormContext();
  const [checked, setChecked] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const history = useHistory();

  const collectIds = (item) => {
    let ids = [];

    if (item.permissions?.length) {
      ids.push(...item.permissions.map((permission) => permission.id));
    } else {
      ids.push(item.id);
    }

    if (item.children?.length) {
      item.children.forEach((child) => {
        ids.push(...collectIds(child));
      });
    }

    return ids;
  };

  const handleToggle = (item) => {
    setChecked((prevChecked) => {
      const ids = collectIds(item);

      const newChecked = ids.every((id) => prevChecked.includes(id))
        ? prevChecked.filter((id) => !ids.includes(id))
        : [...new Set([...prevChecked, ...ids])];

      return newChecked;
    });
  };

  const isItemChecked = (item) => {
    const ids = item?.permissions?.length ? item.permissions.map((permission) => permission.id) : [item.id];

    if (item.children?.length) {
      item.children.forEach((child) => {
        const childIds = child?.permissions?.length ? child.permissions.map((permission) => permission.id) : [child.id];
        ids.push(...childIds);
      });
    }

    return ids.every((id) => checked.includes(id));
  };


  useEffect(() => {
    const checkFormData = () => {
      if (formData) {
        setChecked(formData.permissions);
        setValue("label", formData.label);
      }
    }

    checkFormData()
  }, [formData, setValue]);


  useEffect(() => {
    const checkContext = async () => {
      if (history.location.pathname.includes("app")) {
        return await getAllPermissionByContext('app');
      } else {
        return await getAllPermissionByContext('web');
      }
    };

    checkContext().then(response => {
      response.status === 200 && setPermissions(response?.data?.data?.children)
    });
  }, []);

  useEffect(() => {
    const checkedPermissions = checked.filter(element => element !== undefined);
    setValue("permissions", checkedPermissions);
  }, [checked])

  const PermissionItem = (item) => {
    return (
      <ListItem
        role={undefined}
        dense
        button
        onClick={() => handleToggle(item)}
      >
        <ListItemIcon>
          <FormControlLabel
            control={
              <Controller
                render={(props) => {
                  const { ref, ...fieldProps } = props.field;
                  return (
                    <Checkbox
                      {...fieldProps}
                      edge='start'
                      tabIndex={-1}
                      disableRipple
                      checked={item && isItemChecked(item)}
                      value={fieldProps}
                      inputProps={{ 'aria-labelledby': item.id }}
                      style={{ color: '#F7DF36' }}
                    />
                  )
                }}
                name={`permissions[${item.id}]`}
                type="checkbox"
                control={control}
              />
            }
            label={<Typography id={item.id} className={classNames(classes.permission)}>{item.title}</Typography>}
            className={classNames(classes.permissionTop)}
          />
        </ListItemIcon>
      </ListItem>
    )
  };

  const Summary = (item) => {
    return (<AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-label='Expandir'
      aria-controls={`${item.id}-content`}
      id={`${item.id}-header`}
    >
      <FormControlLabel
        aria-label={`${item.id}`}
        onClick={(event) => event.stopPropagation()}
        onFocus={(event) => event.stopPropagation()}
        onChange={() => handleToggle(item)}
        control={
          <Checkbox
            edge='start'
            checked={item && isItemChecked(item)}
            tabIndex={-1}
            disableRipple
            inputProps={{ 'aria-labelledby': item.id }}
            style={{ color: '#F7DF36' }}
          />
        }
        label={<Typography className={classNames(classes.heading, {
          [classes.subheading]: item.subheading
        })}>{item.label}</Typography>}
      />
    </AccordionSummary>
    )
  };

  return (
    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
      <Box sx={{ m: 2 }}>
        <div className={classNames(classes.rootAccordion, classes.fullWidth)}>
          {errors && errors.permissions && (
            <Typography className={classes.errorMessage}>{errors.permissions.message}</Typography>
          )}
          {permissions?.length > 0 && permissions.map(value => {
            return (
              <Accordion
                key={value.id}
              >
                <Summary {...value} />
                <AccordionDetails>
                  <List className={classes.fullWidth}>
                    {value.permissions?.length > 0 &&
                      value.permissions.map(elm => <PermissionItem key={elm.id} {...elm} />)
                    }
                    {value.children && value.children?.length > 0 && (
                      <div className={classNames(classes.rootAccordion, classes.fullWidth)}>
                        {value.children.map(item => {
                          return (
                            <Accordion
                              key={item.id}
                              className={classNames(classes.fullWidth, classes.submenu)}
                            >
                              <Summary {...item} subheading children />
                              <AccordionDetails>
                                <List className={classes.fullWidth}>
                                  {item.permissions?.length > 0 &&
                                    item.permissions.map(elm => <PermissionItem key={elm.id} {...elm} />)
                                  }
                                </List>
                              </AccordionDetails>
                            </Accordion>
                          )
                        })}
                      </div>
                    )}
                  </List>
                </AccordionDetails>
              </Accordion>
            )
          })}
        </div>
      </Box>
    </Grid>
  );
};

export default PermissionFormDriver;
