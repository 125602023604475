import React, { useCallback, useState, useEffect, useRef } from 'react';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import {
  Box,
  Collapse,
  Popover,
  Typography
} from '@mui/material';
import { useReactToPrint } from 'react-to-print';
import * as XLSX from 'xlsx';
import { virtualizedTable } from './styles';
import { FilterPopover } from './fragments/filter-popover';
import { PrintableTable } from './fragments/printable-table';
import { ColumnSelector } from './fragments/column-selector';
import { TopActionButtons } from './fragments/top-action-buttons';
import { SearchBar } from './fragments/search-bar';
import { Row } from './fragments/row';
import { Pagination } from './fragments/pagination';
import { HeaderRow } from './fragments/header-row';
import { RowsPerPageSelector } from './fragments/rows-per-page-selector';
import useUserTableColumns from 'hooks/useUserTableColumns';
import { useAppSelector } from 'store';
import { updateUserSelection, insertUserSelection } from 'services/userSelection';

function VirtualizedTable({
  data,
  onLoadMore,
  columns,
  initialRowsPerPage = 15,
  firstColumnWidth = '20%',
  tableName,
  rowsPerPagebaseOptions = [5, 15, 25, 50, 100]
}) {
  const classes = virtualizedTable();
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
  const [page, setPage] = useState(0);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [filteredData, setFilteredData] = useState(data);
  const [visibleColumns, setVisibleColumns] = useState([]);
  const [columnSelectorAnchorEl, setColumnSelectorAnchorEl] = useState(null);
  const [filters, setFilters] = useState({});
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [currentFilterColumn, setCurrentFilterColumn] = useState(null);
  const remainingWidth = (100 - parseFloat(firstColumnWidth)) /  (visibleColumns.filter(col => col.display).length - 1);
  const { userId } = useAppSelector((state) => state.global.user);
  const allColumnsHidden = visibleColumns.length > 0 && visibleColumns.every(column => !column.display);
  const { userCustomizationColumns, fetchData } = useUserTableColumns(tableName);
  const printableTableRef = useRef(null);

  const handleFilterClick = (event, column) => {
    setFilterAnchorEl(event.currentTarget);
    setCurrentFilterColumn(column);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
    setCurrentFilterColumn(null);
  };

  const handleFilterChange = (columnName, values) => {
    setFilters(prev => ({
      ...prev,
      [columnName]: values
    }));
  };

  const handleFilter = useCallback(() => {
    const filtered = data.filter(item => {
      return Object.entries(filters).every(([key, values]) => {
        if (!values || values.length === 0) return true;
        return values.includes(item[key]);
      });
    });
    setFilteredData(filtered);
    setPage(0);
  }, [data, filters]);

  useEffect(() => {
    handleFilter();
  }, [filters, handleFilter]);

  const handleColumnSelectorClick = (event) => {
    setColumnSelectorAnchorEl(event.currentTarget);
  };

  const handleColumnSelectorClose = () => {
    setColumnSelectorAnchorEl(null);
  };

  const syncColumns = () => {  
    const fieldsFromAPI = userCustomizationColumns.data.fields;
    const missingColumns = columns.filter(
      col => !fieldsFromAPI.some(field => field.name === col.name)
    );
  
    if (missingColumns.length === 0) {
      setVisibleColumns(fieldsFromAPI);
    } else {
      const columnsNewFields = missingColumns.map(col => ({
        name: col.name,
        display: true,
      }));
  
      insertUserSelection(
        {
          tableName: tableName,
          fields: [...fieldsFromAPI, ...columnsNewFields],
        },
        userId
      )
        .then(() => {
          setVisibleColumns([...fieldsFromAPI, ...columnsNewFields]);
        })
        .catch(error => {
          console.error('Erro ao sincronizar colunas:', error);
        });
    }
  };
  

  useEffect(() => {
    if (userCustomizationColumns?.data?.fields && visibleColumns.length === 0) {
      syncColumns();
    }
  }, [userCustomizationColumns]);

  const handleColumnToggle = (columnNames, isVisible) => {
    setVisibleColumns(prev => {
      const updatedColumns = prev.map(column =>
        columnNames.includes(column.name)
          ? { ...column, display: isVisible }
          : column
      );

      updateUserSelection({
        tableName: tableName,
        fields: updatedColumns,
      }, userId)
        .catch(error => {
          console.error('Erro ao atualizar colunas:', error);
        });
  
      return updatedColumns; // Atualiza o estado local
    });
  };
  
  const handleSearch = (searchTerm) => {
    if (!searchTerm) {
      setFilteredData(data);
      return;
    }

    const lowercaseSearchTerm = searchTerm.toLowerCase();
    const filtered = data.filter(item => {
      return columns.some(column => {
        const value = item[column.name];
        return value && value.toString().toLowerCase().includes(lowercaseSearchTerm);
      });
    });

    setFilteredData(filtered);
    setPage(0);
  };

  const getCurrentPageData = () => {
    const startIndex = page * rowsPerPage;
    const endIndex = Math.min((page + 1) * rowsPerPage, filteredData.length);
    return filteredData.slice(startIndex, endIndex);
  };

  const handlePrint = useReactToPrint({
    content: () => printableTableRef.current,
    documentTitle: 'Gerenciamento de unidades eletrônicas',
    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        resolve();
      });
    },
  });

  const handleExcelExport = () => {
    const exportData = getCurrentPageData().map(item => {
      const row = {};
      columns.forEach(column => {
        if (column.options && column.options.customBodyRender) {
          row[column.label] = column.options.customBodyRender(item[column.name], {});
        } else {
          row[column.label] = item[column.name];
        }
      });
      return row;
    });

    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Dados");

    XLSX.writeFile(wb, "gerenciamento_de_unidades_eletrônicas.xlsx");
  };

  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = event.target.value;
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
    if (isSearchOpen) {
      setFilteredData(data);
    }
  };

  useEffect(() => {
    if ((page + 1) * rowsPerPage > filteredData.length) {
      onLoadMore();
    }
  }, [page, rowsPerPage, filteredData.length, onLoadMore]);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  return (
    <Box className={classes.tableRoot}>
      <TopActionButtons
        onSearchClick={toggleSearch}
        isSearchOpen={isSearchOpen}
        onPrint={handlePrint}
        onExcelExport={handleExcelExport}
        onColumnSelectorClick={handleColumnSelectorClick}
        allColumnsHidden={allColumnsHidden}
      />
       {allColumnsHidden || !data.length ? (
        <Box className={classes.emptyState}>
          <Typography variant="h6" align="center" color="textSecondary">
            Selecione ao menos uma coluna para exibir as informações.
          </Typography>
        </Box>
      ) : (
        <>
          <Collapse in={isSearchOpen}>
            <SearchBar
              onSearch={handleSearch}
              onClose={() => setIsSearchOpen(false)}
            />
          </Collapse>
      <HeaderRow
        columns={columns}
        firstColumnWidth={firstColumnWidth}
        remainingWidth={remainingWidth}
        onFilterClick={handleFilterClick}
        visibleColumns={visibleColumns}
      />
      <Box className={classes.listContainer}>
        <AutoSizer>
          {({ height, width }) => (
            <List
              height={height}
              itemCount={getCurrentPageData().length}
              itemSize={50}
              width={width}
              className={classes.scrollBox}
            >
              {({ index, style }) => (
                <Row
                  index={index}
                  style={style}
                  item={getCurrentPageData()[index]}
                  columns={columns}
                  visibleColumns={visibleColumns}
                  firstColumnWidth={firstColumnWidth}
                  remainingWidth={remainingWidth}
                  classes={classes}
                />
              )}
            </List>
          )}
        </AutoSizer>
      </Box>
        <Box className={classes.footer}>
          <RowsPerPageSelector
            rowsPerPage={rowsPerPage}
            handleRowsPerPageChange={handleRowsPerPageChange}
            totalNumberOfItems={data.length}
            rowsPerPagebaseOptions={rowsPerPagebaseOptions}
          />
          <Pagination
            page={page}
            rowsPerPage={rowsPerPage}
            totalItems={filteredData.length}
            onChangePage={handleChangePage}
            classes={classes}
          />
        </Box>
        </>
      )}
      <div style={{ display: 'none', position: 'absolute', left: '-9999px' }}>
        <PrintableTable
          ref={printableTableRef}
          data={getCurrentPageData()}
          columns={columns}
        />
      </div>
      <Popover
        open={Boolean(columnSelectorAnchorEl)}
        anchorEl={columnSelectorAnchorEl}
        onClose={handleColumnSelectorClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <ColumnSelector
          columns={columns}
          visibleColumns={visibleColumns}
          onColumnToggle={handleColumnToggle}
        />
      </Popover>
      <Popover
        open={Boolean(filterAnchorEl)}
        anchorEl={filterAnchorEl}
        onClose={handleFilterClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {currentFilterColumn && (
          <FilterPopover
            column={currentFilterColumn}
            data={data}
            onFilterChange={handleFilterChange}
            currentFilters={filters}
            onClose={handleFilterClose}
          />
        )}
      </Popover>
    </Box>
  );
}

export default VirtualizedTable;