import React from "react";
import PropTypes from "prop-types";

import Item from "../../../componentsNew/atoms/item/item";

import "./headerGraphic.css";

const HeaderGraphic = ({ vehicle = "", date = "" }) => {
  const labelVehicle = vehicle.length > 25 ? `${vehicle.substring(0, 25)}...` : vehicle;
  return (
    <div className={`HeaderGraphic-container`}>
      <Item icon={'truck_full'} label={labelVehicle} />
      <Item icon={'date_calendar'} label={date} />
    </div>
  );
};

export default HeaderGraphic;

HeaderGraphic.propTypes = {
  /**
   * Placa/Identificador do veículo
   */
  vehicle: PropTypes.string.isRequired,
  /**
   * Data
   */
  date: PropTypes.string.isRequired,
};
