import { Grid } from '@mui/material';
import React from 'react';
import {
  Cell,
  Label,
  Pie,
  PieChart,
  ResponsiveContainer,
  Text,
} from 'recharts'
import tinycolor from 'tinycolor2'
import colors from '../../themes/gobrax'

function HalfMoonChart(props) {

  const { value, color, label } = props.data

  const data = [
    { name: label, value: 100-value, color: tinycolor(color).lighten(35).toHexString() },
    { name: label, value: value, color: color }, 
  ];
  
  return (
    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
      <ResponsiveContainer height={140} width={!props.print ? '99%' : 230}>
        <PieChart>
          <Pie
            data={data}
            startAngle={0}
            endAngle={180}
            innerRadius={45}
            outerRadius={58}
            paddingAngle={-5}
            cornerRadius={10}
            dataKey="value"
            isAnimationActive={false}
          >
            {data?.map((entry, index) => {
              return (
                <Cell key={index} fill={entry.color} />
              )
            })}
            <Label
              content={(props) => {
                const positioningProps = {
                  x: props.viewBox.cx,
                  y: props.viewBox.cy-15,
                  textAnchor: 'middle',
                  verticalAnchor: 'middle',
                };
                const presentationProps = {
                  fill: colors.palette.black,
                  fontWeight: 500,
                  fontSize: 20,
                };
                return (
                  <Text
                    {...positioningProps}
                    {...presentationProps}
                  >
                    {data[1].value}
                  </Text>
                );
              }}
            />
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </Grid>
  )
}

export default HalfMoonChart;
