import React, { useEffect, useState, useCallback } from "react";
import { format } from "date-fns";
import { toast } from "react-toastify";
import { Grid, Typography } from "@mui/material";

import DieselControlSkeleton from "components/Skeletons/DieselControlSkeleton";
import { ModalDieselControl } from "componentsNew/organisms/modalDieselControl/modalDieselControl";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";
import StatusEmpty from "componentsNew/atoms/statusEmpty/statusEmpty";
import TableResponsive from "components/TableResponsive";
import { TitlePage } from "components/TitlePage";

import { OperationsSelect } from "components/OperationsSelect";

import { getApproximateDate } from "helpers/fleetChart";
import { convertDateStringToDB } from "helpers/dates";
import { valueToFloat } from "helpers/functions";

import { useWindowSize } from "hooks/useWindowsSize";
import { useAppDispatch, useAppSelector } from "store";
import { setVehicleFilters, clearVehicleFilters } from "store/features/vehicleDashboardSlice";

import {
  getFleetLocations,
} from "services/fleetPerformance";
import { getVehiclesByOperation } from "services/vehicle";
import { getActualCheckMultiple } from "services/fuel";
import { handleAmplitudeEvent } from "services/amplitude";

import { makeColumns } from "./tableColumns";

import useStyles from "./styles";

const CurrentDieselControl = () => {
  const classes = useStyles();

  const size = useWindowSize();

  const dispatch = useAppDispatch();

  const { filters } = useAppSelector((state) => state.vehicleDashboard);

  const dbDateFormat = "yyyy-MM-dd'T'HH:mm:ssXX";

  const [vehicles, setVehicles] = useState([]);
  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [dieselData, setDieselData] = useState(null);
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingOptions, setLoadingOptions] = useState(false)
  const [loadingModalMap, setLoadingModalMap] = useState(false);
  const [showModal, setShowModal] = useState(false);
  
  const [selectedDates, setSelectedDates] = useState({
    initialDate: convertDateStringToDB(new Date().setHours(0, 0, 0, 0)), 
    finalDate: convertDateStringToDB(new Date().setHours(23, 59, 59, 999)), 
  });
  
  const { currentCustomer } = useAppSelector((state) => state.global.user);

  const maxDate = new Date();
  maxDate.setHours(23, 59, 0, 0);

  const isSideMenuOnDashboard = window.location.pathname.includes('dashboard');

  const sanitizeVehicles = useCallback(vehicles => vehicles.map((vehicle) => {
    const vehicleLabel = `${vehicle.identification} - ${vehicle.plate}`;
    const driverLabel = vehicle.currentDriver?.driverName ? ` - (Motorista atual: ${vehicle.currentDriver.driverName})` : '';

    return {
      id: vehicle.id,
      label: `${vehicleLabel}${driverLabel}`,
      operation: {
        id: vehicle.operationId,
        label: vehicle.operation_name
      }
    };
  }), []);

  const handleVehiclesChange = useCallback((selected) => {
    setSelectedVehicles(selected);

    if(!selected.length) {
      setDieselData(null);
    }

    handleAmplitudeEvent(
      'Filter Updated',
      { 
        label: "Frota",
        value: selected.map(item => item?.label).join(", ")
      }
    );
  }, [setSelectedVehicles, handleAmplitudeEvent]);

  const handleOperationSelect = useCallback((operation) => {
    const selectedWithoutOperationVehicles = selectedVehicles.filter(selectedVehicle => selectedVehicle.operation?.id !== operation?.id);
    const operationVehicles = sanitizeVehicles(vehicles).filter(vehicle => vehicle.operation.id === operation.id);

    setSelectedVehicles([...selectedWithoutOperationVehicles, ...operationVehicles]);
  }, [sanitizeVehicles, selectedVehicles, setSelectedVehicles]);

  const handleOperationDelete = useCallback((operationId) => {
    const updatedSelectedVehicles = selectedVehicles.filter(selectedVehicle => selectedVehicle.operation?.id !== Number(operationId));

    if(!updatedSelectedVehicles.length) {
      setDieselData(null);
    }

    setSelectedVehicles(updatedSelectedVehicles);
  }, [selectedVehicles, setSelectedVehicles]);
  
  const handleModal = (open) => {
    setShowModal(open);
  };

  const fetchData = async () => {
    setLoading(true);

    try {
      const vehicleIds = selectedVehicles?.map(item => item?.id) || filters?.vehicles?.map(item => item?.id);
      const vehicleNames = selectedVehicles?.map(item => item?.label) || filters?.vehicles?.map(item => item?.identification);
  
      if (vehicleIds?.length > 0) {
        const response = await getActualCheckMultiple(
          vehicleIds,
          selectedDates?.initialDate ?? filters.selectedDates.initialDate,
          selectedDates.finalDate ?? filters.selectedDates.finalDate,
        );

        if (!response?.data.success) {
          throw new Error("Erro ao carregar dados");
        }

        const fetchedVehicles = response.data?.data;
  
        const processedData = fetchedVehicles?.map(item => ({
          ...item,
          plate: `${item.plate}${item.identification ? ` / ${item.identification}` : ''}`
        })) || [];
        
        const orderedFuelHistory = processedData.sort((a, b) => 
          a.actualFuelData.actualVolume.Percentage - b.actualFuelData.actualVolume.Percentage
        );

        // Removes data without any valid value (other than 0 or '')
        const filteredFuelHistory = orderedFuelHistory.filter((item) => {
          const fuelData = item.actualFuelData;

          const isValid = Object.entries(fuelData).filter(({ 0: key, 1: value }) => {
            const comparedValue = key === 'actualVolume' ? value.Value : value;

            return !!comparedValue;
          });

          return !!isValid.length;
        });

        const dieselFuelHistory = filteredFuelHistory.map((item) => ({
          ...item,
          actualFuelLevel: `${valueToFloat(item?.actualFuelData?.actualVolume?.Percentage)}% (${valueToFloat(item?.actualFuelData?.actualVolume?.Value)}L)`
        }));
  
        setDieselData(dieselFuelHistory);
  
        handleAmplitudeEvent('Diesel Control Loaded', {
          vehicle_id: vehicleIds.join(", "),
          vehicle_name: vehicleNames.join(", "),
          startDate: selectedDates?.initialDate,
          endDate: selectedDates?.finalDate
        });
      }
    } catch (err) {
      setDieselData(null);
      dispatch(clearVehicleFilters());
    } finally {
      setLoading(false);
    }
  };

  const fetchLocations = async (selectedDate, vehicleId, vehicleIdentification) => {
    setLoadingModalMap(true);

    try {
      // Subtract 10 min to startDate and add 10 min to endDate
      let startDateLocation = new Date(selectedDate);
      let endDateLocation = new Date(selectedDate);

      startDateLocation.setTime(startDateLocation.getTime() - 10 * 60 * 1000);
      endDateLocation.setTime(endDateLocation.getTime() + 10 * 60 * 1000);

      const response = await getFleetLocations(
        [String(vehicleId)],
        format(startDateLocation, dbDateFormat),
        format(endDateLocation, dbDateFormat),
      );

      if (response.data.locations.length) {
        const dateStringArray = response.data?.locations[0]?.locations?.map(
          (item) => {
            return {
              date: item[0],
              lat: item[1],
              lon: item[2],
            };
          },
        );

        const location = getApproximateDate(dateStringArray, selectedDate);

        setLocations([
          {
            device: vehicleIdentification,
            locations: [location],
          },
        ]);
      } else {
        setLocations([]);
      }
    } catch (err) {
      setLocations([]);
    } finally {
      setLoadingModalMap(false);
    }
  };

  const showDetails = (selectedDate, vehicleId, vehicleIdentification) => {
    fetchLocations(selectedDate, vehicleId, vehicleIdentification);

    handleModal(true);

    if (!isSideMenuOnDashboard) {
      const selectedFilters = {
        vehicle: selectedVehicles,
        selectedDates: selectedDates,
      };

      dispatch(setVehicleFilters(selectedFilters));
    }
  };

  const columns = makeColumns(size, showDetails);

  const fetchOperations = async () => {
    setLoadingOptions(true);

    try {
      const response = await getVehiclesByOperation(currentCustomer);

      const operations = response.data.customers;

      const orderedOperations = operations.sort(
        (a, b) => (a.name > b.name) - (a.name < b.name)
      );

      // Removes vehicles without a currentDevice
      const sanitizedOperations = orderedOperations.map((operation) => {
        return {
          ...operation,
          vehicles: operation.vehicles.filter(vehicle => vehicle.currentDevice !== null)
        }
      });

      // Removes operations without valid vehicles
      const filteredOperations = sanitizedOperations.filter(sanitizedOperation => !!sanitizedOperation.vehicles.length);

      const operationsVehicles = filteredOperations.reduce((acc, curr) => ([...acc, ...curr.vehicles]), []);

      setVehicles(operationsVehicles);
      setSelectedVehicles(sanitizeVehicles(operationsVehicles));
    } catch (err) {
      toast.error(
        "Erro ao carregar a lista de veículos. Entre em contato com o suporte.",
      );
    } finally {
      setLoadingOptions(false);
    }
  }

  useEffect(() => {
    if (!selectedVehicles && filters.vehicles) {
      setSelectedDates({
        initialDate: filters?.selectedDates.initialDate,
        finalDate: filters?.selectedDates.finalDate,
      });
    }
  }, [filters]);

  useEffect(() => {
    if (selectedVehicles?.length > 0 && selectedDates && !loading) {
      fetchData();
    }
  }, [selectedVehicles, selectedDates]);

  useEffect(() => {
    if(dieselData && filters.vehicles && !!filters.vehicles?.length) {
      handleAmplitudeEvent(
        'Diesel Control Loaded',
        {
          vehicleId: filters.vehicles.map(item => item?.id).join(", "),
          vehicleName: filters.vehicles.map(item => item?.identification).join(", "),
          startDate: filters.selectedDates.initialDate,
          endDate: filters.selectedDates.finalDate,
          totalFills: dieselData?.totalFillEvents,
          totalFilled: dieselData?.totalLitersFilled,
          totalInconsistences: dieselData?.totalInconsistencyEvents,
          actualVolume: dieselData?.actualVolume,
          lastUpdate: dieselData?.lastEntry?.date
        }
      );
    }
  }, [dieselData, filters]);

  useEffect(() => {
    if (currentCustomer && hasPermission({ scopes: ["can_view_dashboard"] })) {
      handleAmplitudeEvent('Diesel Control Screen Viewed');
  
      fetchOperations();
    }
  }, []);

  return (
    <PermissionsGate scopes={["can_view_diesel_control"]}>
      {showModal && locations && (
        <ModalDieselControl
          showModal={showModal}
          handleModal={handleModal}
          loadingModalMap={loadingModalMap}
          showMapOnly
          locations={locations}
        />
      )}
      <Grid container>
        <Grid container item xs={12}>
          {!isSideMenuOnDashboard ? (
            <div className={classes.header}>
              <Grid item xs={12} sm={12} md={6}>
                <Typography variant="h2" className={classes.titlePage}>
                  Nível do tanque atual
                </Typography>
                <span>
                  Visualize detalhes sobre os bastecimentos e inconsistências dos
                  seus veículos
                </span>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={6} xl={6} className={classes.operationsSelect}>
                <OperationsSelect
                  vehicles={sanitizeVehicles(vehicles).filter(vehicle => !selectedVehicles.some(selectedVehicle => selectedVehicle.id === vehicle.id))}
                  value={selectedVehicles}
                  onChange={handleVehiclesChange}
                  onOperationSelect={handleOperationSelect}
                  onOperationDelete={handleOperationDelete}
                  onAllSelect={() => setSelectedVehicles(sanitizeVehicles(vehicles))}
                  onAllDelete={() => {
                    setSelectedVehicles([]);
                    setDieselData(null);
                  }}
                />
              </Grid>
            </div>
          ) : (
            <Grid item xs={12}>
              <TitlePage title={"Nível do tanque atual"} />
            </Grid>
          )}
          {loading || loadingOptions ? (
            <DieselControlSkeleton />
          ) : (
            <>
              {dieselData ? (
                <Grid item xs={12}>
                  <TableResponsive
                    tableBodyMaxHeight={size.mobile ? size.height - 430 + "px" : size.height - 330 + "px"}
                    data={dieselData}
                    options={{ rowsPerPage: 10 }}
                    columns={columns}
                    tableName="diesel-details"
                  />
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <StatusEmpty
                    title="Para gerar informações, selecione o filtro."
                    description="Selecione um veículo clicando no filtro localizado no menu acima."
                  />
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </PermissionsGate>
  );
};

export default CurrentDieselControl;
