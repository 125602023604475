import * as yup from "yup";

export default yup.object().shape({
  name: yup.string().required("Campo obrigatório"),
  permissions: yup.object().test({
    name: 'min',
    exclusive: true,
    message: 'Selecione ao menos uma permissão',
    test: (itens) => {
      let checked = 0;
      for (let key in itens) {
        if(itens[key]) {
          checked++;
        }
      }
      return checked > 0
    },
  })
});
