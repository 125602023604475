import React from "react";
import { Grid, Skeleton } from "@mui/material";

import Aux from "hoc/auxiliar";

const MapSkeleton = () => {
  return (
    <Aux>
      <Grid container p={1}>
        <Skeleton variant="rectangular" width="100%" height={400} style={{ borderRadius: "20px" }} />
      </Grid>
    </Aux>
  );
};

export default MapSkeleton;
