import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid, Typography, Paper } from "@mui/material";
import { toast } from "react-toastify";

// components
import Aux from "hoc/auxiliar";
import BackButton from "components/BackButton";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";

// helpers
import { findValueByKey } from "helpers/functions";

// services
import { getUserById } from "services/user";
import { getUserPermissions } from "services/roles";
import { getCustomersById } from "services/customers";

// style
import useStyles from "./styles";
import "react-datepicker/dist/react-datepicker.css";

const ViewUser = () => {
  const classes = useStyles();
  const { userID } = useParams();
  const [ user, setUser ] = useState(null);
  const [ userRole, setUserRole ] = useState(null);
 
  const fetchUserById = async () => {
    try {
      const response = await getUserById(userID);
      const userData = findValueByKey(response.data, 'user');
      const responseCustomers = await getCustomersById(userData.customerId);
      const customerData = findValueByKey(responseCustomers.data, 'customers');
      const filteredCustomers = userData.isAllCustomers ? 'TODAS AS EMPRESAS' : customerData.map(elm => elm.name).join(',');
      setUser({
        email: userData.email,
        name: userData.name,
        customers: filteredCustomers
      });
      fetchUserRole(userData.email);
    } catch (error) {
      toast.error(
        "Erro ao buscar dados do usuário. Verifique com o suporte",
      );
    };
  };

  const fetchUserRole = async (email) => {
    try {
      const response = await getUserPermissions(email);
      const roleData = findValueByKey(response.data, 'name');
      setUserRole(roleData)
    } catch (error) {
      toast.error(
        "Erro ao buscar dados do usuário. Verifique com o suporte",
      );
    };
  };

  useEffect(() => {
    if (hasPermission({ scopes: ['can_view_users_without_edit'] })) {
      fetchUserById();
    }
  }, []);

  return (
    <Aux>
      <PermissionsGate scopes={['can_view_users_without_edit']}>
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box>
              <Grid item xl={12} xs={12}>
                <Typography variant="h1" className={classes.titleMain}>Visualizar usuário</Typography>
                <Paper elevation={0} className={classes.container}>
                  <Grid container spacing={2}>
                    <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                      <Box sx={{ m: 2 }}>
                        <Typography><strong>Nome: </strong> {user?.name || ' - '}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                      <Box sx={{ m: 2 }}>
                        <Typography><strong>E-mail: </strong> {user?.email || ' - '}</Typography>
                      </Box>
                    </Grid>                    
                    <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                      <Box sx={{ m: 2 }}>
                        <Typography><strong>Tipo de acesso: </strong> {userRole || ' - '}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box sx={{ m: 2 }}>
                        <Typography><strong>Empresa(s): </strong> {user?.customers || ' - '}</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Paper>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Grid container justifyContent="flex-end" spacing={2}>
                    <Grid item xs={12} md={3} sm={4} xl={1} lg={2}>
                      <Box sx={{ m: 2 }}>
                        <BackButton />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </PermissionsGate>
    </Aux>
  );
};

export default ViewUser;
