import React from "react";
import { Card, Typography, Box } from "@mui/material";
import Icon from "@mdi/react";
import { mdiCalendarCheckOutline, mdiCalendarClockOutline, mdiCalendarRemoveOutline } from '@mdi/js';
import dayjs from 'dayjs';

// theme
import colors from '../../../themes/gobrax';

import Badge from "./notification-badge";

export default function HistoryNotificationCard({
  type,
  status,
  id,
  platform,
  createdAt,
  createdBy,
  updatedAt,
  title,
  subtitle,
  body,
  statusType,
  username
}) {


  const formatDefault = 'DD/MM/YYYY HH:mm';

  const renderHtml = (htmlString) => {
    return { __html: htmlString };
  };

  const renderLabelStatusType = (statusType) => {
    if (statusType === 'created') {
      return 'Criada'
    }
    if (statusType === 'updated') {
      return 'Editada'
    }
    if (statusType === 'canceled') {
      return 'Cancelada'
    }
  }
  const Status = ({ status }) => {
    let statusColor = "";
    let statusText = "";
    let statusIcon;
    if (status === "published") {
      statusColor = "#40D81B"
      statusText = "Enviada"
      statusIcon = mdiCalendarCheckOutline;
    } else if (status === "scheduled") {
      statusColor = "#0D69D7"
      statusText = "Agendada"
      statusIcon = mdiCalendarClockOutline
    } else if (status === "canceled") {
      statusColor = "#E2211C"
      statusText = "Cancelada"
      statusIcon = mdiCalendarRemoveOutline
    }
    return (
      <Box sx={{
        padding: '2px 4px',
        color: `${statusColor}`,
        fontWeight: 'medium',
        fontSize: 14,
        alignItems: 'center',
        flexWrap: 'nowrap',
        display: 'flex'
      }}>
        <Icon path={statusIcon} size={1} style={{ marginRight: 4 }} />
        {statusText || ""}
      </Box>
    )
  }

  return (
    <Card key={id} sx={{ height: 'auto', width: '100%', backgroundColor: 'white', boxShadow: '0px 1px 6px 0px #0000000F', marginBottom: 2, padding: '16px', borderRadius: 3, position: 'relative', flexDirection: 'column' }}>
      <Box style={{ gap: '8px', alignItems: 'center', display: 'flex', flexDirection: 'row', marginBottom: 4 }}>
        <Typography style={{
          fontSize: 14, fontWeight: 400, color: '#A6A6A6'
        }}>{dayjs(createdAt).format(formatDefault)}</Typography>
        <Typography style={{
          fontSize: 14, fontWeight: 400, color: '#A6A6A6', textTransform: 'capitalize'
        }}>
          ( {platform} )
        </Typography>
        <Badge type={type} />
        <Status status={status} />
      </Box>
      <Typography style={{
        fontSize: 18, fontWeight: 500, color: '#000000', lineHeight: '1.2em'
      }}>{title}</Typography>
      <Typography style={{
        fontSize: 16, fontWeight: '500', color: colors.palette.primary.grayDark, width: '100%', marginBottom: 4
      }}>{subtitle}</Typography>
      <div dangerouslySetInnerHTML={renderHtml(body)} />
      <Typography style={{ fontSize: 16, fontWeight: 400, color: '#595959', marginBottom: '18px' }}>
        {renderLabelStatusType(statusType)} por: <span style={{ fontWeight: 600, color: '#181814' }}>{username + " em " + dayjs(updatedAt).format(formatDefault)}</span>
      </Typography>
    </Card>
  )
}