import api from "./api";
import { convertStringDate } from "helpers/functions";

const arrayDataUrl = (arrayData = "") => {
  let partialUrl = "";
  if (arrayData) {
    partialUrl = Array.isArray(arrayData) ? arrayData.join() : arrayData;
  }
  return partialUrl;
};

export const getDriversMonitoring = async (drivers, vehicles, startDate, endDate, limit, page, onDemand = false) => {
  const driversUrl = arrayDataUrl(drivers);
  const vehiclesUrl = arrayDataUrl(vehicles);
  const convertedDates = convertStringDate(startDate, endDate);
  const params = `drivers=${driversUrl}&vehicles=${vehiclesUrl}&startDate=${convertedDates.startDate}&endDate=${convertedDates.endDate}&limit=${limit}&page=${page}&onDemand=${onDemand}`;
  try {
    const response = await api.get(`/web/v2/performance/drivers/monitoring?${params}`);
    return response;
  } catch (err) {
    throw err;
  }
};