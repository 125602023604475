import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import { useForm, FormProvider, useFormState } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import stringSimilarity from "string-similarity-js";

// components
import Aux from "hoc/auxiliar";
import UserForm from "components/UserForm";
import BackButton from "components/BackButton";
import PermissionsGate from "components/PermissionsGate";

// helpers
import makeDefaultValues from "helpers/makeDefaultValues";

// domain
import fields from "domain/forms/formUserInitialValues";
import schemaValidation from "domain/validation/yup/formUserValidation";

// models
import UserModel from "models/User";
import UserRoleModel from "models/UserRole";

// services
import { createUser } from "services/user";
import { setUserRole } from "services/roles";

import useStyles from "./styles";
import "react-datepicker/dist/react-datepicker.css";
import api from "../../../services/api";

const AddUser = () => {
  const classes = useStyles();
  const history = useHistory()
  const [customError, setCustomError] = useState(false)
  const defaultValues = makeDefaultValues({ fields });
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schemaValidation),
  });
  const { isSubmitting } = useFormState({ control: methods.control });

  const username = methods.watch("name");
  const password = methods.watch("password");

  const errorMessages = {
    'already exists': 'E-mail já cadastrado.',
  };

  const resetForm = (email) => {
    toast.success(email ? "Usuário criado e email de boas vindas enviado com sucesso" : "Usuário criado com sucesso, porém houve erro ao enviar email de boas vindas")
    methods.reset(defaultValues);
    history.push("/gofurther/user/list-users")
  }

  const onSubmit = async (data) => {
    const userModel = new UserModel(
      data.customer.map(item => item.value),
      data.name,
      data.email,
      data.password,
      data.isAllCustomers,
      data.role.value,
    );
    try {
      const response = await createUser(userModel);
      if (response.status === 200) {
        const response = await api.get(`/web/v2/authenticator/auth/requestEmailCreate/${data.email}`);
        resetForm(response.status === 200 || response.status === 201)
      }
    } catch (err) {
      const errorArray = err.message ? JSON.parse(err.message) : [];
      let errorMessage = '';
      if (errorArray.length > 0) {
        errorMessage = errorMessages[errorArray[0].error];
      }
      toast.error(`Erro ao criar usuário. ${errorMessage} Verifique com o suporte.`);
    }
  };

  useEffect(() => {
    if (username && password) {
      const result = stringSimilarity(username, password)
      if (result >= 0.65) {
        setCustomError(true)
        methods.setError('name', {
          type: 'custom',
          message: "Nome e senha muito similar",
        }, {
          shouldFocus: false
        })
        methods.setError('password', {
          type: 'custom',
          message: "Nome e senha muito similar",
        }, {
          shouldFocus: false
        })
      } else {
        setCustomError(false)
        methods.clearErrors('password')
        methods.clearErrors('name')
      }
    }
  }, [username, password])

  return (
    <Aux>
      <PermissionsGate scopes={['can_create_users']}>
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box>
              <Grid item xl={12} xs={12}>
                <FormProvider {...methods}>
                  <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Typography variant="h1" className={classes.title}>Cadastro de usuário</Typography>
                    <UserForm isAdding={true} />
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Grid container justifyContent="flex-end" spacing={2}>
                        <Grid item container justifyContent="flex-end" xl={12} lg={12} md={12} sm={12} xs={12}>
                          <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                            <Box sx={{ m: 2 }}>
                              <BackButton className={classes.backButton} />
                            </Box>
                          </Grid>
                          <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                            <Box sx={{ m: 2 }}>
                              <Button
                                fullWidth
                                variant="contained"
                                type="submit"
                                disabled={customError || isSubmitting}
                                className={classes.btConfirm}
                              >
                                Salvar
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                </FormProvider>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </PermissionsGate>
    </Aux>
  );
};

export default AddUser;
