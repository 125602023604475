import { 
  Alert,
  Box,
  Button,
  DialogContent,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
  Typography 
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import dayjs from "dayjs";

import fields from "domain/forms/formLinkDeviceInitialvalues";

// services
import { LinkDevice } from "services/device";

// helpers
import { statusOptions } from "helpers/deviceStatus";
import { objIsEmpty } from "helpers/functions";
import makeDefaultValues from "helpers/makeDefaultValues";

// components
import Select from "components/react-hook-form/select";
import Table from "components/Table/Table";
import LinkDeviceForm from "components/LinkDeviceForm";

// styles
import useStyles from "./styles";

const ModalDevice = ({
  data,
  device,
  handleClose,
  loading,
  open,
  tableName,
  vehiclesDevices,
}) => {
  const classes = useStyles();

  const defaultValues = makeDefaultValues({ fields });

  const methods = useForm({
    defaultValues,
  });

  const columns = [
    {
      name: "identification",
      label: "Nome",
    },
    {
      name: "startDate",
      label: "Data inicial",
      options: {
        customBodyRender: (value) => (
          <Typography variant="body2">
            {dayjs(value).format("DD/MM/YYYY HH:mm")}
          </Typography>
        ),
      },
    },
    {
      name: "endDate",
      label: "Data final",
      options: {
        customBodyRender: (value) => {
          let content = "";
          if (value) {
            content = dayjs(value).format("DD/MM/YYYY HH:mm");
          }
          return <Typography variant="body2">{content}</Typography>;
        },
      },
    },
  ];

  let hasDataTable = (
    <Table
      columns={columns}
      data={vehiclesDevices}
      title="Informações sobre unidade eletrônica"
      tableName={tableName}
    />
  );

  const onSubmit = async ({ devices, initialDate }) => {
    try {
      if (!devices) {
        return toast.warning("Selecione uma unidade eletrônica para vincular.");
      }

      const responseData = {
        vehicleId: data,
        deviceId: devices,
        date: initialDate.format('YYYY-MM-DDTHH:mm:ssZ'),
      };

      const responseCreateLink = await LinkDevice(responseData);

      if (!responseCreateLink) {
        return toast.error(
          "Não foi possível vincular unidade eletrônica. Contate o suporte",
        );
      }

      toast.success("Vínculo realizado com sucesso!");
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth={"md"}
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      PaperProps={{
        style: { borderRadius: 22 }
      }}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        <Typography className={classes.title}>Vincular unidade Eletrônica</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
          size="large">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} style={{ marginBottom: 10 }}>
          {loading ? (
            <></>
          ) : !vehiclesDevices ? (
            <Grid item xs={12}>
              <Alert style={{ margin: 10 }} severity="info">
                Ainda não existe histórico para esse veículo.
              </Alert>
            </Grid>
          ) : (
            <Grid item xs={12}>
              {hasDataTable}
            </Grid>
          )}
        </Grid>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <LinkDeviceForm />

            {!objIsEmpty(device.status) && (
              <>
                <Alert
                  className={classes.alert}
                  severity="warning"
                >
                  {" "}
                  <strong>Já existe uma unidade eletrônica em uso.</strong>{" "}
                  Selecione o status da antiga unidade eletrônica.
                </Alert>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                  <Select
                    options={statusOptions.updateOldDevice}
                    name="statusDevices"
                    placeholder="Selecione o status da unidade eletrônica antiga"
                    required
                  />
                </Grid>
              </>
            )}
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Box sx={{ mt: 4 }}>
                <Typography className={classes.explanation}>* Os campos marcados com o asterisco (*) são aqueles que precisam obrigatoriamente ser preenchidos.</Typography>
              </Box>
            </Grid>
            <DialogActions>
              <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item container justifyContent="flex-end" xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                    <Box sx={{ m: 2 }}>
                      <Button
                        fullWidth
                        onClick={handleClose}
                        className={classes.buttonClose}
                      >
                        Cancelar
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                    <Box sx={{ m: 2 }}>
                      <Button
                        fullWidth
                        variant="contained"
                        type="submit"
                        className={classes.btConfirm}
                      >
                        Salvar
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </DialogActions>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
};

export default ModalDevice;
