import React from "react";
import { Button, Grid, IconButton, Tooltip } from "@mui/material";
import { Delete as DeleteIcon, Edit as EditIcon, RemoveRedEyeOutlined as EyeIcon } from "@mui/icons-material";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";
import { Link } from "react-router-dom";

export const makeColumns = (onDelete, onView, onEdit, size) => {
  const centerStyles = {
    display: 'flex',
    justifyContent: 'center'
  }

  let columns = size?.mobile ? [
    {
      name: "grupoLabel",
      label: "Grupo de bonificação",
    },
    {
      name: "name",
      label: " ",
      options: {
        customBodyRender: (value) => {
          const shouldTruncate = value.length > 15 && size?.mobile;
          const truncatedValue = shouldTruncate
            ? `${value.slice(0, 15)}...`
            : value;

          return (
            <Grid
              container
              style={{
                maxWidth: "300px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {truncatedValue}
            </Grid>
          );
        },
      }
    }
  ] : [
    {
      name: "name",
      label: "Grupo de bonificação",
      options: {
        customBodyRender: (value) => {
          const shouldTruncate = value.length > 15 && size?.mobile;
          const truncatedValue = shouldTruncate
            ? `${value.slice(0, 15)}...`
            : value;

          return (
            <Grid
              container
              style={{
                maxWidth: "300px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {shouldTruncate ? (
                <>
                  {truncatedValue}
                  <Tooltip title={shouldTruncate ? value : ""}>
                    <Button size="small" color="primary">
                      [...]
                    </Button>
                  </Tooltip>
                </>
              ) : (
                truncatedValue
              )}
            </Grid>
          );
        },
      }
    }
  ];

  if (!size?.mobile && hasPermission({ scopes: ['can_edit_rewardgroup', 'can_remove_rewardgroup', 'can_view_rewardgroup_without_edit'] })) {
    columns.push({
      name: "id",
      label: "Ações",
      options: {
        sort: false,
        filter: false,
        viewColumns: false,
        print: false,
        customBodyRender: (value, tableData) => {
          return (
            <Grid container style={centerStyles}>
              <PermissionsGate scopes={['can_edit_rewardgroup']}>
                <Grid
                  item
                  style={centerStyles}
                  xs={4}
                >
                  <IconButton
                    onClick={() => onEdit(value)}
                    aria-label="edit"
                    title="Editar"
                    size="large">
                    <EditIcon />
                  </IconButton>
                </Grid>
              </PermissionsGate>
              <PermissionsGate scopes={['can_remove_rewardgroup']}>
                <Grid
                  item
                  style={centerStyles}
                  xs={4}
                >
                  <IconButton
                    onClick={() => onDelete(value, tableData.rowData[0])}
                    aria-label="delete"
                    title="Excluir"
                    size="large">
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </PermissionsGate>
              <PermissionsGate scopes={['can_view_rewardgroup_without_edit']}>
                <Grid
                  item
                  style={centerStyles}
                  xs={4}
                >
                  <IconButton
                    aria-label="view-reward"
                    title="Visualizar grupo de bonificação"
                    component={Link}
                    to={`/gofurther/rewardGroup/view-reward-group/${value}`}
                    size="large">
                    <EyeIcon />
                  </IconButton>
                </Grid>
              </PermissionsGate>
            </Grid>
          );
        },
      },
    });
  }

  return columns;
};
