import React from "react";
import TextField from "@mui/material/TextField";
import { useController, useFormContext } from "react-hook-form";
import { IMaskInput } from 'react-imask';
import classnames from "classnames";

// styles
import useStyles from './styles';

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <IMaskInput
      {...other}
      definitions={{
        '9': /[0-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

const Input = ({ name, useMask, control, inputProps, title, ...rest }) => {
  const classes = useStyles();
  const { control: controlContext } = useFormContext();
  const { field: { ref, value = '', ...inputPropsForm } } = useController({ name, control: control ?? controlContext });

  return (
    <>
    {useMask ? (
      <TextField
        {...inputPropsForm}
        value={value}
        inputRef={ref}
        {...rest}
        className={classnames(
          rest.className,
          classes.textField,
        )}
        InputProps={{
          inputComponent: TextMaskCustom,
          inputProps: {
            mask: useMask
          }
        }}
      />
    ) : (
      <TextField
        {...inputPropsForm}
        value={value}
        inputRef={ref}
        {...rest}
        className={classnames(
          rest.className,
          classes.textField,
        )}
        inputProps={{ ...inputProps }}
      />
    )}

    </>
  );
};

export default Input;
