import { useEffect, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import { Cell, Label, Pie, PieChart, ResponsiveContainer, Text } from 'recharts'

// themes
import colors from 'themes/gobrax';

// styles
import useStyles from './styles';

const ConsolidatedScore = ({ score }) => {
  const classes = useStyles();

  const [data, setData] = useState([{
    color: colors.palette.gray.extraLight,
    score: 0
  }]);

  const handleData = () => {
    // regra de cores: 0 a 40: Vermelho / 41 a 80: Amarelo / 81 a 100: Verde
    let color = '';

    if (score <= 40) {
      color = colors.palette.error.main;
    } else if (score <= 80) {
      color = colors.palette.warning.main;
    } else {
      color = colors.palette.success.main;
    }

    setData([
      {
        color: colors.palette.gray.extraLight,
        value: 100 - score
      },
      {
        color: color,
        value: score
      },
    ])
  }

  useEffect(() => {
    handleData()
  }, [])

  return (
    <Grid>
      <Typography variant="h4" className={classes.cardTitle}>Nota geral</Typography>
      <ResponsiveContainer height={170}>
        <PieChart>
          <Pie
            data={data}
            innerRadius={50}
            outerRadius={65}
            startAngle={90}
            endAngle={-270}
            dataKey="value"
            paddingAngle={-5}
            cornerRadius={10}
            isAnimationActive={false}
          >
            {data?.map((entry, index) => (
              <Cell key={index} fill={entry.color} />
            ))}
            <Label
              content={(props) => {
                const positioningProps = {
                  x: props.viewBox.cx,
                  y: props.viewBox.cy,
                  textAnchor: "middle",
                  verticalAnchor: "middle",
                };
                
                const presentationProps = {
                  fill: colors.palette.gray.light,
                  fontSize: 40,
                  fontWeight: "bold",
                };

                return (
                  <Text
                    {...positioningProps}
                    {...presentationProps}
                  >
                    {data[1].value}
                  </Text>
                );
              }}
            />
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </Grid>
  )
}

export default ConsolidatedScore;
