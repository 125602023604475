export const singleStyles = {
  option: (styles) => {
    return {
      ...styles,
      textAlign: 'left',
    };
  },
  menu: (styles) => {
    return {
      ...styles,
      zIndex: 9999,
    }
  },
  control: (styles, state) => {
    return {
      ...styles,
      border: 'none',
      borderRadius: 18,

    }
  }
};

