import api from "./api";

export const createIndicators = async (data) => {
  try {
    const response = await api.post("/operation-types/indicators", data);
    if (response.status === 200 || response.ok === "ok") {
      return response.data;
    }
  } catch (err) {
    return err;
  }
};

export const updateIndicators = async ({ id, data }) => {
  try {
    const response = await api.put(`/operation-types/indicators/${id}`, data);
    if (response.status === 200 || response.ok === "ok") {
      return response.data;
    }
  } catch (err) {
    return err;
  }
};


export const deleteIndicators = async ({ id, indicatorId, requirementLevel }) => {
  try {
    const response = await api.put(`/operation-types/indicators/${id}/${indicatorId}/${requirementLevel}`);
    if (response.status === 200 || response.ok === "ok") {
      return response.data;
    }
  } catch (err) {
    return err;
  }
};


