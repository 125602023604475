import React from 'react';
import {
  Card,
  Typography,
  Grid,
  Box
} from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import dayjs from 'dayjs';

// assets
import GoBraxLogo from "../../../images/gobrax-header.png";

// theme
import colors from '../../../themes/gobrax';

// constants
import { notifications } from '../../../constants/tabs-notications';

// styles
import useStyles from "../styles";

const NotificationCard = ({ title, type, fromGobrax, subtitle, body, updatedAt }) => {
  const formatDefault = 'DD/MM/YYYY HH:mm'
  const classes = useStyles();
  const isViewed = type === 'viewed';
  
  const renderHtml = (htmlString) => {
    return { __html: htmlString };
  }

  const getColors = (to) => {
    if (isViewed) return '#F0F0F0';
    if (type === notifications.urgent) {
      if (to === 'icon') return '#FFC400'
      return to === 'border' ? '#FFEDC0' : '#FFF4D8'
    }
    if (type === notifications.alert) {
      if (to === 'icon') return '#FF507A'
      return to === 'border' ? '#FFC8C7' : '#FFD9D8'
    }
    if (type === notifications.tips) {
      if (to === 'icon') return '#31C440'
      return to === 'border' ? '#CBFFBE' : '#EAFFE5'
    }
    if (type === notifications.message) {
      if (to === 'icon') return '#0D69D7'
      return to === 'border' ? '#689CF9' : '#98C7FF'
    }
    if (type === notifications.communication) {
      if (to === 'icon') return '#7C19FB'
      return to === 'border' ? '#BE8FFB' : '#F2ECFB'
    } else {
      if (to === 'icon') return '#0D69D7'
      return to === 'border' ? '#689CF9' : '#98C7FF'
    }
  }

  return (
    <Card
      sx={{ height: 'auto', width: '80%', border: `2px solid ${colors.notification[type].borderColor}`, boxShadow: '0px 1px 6px 0px #0000000F', marginBottom: 2, paddingRight: 1, borderRadius: 3, position: 'relative', flexDirection: 'column' }}>
      <Box
        sx={{
          position: 'absolute',
          top: '12px',
          right: '12px',
          width: '45px',
          height: '45px',
          backgroundColor: '#ffff',
          display: 'flex',
          alignItems: 'center',
          borderRadius: '50%',
          boxShadow: '0px 1.764705777168274px 4.411764621734619px 0px #00000026',
        }}
      >
        {fromGobrax ?
          <img
            src={GoBraxLogo}
            alt="Logo Gobrax"
            className={classes.logoHeader}
            data-cy="logoGobrax"
          />
          :
          <BusinessIcon style={{ color: '#595959', marginLeft: 11 }} />
        }
        {!isViewed &&
          <Box
            sx={{
              position: 'absolute',
              top: '-2px',
              right: '-5px',
              width: '18px',
              height: '18px',
              backgroundColor: '#FF2B2BCC',
              borderRadius: '50%',
            }}
          />}
      </Box>
      <Grid container spacing={2} style={{ disply: 'flex', flexDirection: 'column' }}>
        <Grid item xs={9} style={{ padding: '40px 30px 15px' }}>
          <Typography style={{
            fontSize: 22, fontWeight: 500, color: '#000000', marginBottom: '12px'
          }}>{title}</Typography>
          <Typography style={{
            fontSize: 16, fontWeight: '500', color: colors.palette.primary.grayDark, width: '100%', marginBottom: 4
          }}>{subtitle}</Typography>
          <div dangerouslySetInnerHTML={renderHtml(body)} />
          <Typography style={{
            fontSize: 14, fontWeight: 400, color: '#A6A6A6', marginBottom: '18px'
          }}>{dayjs(updatedAt).format(formatDefault)}</Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

export default NotificationCard;
