import React, { useState } from 'react';
import { Box, FormControl, Grid, MenuItem, Paper, Select, Typography } from '@mui/material';

// domain
import { rewardTypeOptions } from 'domain/selectOptions';

// components
import Aux from 'hoc/auxiliar';
import BackButton from 'components/BackButton';
import CreateStandard from 'components/RewardGroupForms/CreateStandard';
import CreateCustom from 'components/RewardGroupForms/CreateCustom';
import PermissionsGate from 'components/PermissionsGate';

// styles
import useStyles from './styles';

const AddRewardGroup = () => {
  const classes = useStyles();
  const [rewardType, setRewardType] = useState('');

  const handleRewardType = (event) => {
    setRewardType(event.target.value)
  }

  return (
    <Aux>
      <PermissionsGate scopes={['can_create_rewardgroup']}>
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box>
              <Grid item xl={12} xs={12}>
                <Typography variant='h1' className={classes.title}>Cadastro de grupo de bonificação</Typography>
                <Paper elevation={2} className={classes.container}>
                  <Grid item xl={3} lg={4} md={4} sm={6} xs={12}>
                    <Typography>Selecione o tipo *</Typography>
                    <FormControl
                      variant="outlined"
                      classes={{
                        root: classes.formControl,
                      }}
                    >
                      <Select
                        id='rewardType'
                        onChange={handleRewardType}
                        value={rewardType}
                        placeholder='Selecione o tipo *'
                      >
                        {rewardTypeOptions.map(item => <MenuItem key={item.label} value={item.value}>{item.label}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xl={12} xs={12}>
                    {rewardType === 1 ? <CreateStandard />
                      : rewardType === 2 && <CreateCustom />}
                  </Grid>
                </Paper>
              </Grid>
            </Box>
          </Grid>
          {!rewardType && (
            <Grid container justifyContent="flex-end" spacing={2} my={2}>
              <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                <BackButton className={classes.backButton} />
              </Grid>
            </Grid>
          )}
        </Grid>
      </PermissionsGate>
    </Aux>
  );
};

export default AddRewardGroup;
