import * as yup from "yup";

export default yup.object().shape({
  brand: yup
    .object()
    .shape({
      value: yup.string().required(),
      label: yup.string().required(),
    })
    .nullable()
    .required("Campo obrigatório"),
  identification: yup
    .string()
    .required("Campo obrigatório"),
  simcard: yup
    .string()
    .transform((value) => value.replace(/[^0-9]/g, ""))
    .required("Campo obrigatório"),
  status: yup
    .object()
    .shape({
      value: yup.number().required(),
      label: yup.string().required(),
    })
    .nullable()
    .required("Campo obrigatório"),
});
