import { createSlice } from '@reduxjs/toolkit';

const TODAY = new Date()

const initialState = {
  fields: {
    step: null
  },
  filters : {
    operation: null,
    year: {
      value: TODAY.getFullYear(),
      label: TODAY.getFullYear(),
    },
    cutoffDate: null,
  },
  performances: null,
  pagination: {
    page: 1,
    rowsPerPage: 10,
    count: 1,
    isLoading: false,
  }
};

export const driversMonitoringSlice = createSlice({
  name: 'driversMonitoring',
  initialState,
  reducers: {
    setFilters: (state, action) => {
      const { name, selectedOption } = action.payload;
      if (name === 'cutoffDate') {
        state.filters[name] = JSON.stringify(selectedOption);
      } else {
        state.filters[name] = selectedOption;
      }
    },
    setPerformancesReducer: (state, action) => {
      state.performances = action.payload
    }, 
    clearFilters: (state, action) => {
      state.fields.step = null;
      state.performances = initialState.performances
      state.filters = initialState.filters
      state.pagination = initialState.pagination
    },
    setStepAdd: (state) => {
      if (!state.fields.step) {
        state.fields.step = 1
      } else {
        state.fields.step = state.fields.step + 1
      }
    },
    setStepDeduct: (state) => {
      state.fields.step = state.fields.step - 1
    },
    resetStep: (state) => {
      state.fields = initialState.fields
    },
    setPaginationReducer: (state, action) => {
      const newPagination = {
        ...state.pagination,
        ...action.payload,
      };
      state.pagination = newPagination;
    },
  }
});

// Action creators are generated for each case reducer function
export const { 
  clearFilters, 
  resetStep, 
  setFilters, 
  setPaginationReducer,
  setPerformancesReducer,
  setStepAdd, 
  setStepDeduct, 
} = driversMonitoringSlice.actions;

export default driversMonitoringSlice.reducer;
