class FormatList {
    static fromChild(items) {
        let list = {};
        const final_list = [];

        items.map(function (item) {
            list.hasOwnProperty(`category_${item.category.id}`)
                ? list[`category_${item.category.id}`].options.push(item)
                : (list[`category_${item.category.id}`] = {
                    label: item.category.name,
                    options: [item]
                });

            return item;
        });

        for (let prop in list) {
            final_list.push(list[prop]);
        }

        return final_list;
    }
}

export default FormatList;