import React, { useState } from "react";
import { Grid, Tooltip, Paper } from "@mui/material";
import { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/styles";
import Icon from "@mdi/react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import colors from "themes/gobrax";
import { clearText } from "helpers/functions";
import { useWindowSize } from "hooks/useWindowsSize";

import useStyles from "./styles";

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: theme.typography.pxToRem(12),
    fontFamily: theme.typography.fontFamily
  },
}));

const StatisticsCard = (props) => {
  const classes = useStyles();
  const size = useWindowSize();
  const dataCy = clearText(props.title.replace(/\s/g, ''));

  const theme = useTheme();

  const [open, setOpen] = useState(false)

  return (
    <Paper
      elevation={0}
      style={size.mobile ? { display: "flex", minHeight: 120 } : {}}
      className={classes.container}
      data-cy={`div${dataCy}`}
    >
      <Grid container justifyContent={"space-between"}>
        <Grid item xs={2} className={classes.contentIcon}>
          <Icon
            path={props.icon}
            color={colors.palette.secondary.contrastText}
            size={1}
          />
        </Grid>
        <Grid item xs={10}>
          <Grid container direction={"column"} alignItems="flex-end">
            <Grid item className={classes.title} xl={12} lg={12} md={12} sm={12} xs={12}>
              {props.title}
              {props?.tooltip && (
                <StyledTooltip
                  open={open}
                  onClick={() => setOpen(!open)}
                  onOpen={() => setOpen(true)}
                  onClose={() => setOpen(false)}
                  placement="bottom-end"
                  title={<>{props?.tooltip}</>}
                >
                  <InfoOutlinedIcon style={{ marginLeft: 8, width: 24, height: 24, color: theme.palette.gray.light }} />
                </StyledTooltip>
              )}
            </Grid>
            <Grid item xs={12}>
              <Grid container alignItems="flex-end">
                <Grid className={classes.label}>
                  {props.label} {props.unit}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default StatisticsCard;
