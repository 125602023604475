import React from "react";
import { IconButton, Grid } from "@mui/material";
import { DeleteOutline, EditOutlined, HistoryOutlined } from "@mui/icons-material";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";
import { valueToPositiveFloat } from "helpers/functions";
import dayjs from "dayjs";

const formatDate = (dateString) => {
  if (!dateString) return '-';
  return dayjs(dateString).format("DD/MM/YYYY HH:mm");
};

export const makeColumns = ({ onDelete, onEdit, onNavigateToHistory, size }) => {
  const getRewardFormatValue = (format) => {
    switch(format) {
      case 'mileageReward':
        return 'Premiação por km e nota';

      case 'scoreMinimunReward':
        return 'Premiação por nota mínima';

      case 'scoreMediumReward':
        return 'Premiação por nota mínima proporcional';

      case 'range':
        return 'Premiação por faixa/range';

      default:
        return '';
    }
  }

  let columns = [
    {
      name: "name",
      label: size.mobile ? ' ' : "Nome",
      options: {
        customBodyRender: (value) => (
          <div data-cy="thName">
            {value}
          </div>
        )
      }
    },
    {
      name: "vehiclesAmount",
      label: "Qtde veículos",
      options: {
        customBodyRender: (value) => (
          <div data-cy="thVehicleQuantity">
            {value}
          </div>
        )
      }
    },
    {
      name: "maxAmount",
      label: "Premiação máxima",
      options: {
        customBodyRender: (value) => (
          <p style={{ margin: 0 }} data-cy="thMaxAmount">
            {value
              ? value.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })
              : 0}
          </p>)
      }
    },
    {
      name: "operationTypeName",
      label: "Grupo de Bonificação",
      options: {
        customBodyRender: (value) => (
          <div data-cy="thAwardType">
            {value}
          </div>
        )
      }
    },
    {
      name: "requirementLevel",
      label: "Exigência",
      options: {
        customBodyRender: (value) => (
          <div data-cy="thRequirement">
            {value}
          </div>
        )
      }
    },
    {
      name: "rewardFormat",
      label: "Tipo de Premiação",
      options: {
        customBodyRender: (value) => (
          <div data-cy="thRequirement">
            {getRewardFormatValue(value)}
          </div>
        ),
        filterOptions: {
          renderValue: (value => getRewardFormatValue(value))
        },
        customFilterListRender: (value => getRewardFormatValue(value))
      }
    }
  ];

  if (hasPermission({ scopes: ['can_view_minscore'] })) {
    columns.push({
      name: "score",
      label: "Nota mínima",
      options: {
        customBodyRender: (value) => (
          <div data-cy="thMinScore">
            {value ? value : '-'}
          </div>
        )
      }
    });
  }

  columns.push({
    name: "created_at",
    label: "Data de criação",
    options: {
      customBodyRender: (value) => (
        <span data-cy="thCreatedAt">
          {formatDate(value)}
        </span>
      ),
      filterOptions: {
        renderValue: (value) => formatDate(value),
      },
      customFilterListRender: (value => formatDate(value))
    },
  });

  if (!size.mobile && hasPermission({ scopes: ['can_edit_operations', 'can_remove_operationsa'] })) {
    columns.push({
      name: "id",
      label: "Ações",
      options: {
        sort: false,
        filter: false,
        viewColumns: false,
        print: false,
        customBodyRender: (value) => {
          return (
            <div data-cy="thActions">
              <Grid container>
                <PermissionsGate scopes={['can_edit_operations']}>
                  <Grid
                    style={{ marginRight: 10 }}
                    item
                    xl={4}
                    lg={4}
                    md={6}
                    sm={2}
                    xs={1}
                  >
                    <IconButton
                      onClick={() => onEdit(value)}
                      aria-label="edit"
                      title="Editar"
                      size="large"
                      data-cy="buttonEdit"
                    >
                      <EditOutlined />
                    </IconButton>
                  </Grid>
                </PermissionsGate>
                <PermissionsGate scopes={['can_remove_operations']}>
                  <Grid
                    style={{ marginRight: 10 }}
                    item
                    xl={3}
                    lg={3}
                    md={2}
                    sm={2}
                    xs={1}
                  >
                    <IconButton
                      onClick={() => onDelete(value)}
                      aria-label="delete"
                      title="Excluir"
                      size="large"
                      data-cy="buttonDelete"
                    >
                      <DeleteOutline />
                    </IconButton>
                  </Grid>
                </PermissionsGate>
                <Grid
                  item
                  xl={3}
                  lg={3}
                  md={2}
                  sm={2}
                  xs={1}
                >
                  <IconButton
                    onClick={() => onNavigateToHistory(value)}
                    aria-label="group history"
                    title="Histórico do grupo"
                    size="large"
                    data-cy="buttonHistory"
                  >
                    <HistoryOutlined />
                  </IconButton>
                </Grid>
              </Grid>
            </div>
          );
        },
      },
    });
  }

  return columns;
};

export function currencyFormat(num) {
  return 'R$' + valueToPositiveFloat(num, 2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
}