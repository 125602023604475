import dayjs from "dayjs";

const dateFormatTemplate = "YYYY-MM-DDTHH:mm:ssZZ";

export const makeInitialAndFinalDate = (indexMonth) => {
  const date = new Date();
  const year = date.getFullYear();
  const selectedMonth = new Date(year, indexMonth);
  const lastDay = new Date(year, selectedMonth.getMonth() + 1, 0);
  
  return {
    initialDate: dayjs(selectedMonth).format(dateFormatTemplate),
    finalDate: dayjs(lastDay).format("YYYY-MM-DDT23:59:59ZZ"),
  };
};