import React from "react";
import { Grid, Skeleton } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

import {useWindowSize} from "hooks/useWindowsSize";

import Aux from "hoc/auxiliar";

const ListVehiclesCompareSkeleton = () => {
  const classes = useStyles();
  const size = useWindowSize();

  return (
    <Aux>
      <Grid container>
        <Grid item xl={size.grid4} lg={size.grid4} md={size.grid4} sm={size.grid4} xs={size.grid4}>
          <Skeleton variant="rectangular" height={45} className={classes.bordered}  />
        </Grid>
      </Grid>
      <Grid container spacing={1} mt={1}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Skeleton 
            variant="rectangular" 
            height={650} 
            className={classes.bordered} 
          />
        </Grid>
      </Grid>
    </Aux>
  );
};

const useStyles = makeStyles(theme => ({
  bordered: {
    borderRadius: "22px",
  },
  tableButtons: {
    marginTop: theme.spacing(1),
  },
  buttons: { 
    marginBottom: "10px", 
    display: "flex", 
    justifySelf: "flex-end",
    borderRadius: "10px"
  }
}));

export default ListVehiclesCompareSkeleton;
