import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  titlePage: {
    fontWeight: 600,
    cursor: "default",
    fontSize: "24px",
    color: theme.palette.text.title2,
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      marginBottom: "8px"
    }
  },
  paper: {
    borderRadius: 20,
    maxWidth: "100%",
    padding: "4px 8px 4px 20px",
    marginBottom: 20,
    boxShadow: `3px 5px 4px ${theme.palette.background.hoverMultiMenu}`,
  },
  multiselect: {
    borderRadius: 20,
    maxWidth: "100%",
    marginBottom: 20,
    boxShadow: `3px 5px 4px ${theme.palette.background.backgroundButton}`,
  },
  helpButton: {
    color: theme.palette.primary.main,
    fontSize: 18,
    textTransform: 'capitalize',
    marginLeft: 8,
    "&:hover": {
      backgroundColor: 'transparent',
    },
  },
  titleContainer: {
    padding: 4,
    paddingRight: 24,
    marginTop: 10
  },
  toolbarContainer: {
    padding: 4,
    marginTop: 10,
    justifyContent: "flex-end"
  },
  arrowUp: {
    color: theme.palette.success.main
  },
  arrowDown: {
    color: theme.palette.error.main
  },
  neutral: {
    color: theme.palette.gray.default
  },
  textBold: {
    fontWeight: "bold",
  },
  iconButton: {
    position: 'absolute',
    right: 16,
    top: 16,
    color: theme.palette.grey[500],
    fill: '#FFFEFF',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.20)',
    width: 16,
    height: 16
  },
  titleModal: {
    fontWeight: 600,
    fontSize: "20px",
    textAlign: "center"
  },
  subtitleModal: {
    color: theme.palette.grey[500],
    paddingTop: 15
  },
  paragraphModal: {
    paddingTop: 15
  },
  listModalItem: {
    paddingTop: 3,
    fontWeight: 600,
  },
  btRankingModal: {
    marginTop: 20,
    borderRadius: 20,
    textTransform: "none"
  }
}));
