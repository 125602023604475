import React, { useEffect, useState } from 'react'
import { Box, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';

import Widget from 'components/Widget/Widget';
import Aux from "hoc/auxiliar";

import FormRoleSkeleton from 'components/Skeletons/FormRoleSkeleton';
import Table from 'components/Table/Table';
import PermissionsGate, { hasPermission } from "components/PermissionsGate";

import { makeColumns } from "./tableColumns";

import useStyles from "./styles";
import { vehicleDeviceHistory } from 'services/device';
import { toast } from 'react-toastify';
import BackButton from 'components/BackButton';
import {useWindowSize} from "../../../hooks/useWindowsSize";
import TableResponsive from "../../../components/TableResponsive";

const HistoricDevices = () => {
  const { deviceId } = useParams();
  const classes = useStyles()
  const [vehiclesHistory, setVehiclesHistory] = useState([])
  const size = useWindowSize()
  const [loading, setLoading] = useState(false)
  const columns = makeColumns({size});

  const fetchVehicleDeviceHistory = async () => {
    try {
      setLoading(true)
      const response = await vehicleDeviceHistory(deviceId)
      if (response.status === 200) {
        const { vehiclesHistory } = response.data
        vehiclesHistory ? setVehiclesHistory(vehiclesHistory) : toast.warning('Nenhum histórico foi encontrado.')
      }
    } catch (error) {

    } finally {
      setLoading(false)
    }
  }
  
  
  useEffect(() => {
    deviceId && hasPermission({scopes: ['can_view_history_devices']}) && fetchVehicleDeviceHistory()
  }, [deviceId])

  return (
    <Aux>
      <PermissionsGate scopes={['can_view_history_devices']}>
        <Grid container spacing={4}>
          <Grid item xs={12} className={classes.tableContent}>
            <Widget disableWidgetMenu title="Histórico de veículos vinculados a unidade eletrônica">
              {loading ? (
                <FormRoleSkeleton />
              ) : (
                size.mobile?
                  <TableResponsive
                    columns={columns}
                    data={vehiclesHistory}
                    tableName="historic-devices"
                  />:
                <Table
                  columns={columns}
                  data={vehiclesHistory}
                  tableName="historic-devices"
                />
              )}
            </Widget>
            <Grid container item justifyContent="flex-end" xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                <Box sx={{ m: 2 }}>
                  <BackButton className={classes.backButton} />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </PermissionsGate>
    </Aux>
  )
}

export default HistoricDevices