import { useJsApiLoader } from '@react-google-maps/api';

const MapLoader = ({ children }) => {
  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script-shared',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    language: 'pt-BR',
    region: 'US',
  });

  if (loadError) return <div>Erro ao carregar mapa.</div>;
  if (!isLoaded) return <div>Carregando mapa...</div>;

  return <>{children}</>;
};

export default MapLoader;
