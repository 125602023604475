import React, { useState, useEffect, useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { Box, Typography } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";

// domain
import fields from "domain/forms/addDriverInitialValues";
import schemaValidation from "domain/validation/yup/addDriverValidation";

// services
import { getDriverDetail, updateDriver } from "services/driver";
import { handleAmplitudeEvent } from "services/amplitude";

// helpers
import makeDefaultValues from "helpers/makeDefaultValues";
import { cpfMask, dateMask, phoneMask, onlyNumberMask, removeAllSpecialCharacters } from "helpers/masks";

// components
import DriverForm from "components/DriverForm";
import BackButton from "components/BackButton";
import HelpButton from "components/HelpButton";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";
import DriverModel from "models/Driver";

// styles
import useStyles from "./styles";

const EditDriver = () => {
  const [driverEmail, setDriverEmail] = useState({
    currentEmail: null,
    defaultEmail: null,
  });

  const classes = useStyles();
  const history = useHistory();
  const { driverId } = useParams();
  const defaultValues = makeDefaultValues({ fields });

  const methods = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schemaValidation),
  });

  const fetchDriverDetail = async (id) => {
    try {
      const response = await getDriverDetail(id);

      setDriverEmail({
        currentEmail: response?.data?.email,
        defaultEmail: `${response?.data?.documentNumber}@gobrax.com.br`,
      });
      
      methods.setValue("id", response.data.id);
      methods.setValue("customerId", response.data.customerId);
      methods.setValue("birthDate", dateMask(response.data.birthDate));
      methods.setValue("customerOption", response.data.customerId);
      methods.setValue("documentNumber", cpfMask(response.data.documentNumber), { shouldValidate: true })
      methods.setValue("name", response.data.name);
      methods.setValue("phone", phoneMask(response.data.phoneNumber), { shouldValidate: true })
      methods.setValue("obs", response.data.note);
      methods.setValue("canLink", response.data.canLink);
      methods.setValue("role", response.data?.role);
      methods.setValue("entityId", response.data?.entityId);
    } catch (error) {
      console.log(error);
    }
  };

  useMemo(() => {
    if (
      driverEmail?.currentEmail &&
      driverEmail?.currentEmail !== driverEmail?.defaultEmail
    ) {
      methods.setValue("email", driverEmail?.currentEmail);
    }
  }, [driverEmail]);

  const onSubmit = async (data) => {
    const cpfNumbers = removeAllSpecialCharacters(data?.documentNumber);

    try {
      const driverModel = new DriverModel({
        birthDate: data.birthDate || null,
        customerId: data.customer.value,
        documentNumber: onlyNumberMask(data.documentNumber),
        email:
          data.email || `${cpfNumbers}@gobrax.com.br`,
        name: data.name.trim(),
        note: data.obs || null,
        phoneNumber: onlyNumberMask(data.phone) || null,
        id: data.id,
        canLink: data.canLink,
        role: data?.role?.value,
        entityId: data?.entityId
      });

      const response = await updateDriver(driverModel);

      if (response.status !== 200) {
        toast.error("Erro ao atualizar motorista!");
      } else {
        handleAmplitudeEvent('Driver Edited');
        toast.success("Motorista atualizado com sucesso");
        history.push("/gofurther/driver/all-drivers");
      }
    } catch (error) {
      console.log(error);
      toast.error("Error ao atualizar motorista! Verifique com o suporte!");
    }
  };

  useEffect(() => {
    handleAmplitudeEvent('Edit Driver Screen Viewed');

    if (hasPermission({ scopes: ["can_edit_drivers"] })) {
      fetchDriverDetail(driverId);
    }
  }, []);

  if (!defaultValues) {
    return "...loading";
  }
  
  return (
    <PermissionsGate scopes={["can_edit_drivers"]}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className={classes.titleBox}>
            <Typography variant="h1" className={classes.title}>
              Edição de motorista
            </Typography>
            <HelpButton />
          </div>
          <DriverForm />
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid
                container
                item
                justifyContent="flex-end"
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
              >
                <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                  <Box sx={{ m: 2 }}>
                    <BackButton className={classes.backButton} />
                  </Box>
                </Grid>
                <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                  <Box sx={{ m: 2 }}>
                    <Button
                      fullWidth
                      variant="contained"
                      type="submit"
                      className={classes.btConfirm}
                    >
                      Atualizar
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </PermissionsGate>
  );
};

export default EditDriver;
