import * as yup from "yup";

import Utils from "helpers/utils";

import { hasPermission } from "components/PermissionsGate";

export default yup.object().shape({
  customer: yup
    .object({
      value: yup
        .number()
        .typeError('Formato inválido'),
      label: yup
        .string()
        .typeError('Formato inválido')
    })
    .required('Campo obrigatório')
    .typeError('Campo obrigatório'),
  documentNumber: yup
    .string()
    .required("Campo obrigatório")
    .min(14, "Preencha corretamente o CPF")
    .test(
      'documentNumber',
      'CPF inválido',
      (cpf) => Utils.validateCPF(cpf)),
  name: yup.string().required("Campo obrigatório"),
  phone:
    yup.string()
      .matches(/^\([1-9]{2}\) (?:9[1-9])[0-9]{3}-[0-9]{4}$/, "Celular inválido")
      .nullable(),
  role: yup
    .object({
      value: yup
        .number()
        .typeError('Formato inválido'),
      label: yup
        .string()
        .typeError('Formato inválido')
    })
    .when('canUpdateUsersRole', {
      is: () => hasPermission({ scopes: ["can_update_users_roles"] }),
      then: yup
        .object({
          value: yup
            .number()
            .typeError('Formato inválido'),
          label: yup
            .string()
            .typeError('Formato inválido')
        })
        .required()
        .typeError('Campo obrigatório'),
      otherwise: yup
        .object({
          value: yup
            .number()
            .typeError('Formato inválido'),
          label: yup
            .string()
            .typeError('Formato inválido')
        })
        .nullable()
    }),
  email: yup
    .string()
    .nullable()
    .email('E-mail inválido')
});
