import React, { useState } from "react";
import { Grid, Box, IconButton, TextField, Button, Typography } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import classnames from "classnames";
import { useForm, FormProvider, useFormContext, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import useStyles from './styles';
import formRangeValidation from "./formRangeValidation";

const RangeComponent = () => {
  const classes = useStyles();
  const methods = useForm({
    resolver: yupResolver(formRangeValidation),
    defaultValues: {
      rewardRange: [],
    }
  });

  const { setValue, getValues, formState: { errors } } = useFormContext(); 
  const ranges = useWatch({ name: 'rewardRange', defaultValue: getValues('rewardRange') || [] });
  const [rangeInput, setRangeInput] = useState({ range: "", reward: "" });
  const [editingIndex, setEditingIndex] = useState(null);
  const [error, setError] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRangeInput((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddRange = async () => {
    const isEdit = editingIndex !== null;

    const updatedRange = rangeInput;   

    const updatedRanges = isEdit ? ranges.map((range, index) => index === editingIndex ? updatedRange : range) : [...ranges, updatedRange];
    
    try {
      await formRangeValidation.validate({
        rewardRange: updatedRanges
      }, { abortEarly: false });
    } catch (error) {
      if (error && error.inner && error.inner.length > 0) {
        setError(error.inner[0].message); 
      } else {
        setError("Erro desconhecido ocorreu."); 
      }
      return;
    }

    const updatedRangeValues = updatedRange.range.split('-').map(Number);

    if (updatedRangeValues[1] > 100) {
      setError("O valor máximo da faixa não pode ser maior que 100.");

      return;
    }

    const isOverlapping = ranges.some(range => {
      const existingRange = range.range.split('-').map(Number);

      return (
        (updatedRangeValues[0] >= existingRange[0] && updatedRangeValues[0] <= existingRange[1]) || 
        (updatedRangeValues[1] >= existingRange[0] && updatedRangeValues[1] <= existingRange[1]) || 
        (updatedRangeValues[0] <= existingRange[0] && updatedRangeValues[1] >= existingRange[1])    
      );
    });

    if (isOverlapping && !isEdit) {
      setError("O novo range se sobrepõe a um range existente.");

      return;
    }

    setValue("rewardRange", updatedRanges);
  
    setRangeInput({ range: "", reward: "" });
    setEditingIndex(null);
    setError("");
  };
  

  const handleEditRange = (index) => {
    setRangeInput(ranges[index]);
    setEditingIndex(index);
  };

  const handleDeleteRange = (index) => {
    const updatedRanges = ranges.filter((_, i) => i !== index);
    setValue("rewardRange", updatedRanges); 
  };

  return (
    <FormProvider {...methods}>
      <Box sx={{ ml: 2, mr: 2, mb: 2, mt: 1 }}>
        <Typography variant="h5">Configure as faixas e quanto cada motorista vai receber em cada</Typography>
        <Typography variant="body2" component="span">Para fazer o cadastro, insira a faixa/range desejado, exemplo: 0 - 10. Na sequência, insira o valor a ser pago ao ser atingindo a faixa/range, exemplo: RS 1,00.</Typography>
        <Typography variant="body2" component="span">Os valores seguintes de faixa/range serão relativos ao inserido anteriormente, devendo ser o último valor inserido + 1. Por exemplo, se inseriu 0 - 10 na primeira faixa, o próximo valor deverá iniciar em 11 e assim sucessivamente até chegar ao valor de 100.</Typography>
        {errors?.rewardRange?.message && (
          <Typography variant="body2" component="span" color="error" mb={2}>{errors?.rewardRange?.message}</Typography>
        )}
        <Grid container spacing={2} alignItems="center" mt={0.5}>
          <Grid item xs={5}>
            <TextField
              label="Faixa (Ex: 0-69)"
              name="range"
              value={rangeInput.range}
              onChange={handleInputChange}
              fullWidth
              error={!!errors?.rewardRange?.[editingIndex]?.range}
              helperText={errors?.rewardRange?.[editingIndex]?.range?.message}
              className={classnames(classes.textField)}
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              label="Valor (R$)"
              name="reward"
              value={rangeInput.reward}
              onChange={handleInputChange}
              fullWidth
              error={!!errors?.rewardRange?.[editingIndex]?.reward}
              helperText={errors?.rewardRange?.[editingIndex]?.reward?.message}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              className={classnames(classes.textField)}
            />
          </Grid>
          <Grid item xs={2}>
            <Button variant="outlined" color="primary" onClick={handleAddRange}>
              {editingIndex !== null ? "Atualizar" : "Adicionar"}
            </Button>
          </Grid>
        </Grid>
        {error && (
          <Typography color="error" variant="body2" sx={{ mt: 1 }}>
            {error}
          </Typography>
        )}

        {ranges.length > 0 && (
          <>
            <Typography variant="h6" mt={3}>Faixas de Premiação</Typography>
            <Box mt={2}>
              {ranges.map((range, index) => (
                <Grid container spacing={2} alignItems="center" key={index} className={classes.borderBottom}>
                  <Grid item xs={5}>
                    <Typography>{`${range.range}`}</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography>{`R$ ${range.reward}`}</Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton onClick={() => handleEditRange(index)}>
                      <EditIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton onClick={() => handleDeleteRange(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
            </Box>
          </>
        )}
      </Box>
    </FormProvider>
  );
};

export default RangeComponent;
