import api from "./api";
import { convertStringDate } from "helpers/functions";

export const getAccidentRisk = async (vehicleIds, startDate, endDate) => {
  try {
    const convertedDates = convertStringDate(startDate, endDate);
    const response = await api.get(`/fleet-accident-risk?vehicles_ids=${vehicleIds}&start_date=${convertedDates.startDate}&end_date=${convertedDates.endDate}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getFleetBrand = async (vehicleIds, startDate, endDate) => {
  try {
    const convertedDates = convertStringDate(startDate, endDate);
    const response = await api.get(`/fleet-brand?vehicles_ids=${vehicleIds}&start_date=${convertedDates.startDate}&end_date=${convertedDates.endDate}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getFleetOperation = async (vehicleIds, startDate, endDate) => {
  try {
    const convertedDates = convertStringDate(startDate, endDate);
    const response = await api.get(`/fleet-operation?vehicles_ids=${vehicleIds}&start_date=${convertedDates.startDate}&end_date=${convertedDates.endDate}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getFleetVehicle = async (vehicleIds, startDate, endDate) => {
  try {
    const convertedDates = convertStringDate(startDate, endDate);
    const response = await api.get(`/fleet-vehicle?vehicles_ids=${vehicleIds}&start_date=${convertedDates.startDate}&end_date=${convertedDates.endDate}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getFleetProductivity = async (vehicleIds, startDate, endDate) => {
  try {
    const convertedDates = convertStringDate(startDate, endDate);
    const response = await api.get(`/fleet-productivity?vehicles_ids=${vehicleIds}&start_date=${convertedDates.startDate}&end_date=${convertedDates.endDate}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getMyFleet = async (vehicleIds, startDate, endDate) => {
  try {
    const convertedDates = convertStringDate(startDate, endDate);
    const response = await api.get(`/my-fleet?vehicles=${vehicleIds}&startDate=${convertedDates.startDate}&endDate=${convertedDates.endDate}`);
    return response;
  } catch (err) {
    throw err;
  }
};