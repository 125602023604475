import {
  PickersLayoutRoot,
  PickersLayoutContentWrapper,
  usePickerLayout,
  pickersLayoutClasses
} from '@mui/x-date-pickers';

function CustomLayout(props) {
  const { content } = usePickerLayout(props);

  const primaryColor = "#F7DF36";
  const hoverColor = "#ffea66";

  return (
    <PickersLayoutRoot
      className={pickersLayoutClasses.root}
      ownerState={props}
      sx={{
        [`.${pickersLayoutClasses.contentWrapper}`]: {
          "& .MuiPickersDay-root": {
            lineHeight: 1,
          },
          "& .Mui-selected": {
            backgroundColor: primaryColor,
            color: "black",
          },
          "& .MuiPickersDay-root:hover": {
            backgroundColor: hoverColor,
            color: "black",
          },
          "& .Mui-selected:focus": {
            backgroundColor: primaryColor,
            color: "black",
          },
        },
      }}
    >
      <PickersLayoutContentWrapper className={pickersLayoutClasses.contentWrapper}>
        {content}
      </PickersLayoutContentWrapper>
    </PickersLayoutRoot>
  );
}

export default CustomLayout;