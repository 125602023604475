import React from "react";
import { Grid } from "@mui/material";
import { Skeleton } from '@mui/material';

const ConsolidatedBrandSkeleton = () => {  
  return (
    <Grid 
      container 
      spacing={3} 
      alignItems="center" 
      justifyContent="center"
      style={{
        padding: "10px",
      }}
    >
      <Grid item container xl={12} lg={12}>
          <Skeleton variant="rectangular" width={'100%'} height={200} style={{ 
            borderRadius: 20
          }} />
      </Grid>
    </Grid>
  );
};

export default ConsolidatedBrandSkeleton;
