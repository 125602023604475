import { makeStyles } from '@mui/styles';
import { alpha } from '@mui/material/styles';

export default makeStyles((theme) => ({
  dialogTitle: {
    marginTop: theme.spacing(4),
  },
  dialogContent: {
    display: 'flex',
    flex: 1,
    overflow: 'visible',
    "@media (min-width: 768px)": {
      minHeight: '480px',
    }
  },
  form: {
    display: 'flex',
    flex: 1,
  },
  boxContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  title: {
    fontSize: 24,
    fontWeight: 500,
    color: theme.palette.text.title,
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.title,
  },
  label: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  calendarBox: {
    marginTop: theme.spacing(1),
    border: `1px solid ${theme.palette.gray.extraLight}`,
    borderRadius: '22px',
    padding: '4px 4px 4px 20px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  btConfirm: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: theme.spacing(1),
    '&:hover': {
      backgroundColor: alpha(theme.palette.secondary.main, 0.8),
      fontWeight: 600
    },
  },
  backButton: {
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.background.backgroundButton
  },
  step: {
    marginTop: theme.spacing(2),
    fontSize: 18,
    fontWeight: 500,
    color: theme.palette.text.title,
    textAlign: 'center',
  },
}));
