import React from 'react';
import { Paper, Typography } from '@mui/material';
import {
  Cell,
  Label,
  Pie,
  PieChart,
  ResponsiveContainer,
  Text,
} from "recharts";

import colors from 'themes/gobrax';

import useStyles from './styles'

function PerformanceIndicator(props) {
  const classes = useStyles();

  let color = '';

  if (props.data.globalScore <= 40) {
    color = colors.palette.error.main;
  } else if (props.data.globalScore <= 80) {
    color = colors.palette.warning.main;
  } else {
    color = colors.palette.success.main;
  }

  const data = [
    {
      color: color,
      value: props.data.globalScore
    },
    {
      color: colors.palette.gray.extraLight,
      value: 100 - props.data.globalScore
    },
  ];

  return (
    <Paper elevation={1} className={classes.container}>
      <Typography variant="h5" className={classes.title}>
        Performance
      </Typography>
      <ResponsiveContainer height={100}> 
        <PieChart>
          <Pie
            data={data}
            innerRadius={35}
            outerRadius={48}
            startAngle={-270}
            dataKey="value"
            isAnimationActive={false}
          >
            {data?.map((entry, index) => (
              <Cell key={index} fill={entry.color} />
            ))}
            <Label
              content={(props) => {
                const positioningProps = {
                  x: props.viewBox.cx,
                  y: props.viewBox.cy,
                  textAnchor: "middle",
                  verticalAnchor: "middle",
                };
                const presentationProps = {
                  fill: colors.palette.gray.light,
                  fontSize: 40,
                  fontWeight: "bold",
                };
                return (
                  <Text
                    {...positioningProps}
                    {...presentationProps}
                  >
                    {data[0].value}
                  </Text>
                );
              }}
            />
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </Paper>
  )
}

export default PerformanceIndicator;
