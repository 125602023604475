import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  CssBaseline,
  FormControlLabel,
  Grid,
  Paper,
  Typography,
  TextField, InputAdornment, IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { toast } from "react-toastify";

// store
import { useAppDispatch } from "store";
import { logout } from "store/globalSlice";

// services
import { apiExt } from "services/api";
import { handleAmplitudeEvent } from "services/amplitude";


// components
import Copyright from 'components/Copyright'

import AuthClient from "../../infra/Auth/Auth";

// styles
import useStyles from "./styles";

// assets
import GoBraxLogo from "../../images/gobrax.svg";
import { saveRecoveryEmail } from "store/features/recoveryEmailSlice";

function Login(props) {
  const classes = useStyles();

  // local
  const [isLoading, setIsLoading] = useState(false);
  const [emailLostPassword, setEmailLostPassword] = useState("");
  const [nameValue, setNameValue] = useState("");
  const [loginValue, setLoginValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [activeTabId, setActiveTabId] = useState(0);
  const [visible, setVisible] = useState(false)

  const [
    formUsernamePasswordAction,
    setFormUsernamePasswordAction,
  ] = useState();
  const [csrfUsernamePasswordToken, setCsrfUsernamePasswordToken] = useState("");
  const [loginMessages, setLoginMessages] = useState();
  const dispatch = useAppDispatch()
  const auth = new AuthClient({ basePath: process.env.REACT_APP_LINK_API_LOGIN });

  const clearFields = () => {
    setEmailLostPassword("");
    setLoginValue("");
    setNameValue("");
    setPasswordValue("");
  };

  const ForgetPassword = async () => {
    try {
      setIsLoading(true)
      const response = await apiExt.get(`/web/v2/authenticator/noauth/requestEmailChangePass/` + emailLostPassword);
      if (response.data.success) {
        setIsLoading(false);
        
        handleAmplitudeEvent('Password Recovery Requested', { email: emailLostPassword });

        toast.success(`Enviamos o link de recuperação de senha para ${emailLostPassword}. Verifique sua caixa de entrada e de spam.`);
      } else {
        setIsLoading(false)
        toast.error('Erro ao enviar e-mail, por favor tente novamente mais tarde ou entre em contato com o suporte.')
      }
    }
    catch (e) {
      setIsLoading(false)
      toast.error('Erro ao enviar e-mail, por favor tente novamente mais tarde ou entre em contato com o suporte.')
    }
  }

  useEffect(() => {
    const fetchAuth = async () => {
      try {
        const flow = await auth.getService({ urlService: `${process.env.REACT_APP_LINK_API_LOGIN}/self-service/login/browser`, urlRedirectError: `${process.env.LINK_API_LOGIN}self-service/login/browser` });
        setFormUsernamePasswordAction(JSON.stringify(flow.methods.password.config.action).replaceAll('"', ""));
        setCsrfUsernamePasswordToken(JSON.stringify(flow.methods.password.config.fields[2].value).replaceAll('"', ""));
        setLoginMessages(flow.methods.password.config.messages && flow.methods.password.config.messages[0].text);
      } catch (err) {
        const flowValue = new URLSearchParams(window.location.search).get('flow');
        if (flowValue) {
          window.location.href = '/login';
        }
      }
    };
    fetchAuth();
  }, []);

  useEffect(() => {
    dispatch(logout())
  }, [dispatch])

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <video autoPlay loop muted className={classes.bgVideo}>
        <source src={require("../../images/1134952923.mp4")} type="video/mp4" />
      </video>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
        className={classes.image}
      >
        <Grid component={Paper} elevation={3} className={classes.loginForm}>
          <div className={classes.paper}>
            <div className={classes.logotype}>
              <img className={classes.logotypeIcon} src={GoBraxLogo} alt="logo" />
            </div>
            {activeTabId === 0 && (
              <form
                className={classes.form}
                action={formUsernamePasswordAction}
                method="POST"
              >
                <Typography className={classes.title1}>Acesse a melhor conectividade da América Latina!</Typography>
                <Typography className={classes.title2}>Insira o seu e-mail e senha para acessar a plataforma da gobrax</Typography>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="identifier"
                  label="Digite seu e-mail"
                  name="identifier"
                  autoComplete="email"
                  onChange={(e) => setNameValue(e.target.value)}
                  value={nameValue}
                  autoFocus
                  InputProps={{
                    style: { borderRadius: '1rem' },
                    startAdornment: (
                      <InputAdornment position="start">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <g clipPath="url(#clip0_1086_16724)">
                            <path d="M20 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM20 8L12 13L4 8V6L12 11L20 6V8Z" fill="#181714" />
                          </g>
                          <defs>
                            <clipPath id="clip0_1086_16724">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  name="password"
                  variant="outlined"
                  style={{ marginTop: '1.5rem' }}
                  fullWidth
                  onChange={(e) => {
                    setPasswordValue(e.target.value)
                  }}
                  label="Digite sua senha"
                  id="password"
                  type={visible ? 'text' : 'password'}
                  value={passwordValue}
                  autoComplete="current-password"
                  InputProps={{
                    style: { borderRadius: '1rem' },
                    startAdornment: (
                      <InputAdornment position="start">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <g clipPath="url(#clip0_614_25887)">
                            <path d="M6 8V7C6 5.4087 6.63214 3.88258 7.75736 2.75736C8.88258 1.63214 10.4087 1 12 1C13.5913 1 15.1174 1.63214 16.2426 2.75736C17.3679 3.88258 18 5.4087 18 7V8H20C20.2652 8 20.5196 8.10536 20.7071 8.29289C20.8946 8.48043 21 8.73478 21 9V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V9C3 8.73478 3.10536 8.48043 3.29289 8.29289C3.48043 8.10536 3.73478 8 4 8H6ZM19 10H5V20H19V10ZM11 15.732C10.6187 15.5119 10.3207 15.1721 10.1522 14.7653C9.98376 14.3586 9.9542 13.9076 10.0681 13.4823C10.1821 13.057 10.4332 12.6813 10.7825 12.4132C11.1318 12.1452 11.5597 11.9999 12 11.9999C12.4403 11.9999 12.8682 12.1452 13.2175 12.4132C13.5668 12.6813 13.8179 13.057 13.9319 13.4823C14.0458 13.9076 14.0162 14.3586 13.8478 14.7653C13.6793 15.1721 13.3813 15.5119 13 15.732V18H11V15.732ZM8 8H16V7C16 5.93913 15.5786 4.92172 14.8284 4.17157C14.0783 3.42143 13.0609 3 12 3C10.9391 3 9.92172 3.42143 9.17157 4.17157C8.42143 4.92172 8 5.93913 8 7V8Z" fill="black" />
                          </g>
                          <defs>
                            <clipPath id="clip0_614_25887">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={() => setVisible(!visible)}
                      >
                        <IconButton size="large">
                          {visible ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>),
                  }}
                />
                <TextField
                  name="csrf_token"
                  id="csrf_token"
                  type="hidden"
                  required
                  fullWidth
                  variant="outlined"
                  label="Csrf token"
                  value={csrfUsernamePasswordToken}
                  className={classes.csrf}
                />
                <Grid container style={{ marginTop: -10 }}>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <FormControlLabel
                      control={<Checkbox value="remember" color="secondary" />}
                      label="Lembrar"
                    />
                  </Grid>
                  <Grid style={{ alignSelf: 'center', marginTop: -8, cursor: 'pointer' }} item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Typography onClick={() => {
                      setActiveTabId(1);
                      handleAmplitudeEvent('Password Recovery Clicked');
                    }}>Esqueceu sua senha?</Typography>
                  </Grid >
                </Grid >

                {
                  loginMessages
                    ? (
                      <>
                        <Typography color="error" className={classes.errorMessage}>
                          Os dados informados de login ou senha são inválidos.
                        </Typography>
                      </>
                    )
                    : (<>
                    </>)
                }
                {
                  isLoading ? (
                    <CircularProgress size={26} className={classes.loginLoader} />
                  ) : (
                    <Button
                      type="submit"
                      fullWidth
                      className={classes.submit}
                      variant="contained"
                    >
                      Entrar
                    </Button>
                  )
                }
                <Box mt={5}>
                  <Copyright />
                </Box>
              </form >
            )}
            {
              activeTabId === 1 && (
                <form className={classes.form} method="POST" noValidate>
                  <Typography className={classes.title3}>Esqueci minha senha</Typography>
                  <Typography className={classes.title2}>Digite o e-mail cadastrado e enviaremos um link para você redefinir a sua senha.</Typography>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="identifier"
                    label="Digite seu e-mail"
                    name="identifier"
                    autoComplete="email"
                    onChange={(e) => setEmailLostPassword(e.target.value.trim())}
                    value={emailLostPassword}
                    autoFocus
                    InputProps={{
                      style: { borderRadius: '1rem' },
                      startAdornment: (
                        <InputAdornment position="start">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <g clipPath="url(#clip0_1086_16724)">
                              <path d="M20 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM20 8L12 13L4 8V6L12 11L20 6V8Z" fill="#181714" />
                            </g>
                            <defs>
                              <clipPath id="clip0_1086_16724">
                                <rect width="24" height="24" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {isLoading ? (
                    <CircularProgress size={26} className={classes.loginLoader} />
                  ) : (
                    <>
                      <Button
                        fullWidth
                        className={classes.submit}
                        variant="contained"
                        onClick={() => {
                          dispatch(saveRecoveryEmail(emailLostPassword));
                          ForgetPassword();
                        }}
                      >
                        Enviar
                      </Button>
                      <Button
                        onClick={() => {
                          setActiveTabId(0);
                          clearFields();
                        }}
                        fullWidth
                        color={'secondary'}
                        className={classes.submit2}
                        variant="outlined"
                      >
                        Voltar
                      </Button>
                    </>
                  )}
                  <Box mt={5}>
                    <Copyright />
                  </Box>
                </form>
              )
            }
            {
              activeTabId === 2 && (
                <form className={classes.form} method="POST" noValidate>
                  <Typography
                    component="h1"
                    variant="h5"
                    align="center"
                    color="primary"
                  >
                    Cadastro
                  </Typography>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="Nome"
                    name="name"
                    autoComplete="name"
                    value={nameValue}
                    onChange={(e) => setNameValue(e.target.value)}
                    autoFocus
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="E-mail"
                    name="email"
                    autoComplete="email"
                    value={emailLostPassword}
                    onChange={(e) => setEmailLostPassword(e.target.value)}
                    autoFocus
                  />
                  <Button
                    type="submit"
                    className={classes.submit}
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    Registrar
                  </Button>
                  <Button
                    onClick={() => {
                      setActiveTabId(0);
                      clearFields();
                    }}
                    className={classes.cancel}
                    variant="contained"
                    size="large">
                    Cancelar
                  </Button>
                </form>
              )
            }
          </div >
        </Grid >
      </Grid >
    </Grid >
  );
}

export default Login;
