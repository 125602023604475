export const translationsMonths = {
  "January": "Jan",
  "February": "Fev",
  "March": "Mar",
  "April": "Abr",
  "May": "Mai",
  "June": "Jun",
  "July": "Jul",
  "August": "Ago",
  "September": "Set",
  "October": "Out",
  "November": "Nov",
  "December": "Dez"
};