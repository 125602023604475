import { makeStyles } from "@mui/styles";
import gobrax from 'themes/gobrax';

export default makeStyles((theme) => ({
  title: {
    fontSize: 18,
    fontWeight: 500,
    color: theme.palette.text.title
  },
  container: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(3),
    borderRadius: 28
  },
  explanation: {
    fontSize: 16,
    fontWeight: 400
  },
  textError: {
    marginLeft: theme.spacing(2) - 2,
  },
  subTitle: {
    fontWeight: '400',
    fontSize: '16px',
    color: gobrax.palette.text.title2,
    marginBottom: '16px'
  },
  accordion: {
    color: gobrax.palette.text.title2,
    boxShadow: 'none',
    width: '100%',
    marginBottom: '16px',
    borderRadius: '20px !important',
    "& p": {
      fontSize: '16px',
      maxWidth: '440px',
      whiteSpace: 'normal',  
      overflow: 'visible',  
      textOverflow: 'initial', 
      cursor: 'pointer',
      wordWrap: 'break-word',
    },
    "& .Mui-expanded": {
      margin: '0',
      minHeight: '48px',
    },
    "& .MuiAccordionSummary-content": {
      margin: '0',
      height: '48px',
      alignItems: 'center'
    }
  },
  accordionDrop: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  accordionSummary: {
    borderBottom: "1px solid #F0F0F0",
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  rewardTypeTitle: {
    marginBottom: theme.spacing(1),
    wordWrap: 'break-word',
    whiteSpace: 'normal',
    textOverflow: 'ellipsis',
    overflow: 'visible',
    width: '100%',
    fontWeight: 500,
    lineHeight: 1
  },
  rewardTypeDescription: {
    wordWrap: 'break-word',
    whiteSpace: 'normal',
    overflow: 'visible',
    lineHeight: 1,
  },
  form: {
    width: '100%',
    "& .ql-editor": {
      "& span": {
        color: theme.palette.error.main
      },
      height: '266px',
      overflow: 'auto',
      border: "1px solid #F0F0F0",
      borderRadius: '8px',
      "&:focus, &:visited": {
        border: "2px solid #2265b7 !important",
      },
      "&:hover": {
        border: "1px solid #424448",
      },
      [`&::-webkit-scrollbar`]: {
        width: '7px',
        height: '36px',
      },

      [`&::-webkit-scrollbar-track`]: {
        background: '#DADADA',
        borderRadius: '4px',
      },

      [`&::-webkit-scrollbar-thumb`]: {
        background: '#939393',
        borderRadius: '4px',
        height: 'auto',
      },
    },
    "& .ql-container.ql-snow": {
      marginTop: '12px',
      border: "none",
    },
    "& .ql-toolbar.ql-snow": {
      border: 'none',
      display: 'flex',
      justifyContent: 'space-between'
    },
    "& .ql-font.ql-picker": {
      display: 'none'
    },
    "& .quill": {
      display: 'flex',
      flexDirection: 'column-reverse'
    },
    "& .MuiAccordion-rounded:first-child": {
      borderRadius: '32px'
    },
    "& .ql-formats": {
      marginRight: 0,
    }
  },
  checkboxGroup: {
    "& span": {
      fontWeight: 700,
    }
  },
  hint: {
    fontSize: 12,
  }
}));
