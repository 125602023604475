import { makeStyles } from "@mui/styles";

export default makeStyles(theme => ({
  paper: {
    borderRadius: 20,
    padding: "8px 20px",
    maxWidth: "350px",
    margin: "0 auto"
  },
  paper2: {
    borderRadius: 20,
    padding: "8px 8px",
    maxWidth: "355px"
  },
  inputCalendar: {
    fontSize: theme.typography.h5.fontSize,
    color: theme.palette.gray.default,
    "& .show-calendar .drp-buttons":{
      paddingBottom:10
    },
    width: "255px",
    "& .MuiInputBase-input": {
      textAlign: "center",
    },
    "&:before": {
      transition: "none",
      border: "transparent",
    },
    "&:after": {
      transition: "none",
      border: "transparent"
    },
    "& input": {
      cursor: "pointer",
    },
    "&:hover:not(.Mui-disabled):before": {
      border: "transparent"
    },
    
    
  },
  calendarIcon: {
    cursor: "pointer",
    [theme.breakpoints.down('lg')]: {
      display: "none"
    },
  }
}));
