import React from "react";
import { Grid, Paper, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import classNames from "classnames";

// styles
import useStyle from "./styles";

export default function CustomIndicator({ indicator, hasBonus, print }) {
  const theme = useTheme();
  const classes = useStyle();

  const getColor = (value) => {
    if (value <= 40) {
      return theme.palette.error.main;
    } else if (value > 40 && value <= 80) {
      return theme.palette.warning.main;
    } else if (value > 80) {
      return theme.palette.success.main;
    } else {
      return theme.palette.primary.main;
    }
  };

  return (
    <Grid
      item
      style={{display:'flex',justifyContent: 'center'}}
      className={classNames(classes.container, { [classes.print]: print })}
    >
      <Paper
        style={{maxWidth:160,marginRight:5,marginLeft:5}}
        className={classNames(classes.card, { [classes.printCard]: print })}
      >
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <Typography
              className={classNames(classes.percentage)}
              style={{
                color: hasBonus
                  ? getColor(indicator.score)
                  : theme.palette.primary.main,
              }}
            >
              {indicator.value}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.title}>{indicator.label}</Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}
