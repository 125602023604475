import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "86%",
    borderRadius: 28,
    padding: 20,
    paddingTop: 30,
    display: "flex",
  },
  list: {
    display: "flex",
    flexDirection: "row",
    padding: 0,
    marginTop: theme.spacing(2),
  },
  listItem: {
    padding: 0,
  },
  listItemIcon: {
    fontSize: "1.5rem",
    borderRadius: "50%",
    paddingRight: theme.spacing(1),
  },
  barContainer: {
    height: 130,
    minWidth: 50,
    maxWidth: 80,
    borderRadius: "5px",
    overflow: "hidden",
  },
  textBold: {
    fontWeight: "500",
  },
  legendText: {
    height: "100%",
  },
  containerRelative: {
    position: "relative",
  },
  containerAbsolute: {
    position: "absolute",
    right: -8,
    top: -8,
  },
  containerShift: {
    position: "relative",
    width: "100%",
    height: "20px",
    marginBottom: "20px",
    textAlign: "right",
  },
}));
