import React, { useState, useEffect } from 'react';
import { Box, Grid, FormLabel, Typography, Paper } from '@mui/material';
import { useFormContext, useFormState } from 'react-hook-form';
import { useHistory } from 'react-router';

// components
import Aux from '../../hoc/auxiliar';
import { Input } from 'components/react-hook-form';
import PermissionForm from 'components/PermissionForm';
import PermissionFormDriver from 'components/PermissionForm/PermissionFormDriver';

// skeleton
import FormRoleSkeleton from '../Skeletons/FormRoleSkeleton';

import useStyles from './styles';
import 'react-datepicker/dist/react-datepicker.css';

const RoleForm = ({ formData }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [web, setWeb] = useState(null);
  const history = useHistory();

  const { control } = useFormContext();
  const { errors } = useFormState({ control });

  useEffect(() => {

    const checkSystem = () => {
      if (history.location.pathname.includes('app')) {
        setWeb(false);
      } else {
        setWeb(true);
      }
    }

    checkSystem();
  }, []);

  if (isLoading || web === null) {
    return <FormRoleSkeleton />;
  }

  return (
    <Aux>
      <Paper elevation={0} className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xl={5} lg={5} md={5} sm={6} xs={12}>
            <Box sx={{ m: 2 }}>
              <Input
                name={web ? 'name' : 'label'}
                variant='outlined'
                fullWidth
                label='Nome do perfil *'
                helperText={web ? errors.name?.message : errors.label?.message}
                error={errors[web ? 'name' : 'label'] && true}
              />
            </Box>
          </Grid>
          <Grid item xl={5} lg={5} md={5} sm={6} xs={12}>
            {web && <Box sx={{ m: 2 }}>
              <Input
                name='login_redirect'
                variant='outlined'
                fullWidth
                label='URL da página inicial'
                helperText={errors.login_redirect?.message}
                error={errors.login_redirect && true}
              />
              <Box>
                <FormLabel component="label" className={classes.legendTitle}>
                  Exemplo de URL: <b>gofurther/bonus/follow</b>
                </FormLabel>
                {" "}
                <FormLabel component="label" className={classes.legendTitle}>
                  (Deixar em branco se utilizar a url padrão gofurther/bonus/follow)
                </FormLabel>
              </Box>
            </Box>}
          </Grid>
          {web ? <PermissionForm formData={formData} /> : <PermissionFormDriver formData={formData} />}
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Box sx={{ mt: 4 }}>
            <Typography className={classes.explanation}>*Os campos marcados com o asterisco (*) são aqueles que precisam obrigatoriamente ser preenchidos.</Typography>
          </Box>
        </Grid>
      </Paper>
    </Aux>
  );
};

export default RoleForm;
