import { defaultOperation } from "../helpers/follow";
import React, { createContext, useCallback, useState, useContext } from "react";

const SelectContext = createContext();

const SelectProvider = ({ children }) => {
  const [selectedOperation, setSelectedOperation] = useState(defaultOperation);
  const [cutOffDatesOptions, setCutOffDatesOptions] = useState(null);
  const [selectedCutOffDate, setSelectedCutOffDate] = useState(null);
  const [drivers, setDrivers] = useState(null);
  const [historyGoBack, setHistoryGoBack] = useState(false);

  const onChangeCutOffDate = (cutOffDate) => {
    setSelectedCutOffDate(cutOffDate);
  };
  const onChangeOperation = (list, index) => {
    setSelectedOperation(list);
  };
  const resetContext = useCallback(() => {
    setSelectedOperation(defaultOperation);
    setCutOffDatesOptions(null);
    setDrivers(null);
    setHistoryGoBack(false);
    setSelectedCutOffDate(null);
  }, []);

  const contexts = {
    selectedCutOffDate,
    cutOffDatesOptions,
    selectedOperation,
    historyGoBack,
    drivers,
    setDrivers,
    setCutOffDatesOptions,
    setSelectedCutOffDate,
    onChangeCutOffDate,
    setSelectedOperation,
    setHistoryGoBack,
    onChangeOperation,
    resetContext,
  };
  return (
    <SelectContext.Provider value={contexts}>{children}</SelectContext.Provider>
  );
};

function useSelect() {
  const context = useContext(SelectContext);
  return context;
}

export { SelectProvider, useSelect };
