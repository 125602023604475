import React from 'react'

export const reactQuillEditorModules = {
  toolbar: [
    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    ['bold', 'italic', 'underline'],
    ['link', 'video'],
    [{ 'align': [] }],
    ['clean']
  ],
  clipboard: {
    matchVisual: false,
  }
};

export const reactQuillEditorFormats = [
  'header', 'font',
  'list', 'bullet',
  'bold', 'italic', 'underline',
  'link', 'image', 'video',
  'align'
];

export const options = {
  default: {
    label: 'Selecione o que deseja fazer',
    content: 'Selecione o que deseja fazer',
    name: 'default'
  },
  communication: {
    label: 'Comunicados',
    content: <span><strong>Comunicados</strong> (Publique textos, links, videos, PDFs, artigos, imagem)</span>,
    name: 'communication'
  },
  message: {
    label: 'Mensagem',
    content: <span><strong>Mensagem</strong> (Publique textos, links, videos, PDFs, artigos, imagem)</span>,
    name: 'message'
  },
  tips: {
    label: 'Dicas',
    content: <span><strong>Dicas</strong> (Publique textos, links, videos, PDFs, artigos, imagem)</span>,
    name: 'tips'
  },
  alert: {
    label: 'Alertas',
    content: <span><strong>Alertas</strong> (Publique textos, links, videos, PDFs, artigos, imagem)</span>,
    name: 'alert'
  },
  urgent: {
    label: 'Urgente',
    content: <span><strong>Urgente</strong> (Publique textos, links, videos, PDFs, artigos, imagem)</span>,
    name: 'urgent'
  }
}