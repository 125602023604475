import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';
import Item from 'componentsNew/atoms/item/item';
import { Button } from '@mui/material';
import IconComponent from 'components/IconComponent';
import { getVehicleStatus } from 'helpers/statusVehicle';

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return new Intl.DateTimeFormat('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  }).format(date).replace(',', ' às');
};

const VehiclePopover = ({
  vehicle,
  onClose,
  handleSelectVehicle,
  buttonClose = true,
  buttonNavigate = true
}) => {
  return (
    <div className="vehicle-popover">
      {buttonClose && <button className="popover-close-btn" onClick={onClose}>×</button>}
      <div>
        <span className='title'>
          Status diário do veículo selecionado:
        </span>
        <Item icon={'truck_filled'} label={'Placa:'} labelStrong={vehicle.plate} />
        <Item icon={'manager'} label={'Status:'} labelStrong={getVehicleStatus(vehicle.status)} />
        <Item icon={'gas'} label={'Nível do combustível:'} labelStrong={`${vehicle.graphic.fuel.percent}% (${vehicle.graphic.fuel.value.toFixed(2)}) litros`} />
        <Item icon={'speed_velocity'} label={'RPM:'} labelStrong={vehicle.graphic.rpm} />
        <Item icon={'date_calendar'} label={'Data da última comunicação:'} labelStrong={formatDate(vehicle.date)} />
        {buttonNavigate &&
          <div className='wrapper-button'>
            <Button className="button-details" onClick={() => handleSelectVehicle(vehicle)}>
              Ver mais detalhes
              <IconComponent name={'arrow_right_blue'} size={'l'} />
            </Button>
          </div>
        }
      </div>
    </div>
  );
};

VehiclePopover.propTypes = {
  vehicle: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default VehiclePopover;
