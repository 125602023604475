import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  cardTitle: {
    fontWeight: 'bold',
    padding: '10px 0 0 20px'
  },
  contentBox: {
    padding: theme.spacing(2)
  },
  cardContent: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  cardIcon: {
    height: '40px',
    boxShadow: `3px 5px 4px ${theme.palette.background.hoverMultiMenu}`,
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cardValue: {
    fontWeight: '500',
    fontSize: '19px',
    letterSpacing: '-0.8px',
    paddingLeft: '8px'
  },
  cardLabel: {
    color: theme.palette.gray.default,
    fontSize: theme.typography.h6.fontSize,
    paddingLeft: '8px'
  }
}));
