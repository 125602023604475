import React, { useState, useRef } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@mui/material/";
import { Close as CloseIcon, CalendarToday as CalendarIcon } from "@mui/icons-material";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import localizedFormat from 'dayjs/plugin/localizedFormat';

// services
import { createRecalculation } from "services/recalculation";

// helpers
import makeDefaultValues from "helpers/makeDefaultValues";

import fields from "domain/forms/formRecalculationInitialvalues";

// components
import Calendar from "components/Calendar";
import MultiSelect from "components/MultiSelect";

// store
import { useAppSelector } from "store";

// styles
import { multiSelectStyles } from "./multiSelectStyles";
import useStyles from "./styles";

dayjs.extend(localizedFormat);

const ModalRecalculation = (props) => {
  const classes = useStyles();
  const { email } = useAppSelector((state) => state.global.user);
  const { vehicles } = props;

  const defaultValues = makeDefaultValues({ fields });
  const methods = useForm({
    defaultValues,
  });

  const formatDate = 'DD/MM/YYYY HH:mm';
  const formatTemplateDB = "YYYY-MM-DDTHH:mm:ssZZ";

  const [dates, setDates] = useState({
    initialDate: dayjs().format('DD/MM/YYYY 00:00'),
    finalDate: dayjs().format('DD/MM/YYYY 23:59'),
    dbInitialDate: dayjs().format("YYYY-MM-DDT00:00:00ZZ"),
    dbFinalDate: dayjs().format("YYYY-MM-DDT23:59:59ZZ"),
  });
  const selectedDevices = useRef([]);

  const [showBox, setShowBox] = useState(true);
  const handleShowBox = () => setShowBox(!showBox);

  const handleSelectedDate = (startDate, finalDate) => {
    setDates({
      initialDate: dayjs(startDate).format(formatDate),
      finalDate: dayjs(finalDate).format(formatDate),
      dbInitialDate: dayjs(startDate).format(formatTemplateDB),
      dbFinalDate: dayjs(finalDate).format(formatTemplateDB),
    });
  };

  const handleSelectedData = (data) => {
    let errors = [];
    let selected = [];
    selectedDevices.current = [];
    data.forEach((item) => {
      if (!item?.id) {
        errors.push(item.id);
      } else {
        selected.push(item.id);
      }
    });
    if (selected.length > 0) {
      selectedDevices.current = selected;
    }
    if (errors.length > 0) {
      let errorMsg = `Os seguintes veículos não possuem unidade eletrônica cadastrada e não serão recalculados: ${errors.join(', ')}`;
      return toast.error(errorMsg);
    }
  };

  const onSubmit = async () => {
    if (selectedDevices.current.length === 0) {
      return toast.error(
        "Selecione um ou mais veículos para efetuar o recálculo.",
      );
    }
    try {
      const response = await createRecalculation(
        selectedDevices.current,
        email,
        dates.dbInitialDate,
        dates.dbFinalDate,
      );
      if (response.data?.err?.status === 401 && response.data?.err?.msg !== "") {
        return toast.error(response.data.err.msg);
      }
      toast.success("Recálculo iniciado com sucesso!");
      props.handleClose();
      setShowBox(true);
      setDates({
        initialDate: dayjs().format(formatDate),
        finalDate: dayjs().format(formatDate),
        dbInitialDate: dayjs().format(formatTemplateDB),
        dbFinalDate: dayjs().format(formatTemplateDB),
      });
      selectedDevices.current = [];
    } catch (error) {
      console.log(error);
      return toast.error(
        "Não foi possível iniciar o recálculo. Contate o suporte",
      );
    }
  };

  const BoxData = () => {
    return (
      <Box
        className={classes.boxContainer}
        flexDirection="column"
        justifyContent="space-between"
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className={classes.label}>
              Por favor, selecione o período de recálculo
            </Typography>
            <Box className={classes.calendarBox}>
              <CalendarIcon
                fontSize='medium'
                data-cy='svgButtonCalendar'
              />
              <Calendar
                selectedDates={{
                  initialDate: dates.dbInitialDate,
                  finalDate: dates.dbFinalDate,
                }}
                handleCalendar={(selectedInitialDate, selectedFinalDate) => handleSelectedDate(selectedInitialDate, selectedFinalDate)}
                maxSpanDays={31}
                showFilterButton={false}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.step}>
              Etapa 1 de 2
            </Typography>
          </Grid>
          <Grid item container justifyContent="flex-end" gap={2} xs={12}>
            <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
              <Button
                fullWidth
                onClick={props.handleClose}
                className={classes.backButton}
              >
                Voltar
              </Button>
            </Grid>
            <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
              <Button
                fullWidth
                onClick={handleShowBox}
                variant="contained"
                className={classes.btConfirm}
              >
                Avançar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const BoxRecalc = () => {
    return (
      <Box
        className={classes.boxContainer}
      >
        <Grid container spacing={2}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <MultiSelect
              isSingleMode={false}
              listData={vehicles}
              handleSelectedListData={handleSelectedData}
              customStyles={multiSelectStyles}
            />
          </Grid>
        </Grid>
        <DialogActions>
          <Grid container justifyContent="flex-end" spacing={2}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography className={classes.step}>
                Etapa 2 de 2
              </Typography>
            </Grid>
            <Grid item container justifyContent="flex-end" gap={2} xs={12}>
              <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                <Button
                  fullWidth
                  onClick={handleShowBox}
                  className={classes.backButton}
                >
                  Voltar
                </Button>
              </Grid>
              <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  type="submit"
                  className={classes.btConfirm}
                >
                  Recalcular
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </Box>
    );
  };

  return (
    <Dialog
      fullWidth
      maxWidth={"md"}
      onClose={props.handleClose}
      aria-labelledby="customized-dialog-title"
      open={props.open}
      PaperProps={{
        style: { borderRadius: 22, overflow: 'visible' }
      }}
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={props.handleClose}
        className={classes.dialogTitle}>
        <Typography className={classes.title}>
          Recalcular veículo
        </Typography>
        <Typography className={classes.subtitle}>
          Com base no período selecionado serão recalculados os dados do(s) veículo(s) até o período atual.
        </Typography>
        {!showBox && <Typography className={classes.subtitle}>
          ATENÇÃO: Todos os motoristas que se vincularam nesse veículo sofrerão atualizações em sua bonificação.
        </Typography>}
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={props.handleClose}
          size="large">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)} className={classes.form}>
            {showBox ? <BoxData /> : <BoxRecalc />}
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
};

export default ModalRecalculation;
