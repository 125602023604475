import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogContent, Grid, IconButton, Paper, Typography } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import Select from "react-select";
import makeAnimated from "react-select/animated";

// helpers
import { generateRange } from "helpers/operations";
import { getCurrentMonth, orderByIndex } from "helpers/functions";
import Aux from "hoc/auxiliar";

// hooks
import { useWindowSize } from "hooks/useWindowsSize";

// components
import Infobox from "components/Infobox";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";
import TableResponsive from "components/TableResponsive";

// services
import { getCustomersById } from "services/customers";
import { getDrivers } from "services/driver";
import { getRankingByCustomer, getRankingByDriverCustomer } from "services/ranking";

// context
import { clearPagination, setPaginationReducer } from "store/features/rankingSlice";
import { useAppDispatch, useAppSelector, store } from "store";

// styles
import colors from "themes/gobrax";
import { colourStyles } from "./colourStyles";
import makeStyles from "./styles";

import { makeColumns } from "./tableColumns";

export default function Ranking() {

  const animatedComponents = makeAnimated();
  const classes = makeStyles();
  const size = useWindowSize();
  const columns = makeColumns(size);
  const dispatch = useAppDispatch();

  const today = new Date(new Date().setHours(0, 0, 0, 0));
  const dateFormatTemplate = "YYYY-MM-DDTHH:mm:ssZZ";
  const firstOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const maxDate = new Date();
  maxDate.setHours(23, 59, 0, 0);

  const { currentCustomer } = useAppSelector((state) => state.global.user);
  const { pagination } = useAppSelector((state) => state.ranking);
  
  const [ customersDates, setCustomersDates ] = useState(null);
  const [ cutOffDatesOptions, setCutOffDatesOptions ] = useState(null);
  const [ selectedCutOffDate, setSelectedCutOffDate ] = useState(null);
  const [ drivers, setDrivers ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ modalOpen, setModalOpen ] = useState(false);
  const [ rankingData, setRankingData ] = useState(null);
  const [ selectedDates, setSelectedDates ] = useState({
    initialDate: dayjs(firstOfMonth).format(dateFormatTemplate),
    finalDate: dayjs().format("YYYY-MM-DDT23:59:59ZZ"),
  });
  const [ selectedDriver, setSelectedDriver ] = useState(null);
  const [ selectedYear, setSelectedYear ] = useState({ value: today.getFullYear(), label: today.getFullYear() });

  const yearOptions = [];
  const currentYear = today.getFullYear();
  const lastYearToShow = today.getMonth() > 10 ? currentYear + 1 : currentYear;

  for (let i = 2020; i <= lastYearToShow; i++) {
    yearOptions.push({ value: i, label: i });
  }

  const fetchData = async () => {
    setLoading(true);
    try {

      const response = await getDrivers(currentCustomer);
      if (response.status !== 200) {
        throw new Error("Error fetching drivers");
      }

      const { drivers } = response.data;
      if(!!drivers?.length) {
        setDrivers(orderByIndex(drivers, "name"));
      }

      const responseCustomers = await getCustomersById(currentCustomer);
      if (responseCustomers.status !== 200) {
        throw new Error("Error fetching customers");
      }

      const customersOptions = responseCustomers.data.customers[0];
      setCustomersDates(customersOptions);
      handleCutOffDates(customersOptions);

    } catch(error) {
      setDrivers([]);
      setCustomersDates(null);
      toast.error(
        "Erro ao carregar dados. Entre em contato com o suporte.",
      );
    } finally {
      setLoading(false);
    }
  }

  const fetchSearchData = async (selectedDriver, initialDate, finalDate) => {
    const startDate = dayjs(initialDate).format("YYYY-MM-DD");
    const endDate = dayjs(finalDate).format("YYYY-MM-DD");
    
    setLoading(true);
    dispatch(setPaginationReducer({ isLoading: true }));

    try {
      let response;
      const updatedPagination = store.getState().ranking.pagination;
      if (!selectedDriver) {
        response = await getRankingByCustomer(
          currentCustomer,
          startDate,
          endDate,
          updatedPagination.rowsPerPage,
          updatedPagination.page,
        );
        if (response.status !== 200) {
          throw new Error();
        }
        setRankingData(response?.data?.data || null);
      } else {
        response = await getRankingByDriverCustomer(
          currentCustomer,
          selectedDriver.value,
          startDate,
          endDate
        );
        if (response.status !== 200) {
          throw new Error();
        }
        setRankingData(response?.data?.data?.rankings || null);
      }

      dispatch(
        setPaginationReducer({
          isLoading: false,
          count: response.data?.total || 0
        })
      );

      if (!response.data.data) {
        toast.warning(
          "Sem dados de ranking para exibir no momento.",
        );
      }

    } catch (error) {
      toast.error(
        "Erro ao carregar ranking. Entre em contato com o suporte.",
      );
    } finally {
      setLoading(false);
    }
  };

  const handleCutOffDates = (customersOptions) => {
    const startingDay = customersOptions.starting_day;
    const finishingDay = customersOptions.finishing_day;
    const period = customersOptions.period;

    if (startingDay && finishingDay && !isNaN(period)) {
      const cutOffDate = generateRange(startingDay, finishingDay, period, selectedYear.value);
      const selectedMonth = getCurrentMonth(finishingDay);

      setCutOffDatesOptions(cutOffDate);
      setSelectedCutOffDate(cutOffDate[selectedMonth]);

      const initialDate = dayjs(cutOffDate[selectedMonth].startDate).format(dateFormatTemplate);
      const finalDate = dayjs(cutOffDate[selectedMonth].finishDate).format("YYYY-MM-DDT23:59:59ZZ");

      handleSelectDate(initialDate, finalDate);
    } else {
      setCutOffDatesOptions(null);
      setSelectedCutOffDate(null);
    }
  };

  const handleSelectDriver = async (driver) => {
    try {
      setLoading(true);
      setSelectedDriver(driver);
      fetchSearchData(
        driver,
        selectedCutOffDate.startDate,
        selectedCutOffDate.finishDate
      );
    } catch(err) {
      toast.error(
        "Erro ao carregar ranking. Entre em contato com o suporte.",
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSelectDate = async (initialDate, finalDate) => {
    setSelectedDates({
      initialDate: initialDate,
      finalDate: finalDate,
    });

    if (!!selectedDriver?.value) {
      fetchSearchData(
        selectedDriver,
        initialDate,
        finalDate,
      );
    }
  };

  const handleClose = () => setModalOpen(false);

  const handleTablePagination = (action, tableState) => {
    switch (action) {
      case "changePage":
        dispatch(setPaginationReducer({ isLoading: true, page: tableState.page + 1 }));
        fetchSearchData(selectedDriver, selectedDates.initialDate, selectedDates.finalDate);
        break;
      case "changeRowsPerPage":
        dispatch(setPaginationReducer({ isLoading: true, page: 1, rowsPerPage: tableState.rowsPerPage }));
        fetchSearchData(selectedDriver, selectedDates.initialDate, selectedDates.finalDate);
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    dispatch(clearPagination());
  }, [dispatch]);

  useEffect(() => {
    if (currentCustomer && hasPermission({ scopes: ["can_view_ranking"] })) {
      fetchData();
    }
  }, [currentCustomer]);

  useEffect(() => {
    selectedCutOffDate && fetchSearchData(selectedDriver, selectedCutOffDate.startDate, selectedCutOffDate.finishDate);
  }, [selectedCutOffDate]);
  
  useEffect(() => {
    if (selectedYear) {
      customersDates && handleCutOffDates(customersDates);
    }
  }, [selectedYear]);

  return (
    <Aux>
      <PermissionsGate scopes={["can_view_ranking"]}>
        <Dialog
          open={modalOpen}
          onClose={handleClose}
          aria-labelledby="divModalRanking"
          data-cy="divModalRanking"
          PaperProps={{
            style: { 
              borderRadius: 24,
              width: 492,
            }
          }}
        >
          <IconButton
            aria-label="close"
            className={classes.iconButton}
            onClick={handleClose}
            size="large">
            <CloseIcon />
          </IconButton>
          <DialogContent style={{ padding: 32 }}>
            <Typography variant="h4" className={classes.titleModal}>
              Critérios de avaliação
            </Typography>
            <Typography className={classes.paragraphModal}>
              Para a montagem do ranking, são avaliados os seguintes itens como critérios de desempate:
            </Typography>
            <Typography className={classes.listModalItem}>
              1. Nota da sua performance
            </Typography>
            <Typography className={classes.listModalItem}>
              2. Km percorrida.
            </Typography>
            <Typography className={classes.listModalItem}>
              3. Nota do indicador Inicio da faixa verde
            </Typography>
            <Typography className={classes.listModalItem}>
              4. Nota do indicador motor ligado parado
            </Typography>
            <Typography className={classes.listModalItem}>
              5. Nota do indicador aproveitamento de embalo
            </Typography>
            <Typography className={classes.listModalItem}>
              6. Nota no indicador acelerando acima do verde
            </Typography>
            <Typography className={classes.listModalItem}>
              7. Nota no indicador excesso de velocidade
            </Typography>
            <Typography className={classes.listModalItem}>
              8. Nota do indicador pressão do acelerador
            </Typography>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={handleClose}
              className={classes.btRankingModal}
              data-cy="buttonRankingModal"
            >
              Entendi
            </Button>
          </DialogContent>
        </Dialog>
        <Grid container>
          <Grid item xl={5} lg={5} md={5} sm={12} xs={12} className={classes.titleContainer}>
            <Typography variant="h2" className={classes.titlePage}>
              Ranking
            </Typography>
            <Typography>
              Veja a pontuação de todos os seus motoristas e o ranking dos
              melhores de acordo com a nota e km rodado
            </Typography>
          </Grid>
          <Grid item xl={7} lg={7} md={7} sm={12} xs={12} display={"flex"} justifyContent={"flex-end"}>
            <Button title={"Critérios de avaliação"} onClick={() => setModalOpen(true)} className={classes.helpButton} data-cy="buttonHelp">
              Critérios de avaliação
            </Button>
          </Grid>
          <Grid item container xs={12} style={{ marginTop: 20 }}>
            <Grid item xs={size.grid4} style={size.mobile ? {} : { paddingLeft: 5, paddingRight: 5 }} data-cy="divSelectDriver">
              <Paper elevation={0} className={classes.multiselect}>
                <Select
                  data-cy="selectDriver"
                  placeholder="Selecione um motorista"
                  options={drivers ? drivers.map((item) => ({ value: item.id, label: item.name })) : []}
                  onChange={handleSelectDriver}
                  noOptionsMessage={() => drivers ? "Nenhum resultado encontrado" : "Nenhum motorista cadastrado"}
                  isClearable
                  styles={{
                    ...colourStyles,
                    control: base => ({
                      ...base,
                      borderRadius: 20,
                      border: "none",
                      padding: "5px 20px",
                      "&:hover": {
                        border: "none"
                      }
                    }),
                    menuPortal: base => ({
                      ...base,
                      zIndex: 9999
                    })
                  }}
                />
              </Paper>
            </Grid>
            <Grid item xs={size.grid4} style={size.mobile ? {} : { paddingLeft: 5, paddingRight: 5 }} data-cy="divSelectYear">
              <Paper elevation={0} className={classes.paper}>
                <Select
                  data-cy="selectYear"
                  placeholder="Ano"
                  options={yearOptions.reverse()}
                  styles={colourStyles}
                  onChange={(item) => setSelectedYear(item)}
                  value={selectedYear}
                  defaultValue={selectedYear}
                  components={animatedComponents}
                />
              </Paper>
            </Grid>
            {!cutOffDatesOptions ? (
              <Grid item xs={size.grid4} style={size.mobile ? {} : { paddingLeft: 5, paddingRight: 5 }} data-cy="divSelectCutOfDate">
                <Paper elevation={0} className={classes.paper}>
                  <Typography>Não foram encontradas data de corte.</Typography>
                </Paper>
              </Grid>
            ) : (
              <Grid item xs={size.grid4} style={size.mobile ? {} : { paddingLeft: 5, paddingRight: 5 }}>
                <Paper elevation={0} className={classes.paper} data-cy="divCutOffDateSelect">
                  <Select
                    data-cy="cutOffDateSelect"
                    placeholder="Data de corte"
                    options={cutOffDatesOptions}
                    styles={colourStyles}
                    components={animatedComponents}
                    onChange={(date) => setSelectedCutOffDate(date)}
                    value={selectedCutOffDate}
                  />
                </Paper>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {rankingData ? 
            <TableResponsive
              options={{ 
                rowsPerPage: pagination.rowsPerPage,
                serverSide: true,
                count: pagination.count,
                onTableChange: handleTablePagination,
                isLoading: pagination.isLoading,
                page: pagination.page - 1,
                search: false,
                filter: false,
                setRowProps: (row, dataIndex, rowIndex) => {
                  return (rankingData[rowIndex]?.position_today <= 3) ? { 
                    style: {
                      borderBottom: `2px solid ${colors.palette.success.main}`
                    }
                  } : "";
                },
              }}
              columns={columns}
              data={rankingData}
              loading={loading}
              tableName="list-ranking"
            /> :
          <Infobox />}
        </Grid>
      </PermissionsGate>
    </Aux>
  );
}
