import { useMemo } from "react";
import { Box, Checkbox, FormHelperText, Grid, Input, Paper, Typography, TextField, Autocomplete } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import DateRangePicker from "react-bootstrap-daterangepicker";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { daysOfWeek, monthNames } from "constants/calendar";

// components
import Aux from "hoc/auxiliar";


// styles
import useStyles from "./styles";

dayjs.extend(customParseFormat);

const StyledAutocomplete = styled(Autocomplete)(() => ({
  maxWidth: '540px',
  '.MuiInputBase-root': {
    borderRadius: 20,
  }
}));

const StyledCheckbox = styled(Checkbox)(() => ({
  color: '#F7DF36',
  '&.Mui-checked': {
    color: '#F7DF36'
  }
}));

const AssignForm = ({ drivers = [] }) => {
  const classes = useStyles();

  const formatDate = "DD/MM/YYYY HH:mm";

  const { control, clearErrors } = useFormContext();

  const driversOptions = useMemo(() =>
    drivers?.map(
      (driver) => ({
        value: driver.id,
        label: driver.name,
        vehicleId: driver.customerId
      })
    ) ?? [],
    [drivers]
  );


  const watchStartDate = useWatch({ control, name: "startDate" });
  const watchIsChecked = useWatch({ control, name: "isChecked", defaultValue: true });



  return (
    <Aux>
      <Paper elevation={2} className={classes.container}>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.title}>Motorista</Typography>
            <Grid container>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Box sx={{ m: 2 }}>
                  <Controller
                    control={control}
                    name="drivers"
                    render={({ field: { value = '', onChange, ...rest }, fieldState: { error } }) => (
                      <>
                        <StyledAutocomplete
                          {...rest}
                          options={driversOptions}
                          renderInput={(params) => <TextField {...params} label="Selecione um motorista *" />}
                          value={value}
                          onChange={(_, value) => onChange(value)}
                          isOptionEqualToValue={(option, value) => value ? option.label === value?.label || value === '' : false}
                          getOptionLabel={(option) => option?.label ?? ''}
                          renderOption={({ key, ...props }, option) => (
                            <Box key={option.value} component="li" {...props}>
                              <Typography>{option.label}</Typography>
                            </Box>
                          )}
                          disablePortal
                          noOptionsText="Nenhum motorista cadastrado"
                        />
                        <FormHelperText className={classes.formHelperText} error={true}>
                          {error?.message}
                        </FormHelperText>
                      </>
                    )}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.title}>Dia do vínculo</Typography>
            <Grid container>
              <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                <Box sx={{ m: 2 }}>
                  <Controller
                    key={watchStartDate}
                    name="startDate"
                    control={control}
                    render={({ field }) => (
                      <DateRangePicker
                        initialSettings={{
                          startDate: field.value,
                          singleDatePicker: true,
                          timePicker24Hour: true,
                          timePicker: true,
                          maxDate: dayjs().toDate(),
                          locale: {
                            format: formatDate,
                            daysOfWeek,
                            monthNames,
                            applyLabel: "Aplicar",
                            cancelLabel: "Cancelar",
                          },
                          opens: "center"
                        }}
                        autoApply={true}
                        onApply={(_, picker) => {
                          const selectedDate = picker.startDate._d;

                          field.onChange(selectedDate);

                          clearErrors('endDate');
                        }}
                      >
                        <Input
                          data-cy="inputCalendarStart"
                          className={classes.inputCalendar}
                          value={dayjs(field.value).format(formatDate)}
                        />
                      </DateRangePicker>
                    )}
                  />
                </Box>
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  className={classes.containerCheckbox}
                >
                  <Controller
                    name="isChecked"
                    type="checkbox"
                    control={control}
                    render={({ field }) => (
                      <StyledCheckbox
                        tabIndex={-1}
                        onChange={(event) => {
                          const checked = event.target.checked;

                          field.onChange(checked);
                        }}
                        checked={field.value}
                      />
                    )}
                  />
                  <Typography variant="h5" className={classes.textDriver}>
                    O motorista vai continuar vinculado
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                <Box sx={{ m: 2 }}>
                  <Controller
                    key={watchStartDate}
                    name="endDate"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <>
                        <DateRangePicker
                          initialSettings={{
                            startDate: field.value,
                            singleDatePicker: true,
                            timePicker24Hour: true,
                            timePicker: true,
                            minDate: watchStartDate,
                            maxDate: dayjs().toDate(),
                            locale: {
                              format: formatDate,
                              daysOfWeek,
                              monthNames,
                              applyLabel: "Aplicar",
                              cancelLabel: "Cancelar",
                            },
                            opens: "center"
                          }}
                          autoApply={true}
                          onApply={(_, picker) => {
                            const selectedDate = picker.startDate._d;

                            field.onChange(selectedDate);
                          }}
                        >
                          <Input
                            data-cy="inputCalendarEnd"
                            className={classes.inputCalendar}
                            value={!watchIsChecked ? dayjs(field.value).format(formatDate) : ''}
                            disabled={watchIsChecked}
                          />
                        </DateRangePicker>
                        <FormHelperText className={classes.formHelperText} error={true}>
                          {error?.message}
                        </FormHelperText>
                      </>
                    )}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Box sx={{ mt: 4 }}>
              <Typography className={classes.explanation}>
                **Ao desmarcar a opção “O motorista vai continuar vinculado”, é possível definir um período fixo em que o motorista selecionado dirigiu.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Aux>
  );
};

export default AssignForm;
