import React from 'react';
import { Grid, Paper, Box, FormHelperText } from "@mui/material";
import Aux from "hoc/auxiliar";
import useStyles from "./styles";
import TransferList from 'components/TransferList/';


function LinkVehicleGroupComponent({availableVehicles, handleSelectedVehicles, unavailableVehiclesByOperation, error}) {
  const classes = useStyles()
  return (
    <Aux>
      <Paper elevation={2} className={classes.container}>
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Box sx={{ m: 2 }}>
                      <TransferList
                        handleSelectedItems={handleSelectedVehicles}
                        availableVehicles={availableVehicles}
                        allUnavailableVehicles={unavailableVehiclesByOperation}
                      />
                  </Box>
                  {error && (
                  <FormHelperText className={classes.textError} error={true}>
                    Deve selecionar no mínimo um veículo.
                  </FormHelperText>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Aux>
  )
}

export default LinkVehicleGroupComponent;