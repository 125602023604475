import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Item from "componentsNew/atoms/item/item";
import { convertDateBr } from "helpers/dates";
import { getVehicleStatus } from "helpers/statusVehicle";
import { handleAmplitudeEvent } from "services/amplitude";
import { getVehicle } from "services/vehicle";
import { getDriverDetail } from "services/driver";
import { useHistory } from "react-router-dom";
import { useAppDispatch } from "store";
import { setStepDeduct } from "store/features/driversMonitoringSlice";
import { setStepDeductBonus } from "store/features/bonusFollowSlice";
import { useWindowSize } from "hooks/useWindowsSize";

import useStyles from "./styles";

function VehiclePerformanceDetailComponent({ vehicleProps }) {
  
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const history = useHistory();
  const size = useWindowSize();

  const [ driverName, setDriverName ] = useState(null);
  const [ vehicleIdentification, setVehicleIdentification ] = useState(null);
  const { detail, finishDate, screen, vehicleId } = vehicleProps;
  const lastCommunication = finishDate ? convertDateBr(finishDate) : "";

  const fetchVehicleDetails = async () => {
    try {
      const response = await getVehicle(vehicleId);
      const vehiclePlate = response.data?.identification ? `${detail?.plate} / ${response.data?.identification}` : detail?.plate;
      setVehicleIdentification(vehiclePlate || "");
      if (response.data?.currentDriver?.driverId) {
        const responseDriver = await getDriverDetail(response.data?.currentDriver?.driverId);
        setDriverName(responseDriver.data?.name || "");
      }
    } catch (error) {
      console.log("error", error)
    }
  };

  useEffect(() => {
    fetchVehicleDetails();
  }, [])
  
  return (
    <Box>
      <Typography className={classes.title} data-cy="pTitulo" textAlign={{ xs: "center", md: "left" }}>
        Performance do veículo selecionado
      </Typography>
      <Paper className={classes.paper} data-cy="divVehicleDetails">
        <Grid className={classes.wrapperContent} container item xl={12} lg={12} md={12} sm={12} xs={12}>
          {vehicleIdentification && <Grid className={classes.flex} data-cy="divVehicleDetailsPlate">
            <Item icon={"truck_filled"} label={"Placa:"} labelStrong={vehicleIdentification} />
          </Grid>}
          <Grid className={classes.flex} data-cy="divVehicleDetailsStatus">
            <Item icon={"manager"} label={"Status:"} labelStrong={getVehicleStatus(detail.status)} />
          </Grid>
          {driverName && <Grid className={classes.flex} data-cy="divVehicleDetailsStatus">
            <Item icon={"driver"} label={"Motorista:"} labelStrong={driverName} />
          </Grid>}
          <Grid className={classes.flex} data-cy="divVehicleDetailsLastCommunication">
            <Item icon={"date_calendar"} label={"Data da última comunicação:"} labelStrong={lastCommunication} />
          </Grid>
          {size.mobile ? null :
            <Grid className={classes.flex}>
              <Button
                onClick={() => {
                  handleAmplitudeEvent("Backward Button Clicked");
                  
                  if (screen !== "bonus") {
                    dispatch(setStepDeduct())
                  } else {
                    dispatch(setStepDeductBonus())
                  }
                  history.goBack();

                }}
                variant="contained"
                className={classes.backButton}
                data-cy="buttonVoltar"
              >
                <ArrowBackIcon fontSize="small" />{" "}Voltar
              </Button>
            </Grid>
          }
        </Grid>
      </Paper>
    </Box>
  )
}

export default VehiclePerformanceDetailComponent;
