import React, { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";

// components
import Aux from "hoc/auxiliar";
import BackButton from "components/BackButton";
import VehicleForm from "components/VehicleForm";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";

// helpers
import makeDefaultValues from "helpers/makeDefaultValues";

// domain
import fields from "domain/forms/formVehicleInitialValues";
import schemaValidation from "domain/validation/yup/formVehicleValidation";

// services
import {
  getVehicle,
  linkVehicleOperation,
  updateVehicle,
} from "services/vehicle";

// data
import VehicleModel from "models/Vehicle";

import useStyles from "./styles";
import "react-datepicker/dist/react-datepicker.css";
import { odomTypeOptions } from "domain/selectOptions";

const EditVehicle = ({ history }) => {
  const classes = useStyles();
  const { vehicleId } = useParams();

  const defaultValues = { ...makeDefaultValues({ fields }), hasEcoRoll: false, hasDafEcoRoll: false };
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schemaValidation),
  });

  const onSubmit = async (data) => {
    const vehicleModel = new VehicleModel(
      data.customer,
      data.operation,
      data.identification,
      data.plate,
      data.fueltype || null,
      data.wheeldriver || null,
      data.odomType,
      data.initialKm || 0,
      data.maxFuelCapacity,
      data.maxAcceleration,
      data.chassi || null,
      data.distanceCalcMethod,
      data.fuelCalcMethod,
      data.accelerationIdentificationMethod,
      data.hasEcoRoll,
      data.hasDafEcoRoll,
      data.maxSpeedAllowed,
      data.maxSpeedAllowedWithRain,
      data.leveragePeak,
      data.brand,
      data.model,
      data.observation,
      data.motor,
      data.truckModel,
      Number(data.fabricationYear)
    );

    try {
      const response = await updateVehicle(vehicleId, vehicleModel);
      if (response.status === 200 && !response?.data?.err) {
        toast.success("Veículo editado com sucesso!");
        if (data.operation?.value) {
          const linkResponse = await linkVehicleOperation(data.operation.value, {
            vehicleId: parseInt(vehicleId),
          });
          if (linkResponse.status !== 200) {
            toast.error(
              "Erro ao editar vínculo de Veículo com Operação. Verifique com o suporte",
            );
          }
        }
        history.push("/gofurther/vehicle/list-vehicles");
      } else {
        throw new Error(response?.data?.err);
      }
    } catch (error) {
      toast.error("Erro ao editar Veículo. Verifique com o suporte");
    }
  };

  const fetchVehicleDetail = useCallback(async () => {
    try {
      const response = await getVehicle(vehicleId);

      const vehicleDetails = response.data;

      const defaultVehicleDetails = [
        { key: 'isEdit', value: 1 },
        { key: 'customerOption', value: vehicleDetails.customerId },
        { key: 'operationOption', value: vehicleDetails.operationId },
        { key: 'identification', value: vehicleDetails.identification },
        { key: 'plate', value: vehicleDetails.plate },
        { key: 'fueltypeOption', value: vehicleDetails.fuelTypeId },
        { key: 'wheeldriverOption', value: vehicleDetails.wheelDriveTypeId },
        { key: 'odomType', value: odomTypeOptions.find((odomTypeOption) => odomTypeOption.value === vehicleDetails.odomType) },
        { key: 'initialKm', value: vehicleDetails.initialKM?.toString() },
        { key: 'maxFuelCapacity', value: vehicleDetails.maxFuelCapacity },
        { key: 'maxAcceleration', value: vehicleDetails.maxAcceleration.toString() },
        { key: 'chassi', value: vehicleDetails.chassi },
        { key: 'motor', value: vehicleDetails.motor },
        { key: 'fabricationYear', value: vehicleDetails.fabricationYear },
        { key: 'truckModel', value: vehicleDetails.truckModel },
        { key: 'distanceCalcMethodOption', value: vehicleDetails.profile.distanceCalcMethod },
        { key: 'fuelCalcMethodOption', value: vehicleDetails.profile.fuelCalcMethod },
        { key: 'accelerationIdentificationMethodOption', value: vehicleDetails.profile.accelerationIdentificationMethod },
        { key: 'hasEcoRoll', value: vehicleDetails.profile.hasEcoRoll },
        { key: 'hasDafEcoRoll', value: vehicleDetails.profile.hasDafEcoRoll },
        { key: 'maxSpeedAllowed', value: vehicleDetails.maxSpeedAllowed.toString() },
        { key: 'maxSpeedAllowedWithRain', value: vehicleDetails.maxSpeedAllowedWithRain.toString() },
        { key: 'leveragePeak', value: vehicleDetails.leveragePeak.toString() },
        { key: 'brandOption', value: vehicleDetails.brandId },
        { key: 'modelOption', value: vehicleDetails.modelId },
        { key: 'observation', value: vehicleDetails.observation },
      ];

      defaultVehicleDetails.forEach(({ key, value }) => {
        if (!!value) {
          methods.setValue(key, value);
        }
      });

    } catch (error) {
      toast.error("Erro ao buscar dados do veículo. Verifique com o suporte");
    }
  }, [methods, vehicleId]);

  useEffect(() => {
    if (hasPermission({ scopes: ['can_edit_vehicles'] })) {
      fetchVehicleDetail();
    }
  }, [fetchVehicleDetail]);

  if (!defaultValues) {
    return "...carregando";
  }

  return (
    <Aux>
      <PermissionsGate scopes={['can_edit_vehicles']}>
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box>
              <Grid item xl={12} xs={12}>
                <FormProvider {...methods}>
                  <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Typography variant="h1" className={classes.title}>Edição de veículo</Typography>
                    <VehicleForm />
                    <Grid justifyContent="flex-end" container spacing={2} mt={2}>
                      <Grid container justifyContent="flex-end" spacing={2}>
                        <Grid item container justifyContent="flex-end" xl={12} lg={12} md={12} sm={12} xs={12}>
                          <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                            <Box sx={{ m: 2 }}>
                              <BackButton className={classes.backButton} />
                            </Box>
                          </Grid>
                          <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                            <Box sx={{ m: 2 }}>
                              <Button
                                fullWidth
                                variant="contained"
                                type="submit"
                                className={classes.btConfirm}
                              >
                                Atualizar
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                </FormProvider>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </PermissionsGate>
    </Aux>
  );
};

export default EditVehicle;
