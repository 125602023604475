import React, { useCallback, useEffect, useState } from "react";
import { Grid, Typography, Paper, Box, Button } from "@mui/material";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useHistory, useLocation } from "react-router-dom";
import dayjs from "dayjs";
import { toast } from "react-toastify";

// assets
import notFound from "assets/lottie/sad.json";

// store
import { useAppDispatch, useAppSelector, store } from "store";
import { saveCustomerProfile } from "store/features/customerProfileSlice";
import { useSelectDrivers } from "context/useSelectDrivers";
import {
  clearFilters,
  setFilters,
  setPerformancesReducer,
  setPaginationReducer,
} from "store/features/driversMonitoringSlice";

// helpers
import { defaultOperation } from "helpers/follow";
import {
  formatVehicleGroups,
  generateRange,
  getYearsOptions,
} from "helpers/operations";
import { getCurrentMonth, valueToStringFloat } from "helpers/functions"

// services
import { getDrivers, getDriversByOperation } from "services/driver";
import { getDriversMonitoring } from "services/drivers";
import { getOperations } from "services/operations";
import { getCustomersById } from "services/customers";
import { handleAmplitudeEvent } from "services/amplitude";

// components
import Aux from "hoc/auxiliar";
import NotFoundWithLottie from "components/NotFoundWithLottie";
import DriversMonitoringSkeleton from "components/Skeletons/DriversMonitoringSkeleton";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";
import Infobox from "components/Infobox";
import TableResponsive from "components/TableResponsive";
import Table from "components/Table";
import makeColumns from "./tableColumns";

// hooks
import { useWindowSize } from "../../../hooks/useWindowsSize";

// styles
import useStyles from "./styles";
import { colourStyles } from "./colourStyles";

export default function DriversMonitoring() {
  const { currentCustomer } = useAppSelector((state) => state.global.user);
  const dispatch = useAppDispatch();
  const { profile } = useAppSelector((state) => state.customerProfile);
  const { performances, fields, filters, pagination } = useAppSelector(
    (state) => state.driversMonitoring,
  );

  const { state } = useLocation();
  const [isPenalizer, setIsPenalizer] = useState({
    engineBrakeScore: false,
    throttlePressureScore: false
  });
  const history = useHistory();
  const classes = useStyles();
  const animatedComponents = makeAnimated();
  const yearsOptions = getYearsOptions();
  const dateFormatTemplate = "YYYY-MM-DDTHH:mm:ssZZ";

  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [allDrivers, setAllDrivers] = useState(null);
  const [operationsOptions, setOperationsOptions] = useState([]);

  const { cutOffDatesOptions, setCutOffDatesOptions } = useSelectDrivers();

  const [selectedRowData, setSelectedRowData] = useState();
  const [id, setId] = useState();
  const size = useWindowSize();

  const translateForAmplitudeEvent = (name) => {
    if (name === "cutoffDate") {
      return "Periodo"
    }
    if (name === "year") {
      return "Ano"
    }
    if (name === "operation") {
      return "Frota"
    }
  }

  const handleSelectRowMobile = (data, data2) => {
    setId(data2.dataIndex);
    if (!hasPermission({ scopes: ["can_view_drivers_follow_details"] })) {
      return false;
    }
    const driverId = data[Object.keys(data).length - 1];
    const driverDetails = performances.find((item) => item.id === driverId);
    const driverName = driverDetails.driverName;
    const vehiclesMonitoring = driverDetails?.vehiclesMonitoring;
    if (!vehiclesMonitoring || vehiclesMonitoring.lenght === 0) {
      toast.error("Motorista sem viagens registradas.");
      return;
    }
    const dataTable = vehiclesMonitoring.map((trip) => ({
      ...trip,
      id: trip.vehicleId,
    }));
    setSelectedRowData({
      data: dataTable,
      name: driverName,
      driverId: driverId,
    });
    handleAmplitudeEvent('Driver Selected', { driver_id: driverId, driver_name: driverName });
  };

  const handleSelectRow = async (data) => {
    if (!hasPermission({ scopes: ['can_view_drivers_follow_details'] })) {
      return false
    }
    const driverId = data[Object.keys(data).length - 1];
    const driverDetails = performances.find((item) => item.id === driverId);
    const driverName = await driverDetails.driverName;
    const vehiclesMonitoring = await driverDetails?.vehiclesMonitoring;
    if (!vehiclesMonitoring || vehiclesMonitoring.lenght === 0) {
      toast.error("Motorista sem viagens registradas.");
      return;
    }
    const dataTable = await vehiclesMonitoring.map((trip) => ({
      ...trip,
      id: trip.vehicleId,
    }))
    setSelectedRowData({
      data: dataTable,
      name: driverName,
      driverId: driverId,
    })
    handleClickDetail({
      data: dataTable,
      name: driverName,
      driverId: driverId,
    })
    handleAmplitudeEvent('Driver Selected', { driver_id: driverId, driver_name: driverName });
  }

  const handleClickDetail = (data) => {
    history.push({
      pathname: "/gofurther/driversMonitoring/details",
      state: data,
    });
  };

  const handleCutOffDates = useCallback(
    (customerOptions, year, isInitialRender = true) => {
      const { starting_day, period, finishing_day } = customerOptions;
      const currentYear = year.value;
      if (starting_day && finishing_day && !isNaN(period) && currentYear) {
        const cutOffDate = generateRange(
          starting_day,
          finishing_day,
          period,
          currentYear,
        );
        setCutOffDatesOptions(cutOffDate);
        if (fields.step === 0 || fields.step === null && filters.cutoffDate !== null) {
          return;
        } else {
          const selectedMonth = getCurrentMonth(finishing_day);
          dispatch(
            setFilters({
              selectedOption: isInitialRender
                ? cutOffDate[selectedMonth]
                : null,
              name: "cutoffDate",
            }),
          );
        }
      } else {
        setCutOffDatesOptions(null);
        dispatch(setFilters({ selectedOption: null, name: "cutoffDate" }));
      }
    }, []);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const driversResponse = await getDrivers(currentCustomer);
      const operationsResponse = await getOperations(currentCustomer);
      const customersResponse = await getCustomersById(currentCustomer);

      if (driversResponse.status !== 200) {
        throw new Error("Error fetching drivers");
      }

      if (operationsResponse.status !== 200) {
        throw new Error("Error fetching operations");
      }

      if (customersResponse.status !== 200) {
        throw new Error("Error fetching customers");
      }

      setAllDrivers(driversResponse.data.drivers);
      const operationData = operationsResponse?.data?.data?.operation;
      if (operationData) {
        let flatArrayAllVehicles = formatVehicleGroups(operationData).flatMap(
          (item) => item.vehicles,
        );
        flatArrayAllVehicles = flatArrayAllVehicles.filter(item => !!item) // removing undefined
        const newOperation = {
          ...defaultOperation,
          vehicles: flatArrayAllVehicles,
        };
        const newArrayGroups = [
          newOperation,
          ...formatVehicleGroups(operationData),
        ];
        setOperationsOptions(newArrayGroups);
      }
      const customersOptions = customersResponse.data.customers[0];
      dispatch(saveCustomerProfile(customersOptions));
      handleCutOffDates(customersOptions, filters.year);
    } catch (err) {
      toast.error("Erro ao buscar dados. Contate o suporte.");
    } finally {
      setLoading(false);
    }
  }, [currentCustomer]);

  const handleDataByOperation = async (operation, cutoffDate) => {
    setLoading(true);
    try {
      const { id: operationId, value } = operation;
      const { startDate, finishDate } = JSON.parse(cutoffDate);
      const formattedStartDate = dayjs(startDate).format(dateFormatTemplate);
      const formattedFinishDate = dayjs(finishDate).format(dateFormatTemplate);

      let drivers = [];

      if (value === 0) {
        drivers = allDrivers;
      } else {
        const response = await getDriversByOperation(
          operationId,
          formattedStartDate,
          formattedFinishDate,
        );

        if (response.status === 200 || response.status === 201) {
          drivers = response.data.drivers || [];
        } else {
          throw new Error("Error fetching drivers");
        }
      }

      const listVehicleByOperation = operation.vehicles?.map(
        (elm) => elm.vehicleId,
      );

      const modelFormattedCutoffDates = {
        startDate: formattedStartDate,
        finishDate: formattedFinishDate,
      };

      fetchDriversMonitoring(
        drivers,
        listVehicleByOperation,
        modelFormattedCutoffDates,
      );

      handleAmplitudeEvent('Drivers List Loaded', { total_drivers: drivers.length })
    } catch (error) {
      toast.error(
        "Erro ao carregar motoristas. Entre em contato com o suporte.",
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchDriversMonitoring = async (
    drivers,
    listVehicleByOperation,
    modelFormattedCutoffDates,
  ) => {
    try {
      const { startDate, finishDate } = modelFormattedCutoffDates;
      setLoadingData(true);
      dispatch(setPaginationReducer({ isLoading: true }));
      if (drivers && drivers.length && modelFormattedCutoffDates) {
        const driverList = drivers.map(
          (driver) => driver.id || driver.driverId,
        );
        const updatedPagination = store.getState().driversMonitoring.pagination;
        const response = await getDriversMonitoring(
          driverList,
          listVehicleByOperation,
          startDate,
          finishDate,
          updatedPagination.rowsPerPage,
          updatedPagination.page,
        );
        if (response.success) {
          throw new Error("Error fetching reward");
        }
        const { driverMonitoring } = response.data?.data;
        const driverPerformances = driverMonitoring?.drivers.map((driver) => {
          const driverIndicators = driver?.indicators?.reduce((acc, curr) => ({ ...acc, [curr.name]: curr.score }), {});

          return {
            ...driver,
            id: driver.driverId,
            consumption: valueToStringFloat(driver?.consumption),
            achievedMileage: valueToStringFloat(driver?.achievedMileage),
            consumptionAvg: valueToStringFloat(driver?.consumptionAvg),
            ...driverIndicators
          };
        });

        dispatch(setPerformancesReducer(driverPerformances));
        dispatch(
          setPaginationReducer({
            isLoading: false,
            count: response.data?.data.total_items || 0,
          }),
        );

      } else {
        dispatch(setPerformancesReducer([]));
      }
    } catch (error) {
      toast.error("Erro ao buscar dados. Contate o suporte.");
      console.error(error);
    } finally {
      setLoadingData(false);
    }
  };

  const onChangeFilters = (selectedOption, name) => {
    handleAmplitudeEvent('Filter Updated', { label: translateForAmplitudeEvent(name), value: selectedOption.label })
    try {
      setLoading(true);
      dispatch(setFilters({ selectedOption, name }));
      const updatedFilters = store.getState().driversMonitoring.filters;
      if (name === "year") {
        const isInitialRender = false;
        handleCutOffDates(profile, updatedFilters.year, isInitialRender);
        dispatch(setPerformancesReducer(null));
      } else if (!!updatedFilters.operation && !!updatedFilters.cutoffDate) {
        handleDataByOperation(
          updatedFilters.operation,
          updatedFilters.cutoffDate,
        );
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleTablePagination = (action, tableState) => {
    const updatedFilters = store.getState().driversMonitoring.filters;
    switch (action) {
      case "changePage":
        dispatch(
          setPaginationReducer({ isLoading: true, page: tableState.page + 1 }),
        );
        handleDataByOperation(
          updatedFilters.operation,
          updatedFilters.cutoffDate,
        );
        break;
      case "changeRowsPerPage":
        dispatch(
          setPaginationReducer({
            isLoading: true,
            rowsPerPage: tableState.rowsPerPage,
          }),
        );
        handleDataByOperation(
          updatedFilters.operation,
          updatedFilters.cutoffDate,
        );
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    handleAmplitudeEvent('Drivers Monitoring Screen Viewed');
  }, [])

  useEffect(() => {
    if (hasPermission({ scopes: ["can_view_drivers_follow"] })) {
      fetchData();
    }
  }, [fetchData]);

  useEffect(() => {
    if (fields.step > 0) {
      dispatch(clearFilters());
    }
  }, [fields, dispatch]);


  useEffect(() => {
    if (state?.data) {
      setIsPenalizer({
        engineBrakeScore: state?.data[0]?.hasEngineBrake,
        throttlePressureScore: state?.data[0]?.hasThrottlePenalizer
      });
    }
  }, [state]);

  const columns = makeColumns(size, isPenalizer);

  if ((loading || loadingData) && filters.operation?.id >= 0)
    return <DriversMonitoringSkeleton />;

  const Filters = () => (
    <Grid container justifyContent="space-around" data-cy="divGrupoSelects">
      {operationsOptions.lenght === 0 ? (
        <Grid
          item
          xl={size.grid4}
          lg={size.grid4}
          md={size.grid4}
          sm={size.grid4}
          xs={size.grid4}
          style={size.mobile ? {} : { paddingLeft: 5, paddingRight: 5 }}
        >
          <Paper elevation={0} className={classes.center}>
            <Typography>
              Não foram encontradas operações disponíveis.
            </Typography>
          </Paper>
        </Grid>
      ) : (
        <Grid
          item
          xl={size.grid4}
          lg={size.grid4}
          md={size.grid4}
          sm={size.grid4}
          xs={size.grid4}
          style={size.mobile ? {} : { paddingLeft: 5, paddingRight: 5 }}
        >
          <Paper elevation={0} className={classes.paper} data-cy="divSelectOperacao">
            <Select
              isSearchable={!size.mobile}
              name="operation"
              placeholder="Selecione uma operação"
              options={operationsOptions}
              styles={{ ...colourStyles, menuPortal: base => ({ ...base, zIndex: 9999999 }) }}
              onChange={(selectedOption) => onChangeFilters(selectedOption, "operation")}
              value={filters.operation}
              defaultValue={filters.operation}
              components={animatedComponents}
              noOptionsMessage={() => "Nenhum resultado encontrado!"}
            />
          </Paper>
        </Grid>
      )}
      <Grid
        item
        xl={size.grid4}
        lg={size.grid4}
        md={size.grid4}
        sm={size.grid4}
        xs={size.grid4}
        style={size.mobile ? {} : { paddingLeft: 5, paddingRight: 5 }}
      >
        <Paper elevation={0} className={classes.paperSelect} data-cy="divSelectDataAno">
          <Select
            name="year"
            isSearchable={!size.mobile}
            placeholder="Ano"
            options={yearsOptions.reverse()}
            styles={{ ...colourStyles, menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            onChange={(selectedOption) => onChangeFilters(selectedOption, "year")}
            value={filters.year}
            defaultValue={filters.year}
            components={animatedComponents}
          />
        </Paper>
      </Grid>
      {cutOffDatesOptions === null ? (
        <Grid
          item
          xl={size.grid4}
          lg={size.grid4}
          md={size.grid4}
          sm={size.grid4}
          xs={size.grid4}
          style={size.mobile ? {} : { paddingLeft: 5, paddingRight: 5 }}
        >
          <Paper elevation={0} className={classes.center}>
            <Typography>Não foram encontradas data de corte.</Typography>
          </Paper>
        </Grid>
      ) : (
        <Grid
          item
          xl={size.grid4}
          lg={size.grid4}
          md={size.grid4}
          sm={size.grid4}
          xs={size.grid4}
          style={size.mobile ? {} : { paddingLeft: 5, paddingRight: 5 }}
        >
          <Paper elevation={0} className={classes.paper} data-cy="divSelectDataMes">
            <Select
              name='cutoffDate'
              isSearchable={!size.mobile}
              placeholder="Selecione a data de corte"
              options={cutOffDatesOptions}
              styles={{ ...colourStyles, menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              components={animatedComponents}
              onChange={(selectedOption) => onChangeFilters(selectedOption, "cutoffDate")}
              value={filters.cutoffDate && JSON.parse(filters.cutoffDate)}
            />
          </Paper>
        </Grid>
      )}
    </Grid>
  );

  return (
    <Aux>
      <PermissionsGate scopes={["can_view_drivers_follow"]}>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.spacingContainer}
        >
          <Filters />
          {performances === null && <Infobox />}
          {performances?.length === 0 && (
            <NotFoundWithLottie
              lottieFiles={notFound}
              description="Não foram encontradas dados de motoristas."
              strongDescription="Verifique os filtros!"
            />
          )}
          {performances?.length > 0 && (
            <Grid container spacing={1}>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Typography className={classes.followTitle} data-cy="pTitulo">
                  Acompanhamento dos motoristas
                </Typography>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12} data-cy="divTabela">
                <TableResponsive
                  tableBodyMaxHeight={size.mobile ? size.height - 500 + 'px' : size.height - 350 + 'px'}
                  columns={columns}
                  data={performances}
                  options={{
                    onRowClick: size.mobile ? handleSelectRowMobile : handleSelectRow,
                    rowsPerPage: pagination.rowsPerPage,
                    count: pagination.count,
                    onTableChange: handleTablePagination,
                    isLoading: pagination.isLoading,
                    page: pagination.page - 1,
                    serverSide: true,
                    setRowProps: (row, index) => {
                      return index === id ? { style: { backgroundColor: '#FFF5CB', cursor: 'pointer', width: '100%' } } : null
                    },
                    search: false,
                    filter: false,
                    sort: false
                  }}
                  pointer={true}
                  tableName="drivers-monitoring"
                />
              </Grid>
              {/* botoes */}
              {size.mobile ? (
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Grid container justifyContent="flex-end" spacing={2}>
                    <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                      <Box marginTop={size.mobile ? "25px" : 0}>
                        <Button
                          fullWidth
                          variant="contained"
                          color="secondary"
                          onClick={() => handleClickDetail(selectedRowData)}
                          disabled={!selectedRowData}
                          className={classes.buttonDetail}
                        >
                          Ver detalhes
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
          )}
        </Grid>
      </PermissionsGate>
    </Aux>
  );
}
