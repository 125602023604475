import React, { useState } from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { MoreVert as MoreIcon } from "@mui/icons-material";
import classnames from "classnames";

// styles
import useStyles from "./styles";
import { useWindowSize } from "../../hooks/useWindowsSize";
import HelpButton from "components/HelpButton";

export default function Widget({
  children,
  title,
  subtitle,
  subtitleClass,
  noBodyPadding,
  wrapperClass,
  paperClass,
  bodyClass,
  disableWidgetMenu,
  header,
  noHeaderPadding,
  headerClass,
  titleClass,
  style,
  noWidgetShadow,
  upperTitle,
  customMenu,
  customMenuIcon,
  helpButton,
  customButton = () => <></>,
  ...props
}) {
  const classes = useStyles();

  // local
  const [moreButtonRef, setMoreButtonRef] = useState(null);
  const [isMoreMenuOpen, setMoreMenuOpen] = useState(false);

  const size = useWindowSize()

  return (
    <div
      className={
        classnames(classes.widgetWrapper, {
          [wrapperClass]: wrapperClass
        })
      }
      style={style && { ...style }}
    >
      <div
        className={classnames(classes.paper, {
          [paperClass]: paperClass
        })}
        classes={{
          root: classnames(classes.widgetRoot, {
            [classes.noWidgetShadow]: noWidgetShadow
          })
        }}
      >
        {title &&
          <div className={classnames(classes.widgetHeader, {
            [classes.noPadding]: noHeaderPadding,
            [headerClass]: headerClass
          })}>
            {header ? (
              header
            ) : (
              <React.Fragment>
                <Typography variant="h3" data-cy="h3Title" className={classnames(classes.titleClassDefault, {
                  [titleClass]: titleClass
                })} >
                  {(upperTitle) ? title.toUpperCase() : title}
                </Typography>
                {helpButton && <HelpButton />} {customButton && customButton()}
                {customMenuIcon ?? (!disableWidgetMenu && <IconButton
                  color="primary"
                  classes={{ root: classes.moreButton }}
                  aria-owns="widget-menu"
                  aria-haspopup="true"
                  onClick={() => setMoreMenuOpen(true)}
                  ref={setMoreButtonRef}
                  size="large">
                  <MoreIcon />
                </IconButton>)}
              </React.Fragment>
            )}
          </div>
        }
        {subtitle &&
          <div className={classnames(classes.widgetSubtitle, {
            [classes.noPadding]: noHeaderPadding,
            [subtitleClass]: subtitleClass
          })}>
            <Typography variant="caption" data-cy="divDescricao">
              {subtitle}
            </Typography>
          </div>
        }
        <div
          className={classnames(size.mobile ? {} : classes.widgetBody, {
            [classes.noPadding]: noBodyPadding,
            [bodyClass]: bodyClass,
          })}
          data-cy="tableRole"
        >
          {children}
        </div>
      </div>
      {(customMenu) ?? <Menu
        id="widget-menu"
        open={isMoreMenuOpen}
        anchorEl={moreButtonRef}
        onClose={() => setMoreMenuOpen(false)}
        disableAutoFocusItem
        {...props}
      >
        <MenuItem>
          <Typography variant="h5">Editar</Typography>
        </MenuItem>
        <MenuItem>
          <Typography variant="h5">Copiar</Typography>
        </MenuItem>
        <MenuItem>
          <Typography variant="h5">Excluir</Typography>
        </MenuItem>
        <MenuItem>
          <Typography variant="h5">Imprimir</Typography>
        </MenuItem>
      </Menu>}
    </div>
  );
}
