import { Box, Skeleton } from "@mui/material";

const TelemetryDataSkeleton = () => {
  return (
    <Box>
      <Skeleton
        variant="rectangular"
        width='100%'
        height={625}
        style={{ marginTop: "2px" }}
      />
    </Box>
  );
};

export default TelemetryDataSkeleton;
