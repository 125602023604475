import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import PropTypes from "prop-types";
import { color_blue_400, color_green_300, color_neutral_200, color_neutral_500, color_red_500, color_yellow_500 } from '../../../themes/colors';
import "./clusterGroup.css";

const ClusterGroup = ({
  width = 74,
  height = 74,
  blue = 10,
  green = 53,
  yellow = 90,
  gray = 0,
  red = 0,
  white = 0,
  onClick = () => {},
}) => {
  const data = [
    { name: "Motor ligado parado", value: blue },
    { name: "Em movimento", value: green },
    { name: "Risco de pane seca", value: yellow },
    { name: "Motor desligado", value: gray },
    { name: "Dados insuficientes", value: red },
    { name: "Sem comunicação", value: white },
  ];
  const COLORS = [color_blue_400, color_green_300, color_yellow_500, color_neutral_500, color_red_500, color_neutral_200];
  const total = blue + green + yellow + gray + red + white;

  return (
    <div className="ClusterGroup-container" onClick={onClick} style={{ position: 'relative', width, height }}>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Pie
            data={data}
            dataKey="value"
            cx="50%"
            cy="50%"
            innerRadius={20}
            outerRadius={35}
            startAngle={90}
            endAngle={450} 
            paddingAngle={0} 
            fill="#8884d8"
          >
            {data.map((entry, index) => (
              <Cell style={{fontSize: '10px'}} key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <div
        className="ClusterGroup-total"
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          fontSize: '24px',
          fontWeight: 'bold',
          color: '#333',
          backgroundColor: '#FFFFFF', 
          borderRadius: '50%',
          width: '45px', 
          height: '45px', 
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {total}
      </div>
    </div>
  );
};

ClusterGroup.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  blue: PropTypes.number,
  green: PropTypes.number,
  yellow: PropTypes.number,
  onClick: PropTypes.func,
};

export default ClusterGroup;
