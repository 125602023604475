import React, { useCallback, useEffect, useState } from "react";
import CryptoJS from 'crypto-js';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Typography,
  Alert,
  AlertTitle
} from "@mui/material";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { mapValues, toArray } from "lodash";
import { Link, useHistory, useLocation } from "react-router-dom";
import sign from "jwt-encode";
import classnames from "classnames";
import { format } from 'date-fns';

// hoc
import Aux from "hoc/auxiliar";

// components
import CardsSkeleton from "components/Skeletons/CardsSkeleton";
import FleetPerformance from "components/FleetPerformance";
import GForce from "components/GForce";
import HeaderDetails from "componentsNew/molecules/headerDetails/headerDetails";
import Map from "components/Map";
import MapSkeleton from "components/Skeletons/MapSkeleton";
import ModalFleetChart from "components/ModalFleetChart";
import PageToolbar from "components/PageToolbar";
import PerformanceComponent from "components/PerformanceComponent";
import PerformanceSkeleton from "components/Skeletons/PerformanceSkeleton";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";
import Pressure from "components/Pressure";
import PressureSkeleton from "components/Skeletons/PressureSkeleton";
import StatisticsCards from "components/StatisticsCards";
import { ValidationMessageComponent } from "components/ValidationMessageComponent";
import FixedContainer from "components/FixedContainer";

// services
import {
  getFleetPercentages,
  getFleetLocations,
  getFleetChartData,
  getVehicleProfile,
  getVehiclesPerformancePack,
  getFleetStatistics,
} from "services/fleetPerformance";
import {
  getPressureGasPedal,
  getVehiclesByOperation,
  getVehicleValidation,
} from "services/vehicle";
import { getOperationById } from "services/operations";
import { getOperationTypeById } from "services/operationType";
import { handleAmplitudeEvent } from "services/amplitude";

// helpers
import { getNameIndicator } from "helpers/indicators";
import { parseChartData } from "helpers/fleetChart";
import { orderVehiclesByCrescent } from "helpers/vehicles";
import { allRequirementLevelOptions, indicatorsAndPenalizingOptionsConcat } from "domain/selectOptions";
import { verifyDateInRange, verifyDatesInRange, findValueByKey } from "helpers/functions";
import { convertDateStringToDB } from "helpers/dates";

// context
import { useLayoutState } from "context/LayoutContext";
import { useAppDispatch, useAppSelector } from "store";
import {
  setStepAdd,
  setPrintData,
  setStepDeductBonus,
} from "store/features/bonusFollowSlice";
import { useWindowSize } from "hooks/useWindowsSize";
import { setStepDeduct } from "store/features/driversMonitoringSlice";
import {
  setVehicleFilters,
  setVehicleStepAdd,
  setFleetData,
} from "store/features/vehicleDashboardSlice";

// styles
import useStyles from "./styles";

export default function Dashboard() {
  const dispatch = useAppDispatch();
  const size = useWindowSize();
  const classes = useStyles();
  const layoutState = useLayoutState();
  const history = useHistory();

  const maxDate = new Date();
  maxDate.setHours(23, 59, 0, 0);
  const dateFormatTemplate = "YYYY-MM-DDTHH:mm:ssZZ";
  const dateFormatTemplateOnly = "DD/MM/YYYY";
  const timeFormatTemplateOnly = "HH:mm:ss";
  const dateFormatTemplateWithGMT = "YYYY-MM-DDTHH:mm:ss-0300";

  const { state } = useLocation();
  const { filters } = useAppSelector((state) => state.vehicleDashboard);
  const { currentCustomer } = useAppSelector((state) => state.global.user);
  const { fleetData: dataToRequestOfChart } = useAppSelector((state) => state.vehicleDashboard);

  const [consumption, setConsumption] = useState({
    idleConsumption: "",
    movementConsumption: "",
  });
  const [validationMessageComponent, setValidationMessageComponent] = useState(false);
  const [pressureGasPedal, setPressureGasPedal] = useState(null);
  const [validationMessage, setValidationMessage] = useState(false);
  const [infoBox, setInfoBox] = useState(false);
  const [checkboxInfo, setCheckboxInfo] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [indicators, setIndicators] = useState([]);
  const [indicatorsByOperation, setIndicatorsByOperation] = useState([]);
  const [indicatorsPerformance, setIndicatorsPerformance] = useState([]);
  const [indicatorsPercentage, setIndicatorsPercentage] = useState([]);
  const [indicatorsPressure, setIndicatorsPressure] = useState([]);
  const [locations, setLocations] = useState([]);
  const [locationsUnique, setLocationsUnique] = useState(false);
  const [statistics, setStatistics] = useState([]);
  const [summary, setSummary] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [showFleetChart, setShowFleetChart] = useState(false);
  const [throttlePressureScore, setThrottlePressureScore] = useState(0);
  const [hasThrottlePressureScore, setHasThrottlePressureScore] = useState(false);
  const [showThrottleGraphPressureScore, setShowThrottleGraphPressureScore] = useState(false);
  const [gForce, setGForce] = useState(null);
  const [penalty, setPenalty] = useState(false);
  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [selectedDates, setSelectedDates] = useState(null);
  const [alertDateDivergent, setAlertDateDivergent] = useState(null);
  const [segment, setSegment] = useState(null);
  const canViewDashboardGForce = hasPermission({ scopes: ["can_view_dashboard_gforce"] });

  const [loading, setLoading] = useState({
    chart: true,
    drivers: true,
    indicators: true,
    vehicles: true,
    locations: true,
    percentages: true,
    statistics: true,
    gForce: true,
  });
  const [searchParams, setSearchParams] = useState({
    dates: {},
    vehicles: [],
  });

  const fetchVehicles = useCallback(async () => {
    setLoading((oldState) => ({ ...oldState, vehicles: true }));
    try {
      const response = await getVehiclesByOperation(currentCustomer);
      if ((state || filters?.vehicle) && response.data.customers.length > 0) {
        const stateVehicleId = findValueByKey(state?.vehicleId, 'vehicleId') || findValueByKey(filters, 'id');
        const returnData = response.data.customers;
        returnData.map((o) =>
          o.vehicles.filter((truck) => {
            const vehicleId = stateVehicleId || stateVehicleId || "";
            if (truck.id === vehicleId) {
              const model = {
                ...truck,
                operation: { id: o.id, name: o.name },
              };
              setSelectedVehicles([model]);
            }
          }),
        );
      } else {
        setSelectedVehicles([]);
      }
      let orderedVehiclesByCustomer = response.data.customers.sort(
        (a, b) => (a.name > b.name) - (a.name < b.name)
      );
      orderedVehiclesByCustomer.map((option, i) => {
        const optionVehicles = option.vehicles.filter(item => item.devicesHistory !== null)
        orderedVehiclesByCustomer[i].vehicles = orderVehiclesByCrescent(optionVehicles);
      });
      setVehicles(orderedVehiclesByCustomer);
    } catch (err) {
      setVehicles([]);
      toast.error(
        "Erro ao carregar lista de Veículos. Entre em contato com o suporte.",
      );
    } finally {
      setLoading((oldState) => ({ ...oldState, vehicles: false }));
    }
  }, [state]);

  //Função esta colocada no useEffect. Toda vez que a lista de veiculos ou data forem alterados, irar fazer uma nova busca na api conforme dados selecionados.
  const fetchSearchData = (vehicleList, initialDate, finalDate) => {
    const devicesHistory = findValueByKey(vehicleList, 'devicesHistory');

    let params = {
      data: [],
    };

    devicesHistory &&
      devicesHistory.map((elem) => {
        let updatedObj;
        if (!elem?.endDate) {
          updatedObj = {
            ...elem,
            endDate: dayjs().format(dateFormatTemplate),
          };
          elem = updatedObj;
        }

        if (
          !verifyDatesInRange(
            elem.startDate,
            elem.endDate,
            initialDate,
            finalDate,
          )
        ) {
          return;
        }

        let vehicleList = {
          deviceId: elem.id,
          startDate: verifyDateInRange(elem.startDate, initialDate, finalDate)
            ? dayjs(elem?.startDate).format(dateFormatTemplateWithGMT)
            : initialDate,
          endDate: verifyDateInRange(elem.endDate, initialDate, finalDate)
            ? dayjs(elem?.endDate).format(dateFormatTemplateWithGMT)
            : finalDate,
        };
        params.data.push(vehicleList);
      });

    const vehicle = findValueByKey(vehicleList, 'id');

    fetchVehiclePerformances(vehicle, initialDate, finalDate);
    fetchPercentages(vehicle, initialDate, finalDate);
    fetchStatistics(vehicle, initialDate, finalDate);
    fetchLocations(vehicle, initialDate, finalDate);
    fetchIndicatorsByOperation(vehicleList);
  };

  const fetchVehiclePerformances = async (vehicle, initialDate, finalDate) => {
    try {
      setLoading((oldState) => ({
        ...oldState,
        lastPack: true,
        indicators: true,
      }));

      const responseData = await getVehiclesPerformancePack(
        vehicle,
        initialDate,
        finalDate,
      );
      const performancesData = findValueByKey(responseData, 'performances');
      if (performancesData?.length > 0) {
        const firstVehiclePerformance = performancesData.shift();
        if (firstVehiclePerformance?.indicators) {
          setHasThrottlePressureScore(
            firstVehiclePerformance.indicators.hasThrottlePenalizer,
          );
          setShowThrottleGraphPressureScore(
            firstVehiclePerformance.indicators.showThrottleGraph,
          );
          setThrottlePressureScore(
            firstVehiclePerformance.indicators.throttlePressureScore,
          );

          const formatedIndicators = mapValues(
            firstVehiclePerformance.indicators,
            (indicator, key) => ({
              ...indicator,
              name: key,
              title: getNameIndicator(`${key}Score`),
            }),
          );
          setIndicatorsPerformance(formatedIndicators);
          setConsumption({
            idleConsumption:
              firstVehiclePerformance.indicators.idle.fuelConsumption,
            movementConsumption: firstVehiclePerformance.indicators.movement
              ? firstVehiclePerformance.indicators.movement.fuelConsumption
              : [],
          });
        }
        setSummary({ globalScore: firstVehiclePerformance.score });
        const { segments } = firstVehiclePerformance;
        if (segments && segments.length > 0) {
          const startSegment = segments[0]?.start_date ? dayjs(segments[0]?.start_date).format('DD/MM/YYYY HH:mm') : ' - ';
          const endSegment = segments.at(-1)?.end_date ? dayjs(segments.at(-1)?.end_date).format('DD/MM/YYYY HH:mm') : ' - ';
          const segmentData = `${startSegment} até ${endSegment}`;
          setSegment(segmentData);
        }
      } else {
        toast.warning("Não há data da última comunicação com o veículo.");
      }
    } catch (error) {
      setIndicatorsPerformance([]);
      setSummary([]);
      setSegment(null);
      setConsumption({
        idleConsumption: "",
        movementConsumption: "",
      });
      setHasThrottlePressureScore(0);
      setShowThrottleGraphPressureScore(false);
      setThrottlePressureScore(false);
      toast.error(
        "Erro ao buscar data de última comunicação. Contate o administrador.",
      );
    } finally {
      setLoading((oldState) => ({
        ...oldState,
        lastPack: false,
        indicators: false,
      }));
    }
  };

  // Para entender o rolo a seguir, veja o comentário aqui:
  // https://gitlab.com/gobrax-dev/fleet-analytics/-/issues/384#note_897840649
  const fetchIndicatorsByOperation = async (vehicleList) => {
    if (Object.keys(vehicleList[0])?.length > 0) {
      try {
        const operation = findValueByKey(vehicleList, 'operation');
        if (operation) {
          const operationId = operation.id;
          const response = await getOperationById(Number(operationId));
          const { operationType, requirementLevel } = response.data.data.operation;
          const responseOperation = await getOperationTypeById(operationType);
          const level = allRequirementLevelOptions.filter(
            (o) => o.value === requirementLevel,
          );
          const operationsName = [];
          const penaltyTypes = ["lowPressure", "medPressure", "higPressure"];
          setPenalty(false);
          responseOperation.data.operationTypes.indicators.forEach(
            (indicator) => {
              if (indicator.requirement_level === level[0].type) {
                let indicatorName = indicatorsAndPenalizingOptionsConcat.filter(
                  (o) => o.value === indicator.indicator_id,
                );
                operationsName.push(indicatorName[0].type);
                if (
                  penaltyTypes.includes(indicatorName[0].type) &&
                  indicator?.penalizing
                ) {
                  setPenalty(true);
                }
              }
            },
          );
          setIndicatorsByOperation(operationsName);
        }
      } catch (err) {
        setIndicatorsByOperation([]);
        toast.error("Erro ao buscar indicadores");
      }
    }
  };

  const fetchPercentages = async (vehicle, initialDate, finalDate) => {
    setLoading((oldState) => ({ ...oldState, percentages: true }));
    try {
      const response = await getFleetPercentages(
        vehicle,
        initialDate,
        finalDate,
      );
      if (response.data) {
        const fleetIndicators = response.data;
        const formatedResponseIndicators = mapValues(
          fleetIndicators,
          (indicator, key) => {
            if (
              key === "ecoRoll" &&
              !hasPermission({ scopes: ["can_view_eco_roll_indicator"] })
            ) {
              return undefined;
            }

            return {
              ...indicator,
              name: key,
              label: getNameIndicator(`${key}Score`),
            };
          },
        );
        const transformIndicatorsToArray = toArray(formatedResponseIndicators);

        setIndicatorsPercentage(transformIndicatorsToArray);
        setIndicatorsPressure({
          higPressure: fleetIndicators.higPressure,
          lowPressure: fleetIndicators.lowPressure,
          medPressure: fleetIndicators.medPressure,
        });
      }
    } catch (err) {
      setIndicatorsPercentage([]);
      setIndicatorsPressure([]);
      /* TODO - tratar erro */
      console.log(err);
    } finally {
      setLoading((oldState) => ({ ...oldState, percentages: false }));
    }
  };

  const fetchStatistics = async (vehicle, initialDate, finalDate) => {
    setLoading((oldState) => ({ ...oldState, statistics: true, gForce: true }));
    try {
      if (!vehicle) {
        setStatistics([]);
        return;
      }

      const response = await getFleetStatistics(
        vehicle,
        initialDate,
        finalDate,
      );
      setGForce(response.data);
      setStatistics(response.data);
    } catch (err) {
      setGForce(null);
      setStatistics([]);
      /* TODO - tratar erro */
      console.log(err);
    } finally {
      setLoading((oldState) => ({ ...oldState, statistics: false, gForce: false }));
      return;
    }
  };

  const fetchLocations = async (vehicleId, initialDate, finalDate) => {
    setLoading((oldState) => ({ ...oldState, locations: true }));
    if (state) {
      vehicleId = [String(vehicleId)];
    }
    try {
      if (vehicleId.length > 1) {
        setLocationsUnique(true);
        return;
      }
      const response = await getFleetLocations(
        vehicleId,
        initialDate,
        finalDate,
      );

      if (response.data.locations.length) {
        const newLocations = response.data.locations.map((location) => {
          return {
            device: location.device,
            locations: location.locations.map((sublocation) => {
              return {
                date: sublocation[0],
                lat: sublocation[1],
                lon: sublocation[2],
              };
            }),
          };
        });
        const transformed = {
          locations: newLocations,
        };
        setLocationsUnique(false);
        setLocations(transformed.locations);
      } else {
        setLocations([]);
      }
    } catch (err) {
      setLocations([]);
    } finally {
      setLoading((oldState) => ({ ...oldState, locations: false }));
    }
  };

  const noIndicatorsChartToShow = () => {
    toast.warning(
      "Sem dados para exibir, tente buscar outro período ou frota para análise.",
    );
  };

  const fetchChart = async (vehicles, initialDate, finalDate) => {
    setLoading((oldState) => ({ ...oldState, chart: true }));
    try {
      if (
        dataToRequestOfChart?.vehicles === vehicles &&
        dataToRequestOfChart?.initialDate === initialDate &&
        dataToRequestOfChart?.finalDate === finalDate
      ) {
        setShowFleetChart(true);
        const vehicleId = findValueByKey(filters, 'id');
        const vehicleIdentification = findValueByKey(filters, 'identification');
        handleAmplitudeEvent('Chart Modal Loaded', {
          vehicleId: vehicleId,
          vehicleName: vehicleIdentification,
          initialDate: initialDate,
          endDate: finalDate
        })
        return;
      }
      const vehicle = findValueByKey(filters, 'id', true) || vehicles[0];
      const vehicleProfile = await getVehicleProfile(vehicle.id);
      if (!vehicleProfile?.data) {
        throw new Error("failed to fetch chart data");
      }

      const response = await getFleetChartData(
        vehicle?.id,
        initialDate,
        finalDate,
      );

      if (!response?.data || !response?.data?.data) {
        throw new Error("failed to fetch chart data");
      }

      dispatch(
        setFleetData({ vehicles, initialDate, finalDate }),
      );

      const parsedChartData = parseChartData(
        response.data.data,
        vehicleProfile.data,
        vehicle,
        vehicleProfile,
        initialDate,
        finalDate
      );

      setChartData(parsedChartData);
      
      handleAmplitudeEvent('Chart Modal Loaded', { vehicle_id: vehicle.id, vehicle_name: vehicle.identification, initial_date: initialDate, end_date: finalDate })
    } catch (err) {
      setChartData([]);
      setShowFleetChart(false);
      noIndicatorsChartToShow();
      console.log(err);
    } finally {
      setLoading((oldState) => ({ ...oldState, chart: false }));
    }
  };

  const handleSelectDate = useCallback(
    async (initialDate, finalDate) => {
      setValidationMessageComponent(false);
      setSegment(null);

      const formatDate = "dd/MM/yyyy HH:mm";
      handleAmplitudeEvent('Filter Updated', { label: "Periodo", value: `${format(initialDate, formatDate)} - ${format(finalDate, formatDate)}` });

      setSelectedDates({
        initialDate: dayjs(initialDate).format(dateFormatTemplate),
        finalDate: dayjs(finalDate).format(dateFormatTemplate),
      });

      if (selectedVehicles.length > 0 && Object.keys(selectedVehicles[0]).length > 0) {
        let vehicles = [];

        vehicles = selectedVehicles.map((vehicle) => vehicle.id);

        try {
          const validation = await getVehicleValidation(
            vehicles,
            dayjs(initialDate).format(dateFormatTemplate),
            dayjs(finalDate).format(dateFormatTemplate),
          );

          const { response: vehicleValidationConfig } = validation.data;

          setValidationMessage(vehicleValidationConfig);

          if (vehicleValidationConfig === "ok") {
            fetchSearchData(
              selectedVehicles,
              dayjs(initialDate).format(dateFormatTemplate),
              dayjs(finalDate).format(dateFormatTemplate),
            );
          } else {
            setInfoBox(true);
            setValidationMessageComponent(true);

            return false;
          }
        } catch (error) {
          console.log("error", error);
          toast.error(
            "Erro ao validar configurações do veículo. Por favor, entre em contato com o suporte.",
          );
        }
      }
    },
    [selectedVehicles, dateFormatTemplate]
  );

  const handleAlertDateDivergent = (deviceHistory) => {
    const hasStartDate = deviceHistory?.startDate;
    const hasEndDate = deviceHistory?.endDate;
    const deviceStartDate = new Date(deviceHistory?.startDate).getTime();
    const deviceEndDate = new Date(deviceHistory?.endDate).getTime();
    const filterStartDate = new Date(selectedDates?.initialDate).getTime();
    const filterEndDate = new Date(selectedDates?.finalDate).getTime();

    if (
      hasStartDate &&
      deviceStartDate >
      filterStartDate &&
      deviceStartDate <
      filterEndDate
    ) {
      setAlertDateDivergent({
        install: true,
        date: dayjs(deviceHistory?.startDate).format(dateFormatTemplateOnly),
        time: dayjs(deviceHistory?.startDate).format(timeFormatTemplateOnly),
        dateHistory: deviceHistory,
      });
    } else if (
      hasEndDate &&
      deviceEndDate <
      filterEndDate &&
      deviceEndDate >
      filterStartDate
    ) {
      setAlertDateDivergent({
        install: false,
        date: dayjs(deviceHistory?.endDate).format(dateFormatTemplateOnly),
        time: dayjs(deviceHistory?.endDate).format(timeFormatTemplateOnly),
        dateHistory: deviceHistory,
      });
    } else {
      setAlertDateDivergent(null);
    }
  }

  const handleSelectedVehicles = useCallback(
    async (selected) => {
      setValidationMessageComponent(false);
      setSegment(null);

      if (Object.keys(selected[0]).length > 0) {
        const itemSelected = findValueByKey(selected, 'id', true) || findValueByKey(filters.vehicle, 'id', true);
        
        if (!itemSelected.id) {
          return;
        }

        try {
          handleAmplitudeEvent('Filter Updated', { label: "Frota", value: itemSelected.identification });

          const vehicleId = itemSelected.id;
          const validation = await getVehicleValidation(
            vehicleId,
            selectedDates.initialDate,
            selectedDates.finalDate,
          );

          fetchPressure(vehicleId);

          const { response: messageResponse } = validation.data;
          setValidationMessage(messageResponse);

          if (messageResponse === "ok") {
            fetchSearchData(
              [itemSelected],
              selectedDates.initialDate,
              selectedDates.finalDate,
            );
          } else {
            setInfoBox(true);
            setValidationMessageComponent(true);
            return false;
          }
        } catch (error) {
          console.log("error", error);
          toast.error(
            "Erro ao validar configuração do veículo. Por favor, entre em contato com o suporte.",
          );
          setLoading({
            chart: false,
            drivers: false,
            indicators: false,
            vehicles: false,
            locations: false,
            percentages: false,
            statistics: false,
          });
        } finally {
          setSelectedVehicles([itemSelected]);
          const selectedFilters = {
            vehicles: [itemSelected],
            vehicle: [itemSelected],
            selectedDates: selectedDates,
          };
          dispatch(setVehicleFilters(selectedFilters));
        }
      }
    },
    [filters, selectedDates, dispatch]
  );


  const handleChangeVisibleInfoBox = () => {
    if (!checkboxInfo) return setInfoBox(true);
    localStorage.setItem("@infobox", checkboxInfo);
    getLocalStorageInfo();
  };

  const handleFleetChart = (open) => {
    let actualSearchParams = {
      dates: selectedDates,
      vehicles: selectedVehicles,
    };

    if (
      JSON.stringify(actualSearchParams) !== JSON.stringify(searchParams) ||
      dataToRequestOfChart?.vehicles !== ""
    ) {
      setShowFleetChart(open);
      open &&
        fetchChart(
          selectedVehicles,
          selectedDates.initialDate,
          selectedDates.finalDate,
        );

      setSearchParams(actualSearchParams);
    } else {
      noIndicatorsChartToShow();
    }
  };

  const handleCheckboxInfoIsVisible = () => {
    setCheckboxInfo(!checkboxInfo);
  };

  const handleDieselControl = () => {
    handleAmplitudeEvent('Diesel Control Button Clicked');
    const selectedFilters = {
      vehicles: selectedVehicles,
      vehicle: selectedVehicles,
      selectedDates: selectedDates,
    };
    dispatch(setVehicleFilters(selectedFilters));
    dispatch(setVehicleStepAdd());
    dispatch(setStepAdd());
    const vehicle = findValueByKey(selectedFilters, 'id', true);
    history.push({
      pathname: "/gofurther/dashboard/dieselControl",
      state: {
        type: "vehicleDashboard",
        vehicle: [vehicle],
        initialDate: selectedDates.initialDate,
        finishDate: selectedDates.finalDate,
      },
    });
  };

  const ordernateIndicators = (indicatorList) => {
    let ordenateArray = [];
    const ordenateIndicators = [
      "Início faixa verde",
      "Aproveitamento de embalo",
      "Motor ligado parado",
      "Motor ligado parado em alta rotação",
      "Total motor ligado parado",
      "Acelerando acima do verde",
      "Excesso de velocidade",
      "Piloto automático",
      "Total faixa verde",
      "Final faixa verde",
      "Freio motor",
      "Eco-Roll/I-Roll",
      "Em movimento",
    ];

    for (const label of ordenateIndicators) {
      for (const indicator of indicatorList) {
        if (indicator.label === label) {
          ordenateArray.push(indicator);
        }
      }
    }
    return ordenateArray;
  };

  const mergeIndicatorsInfo = useCallback((indPerformance, indPercentage) => {
    const mergeIndicatorsList = [];
    for (let ind of indPercentage) {
      for (let key in indPerformance) {
        if (indPerformance?.[key]?.name === ind?.name) {
          mergeIndicatorsList.push({
            ...indPerformance[key],
            ...ind,
            color: indPerformance[key].color,
          });
        }
      }
    }
    const indOrdernated = ordernateIndicators(mergeIndicatorsList);

    setIndicators(indOrdernated);
  }, []);

  const getLocalStorageInfo = () => {
    setInfoBox(localStorage.getItem("@infobox"));
  };

  const fetchPressure = async (vehicleId) => {
    try {
      const response = await getPressureGasPedal(vehicleId);
      if (response.status === 200) {
        setPressureGasPedal(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  let map = <Map data={locations} />;

  if (!locations || locations.length === 0) {
    map = (
      <Alert severity="info">
        <AlertTitle>Atenção!</AlertTitle>
        Não foram encontrados indicadores para a frota selecionada,{" "}
        <strong>verifique os filtros.</strong>
      </Alert>
    );
  } else if (locationsUnique) {
    map = (
      <Alert severity="info">
        <AlertTitle>Atenção!</AlertTitle>
        Para que possa visualizar o trajeto das rotas do veículo no mapa é
        necessário selecionar <strong>apenas um veículo.</strong>
      </Alert>
    );
  }

  const linkPrint = () => {
    handleAmplitudeEvent('Print Button Clicked');
    
    const randomBytes = CryptoJS.lib.WordArray.random(256);
    const token = randomBytes.toString(CryptoJS.enc.Base64);

    const vehicle = findValueByKey(selectedVehicles, 'id', true);
    const dataToPrint = {
      consumption: consumption,
      indicators: indicators,
      indicatorsByOperation: indicatorsByOperation,
      indicatorsPressure: indicatorsPressure,
      selectedVehicles: selectedVehicles,
      statistics: statistics,
      summary: summary,
      vehicleId: vehicle,
      initialDate: selectedDates.initialDate,
      finalDate: selectedDates.finalDate,
      vehicleProps: {
        vehicleIdentification: vehicle.identification,
        startDate: selectedDates.initialDate,
        finishDate: selectedDates.finalDate,
      },
      hasThrottlePressureScore: hasThrottlePressureScore,
      throttlePressureScore: throttlePressureScore,
      pressureGasPedal: pressureGasPedal,
      penalty: penalty,
      segment: segment,
      gForce: gForce
    };
    const jwtData = sign(dataToPrint, token);
    dispatch(setPrintData(jwtData));
  };

  const PrintButton = React.forwardRef(
    ({ navigate, jwtData, ...props }, ref) => {
      return (
        <Button
          fullWidth
          variant="contained"
          className={classnames(classes.btnPrint, {
            [classes.btBarginTop]: state,
          })}
          onClick={() => linkPrint()}
          ref={ref}
          {...props}
          style={{ height: "40px" }}
        >
          {props.children}
        </Button>
      );
    },
  );

  useEffect(() => {
    const devices = findValueByKey(selectedVehicles, 'devicesHistory');
    devices && devices?.length > 0 && handleAlertDateDivergent(devices[0])
  }, [filters]);

  useEffect(() => {
    if (selectedVehicles.length > 0) {
      dispatch(
        setVehicleFilters({
          vehicles: selectedVehicles,
          vehicle: selectedVehicles,
          selectedDates: selectedDates,
        }),
      );
    }
  }, [selectedVehicles, selectedDates]);

  useEffect(() => {
    if (currentCustomer && hasPermission({ scopes: ["can_view_dashboard"] })) {
      fetchVehicles();
      getLocalStorageInfo();
    }
  }, [fetchVehicles, currentCustomer]);

  useEffect(() => {
    if (indicatorsPercentage && indicatorsPerformance) {
      mergeIndicatorsInfo(indicatorsPerformance, indicatorsPercentage);
    }
  }, [indicatorsPercentage, indicatorsPerformance]);

  useEffect(() => {
    if (state?.startDate) {
      setSelectedDates({
        initialDate: convertDateStringToDB(state.startDate),
        finalDate: convertDateStringToDB(state.finishDate),
      });
    } else if (filters?.selectedDates?.initialDate) {
      setSelectedDates({
        initialDate: filters?.selectedDates.initialDate,
        finalDate: filters?.selectedDates.finalDate,
      });
      const vehicle = findValueByKey(filters, 'vehicle', true);
      setSelectedVehicles([vehicle])
    } else {
      setSelectedDates({
        initialDate: dayjs().format("YYYY-MM-DDT00:00:00ZZ"),
        finalDate: dayjs().format("YYYY-MM-DDT23:59:59ZZ"),
      });
    }
  }, []);

  useEffect(() => {
    (!!state && !state?.type) && handleAmplitudeEvent('Driver Performance Screen Viewed', { driverId: state.detail.driverId, driverName: state.detail.name, vehicleName: state.vehicleId.vehicleIdentification, startDate: state.vehicleId.startDate, endDate: state.vehicleId.endDate, score: state.vehicleId.score });
  }, [state])

  useEffect(() => {
    handleAmplitudeEvent('Individual Fleet Analysis Viewed');
  }, [])

  return (
    <Aux>
      <PermissionsGate scopes={["can_view_dashboard"]}>
        {showFleetChart && (
          <ModalFleetChart
            showFleetChart={showFleetChart}
            handleFleetChart={handleFleetChart}
            loading={loading.chart}
            chartData={chartData}
          />
        )}
        <FixedContainer>
          {(selectedDates?.initialDate || state?.startDate) && (
            <PageToolbar
              isDetail={!!state && !state?.type}
              handleSelectDate={handleSelectDate}
              vehicleProps={state}
              selectedDates={selectedDates}
              listData={vehicles}
              selectedData={selectedVehicles}
              handleSelectedData={handleSelectedVehicles}
              menuCalendar
              calendarMaxRanges={{
                ...(selectedVehicles?.[0]?.devicesHistory && {
                  minDate: new Date(selectedVehicles[0].devicesHistory.at(-1).startDate),
                }),
                maxDate: maxDate,
              }}
              vehiclesMenuButton
              isSingleMode={true}
            />
          )}
        </FixedContainer>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ marginBottom: 15 }}
        >
          {alertDateDivergent && (
            <Alert severity="error">
              <AlertTitle>Atenção!</AlertTitle>
              {`Unidade eletrônica ${alertDateDivergent?.install
                ? "instalada"
                : "desinstalada ou iniciou com erro"
                } no dia
                ${alertDateDivergent?.date} às ${alertDateDivergent?.time
                }. Selecione um novo período ${alertDateDivergent?.install
                  ? "a partir da data e hora que foi realizada a instalação"
                  : "até a data da desinstalação."
                } para obter dados e a análise corretamente.`}
            </Alert>
          )}
        </Grid>
        {segment &&
          <Grid item xs={12}>
            <Grid
              item
              xs={12}
              data-cy="detalhesVeiculo"
              className={classes.detailSegment}
            >
              <HeaderDetails
                vehicle={
                  !state ? selectedVehicles[0]?.identification + ' / ' + selectedVehicles[0]?.plate || ' - '
                    : state?.vehicleId?.vehicleIdentification + ' / ' + state?.vehicleId?.plate || ' - '
                }
                driver={
                  !state ? selectedVehicles[0]?.currentDriver?.driverName || ' - '
                    : state?.detail?.name || ' - '
                }
                date={segment}
                showBackButton={false}
                loading={false}
              />
            </Grid>
          </Grid>
        }
        <Grid container direction="row" justifyContent="flex-end">
          {size.mobile ? null : (
            <PermissionsGate scopes={["can_view_dashboard_print"]}>
              <Grid item xs={12} sm={12} md={2}>
                {selectedVehicles.length > 0 &&
                  !validationMessageComponent && (
                    <Link
                      target="_blank"
                      component={PrintButton}
                      to={{
                        pathname: "/dashboard/print",
                      }}
                      data-cy="divImprimir"
                    >
                      Imprimir
                    </Link>
                  )}
              </Grid>
            </PermissionsGate>
          )}
        </Grid>
        {validationMessageComponent && (
          <ValidationMessageComponent message={validationMessage} />
        )}
        {!selectedVehicles.length > 0 && !infoBox ? (
          <Grid container className={classes.containerInfobox}>
            <Paper elevation={0} className={classes.paper}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Box
                    fontFamily="fontFamily"
                    justifyContent="center"
                    fontSize="h3.fontSize"
                    textAlign="center"
                    lineHeight={2}
                    className={classes.infoBox}
                    p={4}
                  >
                    Para gerar as informações da sua frota, selecione um veículo{" "}
                    <strong>
                      clicando no filtro localizado no menu acima.
                    </strong>
                  </Box>
                  <Grid item className={classes.containerButton}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkboxInfo}
                          onChange={() => handleCheckboxInfoIsVisible()}
                        />
                      }
                      label="Não mostrar essa mensagem novamente"
                    />
                    <Button
                      onClick={() => handleChangeVisibleInfoBox()}
                      variant="contained"
                      className={classes.okButton}
                    >
                      Ok
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        ) : !selectedVehicles.length > 0 && infoBox ? (
          <Grid />
        ) : (
          <Grid
            container
            spacing={4}
            data-cy="contentAnaliseIndividual"
            className={classnames(classes.container, {
              [classes.containerShift]: layoutState.isSidebarOpened,
            })}
          >
            {validationMessageComponent ? (
              <Grid />
            ) : (
              <>
                {/* desempenho */}
                <PermissionsGate scopes={["can_view_dashboard_performance"]}>
                  <Grid
                    item
                    xl={2}
                    lg={!layoutState.isSidebarOpened ? 2.5 : 3}
                    md={3}
                    sm={4}
                    xs={12}
                    data-cy="performanceDesempenho"
                  >
                    <Typography variant="h4" className={classes.cardTitle} data-cy="h4TituloDesempenho">
                      Desempenho
                    </Typography>
                    <Paper elevation={0} className={classes.performance} data-cy="h4DesempenhoDados">
                      <Grid container alignItems="center">
                        {loading.indicators ? (
                          <PerformanceSkeleton />
                        ) : (
                          <PerformanceComponent
                            data={summary}
                            selectedVehicles={selectedVehicles}
                            indicators={indicators}
                          />
                        )}
                      </Grid>
                    </Paper>
                  </Grid>
                </PermissionsGate>
                {/* indicadores */}
                <PermissionsGate scopes={["can_view_dashboard_indicators"]}>
                  <Grid
                    item
                    xl={10}
                    lg={!layoutState.isSidebarOpened ? 9.5 : 9}
                    md={9}
                    sm={8}
                    xs={12}
                    data-cy="divperformanceIndicadores"
                  >
                    <Typography variant="h4" className={classes.cardTitle} data-cy="h4Indicadores">
                      Indicadores
                    </Typography>
                    <FleetPerformance
                      loading={loading}
                      indicators={
                        !hasPermission({
                          scopes: ["can_view_eco_roll_indicator"],
                        })
                          ? indicators.filter((obj) => obj.name !== "ecoRoll")
                          : indicators
                      }
                      consumption={consumption}
                      summary={summary}
                      handleDieselControl={handleDieselControl}
                      handleFleetChart={() => {
                        handleAmplitudeEvent('Chart Button Clicked');
                        handleFleetChart(true);
                      }}
                      indicatorsByOperation={indicatorsByOperation}
                    />
                  </Grid>
                </PermissionsGate>
                {/* força G */}
                <PermissionsGate scopes={["can_view_dashboard_gforce"]}>
                  <Grid
                    item
                    xl={3}
                    lg={3}
                    md={3}
                    sm={12}
                    xs={12}
                    data-cy="divPerformanceForcaG"
                  >
                    <Typography variant="h4" className={classes.cardTitle} data-cy="h4TituloForcaGLateral">
                      Força G Lateral
                    </Typography>
                    {loading.gForce || !gForce ? (
                      <PressureSkeleton />
                    ) : (
                      <GForce data={gForce} />
                    )}
                  </Grid>
                </PermissionsGate>
                {/* pressao do acelerador */}
                {showThrottleGraphPressureScore && !canViewDashboardGForce && (
                  <PermissionsGate scopes={["can_view_dashboard_pressure"]}>
                    <Grid
                      item
                      xl={3}
                      lg={3}
                      md={3}
                      sm={12}
                      xs={12}
                      data-cy="divPerformancePressaoAcelerador"
                    >
                      <Typography variant="h4" className={classes.cardTitle} data-cy="h4TituloPressaoAcelerador"
                      >
                        Pressão do acelerador
                      </Typography>
                      {loading.percentages ? (
                        <PressureSkeleton />
                      ) : (
                        <Pressure
                          hasThrottlePressureScore={hasThrottlePressureScore}
                          data={indicatorsPressure}
                          throttlePressureScore={throttlePressureScore}
                          penalty={penalty}
                          pressureGasPedal={pressureGasPedal}
                        />
                      )}
                    </Grid>
                  </PermissionsGate>
                )}
                {/* estatisticas */}
                <PermissionsGate scopes={["can_view_dashboard_statistics"]}>
                  <Grid
                    item
                    xl={showThrottleGraphPressureScore > 0 || canViewDashboardGForce ? 9 : 12}
                    lg={showThrottleGraphPressureScore > 0 || canViewDashboardGForce ? 9 : 12}
                    md={showThrottleGraphPressureScore > 0 || canViewDashboardGForce ? 9 : 12}
                    sm={12}
                    xs={12}
                    data-cy="performanceEstatisticas"
                  >
                    <Typography variant="h4" className={classes.cardTitle} data-cy="h4Estatisticas">
                      Estatísticas
                    </Typography>
                    {loading.statistics || loading.percentages ? (
                      <CardsSkeleton />
                    ) : (
                      <StatisticsCards
                        indicatorsPressure={
                          Object.keys(indicatorsPressure).length > 0 &&
                          indicatorsPressure?.higPressure?.percentage > 0
                        }
                        statistics={statistics}
                      />
                    )}
                  </Grid>
                </PermissionsGate>
                {/* trajeto da viagem */}
                <PermissionsGate scopes={["can_view_dashboard_map"]}>
                  <Grid
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    data-cy="divPerformanceTrajetoViagem"
                  >
                    <Typography variant="h4" className={classes.cardTitle} data-cy="h4TituloTrajetoViagem">
                      Trajeto da viagem
                    </Typography>
                    {loading.locations ? <MapSkeleton /> : map}
                  </Grid>
                </PermissionsGate>
              </>
            )}
          </Grid>
        )}
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item xs={12} sm={12} md={2} lg={2} xl={2}></Grid>
          {state?.screen && size.mobile ? (
            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
              <Button
                fullWidth
                variant="contained"
                className={classes.btnBack}
                onClick={() => {
                  if (state.screen !== "bonus") {
                    dispatch(setStepDeduct());
                  } else {
                    dispatch(setStepDeductBonus());
                  }
                  history.goBack();
                }}
              >
                Voltar
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </PermissionsGate>
    </Aux>
  );
}
