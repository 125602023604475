import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  indicators: {
    paddingBottom: theme.spacing(2),
    display: 'flex',
    overflowX: 'auto',
    gap: theme.spacing(2),
    maxWidth: '100%',
  },
  card: {
    borderRadius: 28,
    padding: theme.spacing(2),
    minWidth: 140,
    position: 'relative',
  },
  cardTitle: {
    fontSize: '14px',
    lineHeight: 1.5,
    textAlign: 'center',
  },
}));
