import * as yup from "yup";

const passwordValidator = (password) => {
  const hasNumber = /[0-9]/.test(password);
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasSpecialChar = /[!@#\$%\^&\*]/.test(password);
  const isLongEnough = password.length >= 8;

  if (!hasNumber) {
    return false;
  }
  if (!hasUpperCase) {
    return false;
  }
  if (!hasLowerCase) {
    return false;
  }
  if (!hasSpecialChar) {
    return false;
  }
  if (!isLongEnough) {
    return false;
  }
  
  return true;
};

export default yup.object().shape({
  name: yup.string().required("Campo obrigatório"),
  email: yup.string().email('O campo deve ser um e-mail válido').required("Campo obrigatório"),
  password: yup
    .string()
    .required('Campo obrigatório')
    .test('is-strong-password', "Deve conter 8 caracteres, uma maiúscula, uma minúscula, um número e um caractere especial", 
      (value) => passwordValidator(value) === true
    ),
  role: yup
    .object()
    .shape({
      value: yup.string().required(),
      label: yup.string().required(),
    })
    .nullable()
    .required("Campo obrigatório"),
});
