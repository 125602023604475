import api from "./api";

export const getDownload = async (url) => {
  try {
    const response = await api.get(url);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getDownloadWithParams = async (url, data) => {
  try {
    const response = await api.get(url, data);
    return response;
  } catch (err) {
    throw err;
  }
};