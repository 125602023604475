import React from 'react';
import {
  Card,
  Typography,
  Grid,
  Box,
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import dayjs from 'dayjs';

import { notifications } from '../../../constants/tabs-notications';
import { ExclamationIcon } from '../../ExclamationIcon';
import { WrongChatIcon } from '../../WrongChatIcon';
import { CheckIcon } from '../../CheckIcon';
import { ChatIcon } from '../../ChatIcon';

// styles
import useStyles from "../styles";

const NotificationCard = ({ title, subtitle, publishAt, type, read }) => {
  const classes = useStyles();

  const formatDefault = 'DD/MM/YYYY HH:mm';

  const getIcon = () => {
    if (read) return <></>;
    if (type === notifications.alert) {
      return (
        <ExclamationIcon id={'ExclamationIconNoticationCard'} className={classes.notificationSvg} />
      )
    }
    if (type === notifications.urgent) {
      return (
        <WrongChatIcon id={'WrongChatIconNotificationCard'} className={classes.notificationSvg} />
      )
    }
    if (type === notifications.tips) {
      return (
        <CheckIcon id={'CheckIconNoticationCard'} className={classes.notificationSvg} />
      )
    }
    if (type === notifications.communication) {
      return (
        <ChatIcon id={'ChatIconNoticationCard'} className={classes.notificationSvg} iconColor='#7131C4'/>
      )
    }
    if (type === notifications.message) {
      return (
        <ChatIcon id={'ChatIconNoticationCard'} className={classes.notificationSvg} />
      )
    }
  }

  const getColors = (to) => {
    if (type === notifications.alert) {
      if (to === 'icon') return '#FFC400'
      return to === 'border' ? '#FFEDC0' : '#FFF4D8'
    }
    if (type === notifications.urgent) {
      if (to === 'icon') return '#FF507A'
      return to === 'border' ? '#FFC8C7' : '#FFD9D8'
    }
    if (type === notifications.tips) {
      if (to === 'icon') return '#31C440'
      return to === 'border' ? '#CBFFBE' : '#EAFFE5'
    }
    if (type === notifications.message) {
      if (to === 'icon') return '#0D69D7'
      return to === 'border' ? '#689CF9' : '#98C7FF'
    }
    if (type === notifications.communication) {
      if (to === 'icon') return '#7131C4'
      return to === 'border' ? '#BE8FFB' : '#DABEFF'
    } else {
      if (to === 'icon') return '#0D69D7'
      return to === 'border' ? '#689CF9' : '#98C7FF'
    }
  }


  return (
    <Card sx={{ height: 'auto', width: '98%', border: `2px solid ${getColors('border')}`, boxShadow: '0px 1px 6px 0px #0000000F', marginBottom: 2, paddingRight: 1, borderRadius: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={2} sx={{
          height: 'auto',
          maxWidth: 48,
        }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: '100%',
              maxWidth: 48,
              backgroundColor: getColors()
            }}
          >
            {getIcon()}
          </Box>
        </Grid>
        <Grid item xs={9} style={{ padding: '20px 0', position: 'relative' }}>
          <Typography className={classes.notificationTitle} style={{ fontSize: 19, fontWeight: 700, marginBottom: '8px' }}>{title}</Typography>
          <Typography className={classes.notificationTitle} style={{ fontSize: 16 , fontWeight: 400, marginBottom: '8px' }}>{subtitle}</Typography>
          <Typography variant="body2" style={{ position: 'absolute', top: 25, right: -15 }}>({dayjs(publishAt).format(formatDefault)})</Typography>
        </Grid>
        <Grid item xs={1} style={{ display: 'flex', alignItems: 'center' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <ArrowForwardIosIcon fontSize="small" style={{ margin: 'auto', color: getColors('icon') }} />
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

export default NotificationCard;
