import React from 'react';
import { Box, Typography } from '@mui/material';

export function Row({
  index,
  style,
  item,
  columns,
  visibleColumns,
  firstColumnWidth,
  remainingWidth,
  classes
}) {
  if (!item) return null;

  const displayedColumns = visibleColumns
    .filter(col => col.display)
    .map(visibleCol => {
      const columnDetails = columns.find(col => col.name === visibleCol.name);
      return {
        ...visibleCol,
        ...columnDetails,
      };
    });

  return (
    <div
      style={{
        ...style,
        display: 'flex',
        alignItems: 'center',
      }}
      className={classes.row}
    >
      {displayedColumns.map((column, colIndex) => (
        <Box
          key={colIndex}
          width={colIndex === 0 ? firstColumnWidth : `${remainingWidth}%`}
          className={classes.cell}
        >
          {column.options && column.options.customBodyRender ? (
            column.options.customBodyRender(item[column.name], { rowIndex: index })
          ) : (
            <Typography variant="body2">{item[column.name]}</Typography>
          )}
        </Box>
      ))}
    </div>
  );
}
