import { enc } from 'crypto-js';

export function generateRandomBase64(size) {
  const array = new Uint8Array(size);
  
  window.crypto.getRandomValues(array);

  const wordArray = enc.Utf8.parse(String.fromCharCode(...array));

  return enc.Base64.stringify(wordArray);
}