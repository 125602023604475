import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  Paper,
} from "@mui/material";
import { toast } from "react-toastify";
import dayjs from "dayjs";

// store
import { useAppSelector } from "store";

//services
import { getVehiclesByOperation } from "services/vehicle";
import { getTelemetryData } from "services/telemetry";

// components
import Aux from "hoc/auxiliar";
import TelemetryDataSkeleton from "components/Skeletons/TelemetryDataSkeleton";
import PageToolbar from "components/PageToolbar";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";
import TableResponsive from "components/TableResponsive";

// helpers
import { orderVehiclesByCrescent } from "helpers/vehicles";

import { tableColumns } from "./config/tableColumns";

// hooks
import { useWindowSize } from "hooks/useWindowsSize";

// styles
import useStyles from "./styles";

export default function TelemetryData() {
  const { currentCustomer } = useAppSelector((state) => state.global.user);
  const { state } = useLocation();
  const classes = useStyles();
  const size = useWindowSize()
  const dateFormatTemplate = "YYYY-MM-DDTHH:mm:ssZZ";
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [vehicles, setVehicles] = useState([]);
  const [loadingVehicles, setLoadingVehicles] = useState(false);
  const [loading, setLoading] = useState({
    vehicles: true,
    data: true,
  });
  const [vehiclesId, setVehiclesId] = useState([]);
  const [selectedDates, setSelectedDates] = useState({
    initialDate: dayjs().format("YYYY-MM-DDT00:00:00ZZ"),
    finalDate: dayjs().format("YYYY-MM-DDT23:59:59ZZ"),
  });
  const [data, setData] = useState([]);
  const [infoBox, setInfoBox] = useState(false);
  const [hasGNV, setHasGNV] = useState(false);
  const columns = tableColumns(size, hasGNV);

  // Responsavel por pegar as datas no componente pageToolbar.
  const handleSelectDate = (initialDate, finalDate) => {
    setSelectedDates({
      initialDate: dayjs(initialDate).format(dateFormatTemplate),
      finalDate: dayjs(finalDate).format(dateFormatTemplate),
    });
  };

  const getVehicleIdFilter = (selectedVehicle) => {
    let selected = [];
    vehicles.forEach((operation) => {
      operation.vehicles.forEach((ident) => {
        if (ident.id === selectedVehicle[0].id) {
          selected = [{
            identification: ident.currentDevice?.identification,
            id: ident.id,
          }];
        }
      });
    });
    setVehiclesId(selected);
  };

  //Função esta colocada no useEffect. Toda vez que a lista de veiculos ou data forem alterados, irar fazer uma nova busca na api conforme dados selecionados.
  const fetchData = async (vehicleList, initialDate, finalDate) => {
    setLoading((oldState) => ({ ...oldState, data: true }));
    try {
      const vehicle = vehicleList.map((vehicle) => vehicle.id);
      const response = await getTelemetryData(vehicle[0], initialDate, finalDate);
      if (response.status !== 200) {
        throw new Error();
      }
      if (response.data.data) {
        let checkGNV = false;
        const mappedData = response.data.data.map((row, idx) => {
          if (row?.total_fuel_consumption_gaseous) {
            checkGNV = true; // verifica dessa forma pois pode ser que a configuração de combustível do veículo tenha mudado
          }
          return {
            id: idx,
            ...row,
          };
        });
        setHasGNV(checkGNV);
        mappedData.splice(-1);
        setData(mappedData);
      }
    } catch (err) {
      toast.error(
        "Erro ao carregar dados de conectividade. Entre em contato com o suporte.",
      );
    } finally {
      setLoading((oldState) => ({ ...oldState, data: false }));
    }
  };

  const fetchVehicles = useCallback(async () => {
    setLoading((oldState) => ({ ...oldState, vehicles: true }));
    try {
      const response = await getVehiclesByOperation(currentCustomer);

      if (state && response.data.customers) {
        const returnData = response.data.customers;
        const foundIdVehicle = returnData
          .map((o) =>
            o.vehicles.filter((truck) => truck.id === state.vehicleId.id),
          )
          .reduce((last, now) => last.concat(now))
          .reduce((_last, now) => now);
        setVehiclesId([
          {
            identification: foundIdVehicle.currentDevice?.identification,
            driverName: foundIdVehicle.currentDriver?.driverName,
            id: foundIdVehicle.id,
          },
        ]);
      } else {
        setVehiclesId([]);
      }

      let orderedVehiclesByCustomer = response.data?.customers.sort(
        (a, b) => (a.name > b.name) - (a.name < b.name)
      );

      orderedVehiclesByCustomer.map((option, i) => {
        const optionVehicles = option.vehicles.filter(item => {
          const deviceId = item?.currentDevice?.identification;
          return item?.devicesHistory !== null && deviceId && deviceId.trim() !== '';
        });

        orderedVehiclesByCustomer[i].vehicles = orderVehiclesByCrescent(optionVehicles);
      });

      setVehicles(orderedVehiclesByCustomer);
    } catch (err) {
      toast.error(
        "Erro ao carregar lista de Veículos. Entre em contato com o suporte.",
      );
    } finally {
      setLoading((oldState) => ({ ...oldState, vehicles: false }));
    }
  }, [state, currentCustomer]);

  const handleChangeVisibleInfoBox = () => {
    if (!infoBox) return setInfoBox(true);
  };

  useEffect(() => {
    if (currentCustomer && hasPermission({ scopes: ['can_view_telemetrydata'] })) {
      fetchVehicles();
    }
  }, [currentCustomer, fetchVehicles]);

  useEffect(() => {
    setLoadingVehicles(vehiclesId.length > 0);
    if (vehiclesId.length > 0) {
      fetchData(vehiclesId, selectedDates.initialDate, selectedDates.finalDate);
    }
  }, [vehiclesId, selectedDates]);

  return (
    <Aux>
      <PermissionsGate scopes={['can_view_telemetrydata']}>
        <PageToolbar
          handleSelectDate={handleSelectDate}
          selectedDates={selectedDates}
          listData={vehicles}
          selectedData={vehiclesId}
          handleSelectedData={getVehicleIdFilter}
          menuCalendar
          vehiclesMenuButton
          isSingleMode
          initialDate={selectedDates.initialDate}
          finalDate={selectedDates.finalDate}
          detailedSelect
          loading={loading.vehicles}
        />
        {!loadingVehicles && !infoBox ? (
          <Grid container className={classes.containerInfobox}>
            <Paper elevation={0} className={classes.paper}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Box
                    fontFamily="fontFamily"
                    justifyContent="center"
                    fontSize="h3.fontSize"
                    textAlign="center"
                    lineHeight={2}
                    className={classes.infoBox}
                    p={4}
                  >
                    Para gerar as informações da sua frota, selecione um veículo{" "}
                    <strong>
                      clicando no filtro localizado no menu acima.
                    </strong>
                  </Box>
                  <Grid item className={classes.containerButton}>
                    <Button
                      onClick={() => handleChangeVisibleInfoBox()}
                      variant="contained"
                      className={classes.okButton}
                    >
                      Ok
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        ) : (
          <Grid container spacing={1} className={classes.container}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box>
                <Grid item xl={12} xs={12} className={classes.table}>
                  {loading?.data ?
                    <TelemetryDataSkeleton /> :
                    <TableResponsive
                      data={data}
                      columns={columns}
                      tableName="list-telemetry"
                      pointer={true}
                      setRowsPerPage={setRowsPerPage}
                      options={{
                        rowsPerPageOptions: [5, 15, 25, 50, 100, 200, 500, 1000, 5000],
                        download: true,
                        rowsPerPage: rowsPerPage,
                        setRowProps: (row, index) => {
                          return { style: { backgroundColor: "white", cursor: 'default', width: '100%' } }
                        },
                      }}
                    />
                  }
                </Grid>
              </Box>
            </Grid>
          </Grid>
        )}
      </PermissionsGate>
    </Aux>
  );
}