export const statusFilterHelper = [{
  id: 'scheduled',
  name: 'Agendada',
},
{
  id: 'published',
  name: 'Enviada',
},
{
  id: 'canceled',
  name: 'Cancelada',
}];

export const typeFilterHelper = [{
  id: 'communication',
  name: 'Comunicados',
},
{
  id: 'message',
  name: 'Mensagens',
},
{
  id: 'tip',
  name: 'Dicas',
},
{
  id: 'alert',
  name: 'Alertas'
},
{
  id: 'urgent',
  name: 'Urgentes'
}];

export const channelFilterHelper = [{
  id: 'app',
  name: 'Aplicativo',
},
{
  id: 'web',
  name: 'Web',
}];