import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  email: null
};

export const recoveryEmailSlice = createSlice({
  name: 'recoveryEmail',
  initialState,
  reducers: {
    saveRecoveryEmail: (state, action) => {
      state.email = action.payload
    },
    clearRecoveryEmail: (state, action) => {
      state.email = initialState.email
    }
  },
});

// Action creators are generated for each case reducer function
export const { saveRecoveryEmail, clearRecoveryEmail } = recoveryEmailSlice.actions;

export default recoveryEmailSlice.reducer;
