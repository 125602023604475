import api from "./api";
import { convertStringDate } from "helpers/functions";

export const getFleetEvolutive = async (vehicleIds, startDate, dateType, customer) => {
  const convertedDates = convertStringDate(startDate);
  const params = `vehicle_ids=${vehicleIds}&start_date=${convertedDates.startDate}&date_type=${dateType}&customer_id=${customer}`
  try {
    const response = await api.get(`/fleet-evolutive?${params}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const postFleetEvolutive = async (model) => {
  try {
    const response = await api.post('fleet-evolutive/order', model)
    return response;
  } catch (err) {
    throw err;
  }
};