import { useController, useFormContext } from "react-hook-form";
import { Box, Chip, Select, InputLabel, FormControl, MenuItem } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

const ITEM_HEIGHT = 34;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 8 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const StyledSelect = styled(Select)(({ theme }) => ({
  '&.Mui-disabled': {
    backgroundColor: theme.palette.grey[200],
  }
}));

const ChipsBox = styled(Box)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: 1
}));

const StyledCloseIcon = styled(CloseIcon)(() => ({
  width: 16,
  height: 16,
  path: {
    fill: '#424448'
  },
  '&:hover': {
    path: { 
      fill: '#de350b'
    }
  }
}));

const CustomSelect = ({
  placeholder = "",
  options = [],
  placeholderEmpty = "Sem dados",
  onDelete,
  ...props 
}) => {
  const { id = "custom-select" } = props;

  const { control } = useFormContext();

  const { field: { value = "", ...rest } } = useController({
    name: props.name,
    control,
  });

  const labelId = `${id}-label`;

  const renderChipsBox = props.multiple && ((selected) => (
    <ChipsBox>
      {selected.map((value) => {
        const selectedOption = options.find(option => option.value === value);
        
        return (
          <Chip
            key={selectedOption?.value}
            value={value}
            label={selectedOption?.label}
            onDelete={onDelete && (() => onDelete(value))}
            onMouseDown={(event) => event.stopPropagation()}
            deleteIcon={
              <StyledCloseIcon />
            }
          />
        );
      })}
    </ChipsBox>
  ));

  return (
    <FormControl fullWidth>
      <InputLabel id={labelId}>{placeholder}</InputLabel>
      <StyledSelect
        {...rest}
        value={value}
        {...props}
        labelId={labelId}
        label={placeholder}
        style={{ borderRadius: 20 }}
        renderValue={renderChipsBox}
        MenuProps={MenuProps}
      >
        {!options?.length && (
          <MenuItem disabled value="">{placeholderEmpty}</MenuItem>
        )}
        {options?.map((option) => (
          <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  );
};

export default CustomSelect;
