import { Grid, Skeleton } from "@mui/material";

const PerformanceSkeleton = () => {  
  return (
    <Grid display="flex" flexDirection="column" alignItems="center" justifyContent="center" flex={1} gap={4}>
      <Grid item xl={12} lg={12}>
        <Skeleton variant="circular" width={145} height={145} />
      </Grid>
      <Grid item xl={12} lg={12}>
        <Skeleton variant="rectangular" width={130} height={25} />
      </Grid>
    </Grid>
  );
};

export default PerformanceSkeleton;
