import React from 'react';
import PropTypes from 'prop-types';

export const renderScale = (scale) => {
  const scales = {
    xxs: 4,
    xs: 8,
    s: 12,
    m: 16,
    l: 20,
    xl: 24,
    xxl: 32,
  };

  const getScale = (scale) => {
    const index =
      window.innerWidth > 870
        ? Object.keys(scales).indexOf(scale)
        : Object.keys(scales).indexOf(scale) - 1;
    return scales[Object.keys(scales)[index]] || '';
  };

  return getScale(scale)
};

renderScale.propTypes = {
  scale: PropTypes.oneOf(['xxs', 'xs', 's', 'm', 'l', 'xl', 'xxl']),
};
