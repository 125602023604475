import React from 'react'
import { useHistory } from "react-router-dom"
import { Grid, Button, Box, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { useQuery } from "react-query"
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";

// store
import { useAppSelector } from 'store';
import { setGlobalUser } from 'store/globalSlice';

// services
import { getOperationTypes } from 'services/operationType';
import { getCustomersList, createCustomer } from 'services/customers';

// helpers
import { makeSelectOptions } from 'helpers/makeSelectOptions';
import makeDefaultValues from 'helpers/makeDefaultValues';
import { errorsMessages } from 'helpers/errors';

// components
import Aux from 'hoc/auxiliar';
import { CustomerForm } from "components/CustomerForm";
import BackButton from "components/BackButton";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";

import createSchemaValidation from "domain/validation/yup/customerValidationForm";
import fields from "domain/forms/customerDefaultValues";

// styles
import useStyles from './styles';

const CreateCustomer = () => {
  const { email, currentCustomer } = useAppSelector((state) => state.global.user);
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch();
  const defaultValues = makeDefaultValues({ fields, data: { operations: [] } });
  const methods = useForm({ defaultValues, resolver: yupResolver(createSchemaValidation) });
  const {
    data,
    isLoading: isLoadingOperationTypes,
    isError: isErrorOperationTypes
  } = useQuery(["operationTypes", currentCustomer], async () => {
    if (hasPermission({ scopes: ['can_create_customers'] })) {
      const response = await getOperationTypes()
      const options = makeSelectOptions({
        data: response.data.operationTypes,
        keyValue: "id",
        keyLabel: "name",
      });
      return options;
    }
    return {};
  });

  const getCustomers = async () => {
    const responseCustomer = await getCustomersList(email);
    if (responseCustomer.status === 200 || responseCustomer.status === 201) {
      let customerList = responseCustomer.data.data
        ? responseCustomer.data.data.sort((a, b) => a - b)
        : [];

      dispatch(setGlobalUser({ listCustomers: customerList }));
      toast.success("Cliente criado com sucesso!");
      methods.reset(defaultValues);
      history.push("/gofurther/customer/customer-list");
    }
  };

  const onSubmit = async (data) => {
    const { name, document, period, startingDay, finishingDay, operations, note } = data;

    try {
      const customerModel = {
        name,
        document,
        period: period.value,
        starting_day: startingDay.value,
        finishing_day: finishingDay.value,
        operations: operations.map((operation) => ({ id: operation.value })),
        note
      };

      const response = await createCustomer(customerModel);

      if (response.status !== 200) {
        toast.error("Erro ao criar cliente!");
      } else if (response.data.response && response.data.response[0].errors) {
        response.data.response[0].errors.forEach((error) => {
          methods.setError(errorsMessages[error.field].type, { type: 'custom', message: errorsMessages[error.field].message });
        });
      } else {
        getCustomers();
      }
    } catch (err) {
      toast.error("Erro ao criar cliente. Entre em contato com o suporte.");
    }
  };

  if (isErrorOperationTypes) {
    history.push("/gofurther/customer/customer-list");
    return toast.error("Não foi possível buscar o grupo de bonificação. Contate o suporte.");
  }

  return (
    <Aux>
      <PermissionsGate scopes={['can_create_customers']}>
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box>
              <Grid item xl={12} xs={12} className={classes.table}>
                <FormProvider {...methods}>
                  <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Typography variant="h1" className={classes.title}>Cadastro de cliente</Typography>
                    <CustomerForm
                      operationTypeOptions={data}
                      loading={isLoadingOperationTypes}
                    />
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Grid container justifyContent="flex-end" spacing={2}>
                        <Grid container item justifyContent="flex-end" xl={12} lg={12} md={12} sm={12} xs={12}>
                          <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                            <Box sx={{ m: 2 }}>
                              <BackButton className={classes.backButton} />
                            </Box>
                          </Grid>
                          <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                            <Box sx={{ m: 2 }}>
                              <Button
                                fullWidth
                                variant="contained"
                                type="submit"
                                className={classes.btConfirm}
                              >
                                Salvar
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                </FormProvider>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </PermissionsGate>
    </Aux>
  );
};

export default CreateCustomer;
