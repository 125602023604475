import React from "react";
import { Box, Skeleton } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

const LinkDriversSkeleton = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Skeleton
        variant="rectangular"
        width={280}
        height={35}
        className={classes.title}
      />
      <Skeleton
        variant="rectangular"
        width={270}
        height={35}
        className={classes.tableButtons}
      />
      <Skeleton
        variant="rectangular"
        width='100%'
        height={650}
        className={classes.table}
      />
      <Skeleton
        variant="rectangular"
        width='100%'
        height={35}
        className={classes.scrollTable}
      />
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  container: { 
    display: "grid", 
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  title: {
    marginTop: theme.spacing(4),
  },
  tableButtons: { 
    marginTop: theme.spacing(2),  
    display: "flex", 
    justifySelf: "flex-end",
    borderRadius: "10px",
  },
  table: { 
    marginTop: theme.spacing(2), 
    borderRadius: "10px"
  },
  scrollTable: { 
    marginTop: "2px" 
  }
}));

export default LinkDriversSkeleton;
