import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  fullWidth: {
    width: '100%',
  },
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  rootAccordion: {
    width: '100%',
    '& .MuiAccordionDetails-root': {
      padding: 0
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '10px 0'
    },
    '& .MuiFormControlLabel-root': {
      marginBottom: 0
    }
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  permission: {
    fontSize: '14px',
  },
  permissionTop: {
    marginLeft: '-1px'
  },
  submenu: {
    paddingLeft: theme.spacing(2),
  },
  subheading: {
    fontSize: theme.typography.pxToRem(15),
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: theme.typography.pxToRem(12)
  }
}));
