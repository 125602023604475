import * as yup from "yup";

export default yup.object().shape({
  name: yup.string().required("Campo obrigatório"),
  customControll: yup
    .array()
    .min(1, "Cadastre ao menos um indicador")
    .required("Cadastre ao menos um indicador"),
  customIndicatorsList: yup.array().when("customControll", {
    is: (value) => !value,
    then: yup.array().of(
      yup.object().shape({
        conduction: yup
          .object()
          .shape({
            value: yup.string().required("Campo Obrigatório"),
            label: yup.string().required(),
          })
          .nullable()
          .required("Campo Obrigatório"),
        idealPercentage: yup
          .string()
          .max(3, "Limite excedido")
          .required("Campo obrigatório"),
        acceptablePercentage: yup
          .string()
          .max(3, "Limite excedido")
          .required("Campo Obrigatório"),
        weight: yup
          .string()
          .required()
          .max(3, "Limite excedido")
          .required("Campo Obrigatório"),
      }),
    ),
    otherwise: yup.array(),
  }),
  
});
