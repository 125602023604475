import React from "react";
import { trunkSVG } from "./variables";
import { color_blue_400, color_green_300, color_neutral_200, color_neutral_500, color_red_500, color_yellow_500 } from '../../../themes/colors';

import "./clusterSingle.css";

export const colorMaps = {
  'blue': color_blue_400,
  'green': color_green_300,
  'yellow': color_yellow_500,
  'gray': color_neutral_500,
  'white': color_neutral_200,
  'red': color_red_500
}

const ClusterSingle = ({
  title,
  color,
  onClick
}) => {

  return (
    <div
      className={"ClusterSingle-container"}
      style={{ backgroundColor: colorMaps[color] }}
      onClick={onClick}
    >
      <div className={"ClusterSingle-border"} />
      <span className={"ClusterSingle-item-icon"}>{trunkSVG}</span>
      <span className={"ClusterSingle-item-typography"}>
        {title}
      </span>
    </div>
  );
};

export default ClusterSingle;
