import React, { useState, useEffect } from "react";
import { Grid, FormHelperText } from "@mui/material";
import { styled } from "@mui/material/styles";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useFormState, useFormContext, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';

// services
import { getDevices } from "services/device";

// components
import Select from "components/react-hook-form/select";

import CustomLayout from "./customLayout";

// styles
import useStyles from "./styles";

dayjs.locale('pt-br');

const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  width: '100%',
  '.MuiInputBase-root': {
    borderRadius: 20,
  }
}));

const LinkDeviceForm = () => {
  const classes = useStyles();

  const [devices, setDevices] = useState([]);

  const { control } = useFormContext();
  
  const { errors } = useFormState({ control });

  const fetchDevices = async () => {
    try {
      const response = await getDevices();
      const filteredResponse = response.data.devices.filter((item) => item.status === 1)
      const formatedResponse = filteredResponse.map((item) => ({
      value: item.id,
      label: item.identification
      }));  
      setDevices(formatedResponse); 
    } catch (err) {
      toast.error(
        "Erro ao carregar lista de unidades eletrônicas. Entre em contato com o suporte.",
      );
    }
  };

  useEffect(() => {
    fetchDevices();
  }, []);
  
  return ( 
    <Grid container spacing={2}>
      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Controller 
            control={control}
            name="initialDate"
            placeholder="Data Inicial"
            defaultValue={dayjs()}
            render={({ field: { ref, ...fieldProps }}) => (
              <StyledDatePicker 
                {...fieldProps} 
                label="Data Inicial" 
                format="DD/MM/YYYY HH:mm"
                helperText={errors.initialDate?.message}
                error={!!errors.initialDate}
                slots={{
                  layout: CustomLayout
                }}
              />
            )}
          />
        </LocalizationProvider>
      </Grid> 
      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
        <Select
          options={devices}
          name="devices"
          placeholder="Selecione a unidade eletrônica"
        />
        <FormHelperText className={classes.formHelperText} error={true}>
          {errors.devices?.message}
        </FormHelperText>
      </Grid>
    </Grid>
  );
};

export default LinkDeviceForm;
