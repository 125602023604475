import React from "react";
import {
    Card,
    Typography,
    Grid,
    Box
} from '@mui/material';
import colors from '../../../themes/gobrax';
import { notifications } from '../../../constants/tabs-notications';

export default function Badge({ type }) {
    let typeName = "Mensagem";
    if (type === notifications.communication) {
        typeName = "Comunicado"
    } else if (type === notifications.message) {
        typeName = "Mensagem"
    } else if (type === notifications.tips) {
        typeName = "Dica"
    } else if (type === notifications.alert) {
        typeName = "Alerta"
    } else if (type === notifications.urgent) {
        typeName = "Urgente"
    }

    return (
        <Box sx={{
            border: `1px solid ${colors.notification[type].borderColor}`,
            borderRadius: 1,
            padding: '2px 4px',
            backgroundColor: colors.notification[type].backgroundColor,
            width: '100px'
        }}>
            <Typography style={{
                fontWeight: 500, color: colors.notification[type].textColor, textAlign: 'center', fontSize: 14
            }}>{typeName}</Typography>
        </Box>
    )
};