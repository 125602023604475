import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography
} from "@mui/material";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

import Aux from "hoc/auxiliar";
import BackButton from "components/BackButton";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";

import { getVehicleProfile } from "services/profiles";
import { getBrands } from 'services/vehicle';

import useStyles from "./styles";
import "react-datepicker/dist/react-datepicker.css";

const ViewProfile = () => {
  const classes = useStyles();
  const { profileId } = useParams();
  const [ profile, setProfile ] = useState(null);

  const getInitialInputsState = (getValues) => {
    const fieldsToFill = [
      { name: 'whiteRangeStart', title: 'Branca' },
      { name: 'extraEconomicRangeStart', title: 'Início faixa verde' },
      { name: 'economicRangeStart', title: 'Final faixa verde' },
      { name: 'powerRangeStart', title: 'Acelerando acima do verde' },
      { name: 'engineBrakeRangeStart', title: 'Freio motor' },
      { name: 'redRangeStart', title: 'Vermelha' },
      { name: 'redRangeEnd', title: 'Final vermelha' },
    ];
  
    return fieldsToFill.map(field => {
      const value = getValues[field.name] || '';
      return { ...field, value };
    });
  };

  const fetchProfileDetail = async () => {
    try {
      const responseProfile = await getVehicleProfile(profileId);
      const { profile } = responseProfile.data.data;
      const rpmData = getInitialInputsState(profile);
      const responseBrands = await getBrands();
      const brand = responseBrands.data?.data?.brands?.find((elm) => elm.id === profile.brandId);
      setProfile({
        brandName: brand?.name || ' - ',
        modelName: profile?.modelName || ' - ',
        rpmData: rpmData || []
      })
    } catch (error) {
      toast.error(
        "Erro ao buscar dados do Perfil. Verifique com o suporte",
      );
    };
  };

  useEffect(() => {
    if (profileId && hasPermission({ scopes: ['can_view_profiles_without_edit'] })) {
      fetchProfileDetail();
    }
  }, [profileId])

  return (
    <Aux>
      <PermissionsGate scopes={['can_view_profiles_without_edit']}>
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box>
              <Grid item xl={12} xs={12}>
                <Typography variant="h1" className={classes.titleMain}>Visualizar perfil de veículo</Typography>
                <Paper elevation={2} className={classes.container}>
                  <Grid container spacing={4}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Grid container>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <Typography className={classes.title}>Vínculo marca/perfil</Typography>
                        </Grid>
                        <Grid container>
                          <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                            <Box sx={{ m: 2 }}>
                              <Typography><strong>Marca: </strong> {profile?.brandName || ' - '}</Typography>
                            </Box>
                          </Grid>
                          <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                            <Box sx={{ m: 2 }}>
                              <Typography><strong>Perfil: </strong> {profile?.modelName || ' - '}</Typography>
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <Box sx={{ mt: 2 }}>
                            <Typography className={classes.title}>Faixas de RPM</Typography>
                          </Box>
                        </Grid>
                        <Box sx={{ m: 2 }}>
                          <Grid container>
                            <Grid item xl={1} lg={1} md={1} sm={3} xs={12} className={classes.item}>
                              <Typography><strong>Lenta: </strong> 0</Typography>
                            </Grid>
                            {profile?.rpmData && profile?.rpmData.map((item, index) => (
                              <Grid item xs={12} className={classes.item} key={index}>
                                <Typography><strong>{item.title}: </strong> {item.value}</Typography>
                              </Grid>
                            ))}
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Grid container justifyContent="flex-end" spacing={2}>
                    <Grid item container justifyContent="flex-end" xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                        <Box sx={{ m: 2 }}>
                          <BackButton className={classes.backButton} />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </PermissionsGate>
    </Aux>
  );
};

export default ViewProfile;
