import React from "react";
import {
    Box,
    Dialog,
    DialogTitle,
    Grid,
    IconButton,
    List,
    ListItem,
    Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

// hooks
import { useWindowSize } from "hooks/useWindowsSize";

import useStyles from "./styles";

export default function NewFeatureModal({ title, description, image, topics, handleClose, open }) {
  const classes = useStyles();
  const size = useWindowSize()
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="new-feature-dialog-title"
      aria-describedby="new-feature-dialog-description"
      maxWidth={"lg"}
      fullWidth
      fullScreen={size.mobile}
      PaperProps={{ classes: { root: classes.dialogBase } }}
      scroll="paper"
    >
      <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
          size="large">
          <CloseIcon />
      </IconButton>
      <Box className={classes.contentBox}>
        <DialogTitle className={classes.title}>{title}</DialogTitle>
        <Typography variant="h3" className={classes.description}>
          {description}
        </Typography>
        <div className={classes.gridRoot}>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={7}>
              <img src={image} alt="Controle de diesel" style={{ maxWidth: '100%' }} />
            </Grid>
            <Grid item xs={12} md={5}>
              <List>
                {topics?.map((topic, index) => (
                  <ListItem key={index}>
                    <CheckCircleIcon className={classes.listIcon} />
                    <Typography className={classes.listItem}>
                      {topic.content}
                    </Typography>
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        </div>
      </Box>
    </Dialog>
  );
}