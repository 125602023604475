import React from "react";
import TooltipMUI, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

import "./tooltip.css";

const TooltipCustom = styled(({ className, ...props }) => (
  <TooltipMUI {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFFFFF",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 344,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    borderRadius: 16,
    padding: 8,
  },
}));

export default TooltipCustom;

TooltipCustom.propTypes = {};
