import { alpha } from "@mui/material";
import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  tableContent: {
    marginTop: theme.spacing(2)
  },
  btnNew: {
    padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
    borderRadius: 10,
    maxWidth: 300,
    backgroundColor: theme.palette.secondary.main,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: alpha(theme.palette.secondary.main, 0.8),
      fontWeight: 600
    },
  },
  infoDriverContainer: {
    borderRadius: 20,
    marginBottom: theme.spacing(4),
    marginLeft:(props)=> props?15: 0,
    marginTop:(props)=> props?5: theme.spacing(1),
  },
  buttonDetail: {
    textTransform: "inherit",
    fontSize: 16,
    fontWeight: "bold",
    borderRadius: 10,
    boxShadow: "none",
  },
  backButton: {
    backgroundColor: theme.palette.background.backgroundButton
  },
  label: {
    color: theme.palette.text.title,
    fontSize: 16,
    fontWeight: "bold",
  },
  driverName: {
    color: theme.palette.text.title,
    fontSize: 14,
    fontWeight: '400',
    marginLeft: theme.spacing(1 * 0.5)
  },
  contentDriver: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px !important',
  },
  btnNewCustomer: {
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: alpha(theme.palette.secondary.main, 0.8),
      fontWeight: 600
    },
  },
}));
