import api from './api';


export const getUserSelection = async (tableName, userId) => {
  try {
    const response = await api.get(`/usersel/${userId}/${tableName}`);
    return response.data;
  } catch (err) {
    return err;
  }
};

export const insertUserSelection = async (data, userId) => {
  try {
    const response = await api.post(`/usersel`, {
      userId: Number(userId),
      ...data,
    });
    return response;
  } catch (err) {
    return err;
  }
};

export const updateUserSelection = async (data, userId) => {
  try {
    const response = await api.put(`/usersel`, {
      userId: Number(userId),
      ...data,
    });
    return response;
  } catch (err) {
    return err;
  }
};