import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  chart: {
    paddingBottom: 0,
  },
  legendContent: {
    paddingBottom: 0,
    textAlign: "center",
    "& .MuiFormGroup-root": {
      justifyContent: "center",
    }
  },
}));
