export const defaultOperation = {
  id: 0,
  value: 0,
  name: "Toda minha frota",
  label: "Toda minha frota",
};

export const allSelected = {
  id: 0,
  value: 0,
  name: "Selecionar todos",
  label: "Selecionar todos",
};

export const emptyOperation = {
  id: -1,
  value: -1,
  name: "Selecione",
  label: "Selecione",
};


export const selectFilterOptions = [
  {
    id: 0,
    label: "Data de corte",
    name: "Data de corte",
    value: 0,
  },
  {
    id: 1,
    label: "Calendário",
    name: "Calendário",
    value: 1,
  },
];

export const defaultYearOption = {
  value: new Date().getFullYear(),
  label: new Date().getFullYear(),
};

