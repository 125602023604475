import { makeStyles } from "@mui/styles";

import Image from 'images/background-co2.jpg'; 

export default makeStyles((theme) => ({
  boxContent: {
    backgroundColor: theme.palette.primary.almostWhite,
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    borderRadius: 5,
    minHeight: "200px",
  },
  container: {
    padding: theme.spacing(3),
  },
  text: {
    fontWeight: "bold",
    fontSize: 16,
    fontFamily: theme.typography.fontFamily,
  },
  value: {
    color: theme.palette.success.main,
    fontSize: 20,
    fontWeight: "bold",
  },
  chartContainer: {
    maxWidth: "350px",
    minHeight: "490px",
    borderRadius: 5,
    background: "rgba(0,0,0,0.6)",
    color: theme.palette.primary.contrastText,
    padding: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  chart: {
    position: "relative",
    display: "flex",
    flexDirection:"column",
    alignItems: "center",
    justifyContent: "center",
    height: 230,
    width: '100%'
  },
  iconChart: { position: "relative", left: 55, top: 56 },
  responsiveContainer: { width: "100%", height: 300, position: "relative" },
  legendContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    fontFamily: "Bebas Neue",
  },
  labelCo2: { fontSize: 30, fontWeight: "bold" },
  paperContainer: {
    padding: '1rem',
    backgroundImage: `url(${Image})`,
    backgroundPosition: "center",
    minHeight: "700px",
  }, 
  wrapperLoading: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  textLoading: {
    fontWeight: 'bold'
  }
  }));
