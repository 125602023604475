import { groupBy } from "lodash";
import dayjs from "dayjs";
import minMax from 'dayjs/plugin/minMax';

dayjs.extend(minMax);

export const permissions = [
  {
    title: "Geral",
    id: "general",
    permissions: [
      {
        id: "can_select_customers",
        title: "Selecionar empresa",
      },
      /* {
        id: "can_view_coopernorte_payments",
        title: "Visualizar colunas de pagamentos (Coopernorte)",
      }, */
    ],
    children: null,
  },
  {
    title: "Premiação",
    id: "bonus",
    permissions: [],
    children: [
      {
        title: "Visualizar premiação",
        id: "view_bonus",
        permissions: [
          {
            id: "can_view_bonus",
            title: "Visualizar Premiação",
          },
        ],
        children: null,
      },
      {
        title: "Visualizar visão do app do motorista (antigo tempo real)",
        id: "view_real_time_data",
        permissions: [
          {
            id: "can_view_real_time_data",
            title: "Visualizar visão do app do motorista (antigo tempo real)",
          },
        ],
        children: null,
      },
      {
        title: "Relatório de viagens",
        id: "report_trips",
        permissions: [
          {
            id: "can_view_report_trip_indicators",
            title: "Visualizar indicadores gerais",
          },
          {
            id: "can_view_report_trip_table",
            title: "Visualizar tabela de viagens",
          },
        ],
        children: null,
      },
      {
        title: "Habilitar botão detalhes",
        id: "view_report_details_button",
        permissions: [
          {
            id: "can_view_report_details_button",
            title: "Habilitar botão detalhes",
          },
        ],
        children: null,
      },
    ],
  },
  {
    title: "Emissão de CO²",
    id: "co2",
    permissions: [
      {
        id: "can_view_co2",
        title: "Visualizar emissão de CO²",
      },
    ],
    children: null,
  },
  {
    title: "Frota",
    id: "fleet",
    permissions: [],
    children: [
      {
        title: "Análise individual",
        id: "dashboard",
        permissions: [
          {
            id: "can_view_dashboard",
            title: "Visualizar análise individual",
          },
          {
            id: "can_view_dashboard_performance",
            title: "Visualizar desempenho na análise individual",
          },
          {
            id: "can_view_dashboard_indicators",
            title: "Visualizar indicadores na análise individual",
          },
          {
            id: "can_view_dashboard_pressure",
            title: "Visualizar uso pressão do acelerador",
          },
          {
            id: "can_view_dashboard_statistics",
            title: "Visualizar estatísticas na análise individual",
          },
          {
            id: "can_view_dashboard_map",
            title: "Visualizar trajeto de viagem na análise individual",
          },
          {
            id: "can_view_dashboard_print",
            title: "Imprimir análise individual",
          },
          {
            id: "can_view_dashboard_chart",
            title: "Visualizar gráfico na análise individual",
          },
          {
            id: "can_view_dashboard_chart_bt_mobile",
            title: "Visualizar botão do gráfico na análise individual (mobile)",
          },
          {
            id: "can_view_diesel_control",
            title: "Visualizar botão de controle de diesel",
          },
          {
            id: "can_view_eco_roll_indicator",
            title: "Visualizar indicador Eco-roll",
          },
        ],
        children: null,
      },
      {
        title: "Comparativo de veículos",
        id: "comparison",
        permissions: [
          {
            id: "can_view_comparison",
            title: "Visualizar Comparativo de veículos",
          },
        ],
        children: null,
      },
      {
        title: "Evolutivo",
        id: "evolutive",
        permissions: [
          {
            id: "can_view_evolutive",
            title: "Visualizar evolutivo",
          },
        ],
        children: null,
      },
      {
        title: "Minha frota",
        id: "consolidated",
        permissions: [
          {
            id: "can_view_consolidated",
            title: "Visualizar minha frota",
          },
          {
            id: "can_view_consolidated_score",
            title: "Visualizar minha frota - Nota geral",
          },
          {
            id: "can_view_consolidated_statistics",
            title: "Visualizar minha frota - Estatísticas",
          },
          {
            id: "can_view_consolidated_indicators",
            title: "Visualizar minha frota - Indicadores",
          },
          {
            id: "can_view_consolidated_groups",
            title: "Visualizar minha frota - Grupos",
          },
          {
            id: "can_view_consolidated_brand",
            title: "Visualizar minha frota - Média por marca",
          },
          {
            id: "can_view_consolidated_risk",
            title: "Visualizar minha frota - Maiores riscos de sinistro",
          },
          {
            id: "can_view_consolidated_productivity",
            title: "Visualizar minha frota - Baixa produtividade",
          },
          {
            id: "can_view_consolidated_vehicles",
            title: "Visualizar minha frota - Média por veículo",
          },
        ],
        children: null,
      },
    ],
  },
  {
    title: "Mapa ao vivo",
    id: "liveMap",
    permissions: [
      {
        id: "can_view_liveMap",
        title: "Visualizar trajeto em tempo real",
      },
    ],
  },
  {
    title: "Gerenciamento",
    id: "maintenance",
    permissions: [],
    children: [
      {
        title: "Grupo de veículo",
        id: "operations",
        permissions: [
          {
            id: "can_view_operations",
            title: "Visualizar grupo de veículo",
          },
          {
            id: "can_create_operations",
            title: "Criar grupo de veículo",
          },
          {
            id: "can_edit_operations",
            title: "Editar grupo de veículo",
          },
          {
            id: "can_remove_operations",
            title: "Remover grupo de veículo",
          },
          {
            id: "can_view_minscore",
            title: "Visualizar nota mínima",
          },
          {
            id: "can_create_minscore",
            title: "Criar nota mínima",
          },
          {
            id: "can_edit_minscore",
            title: "Editar nota mínima",
          },
          {
            id: "can_edit_range",
            title: "Editar faixas (range)",
          },
          {
            id: "can_edit_extra_reward",
            title: "Editar premiação extra",
          },
          {
            id: "can_link_extra_reward_score",
            title: "Vincular premiação extra à nota",
          },
        ],
        children: null,
      },
      {
        title: "Vínculo veículo/motorista",
        id: "linkdrivers",
        permissions: [
          {
            id: "can_view_linkdrivers",
            title: "Visualizar vínculo veículo/motorista",
          },
          {
            id: "can_view_linkdrivershistory",
            title: "Visualizar histórico de vínculo veículo/motorista",
          },
          {
            id: "can_edit_linkdrivers",
            title: "Desvincular motorista no vínculo veículo/motorista",
          },
          {
            id: "can_edit_historic_linkdrivers",
            title: "Editar histórico de vínculos de motorista",
          },
          {
            id: "can_retroactive_link",
            title: "Pode vincular retroativamente",
          },
        ],
        children: null,
      },
    ],
  },
  {
    title: "Ranking",
    id: "ranking",
    permissions: [
      {
        id: "can_view_ranking",
        title: "Visualizar ranking",
      },
    ],
  },
  {
    title: "Notificações",
    id: "notifications",
    permissions: [],
    children: [
      {
        title: "Notificação",
        id: "notifications_data",
        permissions: [
          {
            id: "can_view_notifications",
            title: "Visualizar notificações",
          },
          {
            id: "can_create_notification",
            title: "Criar notificação",
          },
          {
            id: "can_edit_notification",
            title: "Editar notificação",
          },
          {
            id: "can_cancel_notification",
            title: "Cancelar notificação",
          },
          {
            id: "can_view_notification_history",
            title: "Visualizar histórico de notificações",
          },
          {
            id: "can_resend_notification",
            title: "Reenviar notificação",
          }
        ],
      },
    ],
  },
  {
    title: "Gestão do nível do abastecimento",
    id: "dieselControl",
    permissions: [
      {
        id: "can_view_dieselActualControl_menu",
        title: "Nível do tanque atual",
      },
      {
        id: "can_view_dieselControl_menu",
        title: "Histórico",
      },
    ],
  },
  {
    title: "Motorista",
    id: "drivers_follow",
    permissions: [],
    children: [
      {
        title: "Motorista",
        id: "drivers_follow_data",
        permissions: [
          {
            id: "can_view_drivers_follow",
            title: "Acompanhamento dos motoristas",
          },
          {
            id: "can_view_drivers_dashboard",
            title: "Comparativo de motoristas",
          },
          {
            id: "can_view_drivers_analysis",
            title: "Análise por motorista",
          },
          {
            id: "can_view_drivers_follow_details",
            title: "Visualizar veículos que o motorista dirigiu",
          },
          {
            id: "can_view_drivers_follow_trip_list",
            title: "Visualizar relatório das viagens detalhadas",
          },
        ],
      },
    ],
  },
  {
    title: "Área administrativa",
    id: "admin",
    permissions: [],
    children: [
      {
        title: "Área administrativa",
        id: "admin_area",
        permissions: [
          {
            id: "can_view_admin_area",
            title: "Visualizar Área administrativa",
          },
          {
            id: "view_super_admin_option",
            title: "Visualizar opção perfil super admin",
          },
        ],
        children: null,
      },
      {
        title: "Área administrativa - Clientes",
        id: "customers",
        permissions: [
          {
            id: "can_view_customers",
            title: "Área administrativa - Visualizar clientes",
          },
          {
            id: "can_view_customers_without_edit",
            title: "Área administrativa - Visualizar clientes sem permissão para editar",
          },
          {
            id: "can_create_customers",
            title: "Área administrativa - Criar clientes",
          },
          {
            id: "can_edit_customers",
            title: "Área administrativa - Editar clientes",
          },
          {
            id: "can_remove_customers",
            title: "Área administrativa - Remover clientes",
          },
        ],
        children: null,
      },
      {
        title: "Área administrativa - Motoristas",
        id: "drivers",
        permissions: [
          {
            id: "can_view_drivers",
            title: "Área administrativa - Visualizar motoristas",
          },
          {
            id: "can_create_drivers",
            title: "Área administrativa - Criar motoristas",
          },
          {
            id: "can_edit_drivers",
            title: "Área administrativa - Editar motoristas",
          },
          {
            id: "can_disable_drivers",
            title: "Área administrativa - Desabilitar motoristas",
          },
          {
            id: "can_remove_drivers",
            title: "Área administrativa - Remover motoristas",
          },
          {
            id: "can_import_drivers",
            title: "Área administrativa - Importar motoristas em massa",
          },
          {
            id: "can_recalculate_drivers",
            title: "Área administrativa - Recalcular bonificação de motoristas",
          },
          {
            id: "can_change_driver_customers",
            title: "Área administrativa - Mudar motorista de empresa",
          },
          {
            id: "can_import_link_drivers",
            title: "Área administrativa - Importar vínculo de motoristas em massa",
          },
          {
            id: "can_import_link_chassis_to_login",
            title: "Área administrativa - Anexar chassis ao login",
          },
        ],
        children: null,
      },
      {
        title: "Área administrativa - Veículos",
        id: "vehicles",
        permissions: [
          {
            id: "can_view_vehicles",
            title: "Área administrativa - Visualizar veículos",
          },
          {
            id: "can_view_vehicles_without_edit",
            title: "Área administrativa - Visualizar veículos sem permissão para editar",
          },
          {
            id: "can_create_vehicles",
            title: "Área administrativa - Criar veículos",
          },
          {
            id: "can_edit_vehicles",
            title: "Área administrativa - Editar veículos",
          },
          {
            id: "can_remove_vehicles",
            title: "Área administrativa - Remover veículos",
          },
          {
            id: "can_import_vehicles",
            title: "Área administrativa - Importar veículos em massa",
          },
          {
            id: "can_recalculate_vehicles",
            title: "Área administrativa - Recalcular veículos",
          },
          {
            id: "can_activate_vehicles",
            title: "Área administrativa - Ativar/inativar veículos",
          },
          {
            id: "can_edit_vehicledevice",
            title:
              "Área administrativa - Vincular unidade eletrônica em veículos",
          },
          {
            id: "can_view_history_vehicle",
            title:
              "Área administrativa - Visualizar histórico de modificação de veículos",
          },
        ],
        children: null,
      },
      {
        title: "Área administrativa - Perfis",
        id: "profiles",
        permissions: [
          {
            id: "can_view_profiles",
            title: "Área administrativa - Visualizar perfis",
          },
          {
            id: "can_view_profiles_without_edit",
            title: "Área administrativa - Visualizar perfis sem permissão para editar",
          },
          {
            id: "can_create_profiles",
            title: "Área administrativa - Criar perfis",
          },
          {
            id: "can_edit_profiles",
            title: "Área administrativa - Editar perfis",
          },
          {
            id: "can_remove_profiles",
            title: "Área administrativa - Remover perfis",
          },
          {
            id: "can_view_history_profile",
            title: "Área administrativa - Visualizar histórico de modificação de perfis",
          },
        ],
        children: null,
      },
      {
        title: "Área administrativa - Usuários",
        id: "users",
        permissions: [
          {
            id: "can_view_users",
            title: "Área administrativa - Visualizar usuários",
          },
          {
            id: "can_view_users_without_edit",
            title: "Área administrativa - Visualizar usuários sem permissão para editar",
          },
          {
            id: "can_create_users",
            title: "Área administrativa - Criar usuários",
          },
          {
            id: "can_edit_users",
            title: "Área administrativa - Editar usuários",
          },
          {
            id: "can_remove_users",
            title: "Área administrativa - Remover usuários",
          },
          {
            id: "can_update_users_roles",
            title: "Área administrativa - Atualizar perfil de usuários",
          },
          {
            id: "can_delete_user_entirely",
            title: "Área administrativa - Deletar usuário completamente",
          },
        ],
        children: null,
      },
      {
        title: "Área administrativa - Perfil de usuários",
        id: "role",
        permissions: [
          {
            id: "can_view_user_role",
            title: "Área administrativa - Visualizar perfil de usuários",
          },
          {
            id: "can_view_driver_role",
            title: "Área administrativa - Visualizar perfil de motoristas",
          },
          {
            id: "can_create_user_role",
            title: "Área administrativa - Criar perfil de usuários",
          },
          {
            id: "can_edit_user_role",
            title: "Área administrativa - Editar perfil de usuários",
          },
          {
            id: "can_remove_user_role",
            title: "Área administrativa - Remover perfil de usuários",
          },
        ],
        children: null,
      },
      {
        title: "Área administrativa - Unidades eletrônicas",
        id: "devices",
        permissions: [
          {
            id: "can_view_devices",
            title: "Área administrativa - Visualizar unidades eletrônicas",
          },
          {
            id: "can_view_history_devices",
            title:
              "Área administrativa - Visualizar histórico de unidades eletrônicas",
          },
          {
            id: "can_create_devices",
            title: "Área administrativa - Criar unidades eletrônicas",
          },
          {
            id: "can_edit_devices",
            title: "Área administrativa - Editar unidades eletrônicas",
          },
          {
            id: "can_remove_devices",
            title: "Área administrativa - Remover unidades eletrônicas",
          },
          {
            id: "can_import_devices",
            title:
              "Área administrativa - Importar unidades eletrônicas em massa",
          },
        ],
        children: null,
      },
      {
        title: "Área administrativa - Grupo de bonificação",
        id: "rewardgroup",
        permissions: [
          {
            id: "can_view_rewardgroup",
            title: "Área administrativa - Visualizar grupo de bonificação",
          },
          {
            id: "can_view_rewardgroup_without_edit",
            title: "Área administrativa - Visualizar grupo de bonificação sem permissão para editar",
          },
          {
            id: "can_create_rewardgroup",
            title: "Área administrativa - Criar grupo de bonificação",
          },
          {
            id: "can_edit_rewardgroup",
            title: "Área administrativa - Editar grupo de bonificação",
          },
          {
            id: "can_remove_rewardgroup",
            title: "Área administrativa - Remover grupo de bonificação",
          },
        ],
        children: null,
      },
      {
        title: "Área administrativa - Conectividade",
        id: "telemetrydata",
        permissions: [
          {
            id: "can_view_telemetrydata",
            title: "Área administrativa - Visualizar conectividade",
          },
          {
            id: "can_view_telemetrydata_all",
            title: "Área administrativa - Visualizar conectividade de todas as empresas",
          },
          {
            id: "can_view_devicesdata",
            title: "Área administrativa - Visualizar dados de dispositivos",
          },
        ],
        children: null,
      }
    ],
  },
  {
    title: "Beta Tester",
    id: "beta",
    permissions: [
      {
        id: "can_access_beta",
        title: "Liberar versão beta tester",
      },
    ],
    children: null,
  },
  {
    title: "Alterar senha",
    id: "password",
    permissions: [
      {
        id: "can_view_change_password",
        title: "Alterar dados de acesso",
      },
      {
        id: "can_execute_change_password",
        title: "Backend - alterar dados de acesso",
      },
    ],
    children: null,
  },
  {
    title: "Sascar",
    id: "sascar",
    permissions: [
      {
        id: "can_view_dashboard_gforce",
        title: "Visualizar força G lateral no dashboard",
      },
      {
        id: "can_view_dashboard_brute_break",
        title: "Visualizar freadas bruscas no dashboard",
      },
      {
        id: "can_view_sascar_xls",
        title: "Visualizar comparativo XLS formato Sascar + Gobrax",
      },
      {
        id: "can_only_view_sascar_xls",
        title: "Visualizar comparativo XLS formato somente Sascar",
      },
    ],
    children: null,
  },
];

export const findPermissionName = (id) => {
  let founded = [];
  permissions.forEach((obj) => {
    if (obj.children && obj.children.length > 0) {
      obj.children.forEach((el) => {
        if (el.permissions.length > 0) {
          let item = el.permissions.find((item) => item.id === id);
          if (item !== undefined) {
            founded.push(item.title);
          }
        }
      });
    } else if (obj.permissions.length > 0) {
      let item = obj.permissions.find((item) => item.id === id);
      if (item !== undefined) {
        founded.push(item.title);
      }
    }
  });
  if (founded.length > 0) {
    return founded;
  }
  return id;
};

export const permissionsDefaultValues = () => {
  let itensValue = {};
  permissions.forEach((element) => {
    if (element.permissions.length > 0) {
      let newItem = element.permissions.map((item) => item.id);
      itensValue[newItem] = false;
    }
    if (element.children && element.children.length > 0) {
      element.children.forEach((item) => {
        let newItem = item.permissions.map((elm) => elm.id);
        newItem.forEach((e) => (itensValue[e] = false));
      });
    }
  });
  return itensValue;
};

export const getGroupedRoles = (data) => {
  if (!data || data.length === 0) {
    return [];
  }
  // ordena pela role
  data.sort((a, b) => a.role - b.role);
  // agrupa pela role
  const grouped = groupBy(data, "role");
  // monta o objeto para listar na tabela
  const rolesArray = Object.keys(grouped).map((objectKey) => {
    let permissionsArray = grouped[objectKey].map((value) =>
      findPermissionName(value.permission),
    );
    // agrupa as datas para pegar mais antiga (creationDate) e recente (updateDate)
    let dates = grouped[objectKey].map((d) => dayjs(d.date));

    return {
      name: grouped[objectKey][0].name,
      role: grouped[objectKey][0].role,
      creationDate: dayjs.min(dates).toDate(),
      updateDate: dayjs.max(dates).toDate(),
      permissions: permissionsArray.join(", "),
    };
  });
  return rolesArray;
};

export const getGroupedDriverRoles = (data) => {
  if (!data || data.length === 0) {
    return [];
  }

  // ordena pela role
  data.sort((a, b) => a.role - b.role);
  // agrupa pela role

  const grouped = groupBy(data, 'id');

  // monta o objeto para listar na tabela
  const rolesArray = Object.keys(grouped).map((objectKey) => {
    let permissionsArray = grouped[objectKey]?.[0]?.permissions?.map(permission => permission?.title);

    // agrupa as datas para pegar mais antiga (creationDate) e recente (updateDate)
    let datesMax = grouped[objectKey].map(d => dayjs(d.created_at));
    let datesMin = grouped[objectKey].map(d => dayjs(d.updated_at));

    return {
      name: grouped[objectKey][0].label,
      role: grouped[objectKey][0].id,
      updateDate: dayjs.min(datesMin).toDate(),
      creationDate: dayjs.max(datesMax).toDate(),
      permissions: permissionsArray?.join(', '),
    }
  });

  return rolesArray;
};

