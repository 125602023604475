export default [
  "brandId",
  "modelId",
  /* FAIXAS DE RPM */
  "neutralRangeStart",
  "neutralRangeEnd",
  "whiteRangeStart",
  "whiteRangeEnd",
  "extraEconomicRangeStart",
  "extraEconomicRangeEnd",
  "economicRangeStart",
  "economicRangeEnd",
  "powerRangeStart",
  "powerRangeEnd",
  "engineBrakeRangeStart",
  "engineBrakeRangeEnd",
  "redRangeStart",
  "redRangeEnd",
];
