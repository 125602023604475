import { alpha } from "@mui/material";
import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  toolbar: {
    marginBottom: theme.spacing(2)
  },
  subtitle: {
    color: theme.palette.text.secondary,
  },
  tableContent: {
    marginTop: theme.spacing(2)
  },
  btnNew: {
    padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
    borderRadius: 10,
    backgroundColor: theme.palette.secondary.main,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: alpha(theme.palette.secondary.main, 0.8),
      fontWeight: 600
    },
  },
  backButton: {
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.background.backgroundButton
  },
  btItem: {
    [theme.breakpoints.up("md")]: {
      paddingRight: theme.spacing(2)
    },
    [theme.breakpoints.down('md')]: {
      paddingBottom: theme.spacing(2),
      paddingTop: theme.spacing(2)
    },
  },
  btUpdate: {
    marginLeft: 'auto',
  },
}));
