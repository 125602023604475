import React from 'react';
import Aux from "hoc/auxiliar";
import Dialog from '@mui/material/Dialog';
import IconButton from "@mui/material/IconButton";
import { Close as CloseIcon } from "@mui/icons-material";
import DialogContent from '@mui/material/DialogContent';
import useStyles from "./styles";

export default function FollowModal({
  children,
  handleClose,
  isOpen,
  ...props
}) {
  const classes = useStyles()
  return (
    <Aux>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="divModalTempoReal"
        data-cy="divModalTempoReal"
        PaperProps={{
          style: { 
            borderRadius: 24,
            width: 392,
          }
        }}
        { ...props }
      >
        <IconButton
          aria-label="close"
          className={classes.iconButton}
          onClick={handleClose}
          size="large">
          <CloseIcon />
        </IconButton>
        <DialogContent style={{ padding: 32 }}>
          {children}
        </DialogContent>
      </Dialog>
    </Aux>
  );
}
