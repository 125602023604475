import React from "react";
import { Typography, Tooltip } from "@mui/material";
import { CheckCircle, EditOutlined, ErrorOutline } from "@mui/icons-material";
import dayjs from "dayjs";

// assets
import LoadingLottie from 'assets/lotties/loading/loading3.json';

// components
import LottieComponent from "components/LottieComponent";
import { hasPermission } from "components/PermissionsGate";

// styles
import makeStyles from "./styles";

export const makeColumns = ({ handleEdit, size }) => {
  const classes = makeStyles();
  const array = [
    {
      name: "startDate",
      label: "Data inicial",
      options: {
        customBodyRender: (value) => {
          return (
            <Typography variant="body2" className={classes.text400} data-cy="thStartDate">
              {dayjs(value).format("DD/MM/YYYY HH:mm")}
            </Typography>
          );
        },
      },
    },
    {
      name: "endDate",
      label: "Data final",
      options: {
        customBodyRender: (value) => {
          return value ? (
            <Typography variant="body2" className={classes.text400} data-cy="thEndDate">
              {dayjs(value).format("DD/MM/YYYY HH:mm")}
            </Typography>
          ) : (
            <Typography variant="body2" className={classes.textInfo} data-cy="thEndDate">
              Motorista atual
            </Typography>
          );
        },
      },
    },
    {
      name: "driverName",
      label: "Motorista",
      options: {
        customBodyRender: (value) => {
          return value ? (
            <Typography variant="body2" className={classes.text400} data-cy="thDriver">
              {value}
            </Typography>
          ) : (
            <Typography variant="body2" className={classes.textInfo} data-cy="thDriver">
              Sem vínculo atual
            </Typography>
          );
        },
      },
    },
    {
      name: "assignBy",
      label: "Vínculo feito por",
      options: {
        customBodyRender: (value, tableMeta) => {
          return value ? (
            <Typography variant="body2" className={classes.text400} data-cy="thLinkMadeBy">
              {value}
            </Typography>
          ) : (
            <Typography variant="body2" className={classes.textInfo} data-cy="thLinkMadeBy">
              Desconhecido
            </Typography>
          );
        },
      },
    },
    {
      name: "unassignBy",
      label: "Desvínculo feito por",
      options: {
        customBodyRender: (value) => {
          return value ? (
            <Typography variant="body2" className={classes.text400} data-cy="thUnlinkMadeBy">
              {value}
            </Typography>
          ) : (
            <Typography variant="body2" className={classes.textInfo} data-cy="thUnlinkMadeBy">
              Desconhecido
            </Typography>
          );
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value) => {
          return (
            <div className={classes.center} data-cy="thStatus">
              {Number(value) === 0 ? (
                <CheckCircle size={22} className={classes.checkIcon} />
              ) : Number(value) === 1 ? (
                <LottieComponent animationData={LoadingLottie} width={40} height={40} />
              ) : (
                <ErrorOutline size={22} className={classes.errorIcon} />
              )}
            </div>
          );
        },
      },
    },
  ];
  if (!size.mobile && hasPermission({ scopes: ["can_edit_historic_linkdrivers"] })) {
    array.push({
      name: "driverId",
      label: "Ações",
      options: {
        sort: false,
        filter: false,
        viewColumns: false,
        print: false,
        customBodyRender: (value, metaData) => {
          return (
            <div className={classes.actionContent} data-cy="thActions">
              <Tooltip title="Editar">
                <EditOutlined size={22}
                  onClick={() => handleEdit(metaData.rowData)}
                  className={classes.actionButton}
                />
              </Tooltip>
            </div>
          );
        },
      },
    },
    )
  } else {
    array.unshift({
      name: "header",
      label: " ",
      options: {
        customBodyRender: (value) => {
          return (
            value.final ?
              <Typography variant="body2" className={classes.text400}>
                {dayjs(value.init).format("DD/MM/YYYY")} - {dayjs(value.final).format("DD/MM/YYYY")}
              </Typography> :
              <Typography variant="body2" className={classes.text400}>
                {dayjs(value.init).format("DD/MM/YYYY")} - Motorista atual
              </Typography>
          );
        },
      },
    },)
  }
  return array
};
