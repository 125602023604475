import React from "react";
import { Box, Grid, Typography, Skeleton } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

import Aux from "hoc/auxiliar";

const Co2Skeleton = () => {
  const classes = useStyles();
  
  return (
    <Aux>
      <Grid
        container
        justifyContent="center" 
        alignItems="center"
        className={classes.container}>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
          <Skeleton variant="rectangular" width={"100%"} height={50} className={classes.bordered} />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
          <Box display="flex" justifyContent="center">
            <Skeleton variant="rectangular" width={330} height={50} className={classes.bordered} />
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Typography variant="h1">
            <Skeleton width={100} />
          </Typography>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Typography variant="h1">
            <Skeleton width={350} />
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Skeleton variant="rectangular" width={"100%"} height={800} />
        </Grid>
      </Grid>
    </Aux>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(3),
  },
  bordered: {
    borderRadius: "22px",
  },
}));

export default Co2Skeleton;
