export default class VehicleModel {
  constructor(
    customer,
    operation,
    identification,
    plate,
    fueltype,
    wheeldrivetype,
    odomType,
    initialKm,
    maxFuelCapacity,
    maxAcceleration,
    chassi,
    distanceCalcMethod,
    fuelCalcMethod,
    accelerationIdentificationMethod,
    hasEcoRoll,
    hasDafEcoRoll,
    maxSpeedAllowed,
    maxSpeedAllowedWithRain,
    leveragePeak,
    brand,
    model,
    observation,
    motor,
    truckModel,
    fabricationYear
  ) {
    this.customerId = customer?.value;
    this.operationId = operation?.value || null;
    this.identification = identification;
    this.plate = plate;
    this.fuelTypeId = fueltype?.value || null;
    this.wheelDriveTypeId = wheeldrivetype?.value || null;
    this.odomType = odomType?.value;
    this.initialKm = initialKm ? parseInt(initialKm) : null;
    this.maxFuelCapacity = parseInt(maxFuelCapacity);
    this.maxAcceleration = parseInt(maxAcceleration);
    this.chassi = chassi || null;
    this.profile = {
      distanceCalcMethod: distanceCalcMethod?.value,
      fuelCalcMethod: fuelCalcMethod?.value,
      accelerationIdentificationMethod: accelerationIdentificationMethod?.value,
      hasEcoRoll: hasEcoRoll || false,
      hasDafEcoRoll: hasDafEcoRoll || false
    };
    this.maxSpeedAllowed = parseInt(maxSpeedAllowed);
    this.maxSpeedAllowedWithRain = parseInt(maxSpeedAllowedWithRain);
    this.leveragePeak = parseInt(leveragePeak);
    this.brandId = brand?.value;
    this.modelId = model?.value
    this.observation = observation || null;
    this.motor = motor;
    this.truckModel = truckModel;
    this.fabricationYear = fabricationYear;
  }
}
