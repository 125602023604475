import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  cardTitle: {
    fontWeight: 'bold',
    padding: '10px 0 0 20px'
  },
  list: {
    width: '100%',
  },
  odd: {
    backgroundColor: theme.palette.gray.grayAlmostLight
  },
  contentBox: {
    height: '470px',
    width: '100%'
  },
  itemBox: {
    padding: `0 3px 0px 16px !important`,
    marginTop: '10px',
    height: '435px',
    overflowY: 'auto',
    width: '100%',
    "&::-webkit-scrollbar": {
      width: 7,
      left: '-100px'
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 700],
      boxShadow: `-2px 3px 4px ${theme.palette.background.hoverMultiMenu}`,
      borderRadius: 5,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.white,
      outline: `2px solid ${theme.palette.grey[theme.palette.mode === 'light' ? 200 : 700]}`,
      borderRadius: 5,
    },
  },
  itemText: {
    fontSize: '14px',
    fontWeight: 500,
    color: theme.palette.text.primary
  }
}));
