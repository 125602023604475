import { makeStyles } from "@mui/styles";
import colors from '../../themes/gobrax'

export default makeStyles(theme => ({
  paper: {
    borderRadius: 20,
    width: 350,
    maxWidth: "100%",
    padding: "4px 8px 4px 20px",
    marginBottom: 20,
    boxShadow: `3px 5px 4px ${colors.palette.background.hoverMultiMenu}`,
    
  },
  center: {
    borderRadius: 20,
    width: 350,
    maxWidth: "100%",
    padding: "4px 8px 4px 20px",
    marginBottom: 20,
    boxShadow: `3px 5px 4px ${colors.palette.background.hoverMultiMenu}`,
  
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
}));
  