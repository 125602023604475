import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { toast } from "react-toastify";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import classNames from "classnames";
import Swal from "sweetalert2";

// theme
import colors from "themes/gobrax";

// store
import { useAppSelector } from "store";

// services
import { deleteCustomer, getCustomersById } from "services/customers";

// components
import Aux from "hoc/auxiliar";
import Widget from "components/Widget/Widget";
import Table from "components/Table/Table";
import TableResponsive from "components/TableResponsive";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";
import { makeColumns } from "../tableColumns";

// hooks
import { useWindowSize } from "hooks/useWindowsSize";


// styles
import useStyles from "../styles";

const CustomerList = () => {
  const { listCustomers } = useAppSelector((state) => state.global.user);

  const history = useHistory();
  const queryClient = useQueryClient();

  const [selectedRowData, setSelectedRowData] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [id, setId] = useState();

  const {
    data,
    isError
  } = useQuery("customers", async () => {
    const { data } = (hasPermission({ scopes: ['can_view_customers'] })) ?
      await getCustomersById(listCustomers) : { data: null };
    return data;
  });

  const { mutateAsync } = useMutation(deleteCustomer);
  const size = useWindowSize();

  const classes = useStyles();
  
  const handleEdit = (id) => history.push(`/gofurther/customer/edit-customer/${id}`);

  const handleSelectRow = (data1,data2) => {
    setId(data2.dataIndex);
    const id = data?.customers[data2.dataIndex].id;
    setSelectedRowData(id)
  }
  
  const handleDelete = (id) => {
    Swal.fire({
      title: "Tem certeza que deseja excluir?",
      text: "Essa ação é irreversível!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: colors.palette.primary.main,
      cancelButtonColor: colors.palette.error.main,
      confirmButtonText: "Sim, excluir!",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await mutateAsync(id)
        queryClient.invalidateQueries('customers')
        toast.success("Cliente deletado com sucesso!");
        Swal.fire(
          "Excluído!",
          "O registro foi excluído com sucesso",
          "success",
        );
      }
    });
  };

  const columns = makeColumns({ onDelete: handleDelete, onEdit: handleEdit ,size});

  const handleClickAddCustomer = () => history.push("/gofurther/customer/create-customer");

  if (isError) {
    toast.error(
      "Erro buscar lista de clientes. Entre em contato com o suporte.",
    );
  }

  const renderTable = useCallback(() => (
    size.mobile?
    <TableResponsive
      pointer={true}
      options={{
        onRowClick: handleSelectRow,
        rowsPerPage: rowsPerPage,
        setRowProps: (row, index) => {
          return index===id? {style: {backgroundColor: '#FFF5CB',cursor:'pointer',width:'100%'}} : null },
      }}
      columns={columns}
      data={data?.customers}
      tableName="list-customers"
      setRowsPerPage={setRowsPerPage}
    />:
      <Table
        columns={columns}
        data={data?.customers}
        tableName="list-customers"
        options={{ rowsPerPage }}
        setRowsPerPage={setRowsPerPage}
      />
  ), [columns, data, rowsPerPage]);

  return (
    <Aux>
      <PermissionsGate scopes={['can_view_customers']}>
        <Grid container spacing={4}>
          <Grid item xs={12} className={classes.tableContent}>
            <PermissionsGate scopes={['can_create_customers']}>
              <Grid container justifyContent="flex-end">
                <Grid item xl={2} lg={3} md={4} sm={12} xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    className={classes.btnNew}
                    onClick={handleClickAddCustomer}
                  >
                    Novo Cliente
                  </Button>
                </Grid>
              </Grid>
            </PermissionsGate>
            <Widget disableWidgetMenu title="Gerenciamento de clientes">
              {renderTable()}
            </Widget>
          </Grid>
          {/* botoes */}
          {size.mobile?
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Grid container justifyContent="flex-end" spacing={2}>
              <PermissionsGate scopes={['can_edit_customers']}>
              <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                <Box >
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    onClick={()=>handleEdit(selectedRowData)}
                    disabled={!selectedRowData}
                    className={classes.buttonDetail}
                  >
                    Editar
                  </Button>
                </Box>
              </Grid>
              </PermissionsGate>
              <PermissionsGate scopes={['can_remove_customers']}>
                <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                  <Box >
                    <Button
                      fullWidth
                      variant="outlined"
                      className={classNames(
                        classes.buttonDetail,
                        {[classes.buttonDelete]: selectedRowData}
                    )}
                      onClick={()=>handleDelete(selectedRowData)}
                      disabled={!selectedRowData}
                    >
                      Excluir
                    </Button>
                  </Box>
                </Grid>
              </PermissionsGate>
            </Grid>
          </Grid>:null}
        </Grid>
      </PermissionsGate>
    </Aux>
  );
};

export default CustomerList;
