export const makeColumns = () => {
  return [
    {
      name: "name",
      label: "Perfil",
    },
    {
      name: "permissions",
      label: "Permissões",
    },
  ];
};
