import React from "react";
import { Grid, Skeleton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import Aux from "hoc/auxiliar";

const HistoricVehiclesSkeleton = () => {
  const classes = useStyles();
  
  return (
    <Aux>
      <Grid container spacing={1}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Skeleton
            variant="rectangular"
            width={"100%"}
            height={550}
            className={classes.bordered}
          />
        </Grid>
      </Grid>
    </Aux>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(1),
  },
  bordered: {
    borderRadius: "22px",
  },
}));

export default HistoricVehiclesSkeleton;
