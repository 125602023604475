import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  container: {
    flexDirection: "column",
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  },
  itemContainer: {
    display: "flex",
  },
  title: {
    marginLeft: theme.spacing(1),
    fontWeight: "bold",
  },
  icon: {
    color: theme.palette.text.main
  }
}));
