import * as yup from "yup";

export default yup.object().shape({
  customer: yup
    .object({
      value: yup
        .number()
        .typeError('Formato inválido'),
      label: yup
        .string()
        .typeError('Formato inválido')
    })
    .required('Campo obrigatório')
    .typeError('Campo obrigatório'),
  operation: yup
    .object({
      value: yup
        .number()
        .typeError('Formato inválido'),
      label: yup
        .string()
        .typeError('Formato inválido')
    })
    .nullable()
    .typeError('Campo obrigatório'),
  identification: yup.string().required("Campo obrigatório").nullable(),
  plate: yup.string().required("Campo obrigatório").max(10, "Máximo de 10 caracteres").nullable(),
  fueltype: yup
    .object({
      value: yup
        .number()
        .typeError('Formato inválido'),
      label: yup
        .string()
        .typeError('Formato inválido')
    })
    .typeError('Campo obrigatório')
    .nullable(),
  wheeldriver: yup
    .object({
      value: yup
        .number()
        .typeError('Formato inválido'),
      label: yup
        .string()
        .typeError('Formato inválido')
    })
    .typeError('Campo obrigatório')
    .nullable(),
  odomType: yup
    .object({
      value: yup
        .number()
        .typeError('Formato inválido'),
      label: yup
        .string()
        .typeError('Formato inválido')
    })
    .required('Campo obrigatório')
    .typeError('Campo obrigatório'),
  initialKm: yup.string().when("odomType", {
    is: (value) => !!value,
    then: yup.string().required("Campo Obrigatorio"),
    otherwise: yup.string().nullable(),
  }),
  maxFuelCapacity: yup.string().required("Campo obrigatório"),
  maxAcceleration: yup.string().required("Campo obrigatório"),
  chassi: yup.string().max(20, "Máximo de 20 caracteres").nullable(),
  motor: yup.string(),
  fabricationYear: yup.string(),
  truckModel: yup.string(),
  distanceCalcMethod: yup
    .object({
      value: yup
        .string()
        .typeError('Formato inválido'),
      label: yup
        .string()
        .typeError('Formato inválido')
    })
    .required('Campo obrigatório')
    .typeError('Campo obrigatório'),
  fuelCalcMethod: yup
    .object({
      value: yup
        .string()
        .typeError('Formato inválido'),
      label: yup
        .string()
        .typeError('Formato inválido')
    })
    .required('Campo obrigatório')
    .typeError('Campo obrigatório'),
  accelerationIdentificationMethod: yup
    .object({
      value: yup
        .string()
        .typeError('Formato inválido'),
      label: yup
        .string()
        .typeError('Formato inválido')
    })
    .required('Campo obrigatório')
    .typeError('Campo obrigatório'),
  maxSpeedAllowed: yup.string().required("Campo obrigatório"),
  maxSpeedAllowedWithRain: yup.string().required("Campo obrigatório"),
  leveragePeak: yup.string().required("Campo obrigatório"),
  brand: yup
    .object({
      value: yup
        .number()
        .typeError('Formato inválido'),
      label: yup
        .string()
        .typeError('Formato inválido')
    })
    .required('Campo obrigatório')
    .typeError('Campo obrigatório'),
  model: yup.object().when("brand", {
    is: (value) => !!value,
    then: yup
      .object({
        value: yup
          .number()
          .typeError('Formato inválido'),
        label: yup
          .string()
          .typeError('Formato inválido')
      })
      .required('Campo obrigatório')
      .typeError('Campo obrigatório'),
    otherwise: yup
      .object({
        value: yup
          .number()
          .typeError('Formato inválido'),
        label: yup
          .string()
          .typeError('Formato inválido')
      })
      .typeError('Campo obrigatório')
      .nullable()
  }),
  observation: yup.string(),
  hasEcoRoll: yup.boolean(),
  hasDafEcoRoll: yup.boolean(),
});
