import { alpha } from "@mui/material";
import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  title: {
    fontSize: 24,
    fontWeight: 500,
    color: theme.palette.text.title,
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(1),
  },
  btConfirm: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: theme.spacing(1),
    "&:hover": {
      backgroundColor: alpha(theme.palette.secondary.main, 0.8),
      fontWeight: 600
    },
  },
  backButton: {
    backgroundColor: theme.palette.background.backgroundButton
  }
}));
