import api from "./api";
import { convertStringDate } from "helpers/functions";

const parsedUrl = (itens = "") => {
  let partialUrl = "";
  if (itens) {
    for (let i = 0; i < itens.length; i++) {
      if (i + 1 === itens.length) {
        partialUrl += `${itens[i]}`;
      } else {
        partialUrl += `${itens[i]},`;
      }
    }
  }
  return partialUrl;
};

export const getReward = async (drivers, vehicles, startDate, endDate, limit) => {
  const driverslUrl = parsedUrl(drivers);
  const vehicleslUrl = parsedUrl(vehicles);
  const convertedDates = convertStringDate(startDate, endDate);
  let params = `drivers=${driverslUrl}&vehicles=${vehicleslUrl}&startDate=${convertedDates.startDate}&endDate=${convertedDates.endDate}&limit=${limit}`;
  try {
    const response = await api.get(`/web/v2/performance/drivers/efficiency/multiple?${params}`);
    if(!response.ok){
      throw new Error("Api error")
    }
    return response;
  } catch (err) {
    throw err;
  }
};

export const getRewardV2OnDemand = async (drivers, vehicles, startDate, endDate, limit) => {
  const driverslUrl = parsedUrl(drivers);
  const vehicleslUrl = parsedUrl(vehicles);
  const convertedDates = convertStringDate(startDate, endDate);
  let params = `drivers=${driverslUrl}&vehicles=${vehicleslUrl}&startDate=${convertedDates.startDate}&endDate=${convertedDates.endDate}&limit=${limit}&onDemand=true`;
  try {
    const response = await api.get(`/web/v2/performance/drivers/efficiency/multiple?${params}`);
    if(!response.ok){
      throw new Error("Api error")
    }
    return response;
  } catch (err) {
    throw err;
  }
};

export const getRewardV2 = async (drivers, vehicles, startDate, endDate, limit) => {
  const driverslUrl = parsedUrl(drivers);
  const vehicleslUrl = parsedUrl(vehicles);
  const convertedDates = convertStringDate(startDate, endDate);
  let params = `drivers=${driverslUrl}&vehicles=${vehicleslUrl}&startDate=${convertedDates.startDate}&endDate=${convertedDates.endDate}&limit=${limit}&onDemand=false`;
  try {
    const response = await api.get(`/web/v2/performance/drivers/efficiency/multiple?${params}`);
    if(!response.ok){
      throw new Error("Api error")
    }
    return response;
  } catch (err) {
    throw err;
  }
};

export const getRewardXLSByDriver = async (drivers, startDate, endDate) => {
  const partialUrl = parsedUrl(drivers);
  const convertedDates = convertStringDate(startDate, endDate);
  let params = `drivers=${partialUrl}&startDate=${convertedDates.startDate}&endDate=${convertedDates.endDate}`;
  try {
    const response = await api.get(`/web/v2/performance/xls/multidriver?${params}`);
    return response;
  } catch (err) {
    return err;
  }
};

export const getRewardXLS = async (drivers, startDate, endDate, limit) => {
  const partialUrl = parsedUrl(drivers);
  const convertedDates = convertStringDate(startDate, endDate);
  let params = `drivers=${partialUrl}&startDate=${convertedDates.startDate}&endDate=${convertedDates.endDate}&limit=${limit}`;
  try {
    const response = await api.get(`web/v2/performance/xls/multidriver?${params}`);
    return response;
  } catch (err) {
    return err;
  }
};
