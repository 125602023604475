import React, { useMemo } from 'react';
import { Grid, Typography } from '@mui/material';
import {
  Cell,
  Label,
  Pie,
  PieChart,
  ResponsiveContainer,
  Text,
} from "recharts";

import {
  LocalShippingOutlined as TruckIcon,
} from "@mui/icons-material";
import { TbLicense, TbEngine } from 'react-icons/tb'
import { HiOutlineNewspaper } from 'react-icons/hi'

import colors from 'themes/gobrax'

import useStyles from './styles';

function PerformanceComponent(props) {
  const classes = useStyles();

  // regra de cores: 0 a 40: Vermelho / 41 a 80: Amarelo / 81 a 100: Verde
  let color = '';
  if (props.data.globalScore <= 40) {
    color = colors.palette.error.main;
  } else if (props.data.globalScore <= 80) {
    color = colors.palette.warning.main;
  } else {
    color = colors.palette.success.main;
  }

  const data = [
    {
      color: color,
      value: props.data.globalScore
    },
    {
      color: colors.palette.gray.extraLight,
      value: 100 - props.data.globalScore
    },
  ];

  const isSingleVehicle = useMemo(() => props.selectedVehicles.length === 1, [props.selectedVehicles]);

  const firstVehicle = useMemo(() => props.selectedVehicles[0], [props.selectedVehicles]);
  
  return (
    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
      <Grid container data-cy="divNotaGeral">
        <ResponsiveContainer height={170} width={!props.print ? '99%' : 230}>
          <PieChart>
            <Pie
              data={data}
              innerRadius={55}
              outerRadius={70}
              startAngle={-270}
              dataKey="value"
              isAnimationActive={false}
            >
              {data?.map((entry, index) => (
                <Cell key={index} fill={entry.color} />
              ))}
              <Label
                content={(props) => {
                  const positioningProps = {
                    x: props.viewBox.cx,
                    y: props.viewBox.cy,
                    textAnchor: "middle",
                    verticalAnchor: "middle",
                  };
                  const presentationProps = {
                    fill: colors.palette.gray.light,
                    fontSize: 40,
                    fontWeight: "bold",
                  };
                  return (
                    <Text
                      {...positioningProps}
                      {...presentationProps}
                    >
                      {data[0].value}
                    </Text>
                  );
                }}
              />
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </Grid>
      {isSingleVehicle && (
        <Grid container className={classes.container} data-cy="divStatus">
          {firstVehicle?.brand && (
            <Grid item className={classes.itemContainer}>
              <TruckIcon className={classes.icon} fontSize="small"/>
              <Typography className={classes.title}>
                {firstVehicle?.brand}
              </Typography>
            </Grid>
          )}
          {firstVehicle?.fabricationYear > 0 && (
            <Grid item className={classes.itemContainer}>
              <TbLicense size={20} />
              <Typography className={classes.title}>
                {firstVehicle?.fabricationYear || ''}
              </Typography>
            </Grid>
          )} 
          {firstVehicle?.motor && (
            <Grid item className={classes.itemContainer}>
              <TbEngine size={20} />
              <Typography className={classes.title}>
                {firstVehicle?.motor}
              </Typography>
            </Grid>
          )}
          {firstVehicle?.truckModel && (
            <Grid item className={classes.itemContainer}>
              <HiOutlineNewspaper size={20} />
              <Typography className={classes.title}>
                {firstVehicle?.truckModel}
              </Typography>
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  )
}

export default PerformanceComponent;
