import React from 'react';

import './cardTitle.css';

export const CardTitle = ({ title = 'Título', titleStyle, ...props }) => {
  return (
    <div
      className={`CardTitle-container`}
      style={titleStyle}
      {...props}
    >
      {title}
    </div>
  );
};
