import React from 'react';

import './cardValue.css';

export const CardValue = ({ value = 0, valueStyle, ...props }) => {
  return (
    <div
      className={`CardValue-container`}
      style={valueStyle}
      {...props}
    >
      {value}
    </div>
  );
};
