import React from "react";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { ZoomIn } from "@mui/icons-material";
import RecalculationStatus from "components/RecalculationStatus";

import makeStyles from "./styles";

export const makeColumns = ({ showRecalculation }) => {
  const classes = makeStyles();

  const getValue = (value, withoutValue) => {
    return value ? (
      <Typography variant="body2">
        {value}
      </Typography>
    ) : (
      <Typography variant="body2" className={classes.textInfo}>
        {withoutValue}
      </Typography>
    );
  };

  return [
    {
      name: "process_date",
      label: "Data/hora",
      options: {
        customBodyRender: (value) => (
          <Typography variant="body2" className={classes.textInfo}>
            {getValue(value, "Sem dados")}
          </Typography>
        ),
      },
    },
    {
      name: "user",
      label: "Feito por usuário",
      options: {
        customBodyRender: (value) => (
          <Typography variant="body2" className={classes.textInfo}>
            {value}
          </Typography>
        ),
      },
    },
    {
      name: "period",
      label: "Período do recálculo",
      options: {
        customBodyRender: (value) => (
          <Typography variant="body2" className={classes.textInfo}>
            {getValue(value, "Sem período")}
          </Typography>
        ),
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value) => <RecalculationStatus value={value} />,
      },
    },
    {
      name: "id",
      label: "Ações",
      options: {
        sort: false,
        print: false,
        filter: false,
        viewColumns: false,
        customBodyRender: (value) => {
          return (
            <Grid container direction="column" alignItems="center">
              <Tooltip title="Visualizar Recálculo" aria-label="visualizar-recalculo">
                <IconButton
                  onClick={() => showRecalculation(value)}
                  aria-label="visualizar-recalculo"
                  size="large">
                  <ZoomIn />
                </IconButton>
              </Tooltip>
            </Grid>
          );
        },
      },
    },
  ];
};
