import { FrontendApi, Configuration } from "@ory/client"

const basePath = process.env.REACT_APP_LINK_API_LOGIN
export const kratos = new FrontendApi(
  new Configuration({
    basePath,
    baseOptions: {
      withCredentials: true,
    },
  }),
)