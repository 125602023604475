import React from "react";
import { Avatar, Grid, Typography } from "@mui/material";
import { ExpandLess as UpIcon, ExpandMore as DownIcon, DragHandle as EqualIcon } from "@mui/icons-material";

const baseURL = `${process.env.REACT_APP_LINK_API}`;

// styles
import makeStyles from "./styles";

export const makeColumns = (size) => {
  const classes = makeStyles();

  const getPositionIcon = (status) => {
    switch(status) {
      case "subiu":
        return <UpIcon className={classes.arrowUp} />;
      case "desceu":
        return <DownIcon className={classes.arrowDown} />;
      default:
        return <EqualIcon className={classes.neutral} />;
    }
  };

  return [
    {
      name: "position_today",
      label: "Posição",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          <Typography variant="body2"  textAlign={"center"} className={classes.textBold}>
            {value}
          </Typography>
        ),
      },
    },
    {
      name: "driver",
      label: "Motorista",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          let avatar = null;
          if (value?.name) {
            avatar = (!value.image) ? 
              <Avatar
                alt={value.name}
                style={{
                  margin: size.mobile ? "0 auto": "",
                  marginRight: size.mobile ? "auto" : 10
                }}
              >
                {value.name.charAt(0)}
              </Avatar> :
              <Avatar
                alt={value.name}
                style={{
                  margin: size.mobile ? "0 auto": "",
                  marginRight: size.mobile ? "auto" : 10
                }}
                src={`${baseURL}/v1/driver-picture/${value.image}`}
              />;
          }
          return (
            <Grid container direction={"row"} alignItems={"center"}>
              {avatar}
              <Typography variant="body2" textAlign={"center"}>
                {value?.name || "-"}
              </Typography>
            </Grid>
          )
        },
      },
    },
    {
      name: "achieved_mileage",
      label: "Km rodados",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          <Typography variant="body2" textAlign={"center"}>
            {value} Km
          </Typography>
        ),
      },
    },
    {
      name: "general_score",
      label: "Nota",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          <Typography variant="body2" textAlign={"center"} className={classes.textBold}>
            {value}
          </Typography>
        ),
      },
    },
    {
      name: "status",
      label: "Evolução",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        viewColumns: false,
        customBodyRender: (value, tableData) => {
          return (
            <Grid display={"flex"} justifyContent={"center"}>
              {getPositionIcon(value)}
            </Grid>
          )
        },
      },
    }
  ];
};
