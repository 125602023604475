import React, { useEffect, useState, useCallback } from "react";
import ReactQuill from "react-quill";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { FormControlLabel, FormGroup, Container, Grid, Accordion, AccordionSummary, AccordionDetails, Typography, Input, Button, FormControl, RadioGroup, Radio, TextField, Box, Dialog, DialogContent, DialogContentText } from "@mui/material"
import { useForm, Controller } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import EventIcon from "@mui/icons-material/Event";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Group, LocalShipping as TruckIcon } from "@mui/icons-material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import { daysOfWeek, monthNames } from "constants/calendar";

// hoc
import Aux from "hoc/auxiliar";

// components
import PermissionsGate from "components/PermissionsGate";

// services
import { getDrivers } from "services/driver";
import { createNotification, getNotificationById, updateNotificationById } from "services/notifications";
import { getUserByCustomers } from "services/user";
import { handleAmplitudeEvent } from "services/amplitude";

// store
import { useAppSelector } from "store";
import { useSelect } from "context/useSelect";

// styles
import 'react-quill/dist/quill.snow.css';
import useStyles from "./styles";

// config
import { reactQuillEditorModules, reactQuillEditorFormats, options } from "./config";
import MultiSelectDriversNotifications from "./_components/MultiSelectDriversNotifications";
import MultiSelectUsersNotifications from "./_components/MultiSelectUsersNotifications";

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

export default function ManageNotification() {
  const formatDate = 'DD/MM/YYYY HH:mm';

  const classes = useStyles();
  const navigate = useHistory();
  const history = useHistory();
  const { state } = useLocation();
  const { email, currentCustomer, name } = useAppSelector((state) => state.global.user);
  const { drivers, setDrivers } = useSelect();
  const [users, setUsers] = useState([]);

  const [selectedDrivers, setSelectedDrivers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const [pressClearButton, setPressClearButton] = useState(false);
  const [isDisabledCancelButton, setIsDisabledCancelButton] = useState(true);
  const [loadingSelect, setLoadingSelect] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [option, setOption] = useState(options.default);
  const [errorAccordion, setErrorAccordion] = useState(false);
  const [errorUsers, setErrorUsers] = useState(false);
  const [dates, setDates] = useState({
    initialDate: dayjs().format(formatDate)
  });
  const [modalOpen, setModalOpen] = useState(false);

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      notificationField: {
        channels: 'web',
        titleWeb: '',
        subtitle: '',
        bodyWeb: '',
        titleApp: '',
        bodyApp: '',
      }
    }
  });

  const channelsWatch = watch('notificationField.channels');
  const titleWebWatch = watch('notificationField.titleWeb');
  const titleAppWatch = watch('notificationField.titleApp');
  const bodyWebWatch = watch('notificationField.bodyWeb');
  const bodyAppWatch = watch('notificationField.bodyApp');
  const subtitleWatch = watch('notificationField.subtitle');


  const onSubmit = async (data) => {
    const now = dayjs();

    if (state && state.notificationType === "edit") {
      const publishDate = new Date(state.publishAt);

      const differenceInMilliseconds = publishDate - now;
      const fiveMinutesInMilliseconds = 5 * 60 * 1000;

      if (differenceInMilliseconds < fiveMinutesInMilliseconds) {
        setModalOpen(true);
        return;
      }
    }    

    if (option.name === 'default') {
      return setErrorAccordion(true)
    }

    if (channelsWatch === 'app' && !selectedDrivers.length) {
      return setErrorUsers(true)
    }

    if (channelsWatch === 'web' && !selectedUsers.length) {
      return setErrorUsers(true)
    }

    const parseDate = dayjs(dates.initialDate, 'DD/MM/YYYY HH:mm').toDate();
    const finalDate = dayjs(parseDate).format("YYYY-MM-DDTHH:mm:ss-03:00");
    const isOlderDate = new Date(finalDate) < new Date();
    const nowPlusOneMinute = now.tz('America/Sao_Paulo').add(1, 'minute');
    const formattedNowPlusOneMinute = nowPlusOneMinute.format("YYYY-MM-DDTHH:mm:ss");

    const driverIds = selectedDrivers.map((item) => item.id);
    const userEmails = selectedUsers.map((item) => item.email);

    const formatDriversToString = channelsWatch === 'app' ? driverIds.map(String) : [];
    const formatUsersToString = channelsWatch === 'web' ? userEmails.map(String) : [];

    const receivers = {
      app: formatDriversToString,
      web: formatUsersToString,
    }

    const isGobraxMail = email.includes("@gobrax.com");

    const model = [{
      action: 'open_link',
      type: option.name,
      title: data.notificationField.channels === 'web' ? data.notificationField.titleWeb : data.notificationField.titleApp,
      body: data.notificationField.channels === 'web' ? data.notificationField.bodyWeb : data.notificationField.bodyApp,
      platforms: [data.notificationField.channels],
      user: email,
      username: name || '',
      from_gobrax: !!isGobraxMail,
      publish_at: isOlderDate ? formattedNowPlusOneMinute : finalDate,
      receivers: receivers
    }]

    if (data.notificationField.channels === 'web') {
      model[0].subtitle = data.notificationField.subtitle
    }

    try {
      if (state && state.notificationType === "edit") {
        model[0].id = state.notificationId;

        const response = await updateNotificationById(model[0]);

        if(response.status !== 200) {
          throw new Error("Não foi possível enviar a notificação, contate o suporte.");
        }

        toast.success('Notificação atualizada com sucesso!');
      } else {
        const response = await createNotification(model);

        if (response.status !== 200) {
          throw new Error("Não foi possível enviar a notificação, contate o suporte.");
        }

        handleAmplitudeEvent('Notification Created');

        handleClear();
        setErrorAccordion(false);
        setErrorUsers(false);

        toast.success('Notificação criada com sucesso!');
      }

      navigate.push('/gofurther/notificationhistory');
    } catch (error) {
      if (error.message) {
        toast.error(error.message)
      } else {
        toast.error("Não foi possível enviar a notificação, contate o suporte.")
      }
    }
  }

  const handleOptionClick = (selectedOption) => {
    setOption(selectedOption);
    setExpanded(false);
    setErrorAccordion(false)
  };

  const handleDate = (selectedDate) => {
    if (selectedDate) {
      setDates({
        initialDate: dayjs(selectedDate).format(formatDate)
      });
    }
  };
  const fetchDrivers = useCallback(async () => {
    try {
      setLoadingSelect(true)
      const responseDrivers = await getDrivers(currentCustomer);

      if (responseDrivers.status !== 200) {
        throw new Error("Error fetching data");
      }
      const drivers = responseDrivers.data.drivers;

      if (state && !pressClearButton) {
        const { receivers } = state.content;
        if (receivers?.app) {
          const foundDrivers = receivers.app && drivers.filter(driver => receivers?.app.includes(driver.id.toString()));
          setSelectedDrivers(foundDrivers);
        }
      }

      setDrivers(drivers);
    } catch (err) {
      console.log('err', err);
      setDrivers([]);
      console.log(
        "Erro ao carregar lista de motoristas. Entre em contato com o suporte.",
      );
    } finally {
      setLoadingSelect(false)
    }
  }, [currentCustomer, setDrivers, pressClearButton])

  const fetchUsers = useCallback(async () => {
    try {
      setLoadingSelect(true)

      const responseUsers = await getUserByCustomers(currentCustomer);

      if (responseUsers.status !== 200) {
        throw new Error("Error fetching data");
      }
      const users = responseUsers.data.user;

      if (state && !pressClearButton) {
        const { receivers } = state.content;
        if (receivers?.web) {
          const foundUsers = receivers.web && users.filter(user => receivers?.web.includes(user.email));
          setSelectedUsers(foundUsers);
        }
      }
      setUsers(users);
    } catch (err) {
      console.log('err', err);
      setUsers([]);
      console.log(
        "Erro ao carregar lista de Usuarios. Entre em contato com o suporte.",
      );
    } finally {
      setLoadingSelect(false)
    }
  }, [currentCustomer, setUsers, pressClearButton])

  const handleSelectedData = async (data) => {
    setErrorUsers(false)
    if (channelsWatch === 'app') {
      let driverIds = data.map((item) => item.id);
      setSelectedDrivers(data);
    } else {
      let userEmails = data.map((item) => item.email);
      setSelectedUsers(data);
    }
  };

  const handleClear = () => {
    reset({
      notificationField: {
        channels: 'web',
        titleWeb: '',
        subtitle: '',
        bodyWeb: '',
        titleApp: '',
        bodyApp: '',
      }
    });
    setValue('notificationField.channels', 'web')
    handleSelectedData([])
    setOption(options.default);
    setDates({
      initialDate: dayjs().format(formatDate)
    });
    setPressClearButton(true);
  };

  const fetchState = useCallback(async () => {
    try {
      const response = await getNotificationById(state.notificationId)

      if(response.status !== 200) {
        throw new Error("Erro ao obter os dados da notificação");
      }

      const { type, platform, title, subtitle, body } = response.data.data;

      setOption(options[type]);
      setValue('notificationField.channels', platform);

      if (platform === 'web') {
        setValue('notificationField.titleWeb', title);
        setValue('notificationField.subtitle', subtitle);
        setValue('notificationField.bodyWeb', body);
      }

      if (platform === 'app') {
        setValue('notificationField.titleApp', title);
        setValue('notificationField.bodyApp', body);
      }

      handleDate(new Date(state?.publishAt));

    } catch (error) {
      toast.error(error.message)
    }
  }, [])

  useEffect(() => {
    if (state) {
      fetchState()
    }
  }, [state, fetchState])

  useEffect(() => {
    if (channelsWatch === 'app') {
      fetchDrivers()
    } else {
      fetchUsers()
    }
  }, [channelsWatch])

  useEffect(() => {
    if (channelsWatch === 'app') {
      if (titleAppWatch || bodyAppWatch) {
        setIsDisabledCancelButton(false)
      } else {
        setIsDisabledCancelButton(true)
      }
    }
    if (channelsWatch === 'web') {
      if (titleWebWatch || bodyWebWatch || subtitleWatch) {
        setIsDisabledCancelButton(false)
      } else {
        setIsDisabledCancelButton(true)
      }
    }
  }, [titleWebWatch, titleAppWatch, bodyWebWatch, bodyAppWatch, subtitleWatch, channelsWatch]);

  useEffect(() => {
    handleAmplitudeEvent('New Notification Screen Viewed');
  }, []);

  return (
    <Aux>
      <PermissionsGate scopes={['can_view_consolidated']}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container item xs={12} xl={12} style={{ marginTop: 16, flexDirection: 'column', gap: 4 }}>
            <Grid container item style={{ justifyContent: 'space-between' }}>
              <Grid container item xs={8} xl={8} style={{ flexDirection: 'column' }}>
                <Typography variant="h3" className={classes.title}>{state ? 'Edição de notificação' : 'Cadastro de notificação'}</Typography>
                <Typography className={classes.subTitle}> {state ? 'Edite os dados da notificação antes dela ser enviada aos usuários.' : 'Cadastre uma nova notificação para ser enviada aos usuários.'}</Typography>
              </Grid>
              <Box>
                <Button
                  color="secondary"
                  variant='outlined'
                  className={classes.buttonBack}
                  onClick={() => {
                    handleAmplitudeEvent('Backward Button Clicked');
                    history.push('/gofurther/notificationhistory');
                  }}
                >
                  Voltar
                </Button>
              </Box>

            </Grid>
            <Grid container item xs={12} xl={12} >
              <Grid item xl={5} lg={6} md={12} sm={12} xs={12} className={classes.form} gap={2}>
                <Accordion className={classes.accordion} expanded={expanded} onChange={() => setExpanded(!expanded)}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>{option.content}</Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionDrop} >
                    {Object.keys(options)
                      .filter(option => option !== 'default')
                      .map(optionKey => (
                        <Typography key={optionKey} onClick={() => handleOptionClick(options[optionKey])}>
                          {options[optionKey].content}
                        </Typography>
                      ))}
                  </AccordionDetails>
                </Accordion>
                {errorAccordion && (
                  <p className={classes.error}>Deve selecionar um tipo de notificação.</p>
                )}
                <Container className={classes.checkboxes}>
                  <Typography variant="h6" gutterBottom>
                    Marque abaixo os canais que deseja compartilhar a notificação criada:
                  </Typography>
                  <FormGroup row>
                    <FormControl>
                      <Controller
                        name="notificationField.channels"
                        control={control}
                        defaultValue="web"
                        render={({ field }) => (
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            {...field}
                          >
                            <FormControlLabel value="web" control={<Radio sx={{ '&.Mui-checked': { color: '#F7DF36' } }} />} label="Web" disabled={loadingSelect} />
                            <FormControlLabel value="app" control={<Radio sx={{ '&.Mui-checked': { color: '#F7DF36' } }} />} label="Aplicativo" disabled={loadingSelect} />
                          </RadioGroup>
                        )}
                      />
                    </FormControl>
                  </FormGroup>
                </Container>
                <Grid container style={{ justifyContent: 'space-between', marginTop: 16 }}>
                  <Grid className={classes.boxInput}>
                    {channelsWatch === 'app' && <MultiSelectDriversNotifications
                      labelAllData='Selecionar todos'
                      loading={loadingSelect}
                      isSingleMode={false}
                      listData={drivers}
                      selectedData={selectedDrivers}
                      handleSelectedListData={handleSelectedData}
                      placeholder={'Todos os motoristas'}
                      label={'Todos os motoristas'}
                      icon={<Group />}
                    />}
                    {channelsWatch === 'web' && <MultiSelectUsersNotifications
                      labelAllData='Selecionar todos'
                      loading={loadingSelect}
                      isSingleMode={false}
                      listData={users}
                      selectedData={selectedUsers}
                      handleSelectedListData={handleSelectedData}
                      placeholder={'Todos os usuários'}
                      label={'Todos os usuários'}
                      icon={<TruckIcon />}
                    />}
                  </Grid>
                  <div className={classes.boxInput}>
                    <EventIcon className={classes.calendarIcon} />
                    <DateRangePicker
                      initialSettings={{
                        startDate: dates.initialDate,
                        singleDatePicker: true,
                        timePicker24Hour: true,
                        timePicker: true,
                        minDate: new Date(),
                        locale: {
                          format: 'DD/MM/YYYY HH:mm:ss',
                          daysOfWeek,
                          monthNames,
                          applyLabel: 'Aplicar',
                          cancelLabel: 'Cancelar',
                        },
                        opens: 'center'
                      }}
                      autoApply={true}
                      onApply={(event, picker) => {
                        handleDate(picker.startDate._d);
                      }}
                    >
                      <Input data-cy='inputCalendar'
                        className={classes.inputCalendar}
                        value={dates.initialDate}
                      />
                    </DateRangePicker>
                    <span className={classes.arrowIcon}>
                      <hr />
                      <KeyboardArrowDownIcon />
                    </span>
                  </div>
                </Grid>
                {errorUsers && (
                  <p className={classes.error}>Deve selecionar no mínimo um usuário.</p>
                )}
                <div className={classes.editor} style={{ marginTop: 16 }}>
                  {channelsWatch === 'web' && (
                    <>
                      <Typography variant="body1" className={classes.editorTitle}>Nova notificação</Typography>
                      <Typography variant="body1" className={classes.inputSubTitle}>Digite o título</Typography>
                      <Controller
                        name="notificationField.titleWeb"
                        rules={{ required: channelsWatch === 'web' ? true : false }}
                        control={control}
                        render={({ field }) => <TextField {...field} id="outlined-basic" variant="outlined" margin="normal" />}
                      />
                      {errors.notificationField?.titleWeb && errors.notificationField?.titleWeb.type === "required" && (
                        <p className={classes.error}>O título é obrigatório</p>
                      )}
                      <Typography variant="body1" className={classes.inputSubTitle}>Digite o subtítulo</Typography>
                      <Controller
                        name="notificationField.subtitle"
                        control={control}
                        rules={{ required: channelsWatch === 'web' ? true : false }}
                        render={({ field }) => <TextField {...field} id="outlined-basic" variant="outlined" margin="normal" />}
                      />
                      {errors.notificationField?.subtitle && errors.notificationField?.subtitle.type === "required" && (
                        <p className={classes.error}>O subtítulo é obrigatório</p>
                      )}
                      <>
                        <Typography variant="body1" className={classes.inputSubTitle}>Digite o conteúdo da notificação</Typography>
                        <Controller
                          name="notificationField.bodyWeb"
                          control={control}
                          rules={{ required: channelsWatch === 'web' ? true : false }}
                          render={({ field: props }) => {
                            return (
                              <ReactQuill
                                {...props}
                                theme="snow"
                                value={props.value}
                                modules={reactQuillEditorModules}
                                formats={reactQuillEditorFormats}
                              />
                            )
                          }}
                        />
                      </>
                      {errors.notificationField?.bodyWeb && errors.notificationField?.bodyWeb.type === "required" && (
                        <p className={classes.error}>O conteúdo é obrigatório</p>
                      )}
                    </>
                  )}
                  {channelsWatch === 'app' && (
                    <>
                      <Typography variant="body1" className={classes.editorTitle}>Nova notificação</Typography>
                      <Typography variant="body1" className={classes.inputSubTitle}>Digite o título</Typography>
                      <Controller
                        name="notificationField.titleApp"
                        control={control}
                        rules={{ required: channelsWatch === 'app' ? true : false }}
                        render={({ field }) => <TextField {...field} id="outlined-basic" variant="outlined" margin="normal" />}
                      />
                      {errors.notificationField?.titleApp && errors.notificationField?.titleApp.type === "required" && (
                        <p className={classes.error}>O título é obrigatório</p>
                      )}
                      <Typography variant="body1" className={classes.inputSubTitle}>Digite o conteúdo da notificação</Typography>
                      <Controller
                        name="notificationField.bodyApp"
                        rules={{ required: channelsWatch === 'app' ? true : false }}
                        control={control}
                        render={({ field }) => <TextField {...field} id="outlined-basic" variant="outlined" margin="normal" />}
                      />
                      {errors.notificationField?.bodyApp && errors.notificationField?.bodyApp.type === "required" && (
                        <p className={classes.error}>O conteúdo é obrigatório</p>
                      )}
                    </>
                  )}
                  <div className={classes.controlButtons} >
                    <Button type='submit' className={classes.publish}>
                      Publicar
                    </Button>
                    <Button
                      type='button'
                      disabled={!!isDisabledCancelButton}
                      className={isDisabledCancelButton ? classes.deleteDisabled : classes.delete}
                      onClick={() => {
                        handleAmplitudeEvent('Erase Button Clicked');
                        handleClear();
                      }}
                      >
                      <DeleteOutlineIcon className={isDisabledCancelButton ? classes.iconColorDisabled : classes.iconColor} />
                      Apagar
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </form>
        <Dialog
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          style={{ zIndex: 999 }}
          maxWidth={'xs'}
          PaperProps={{ style: { borderRadius: 24, padding: '0 24px' } }}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description" style={{ color: '#181814', fontSize: 18, fontWeight: 600, fontFamily: 'Roboto', lineHeight: '140%', marginBottom: 8 }}>
              Não é possível editar a notificação
            </DialogContentText>
            <DialogContentText id="alert-dialog-description" style={{ color: '#595959', fontSize: 18, fontFamily: 'Roboto', lineHeight: '140%' }}>
              A notificação está prestes a ser enviada ou já foi enviada e por isso não é possível editá-la.
            </DialogContentText>
            <Grid container spacing={2} style={{ padding: 32 }}>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Button
                  style={{ borderRadius: 16, height: 48, textTransform: 'none', fontSize: 16, fontWeight: 700, color: '#181814' }}
                  fullWidth
                  onClick={() => navigate.push('/gofurther/notificationhistory')}
                  variant='outlined'
                  className={classes.buttonBack}
                >
                  Voltar
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </PermissionsGate>
    </Aux>
  );
}

