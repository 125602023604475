import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";

// components
import { makeColumns } from "./tableColumns";
import TableResponsive from "../TableResponsive";

// hooks
import {useWindowSize} from "../../hooks/useWindowsSize";

// styles
import useStyles from "./styles";

export default function ModalComparisonTripListDetail({ selectedComparisonTripListDetail, data, open, handleClose }) {
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const classes = useStyles();
  const size = useWindowSize()
  const columns = makeColumns(data);
  const download = {
    link: "/web/v2/performance/xls/byvehicle",
    fileName: `detalhamento-motoristas-veiculo_${dayjs().format("DD-MM-YYYY_HH-mm")}.xlsx`,
    params: selectedComparisonTripListDetail
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"lg"}
      fullWidth
      fullScreen={size.mobile}
    >
      <DialogTitle className={classes.titleRoot}>
        <Typography>
          Detalhamento de motoristas do veículo
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
          size="large">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ padding: '8px 24px' }}>
        <TableResponsive
          columns={columns}
          data={data}
          pointer={true}
          download={download}
          options={{ rowsPerPage }}
          setRowsPerPage={setRowsPerPage}
          tableName="comparison-trip-list-details"
        />
      </DialogContent>
      <DialogActions style={{ padding: '24px' }}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            className={classes.btnBack}
            onClick={handleClose}
          >
            Voltar
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
