import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  container: {
    position: "relative",
    height: "450px",
    borderRadius: 28,
    padding: 8,
    /* fix to print */
    "& div > img": {
      position: "absolute",
    }
  },
  header: {
    fontSize: "13px",
    fontWeight: "bold",
    color: theme.palette.text.primary,
  },
  subheader: {
    height: "50px",
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
  },
  containerMap: {
    height: "380px",
    padding: 0,
  },
  disabled: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 100,
    backgroundColor: 'rgba(0,0,0,0.7)',
  },
  disabledText: {
    color: 'white',
    position: 'absolute',
    top: 'calc(50%)',
    left: 'calc(50%)',
    transform: 'translateY(-50%) translateX(-50%)',
  }
}));
