import api from "./api";

export const customersUrl = (customers = "") => {
  let partialUrl = "";
  if (customers) {
    partialUrl = Array.isArray(customers) ? customers.join() : customers;
  }
  return partialUrl;
};


export const getCustomersList = async (email) => {
  try {
    const response = await api.get(`/list-customers?user=${email}&relation=view`);
    return response;
  } catch (err) {
    throw err;
  }
};
export const getAllCustomersByEmail = async (email) => {
  if (email) {
    const response = await api.get(
      `/list-customers?user=${email}&relation=view`,
    );

    const customers = response.data.data
      ? response.data.data.sort((a, b) => a - b)
      : [];

    return customers;
  }
}

export const getCustomers = async () => {
  try {
    const response = await api.get(`/customers`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getCustomersById = async (ids) => {
  if (!ids) {
    return;
  }
  try {
    const response = await api.get(`/customers?ids=${ids}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getCustomerDetails = async (id) => {
  try {
    const response = await api.get(`/customers/${id}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const createCustomer = async (data) => {
  try {
    const response = await api.post(`/customers`, data);
    return response;
  } catch (err) {
    throw err;
  }
};

export const deleteCustomer = async (id) => {
  try {
    const response = await api.delete(`/customers/${id}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const updateCustomer = async (data) => {
  try {
    const response = await api.put(`/customers/${data.id}`, data);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getProfileCustomer = async (email, currentCustomer) => {
  try {
    const response = await api.get(`web/v2/core/users/amplitude/info?email=${email}&customer_id=${currentCustomer}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getAmplitudeScoreCustomer = async (currentCustomer) => {
  try {
    const response = await api.get(`web/v2/core/amplitude/info?customer_id=${currentCustomer}`);
    return response;
  } catch (err) {
    throw err;
  }
};
