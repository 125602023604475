import React, { useState, useEffect, useCallback } from "react";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useTheme } from "@mui/styles";
import classNames from "classnames";

// store
import { useAppSelector } from "store";

// services
import { getOperationTypes, deleteOperationType } from "services/operationType";

// components
import Aux from "hoc/auxiliar";
import Widget from "components/Widget/Widget";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";
import Table from "components/Table";
import TableResponsive from "components/TableResponsive";

import { makeColumns } from "./tableColumns";

// hooks
import { useWindowSize } from "hooks/useWindowsSize";

import useStyles from "./styles";

const AllRewardsGroups = () => {
  const { currentCustomer } = useAppSelector((state) => state.global.user);
  const classes = useStyles();
  const [dataTable, setDataTable] = useState([]);
  const history = useHistory();
  const theme = useTheme();
  const customer = localStorage.getItem('customers')
  const [selectedRowData, setSelectedRowData] = useState();
  const [selectedRowName, setSelectedRowName] = useState();
  const [idRow, setId] = useState();
  const size = useWindowSize()

  const handleDeleteRewardGroup = async (id, name) => {
    const { isConfirmed } = await Swal.fire({
      title: `Tem certeza que deseja excluir o grupo ${name}?`,
      text: "Essa ação é irreversível!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: theme.palette.primary.main,
      cancelButtonColor: theme.palette.error.main,
      confirmButtonText: "Sim, excluir!",
      cancelButtonText: "Cancelar",
    });


    if (isConfirmed) {
      try {
        await deleteOperationType(id, customer);
        setDataTable((prevState) => prevState.filter((gp) => gp.id !== id));
        Swal.fire(
          "Excluído!",
          "O registro foi excluído com sucesso",
          "success",
        );
      } catch (err) {
        console.log(err);
        toast.error("Erro ao excluir veículo. Entre em contato com o suporte.");
      }
    }
  };

  const fetchAllOperationsType = useCallback(async () => {
    try {
      const response = await getOperationTypes(currentCustomer);
      const formatedDataTable = response.data.operationTypes.map((op) => ({
        name: op.name,
        id: op.id,
      }));
      setDataTable(formatedDataTable);
    } catch (err) {
      toast.error(
        "Erro ao pegar lista de customers! Entre em contato com o suporte.",
      );
    }
  }, [currentCustomer]);

  const handleEditRewardGroup = (id) => history.push(`/gofurther/rewardGroup/edit/${id}`);

  const columns = makeColumns(handleDeleteRewardGroup, handleEditRewardGroup, handleEditRewardGroup, size);

  const handleClickAddRewardGroup = () => history.push("/gofurther/rewardGroup/add-rewardGroup");

  useEffect(() => {
    if (currentCustomer && hasPermission({ scopes: ['can_view_rewardgroup'] })) {
      fetchAllOperationsType();
    }
  }, [currentCustomer, fetchAllOperationsType]);

  const handleSelectRow = (data, data2) => {
    setId(data2.dataIndex);
    const id = dataTable[data2.dataIndex].id;
    setSelectedRowName(dataTable[data2.dataIndex].name);
    setSelectedRowData(id)
  }

  const getEditOrViewButton = () => {
    let buttonText;

    if (hasPermission({ scopes: ['can_view_operations_without_edit'] })) {
      buttonText = 'Visualizar'
    } else if (hasPermission({ scopes: ['can_edit_rewardgroup'] })) {
      buttonText = 'Editar'
    } else {
      return;
    }

    return <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
      <Box >
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          onClick={() => handleEditRewardGroup(selectedRowData)}
          disabled={!selectedRowData}
          className={classes.buttonDetail}
        >
          {buttonText}
        </Button>
      </Box>
    </Grid>

  }

  return (
    <Aux>
      <PermissionsGate scopes={['can_view_rewardgroup']}>
        <Grid container spacing={4}>
          <Grid item xs={12} className={classes.tableContent}>
            <PermissionsGate scopes={['can_create_rewardgroup']}>
              <Grid container justifyContent="flex-end">
                <Grid item xl={2} lg={3} md={4} sm={12} xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    className={classes.btnNew}
                    onClick={handleClickAddRewardGroup}
                  >
                    Novo grupo
                  </Button>
                </Grid>
              </Grid>
            </PermissionsGate>
            <Widget disableWidgetMenu title="Gerenciamento de grupos de bonificação">
              {size.mobile ?
                <>
                  <TableResponsive
                    pointer={true}
                    options={{
                      onRowClick: handleSelectRow,
                      setRowProps: (row, index) => {
                        return index === idRow ? { style: { backgroundColor: '#FFF5CB', cursor: 'pointer', width: '100%' } } : null
                      },
                    }}
                    columnProps={true}
                    columns={columns}
                    data={dataTable}
                    tableName="list-group-bonus"
                  />
                  {/* botoes */}
                  <PermissionsGate scopes={['can_edit_rewardgroup', 'can_remove_rewardgroup']}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Grid container justifyContent="flex-end" spacing={2}>
                        {getEditOrViewButton()}
                        <PermissionsGate scopes={['can_remove_rewardgroup']}>
                          <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                            <Box >
                              <Button
                                fullWidth
                                variant="outlined"
                                className={classNames(
                                  classes.buttonDetail,
                                  {[classes.buttonDelete]:selectedRowData}
                                )}
                                onClick={() => handleDeleteRewardGroup(selectedRowData, selectedRowName)}
                                disabled={!selectedRowData}
                              >
                                Excluir
                              </Button>
                            </Box>
                          </Grid>
                        </PermissionsGate>
                      </Grid>
                    </Grid>
                  </PermissionsGate>
                </> : <Table
                  columns={columns}
                  data={dataTable}
                  tableName="list-group-bonus"
                />}
            </Widget>
          </Grid>
        </Grid>
      </PermissionsGate>
    </Aux>
  );
};

export default AllRewardsGroups;
