import React, { useState } from 'react';
import { Paper, InputBase, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { virtualizedTable } from '../styles';

export function SearchBar({ onSearch }) {
  const classes = virtualizedTable();
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    onSearch(value);
  };

  const handleClear = () => {
    setSearchTerm('');
    onSearch('');
  };

  return (
    <Paper className={classes.searchBar}>
      <SearchIcon style={{ color: '#888', marginRight: '8px', fontSize: '18px' }} />
      <InputBase
        style={{ flex: 1, fontSize: '14px', color: '#555' }}
        placeholder="Buscar em todas as colunas..."
        value={searchTerm}
        onChange={handleSearch}
        autoFocus
      />
      {searchTerm && (
        <IconButton size="small" onClick={handleClear} style={{ padding: '4px' }}>
          <CloseIcon fontSize="small" style={{ fontSize: '18px' }} />
        </IconButton>
      )}
    </Paper>
  );
}
