import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import dayjs from "dayjs";

import EventIcon from '@mui/icons-material/Event';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

import useStyles from './styles';

function VehiclePerformanceDetails({ vehicleProps }) {
  const classes = useStyles()
  const formatDate = "DD/MM/YYYY HH:mm";

  return (
    <>
      <Grid container>
        <Typography className={classes.title}>Performance do Veículo</Typography>
      </Grid>
      <Paper className={classes.paper}>
        <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid className={classes.wrapperContent} container item xl={8} lg={8} md={8} sm={8} xs={8} >
            <Grid className={classes.flex}>
              <LocalShippingIcon className={classes.icon} />
              <Typography>{vehicleProps.vehicleIdentification}</Typography>
            </Grid>
            <Grid className={classes.flex}>
              <EventIcon className={classes.icon} />
              <Typography className={classes.icon}>{dayjs(vehicleProps.startDate).format(formatDate)}</Typography>
              <Typography className={classes.icon}>{dayjs(vehicleProps.finishDate).format(formatDate)}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}

export default VehiclePerformanceDetails;