import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Grid, Button, Box, Typography, Paper } from "@mui/material";
import { useTheme } from "@mui/styles";
import { useForm, FormProvider } from "react-hook-form";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// store
import { useAppSelector } from "store";

// services
import { getDrivers } from "services/driver";
import { assignDriverToVehicle, updateAssignDriver } from "services/vehicle";
import { handleAmplitudeEvent } from 'services/amplitude';

// helpers
import { verifyAssignVehicle } from "helpers/vehicles";

// components
import Aux from "hoc/auxiliar";
import AssignForm from "components/AssignForm";
import BackButton from "components/BackButton";
import PermissionsGate from "components/PermissionsGate";

// styles
import useStyles from "./styles";

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

const AssignDriver = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { state } = useLocation();
  const { currentCustomer } = useAppSelector((state) => state.global.user);
  const { params: currentVehicle, isEdit, editData } = state;
  const formatDate = "DD/MM/YYYY HH:mm";

  const [drivers, setDrivers] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const defaultValues = {
    drivers: "",
    startDate: dayjs().toDate(),
    isChecked: true,
    endDate: dayjs().toDate()
  };

  const submitSchema = yup.object().shape({
    drivers: yup
      .object()
      .shape({
        value: yup.number().required("Campo obrigatório"),
        label: yup.string().required(),
      })
      .nullable()
      .required("Campo obrigatório"),
    startDate: yup
      .date()
      .required('Campo obrigatório'),
    endDate: yup
      .date()
  });

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(submitSchema)
  });

  const { setValue, resetField, setError } = methods;

  const swalConflictAlert = (text, modelSubmit) => {
    Swal.fire({
      title: "Atenção!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: theme.palette.secondary.main,
      cancelButtonColor: theme.palette.error.main,
      confirmButtonText: "Sim, atualizar!",
      cancelButtonText: "Cancelar",
      html: text
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await updateAssignDriver(modelSubmit);

          if (response.status !== 200 || response?.data?.err) {
            throw new Error("Erro ao atualizar");
          } else {
            toast.success("Novo vínculo criado com sucesso.");
            setTimeout(() => history.goBack(), 500);
          }
        } catch (error) {
          toast.error("Erro ao vincular motorista. Contate o suporte.");
        }
      }
    });
  };

  const onSubmit = async (data) => {
    const selectedDriverId = data.drivers?.value;
    const selectedVehicle = currentVehicle[4];
    const selectedVehicleId = selectedVehicle.vehicleId;

    const startDate = dayjs(data.startDate).tz().format("YYYY-MM-DDTHH:mm:ssZZ");
    const endDate = dayjs(data.endDate).tz().format("YYYY-MM-DDTHH:mm:ssZZ");

    const isAfter = dayjs(data.endDate).startOf('minute').isAfter(dayjs(data.startDate).startOf('minute'));

    if (!isAfter && !data.isChecked) {
      setError('endDate', { type: 'custom', message: 'A data final deve ser superior a data inicial' });

      return;
    }

    const model = !!data.isChecked ? {
      vehicleId: selectedVehicleId,
      driverId: Number(selectedDriverId),
      startDate: startDate,
      recalcInfo: true
    } : {
      vehicleId: selectedVehicleId,
      driverId: Number(selectedDriverId),
      startDate: startDate,
      endDate: endDate,
      recalcInfo: true
    };

    try {
      setLoading(true);
      setIsDisabled(true);
      const driverNameFiltered = drivers.find((item) => item.id === selectedDriverId)
      const driverName = driverNameFiltered.name;

      const { hasConflict } = await verifyAssignVehicle(selectedVehicleId, selectedDriverId, startDate, endDate);

      if (hasConflict?.length > 0) {
        let conflictMessage = "";
        let checkConflict = false;

        hasConflict.map(conflict => {
          const linkStartDate = dayjs(conflict.obj.startDate).format(formatDate);
          const linkEndDate = conflict.obj.endDate ? dayjs(conflict.obj.endDate).format(formatDate) : '';

          if (conflict.source === 'vehicle') {
            checkConflict = true;
            conflictMessage += `• O veículo <b>${currentVehicle[0]} - ${currentVehicle[2]}</b> está vinculado ao motorista <b>${conflict.obj.driverName}</b> de ${linkStartDate}`;
          }

          if (conflict.source === 'driver') {
            checkConflict = true;
            conflictMessage += `• O(a) motorista ${driverName} está vinculado ao veículo <b>${conflict.obj.vehicleIdentification}</b> de ${linkStartDate}`;
          }

          conflictMessage += linkEndDate ? ` até ${linkEndDate}.` : ' até agora.';
          conflictMessage += '<br />';
        });

        if (checkConflict) {
          swalConflictAlert(
            `No período informado: <br/><br/> ${conflictMessage}<br/>Deseja remover o vínculo atual e atualizar para o novo?`,
            model
          );
        }
        
        return;
      }

      const response = await assignDriverToVehicle(model);

      if (response.status !== 200 || response?.data?.err) {
        throw new Error("Erro ao vincular motorista");
      }

      toast.success("Vínculo realizado com sucesso.");

      if (isEdit) {
        handleAmplitudeEvent('Driver/Vehicle Link Edited');
      }
      if (!isEdit) {
        handleAmplitudeEvent('Driver/Vehicle Link Created');
      }

      setTimeout(() => history.goBack(), 500);

    } catch (error) {
      toast.error("Erro ao vincular motorista, contate o suporte.");
    } finally {
      setLoading(false);
      setIsDisabled(false);
    }
  };

  const fetchDrivers = async () => {
    try {
      const response = await getDrivers(currentCustomer);

      if (response.status !== 200) {
        throw new Error();
      }

      setDrivers(response.data.drivers);
    } catch (error) {
      toast.error('Erro ao carregar lista de motoristas. Contate o suporte')
    }
  };

  const fetchEditData = () => {
    const { driverId, startDate, endDate } = editData;
    const isChecked = endDate === null;
    const selectedDriver = drivers.find((driver) => driver.id === driverId);
    const selectedDriverOption = {
      value: selectedDriver.id,
      label: selectedDriver.name,
      vehicleId: selectedDriver.customerId
    };

    setValue("drivers", selectedDriverOption);
    setValue("startDate", dayjs(startDate).toDate());
    if (endDate) {
      setValue("endDate", dayjs(endDate).toDate());
    } else {
      resetField("endDate");
    }
    setValue("isChecked", !!isChecked);
  };

  useEffect(() => {
    drivers && isEdit && fetchEditData();
  }, [drivers]);

  useEffect(() => {
    currentCustomer && fetchDrivers();
  }, [currentCustomer]);

  return (
    <Aux>
      <PermissionsGate scopes={["can_create_drivers"]}>
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box>
              <Grid container item lg={10} md={12} sm={12} xs={12}>
                <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                  <Paper className={classes.paperText}>
                    <Typography className={classes.titleText}>Veículo: </Typography>
                    <Typography>{state.params[0]}</Typography>
                  </Paper>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <Paper className={classes.paperText}>
                    <Typography className={classes.titleText}>Placa: </Typography>
                    <Typography>{state.params[2]} </Typography>
                  </Paper>
                </Grid>
                {!isEdit && <Grid item lg={4} md={4} sm={12} xs={12}>
                  <Paper className={classes.paperText}>
                    {state.params[3]?.driverName ? (
                      <>
                        <Typography className={classes.titleText}>Motorista atual: </Typography>
                        <Typography>{state.params[3].driverName}</Typography>
                      </>
                    ) : <Typography>Sem motorista vinculado.</Typography>}
                  </Paper>
                </Grid>}
              </Grid>
              <Grid item xl={12} xs={12} className={classes.table}>
                <FormProvider {...methods}>
                  <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Grid className={classes.titleBox}>
                      <Typography variant="h1" className={classes.title}>
                        {!isEdit ? 'Novo vínculo' : 'Editar vínculo'}
                      </Typography>
                    </Grid>
                    <AssignForm drivers={drivers} isEdit={isEdit} />
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Grid container justifyContent="flex-end" spacing={2}>
                        <Grid
                          container
                          item
                          justifyContent="flex-end"
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                        >
                          <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                            <Box sx={{ m: 2 }}>
                              <BackButton className={classes.backButton} />
                            </Box>
                          </Grid>
                          <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                            <Box sx={{ m: 2 }}>
                              <Button
                                fullWidth
                                variant="contained"
                                type="submit"
                                className={classes.btConfirm}
                                disabled={isDisabled}
                              >
                                {loading ? 'Carregando...' : 'Vincular Motorista'}
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                </FormProvider>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </PermissionsGate>
    </Aux>
  );
};

export default AssignDriver;
