import { Box, Skeleton } from "@mui/material";

const OperationsListSkeleton = () => {
  return (
    <Box display="flex" flexDirection="column" gap={2} mt={2}>
      <Skeleton variant="rectangular" width="100%" height={40} style={{ borderRadius: '10px', maxWidth: 120, alignSelf: 'flex-end' }} />
      <Skeleton variant="rectangular" width="100%" height={35} style={{ maxWidth: 200 }} />
      <Skeleton variant="rectangular" width="100%" height={35} style={{ borderRadius: '10px', maxWidth: 270, alignSelf: 'flex-end' }} />
      <Skeleton variant="rectangular" height={300} style={{ borderRadius: '10px' }} />
      <Skeleton variant="rectangular" height={35} />
    </Box>
  );
};

export default OperationsListSkeleton;
