import { convertDateBr } from "helpers/dates";
import { valueToPositiveFloat } from "helpers/functions";
import { convertLastBondPointToChart } from "helpers/convertLastBondPointToChart";

export default function getChartSettings(
  chartId,
  initialDate,
  finalDate,
  greenRpmRangeBegin,
  greenRpmRangeEnd,
  handleTooltipData,
  maxFuelCapacity,
  maxSpeedAllowed,
  seconds,
  seriesColors,
  theme,
  datesLastBondPoint,
  hasGNV
) {
  const xaxisMapped = convertLastBondPointToChart(datesLastBondPoint);
  const fuelMeasure = hasGNV ? "m³" : "L";

  return {
    chart: {
      id: chartId,
      toolbar: {
        show: true,
        tools: {
          download: false,
        },
      },
      fontFamily: theme.typography.fontFamily,
      animations: {
        enabled: false,
      },
      zoom: {
        enabled: true,
        type: 'x',
        autoScaleYaxis: false,
      },
      events: {
        zoomed: (chartContext, { xaxis }) => {
          const { min, max } = xaxis;

          if (min < initialDate || max > finalDate) {
            chartContext.updateOptions({
              xaxis: {
                min: Math.max(min, initialDate),
                max: Math.min(max, finalDate),
              },
            }, false, false);
          }
        },
        beforeResetZoom: (chartContext) => {
          chartContext.updateOptions({
            xaxis: {
              min: initialDate,
              max: finalDate,
            },
          }, false, false);
        }
      },
    },
    markers: {
      size: 0,
    },
    dataLabels: { enabled: false },
    stroke: {
      width: 2,
    },
    xaxis: {
      min: initialDate,
      max: finalDate,
      type: "datetime",
      labels: {
        style: {
          color: theme.palette.text.hint,
          fontSize: "0.6rem",
        },
        formatter: function (_value, timestamp) {
          return convertDateBr(timestamp, seconds);
        },
      },
      tooltip: {
        enabled: true,
      },
    },
    yaxis: [
      {
        seriesName: "RPM",
        title: {
          text: "RPM",
          style: {
            color: seriesColors.rpm,
            fontSize: "0.8rem",
          },
        },
        labels: {
          offsetX: -30,
          style: {
            color: theme.palette.text.hint,
            fontSize: "0.6rem",
          },
          formatter: (value) => {
            return valueToPositiveFloat(value, 0);
          },
        },
      },
      {
        seriesName: "Altitude",
        opposite: true,
        title: {
          text: "Altitude",
          style: {
            color: seriesColors.altitude,
            fontSize: "0.8rem",
          },
        },
        labels: {
          offsetX: 0,
          style: {
            color: theme.palette.text.hint,
            fontSize: "0.6rem",
          },
          formatter: (value) => {
            return valueToPositiveFloat(value, 0);
          },
        },
      },
      {
        seriesName: "Combustível",
        title: {
          text: "Combustível",
          style: {
            color: seriesColors.fuel,
            fontSize: "0.8rem",
          },
        },
        labels: {
          offsetX: -25,
          style: {
            color: theme.palette.text.hint,
            fontSize: "0.6rem",
          },
          formatter: (value) => {
            return valueToPositiveFloat(value, 0);
          },
        },
        max: maxFuelCapacity + 100
      },
      {
        seriesName: "Velocidade",
        opposite: true,
        title: {
          text: "Velocidade",
          style: {
            color: seriesColors.speed,
            fontSize: "0.8rem",
          },
        },
        labels: {
          offsetX: 10,
          style: {
            color: theme.palette.text.hint,
            fontSize: "0.6rem",
          },
          formatter: (value) => {
            return valueToPositiveFloat(value, 0);
          },
        },
      },
    ],
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex }) {
        handleTooltipData(series, dataPointIndex);
        return "";
      },
      x: {
        formatter: (date) => {
          return convertDateBr(date, true);
        },
      },
    },
    fill: {
      opacity: [1, 0.25, 1, 1],
      gradient: {
        inverseColors: false,
        shade: "light",
        type: "vertical",
        opacityFrom: 0.25,
        opacityTo: 0.85,
        stops: [0, 100, 100, 100],
      },
    },
    colors: [
      seriesColors.rpm,
      seriesColors.altitude,
      seriesColors.fuel,
      seriesColors.speed,
    ],
    legend: {
      show: false
    },
    annotations: {
      position: "front",
      yaxis: [
        {
          id: "maxFuelCapacity",
          y: maxFuelCapacity,
          yAxisIndex: 2,
          borderColor: theme.palette.primary.light,
          strokeDashArray: 0,
          label: {
            position: "left",
            offsetX: 120,
            borderColor: theme.palette.primary.light,
            style: {
              color: theme.palette.white,
              background: theme.palette.primary.light,
              fontSize: "0.6rem",
            },
            text: `Tanque total - ${maxFuelCapacity}${fuelMeasure}`,
          },
        },
        {
          id: "maxSpeedAllowed",
          y: maxSpeedAllowed,
          yAxisIndex: 3,
          borderColor: theme.palette.primary.main,
          strokeDashArray: 0,
          label: {
            position: "left",
            offsetX: 168,
            offsetY: 17,
            borderColor: theme.palette.primary.main,
            style: {
              color: theme.palette.white,
              background: theme.palette.primary.main,
              fontSize: "0.6rem",
            },
            text: `Vel máx permitida - ${maxSpeedAllowed} Km/h`,
          },
        },
        {
          id: "greenRpmRangeBegin",
          y: greenRpmRangeBegin,
          yAxisIndex: 0,
          borderColor: theme.palette.teal,
          strokeDashArray: 0,
          label: {
            position: "left",
            offsetX: 168,
            offsetY: 17,
            borderColor: theme.palette.teal,
            style: {
              color: theme.palette.white,
              background: theme.palette.teal,
              fontSize: "0.6rem",
            },
            text: `Final do início da faixa verde - ${greenRpmRangeBegin} RPM`,
          },
        },
        {
          id: "greenRpmRangeEnd",
          y: greenRpmRangeEnd,
          yAxisIndex: 0,
          borderColor: theme.palette.teal,
          strokeDashArray: 0,
          label: {
            position: "left",
            offsetX: 168,
            offsetY: 17,
            borderColor: theme.palette.teal,
            style: {
              color: theme.palette.white,
              background: theme.palette.teal,
              fontSize: "0.6rem",
            },
            text: `Final da faixa verde - ${greenRpmRangeEnd} RPM`,
          },
        },
      ],
      ...xaxisMapped,
    },
    grid: {
      padding: {
        top: 30,
        bottom: 10,
        right: 35,
        left: 25,
      },
    },
  };
}