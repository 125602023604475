import { makeStyles } from "@mui/styles";
import { alpha } from '@mui/material/styles';

export default makeStyles((theme) => ({
  container: {
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '1.5rem',
    borderRadius: '1rem',
    background: '#FFF',
    marginTop: '1.5rem',
    maxWidth: '33.5rem',
    
  },
  title: {
    color: '#000',
    fontFamily: 'Roboto',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '120%', 
    paddingBottom: '1.5rem'
  },
  title2: {
    color:  '#595959',
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '150%',
  },
  title3: {
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '150%',
  },
  btBack:{
    borderRadius: '2rem',
    border: '1px solid #FFD31C',
    marginTop:'1rem',
    height: '3rem',
    '&:hover': {
      borderColor: '#FFD31C'
    }
  },
  btConfirm: {
    height: '3rem',
    marginTop:'1.5rem',
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '2rem',
    "&:hover": {
      backgroundColor: alpha(theme.palette.secondary.main, 0.8),
    },
  },
  timePassword:{
    marginBottom:'1rem',
    marginTop:'0.5rem',
  },
  passwordTips: {
    marginBottom:'1.5rem',
  },
  acceptanceCriteria: {
    marginBottom:'1rem',
    marginTop:'1.5rem',
  },
  stylesTextField: {
    marginTop:'1.5rem',
  },
  stylesInputProps: {
    borderRadius:'1rem',
  },
}));
