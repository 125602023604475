import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  container: {
    borderRadius: 28,
    padding: theme.spacing(2),
    minWidth: 140,
    position: 'relative',
  },
  title: {
    marginBottom: '4px',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: 1.5,
    textAlign: 'center',
  }
}));
