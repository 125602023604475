import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  root: {
    "@media print": {
      "& *": {
        maxHeight: "100% !important",
        overflow: "none !important"
      }
    },
    position: "relative",
    zIndex: 0,
    "& .MuiToolbar-root": {
      minHeight: "20px",
    },
    "@media (max-width: 960px)": {
      "& td": {
        borderLeft: "solid 6px #f7df36",
      },
      "& td:last-child": {
        minHeight: "40px",
      },
    },
    "& .MuiToolbar-regular": {
      backgroundColor: theme.palette.background.default,
    },
    "& .MuiIconButton-root": {
      padding: "10px",
    },
    "& thead": {
      "& tr": {
        height: "20px",
      },
      "& tr:first-child": {
        "& th:first-child": {
          borderTopLeftRadius: "10px",
        },
        "& th:last-child": {
          borderTopRightRadius: "10px",
        },
      },
      "& th:not(:last-child)": {
        borderRightWidth: 2,
        borderRightColor: theme.palette.white,
        borderRightStyle: "solid",
      },
      "& th": {
        padding: "5px 15px 0",
        backgroundColor: `${theme.palette.secondary.main} !important`,
        "& .MuiButtonBase-root": {
          margin: 0,
          whiteSpace: "nowrap",
        },
        "& .MuiButton-label": {
          lineHeight: "1",
          fontWeight: "bold",
          fontSize: "0.9rem",
        },
        "& div": {
          lineHeight: "1",
          fontWeight: "bold",
          fontSize: "0.9rem",
          whiteSpace: "nowrap",
          justifyContent: "center",
          width: "100%",
          textAlign: "center",
        },
      },
    },
    "& tbody": {
      "& tr": {
        height: "50px",
      },
      "& td": {
        padding: "0px 20px",
        fontSize: "0.8rem",
        "& p": {
          wordBreak: 'break-word'
        }
      },
    },
    ...customCheckbox(theme),
  },
}));

function customCheckbox(theme) {
  return {
    "& .MuiCheckbox-root svg": {
      width: 16,
      height: 16,
      backgroundColor: "transparent",
      border: `1px solid ${theme.palette.gray.extraLight}`,
      borderRadius: 2,
    },
    "& .MuiCheckbox-root svg path": {
      display: "none",
    },
    "& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg": {
      backgroundColor: theme.palette.primary.light,
      borderColor: theme.palette.primary.light,
    },
    "& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after": {
      position: "absolute",
      display: "table",
      border: `2px solid ${theme.palette.white}`,
      borderTop: 0,
      borderLeft: 0,
      transform: "rotate(45deg) translate(-50%,-50%)",
      opacity: 1,
      transition: "all .2s cubic-bezier(.12,.4,.29,1.46) .1s",
      content: '""',
      top: "50%",
      left: "40%",
      width: 5.71428571,
      height: 9.14285714,
    },
    "& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after":
    {
      width: 8,
      height: 8,
      backgroundColor: theme.palette.primary.light,
      transform: "none",
      top: "40%",
      border: 0,
    },
  };
}