class MenuDTO {
    // format list with childs
    static fromChild(items) {
      let list = {};
      const final_list = [];
  
      items.map(function (item) {
        list.hasOwnProperty(`operation_${item.operation.id}`)
          ? list[`operation_${item.operation.id}`].options.push(item)
          : (list[`operation_${item.operation.id}`] = {
              label: item.operation.name,
              options: [item]
            });
  
        return item;
      });
  
      for (let prop in list) {
        final_list.push(list[prop]);
      }
  
      return final_list;
    }
  
    static fromParent(items) {
      let list = {};
      const final_list = [];
  
      items.map(function (item) {
        list.hasOwnProperty(`operation_${item.id}`)
          ? (list[`operation_${item.id}`].options = item.vehicles)
          : (list[`operation_${item.id}`] = {
              label: item.name,
              options: item.vehicles
            });
  
        return item;
      });
  
      for (let prop in list) {
        final_list.push(list[prop]);
      }
  
      return final_list;
    }
  }
  
  export default MenuDTO;
  
