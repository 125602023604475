import {
  Cell,
  Label,
  Pie,
  PieChart,
  ResponsiveContainer,
  Text,
} from 'recharts';
import tinycolor from 'tinycolor2';

import colors from 'themes/gobrax';

function HalfMoonChart(props) {
  const { value, color, label } = props.data;

  const data = [
    { 
      name: label,
      value: 100 - value,
      color: tinycolor(color).lighten(35).toHexString()
    },
    { 
      name: label, 
      value, 
      color
    },
  ];

  return (
    <ResponsiveContainer height={100} style={{ marginBottom: -36 }}>
      <PieChart style={{ minWidth: 100 }}>
        <Pie
          data={data}
          startAngle={0}
          endAngle={180}
          innerRadius={40}
          outerRadius={50}
          paddingAngle={value === 100 ? 0 : -5} 
          cornerRadius={5}  
          dataKey="value"
          isAnimationActive={false}
        >
          {data.map((entry, index) => (
            <Cell key={index} fill={entry.color} />
          ))}
          <Label
            content={(props) => {
              const { cx, cy } = props.viewBox;

              const yOffset = -8;

              const positioningProps = {
                x: cx,
                y: cy + yOffset,
                textAnchor: 'middle',
                verticalAnchor: 'middle',
              };
              
              const presentationProps = {
                fill: colors.palette.black,
                fontWeight: 500,
                fontSize: 18,
              };

              return (
                <Text
                  {...positioningProps}
                  {...presentationProps}
                >
                  {data[1].value}
                </Text>
              );
            }}
          />
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
}

export default HalfMoonChart;
