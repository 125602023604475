import React, { useState, useRef, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@mui/material/";
import { Close as CloseIcon, CalendarToday as CalendarIcon } from "@mui/icons-material";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import updateLocale from "dayjs/plugin/updateLocale";
import "dayjs/locale/pt-br";
import Swal from "sweetalert2";

// store
import { useAppSelector } from "store";

// services
import { createRecalculation } from 'services/recalculationDrivers';
import { handleAmplitudeEvent } from 'services/amplitude';

// helpers
import makeDefaultValues from "helpers/makeDefaultValues";
import fields from "domain/forms/formRecalculationInitialvalues";

// theme
import { useTheme } from "@mui/styles";

// components
import Calendar from "components/Calendar";
import MultiSelectDrivers from "../MultiSelectDrivers";
import { calendarLanguage } from "../Calendar/calendarLanguage";

// styles
import { multiSelectStyles } from "./multiSelectStyles";
import useStyles from "./styles";

dayjs.extend(updateLocale);
dayjs.extend(localizedFormat);

const ModalRecalculationDriver = ({
  drivers,
  handleClose,
  open,
}) => {
  const { name, email, currentCustomer } = useAppSelector((state) => state.global.user);
  dayjs.updateLocale('pt-br', calendarLanguage);
  dayjs.locale('pt-br');
  
  const classes = useStyles();
  const theme = useTheme();

  const hasDrivers = useMemo(() => {
    return Array.isArray(drivers) && drivers.length > 0;
  }, [drivers]);

  const defaultValues = makeDefaultValues({ fields });
  const methods = useForm({
    defaultValues,
  });

  const formatDate = 'DD/MM/YYYY HH:mm';
  const formatTemplateDB = "YYYY-MM-DDTHH:mm:ssZZ";
  const [dates, setDates] = useState({
    initialDate: dayjs().format('DD/MM/YYYY'),
    finalDate: dayjs().format('DD/MM/YYYY'),
    dbInitialDate: dayjs().format("YYYY-MM-DDT00:00:00ZZ"),
    dbFinalDate: dayjs().format("YYYY-MM-DDT23:59:59ZZ"),
  });
  const selectedDrivers = useRef([]);

  const [showBox, setShowBox] = useState(true);
  const handleShowBox = () => setShowBox(!showBox);

  const handleSelectedDate = (startDate, finalDate) => {
    setDates({
      initialDate: dayjs(startDate).format(formatDate),
      finalDate: dayjs(finalDate).format(formatDate),
      dbInitialDate: dayjs(startDate).format(formatTemplateDB),
      dbFinalDate: dayjs(finalDate).format(formatTemplateDB),
    });
  };

  const handleSelectedData = async (data) => {
    let driverIds = data.map((item) => item.id);
    selectedDrivers.current = driverIds;
  };

  const onSubmit = async () => {
    if (selectedDrivers.current.length === 0) {
      return toast.error(
        'Selecione um ou mais motoristas para efetuar o recálculo.',
      );
    }
    Swal.fire({
      title: 'Atenção!',
      text: 'Você está prestes a alterar a nota e bonificação dos motoristas selecionados. Tenha noção que esse é um processo irreversível. Deseja continuar?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: theme.palette.secondary.main,
      cancelButtonColor: theme.palette.error.main,
      confirmButtonText: 'Sim, atualizar!',
      cancelButtonText: 'Cancelar',
      customClass: {
        container: 'swalAlert'
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await createRecalculation(
            selectedDrivers.current,
            email || name,
            dates.dbInitialDate,
            dates.dbFinalDate,
            currentCustomer
          );
          if (response.data?.err?.status === 401 && response.data?.err?.msg !== '') {
            toast.error(response.data.err.msg);
          }
          toast.success('Recálculo iniciado com sucesso!');
          handleAmplitudeEvent('Bonus Recalculation Created');
          handleClose();
          setShowBox(true);
          setDates({
            initialDate: dayjs().format(formatDate),
            finalDate: dayjs().format(formatDate),
            dbInitialDate: dayjs().format(formatTemplateDB),
            dbFinalDate: dayjs().format(formatTemplateDB),
          });
          selectedDrivers.current = [];
        } catch (error) {
          console.log(error);
          return toast.error(
            'Não foi possível iniciar o recálculo. Contate o suporte',
          );
        }
      }
    });
  };

  const BoxData = () => {
    return (
      <Box
        className={classes.boxContainer}
        flexDirection='column'
        justifyContent='space-between'
      >
        <Grid container spacing={2}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <MultiSelectDrivers
              isSingleMode={false}
              listData={drivers || []}
              handleSelectedListData={handleSelectedData}
              customStyles={multiSelectStyles}
              hasDrivers={hasDrivers}
            />
          </Grid>
        </Grid>
        <DialogActions>
          <Grid container justifyContent='flex-end' spacing={2}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography className={classes.step}>
                Etapa 1 de 2
              </Typography>
            </Grid>
            <Grid item container justifyContent='flex-end' gap={2} xs={12}>
              <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                <Button
                  fullWidth
                  onClick={handleClose}
                  className={classes.backButton}
                >
                  Voltar
                </Button>
              </Grid>
              <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                <Button
                  fullWidth
                  onClick={handleShowBox}
                  variant='contained'
                  className={classes.btConfirm}
                >
                  Avançar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </Box>
    );
  };

  const BoxRecalc = () => {
    return (
      <Box className={classes.boxContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className={classes.label}>
              Por favor, selecione o período de recálculo
            </Typography>
            <Box className={classes.calendarBox}>
              <CalendarIcon
                fontSize='medium'
                data-cy='svgButtonCalendar'
              />
              <Calendar
                selectedDates={{
                  initialDate: dates.dbInitialDate,
                  finalDate: dates.dbFinalDate,
                }}
                handleCalendar={(selectedInitialDate, selectedFinalDate) => handleSelectedDate(selectedInitialDate, selectedFinalDate)}
                maxSpanDays={31}
                showFilterButton={false}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container justifyContent='flex-end' spacing={2}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.step}>
              Etapa 2 de 2
            </Typography>
          </Grid>
          <Grid item container justifyContent='flex-end' gap={2} xs={12}>
            <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
              <Button
                fullWidth
                onClick={handleShowBox}
                className={classes.backButton}
              >
                Voltar
              </Button>
            </Grid>
            <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
              <Button
                fullWidth
                variant='contained'
                type='submit'
                className={classes.btConfirm}
              >
                Recalcular
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <Dialog
      fullWidth
      maxWidth={'md'}
      onClose={handleClose}
      aria-labelledby='customized-dialog-title'
      open={open}
      PaperProps={{
        style: { borderRadius: 22, overflow: 'visible' }
      }}
    >
      <DialogTitle
        id='customized-dialog-title'
        onClose={handleClose}
        className={classes.dialogTitle}>
        <Typography className={classes.title}>
          Recalcular bonificações
        </Typography>
        <Typography className={classes.subtitle}>
          Com base no período selecionado serão recalculados as bonificações do(s) motoristas(s) até o período atual.
        </Typography>
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={handleClose}
          size="large">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)} className={classes.form}>
            {showBox ? <BoxData /> : <BoxRecalc />}
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
};

export default ModalRecalculationDriver;
