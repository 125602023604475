export default {
  textLabels: {
    body: {
      noMatch: "Nenhum registro encontrado",
      toolTip: "Filtrar",
      columnHeaderTooltip: (column) => `Filtrado por ${column.label}`,
    },
    pagination: {
      next: "Próxima página",
      previous: "Página anterior",
      rowsPerPage: "Linhas por páginas:",
      displayRows: "de",
    },
    toolbar: {
      search: "Procurar",
      downloadCsv: "Download CSV",
      print: "Imprimir",
      viewColumns: "Ver colunas",
      filterTable: "Filtrar tabela",
    },
    filter: {
      all: "Todos",
      title: "FILTROS",
      reset: "RESETAR",
    },
    viewColumns: {
      title: "Exibir colunas",
      titleAria: "Exibir/ocultar colunas da tabela",
    },
    selectedRows: {
      text: "linha(s) selecionada(s)",
      delete: "Deletar",
      deleteAria: "Apagar linha(s) selecionada(s)",
    },
  },
};
