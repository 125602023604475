import chroma from "chroma-js";
import colors from "themes/gobrax";

export const colourStyles = {
  control: (styles, { isDisabled }) => ({ 
    ...styles, 
    backgroundColor: isDisabled ? colors.palette.background.light : colors.palette.primary.contrastText, 
    border: "transparent",
    fontSize: "14px",
    overflow: "auto",
    maxHeight: 80
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color || "#f2f2f2");
    return {
      ...styles,
      textAlign: "left",
      fontSize: "14px",
      backgroundColor: isDisabled
        ? undefined
        : isSelected
          ? data.color
          : isFocused
            ? color.alpha(0.1).css()
            : undefined,
      color: isDisabled
        ? colors.palette.text.hint
        : isSelected
          ? chroma.contrast(color, colors.palette.primary.contrastText) > 2
            ? colors.palette.primary.contrastText
            : colors.palette.secondary.contrastText
          : data.color,
      cursor: isDisabled ? "not-allowed" : "default",
      paddingLeft: data.isChild ? 20 : 10,
      display: data.isHidden ? "none" : "block",

      ":hover": {
        backgroundColor: colors.palette.text.hint,
      },
      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? data.color
            : color.alpha(0.3).css()
          : undefined,
      },
    };
  },
};

