import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  title: {
    fontSize: 24,
    fontWeight: 500,
    color: theme.palette.text.title
  },
  container: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(5),
    borderRadius: 28
  },
  explanation: {
    fontSize: 16,
    fontWeight: 400
  },
  formHelperText:{
    marginLeft: 15
  },
  inputCalendar: {
    width: '100%',
    maxWidth: '280px',
    fontSize: theme.typography.h5.fontSize,
    color: theme.palette.gray.default,
    '& .MuiInputBase-input': {
      textAlign: 'center',
      border: '1px solid '+theme.palette.text.hint,
      width: '100%',
      borderRadius: '20px',
      padding: '15px',
    },
    '&:before': {
      transition: 'none',
      border: 'transparent'
    },
    '&:after': {
      transition: 'none',
      border: 'transparent'
    },
    '& input': {
      cursor: 'pointer',
    },
    '&:hover:not(.Mui-disabled):before': {
      border: 'transparent'
    }
  },
  datepicker: {
    [`& fieldset`]: {
      borderRadius: 20,
    }
  },
  containerCheckbox: {
    height:'100px'
  },
  textDriver: {
    marginLeft: 10,
    maxWidth: 200
  },
}));
