import React from "react";
import { IconButton, Grid } from "@mui/material";
import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import dayjs from "dayjs";

// components
import PermissionsGate, { hasPermission } from "components/PermissionsGate";

export const makeColumns = ({ onDelete, onEdit, size }) => {
  let columns = [
    {
      name: "name",
      label: size.mobile ? ' ' : "Perfil",
    },
    {
      name: "creationDate",
      label: "Data de criação",
      options: {
        customBodyRender: (value) => dayjs(value).format("DD/MM/YYYY HH:mm"),
      },
    },
    {
      name: "updateDate",
      label: "Data da última atualização",
      options: {
        customBodyRender: (value) => dayjs(value).format("DD/MM/YYYY HH:mm"),
      },
    },
    {
      name: "permissions",
      label: "Permissões",
      options: {
        print: false,
        customBodyRender: (value) => {
          return value
        },
      },
    }
  ];

  if (!size.mobile && hasPermission({ scopes: ['can_edit_user_role', 'can_remove_user_role'] })) {
    columns.push({
      name: "role",
      label: "Ações",
      options: {
        sort: false,
        print: false,
        filter: false,
        viewColumns: false,
        customBodyRender: (value) => {
          return (
            <Grid container>
              <PermissionsGate scopes={['can_edit_user_role']}>
                <Grid item xs={6}>
                  <IconButton
                    onClick={() => onEdit(value)}
                    aria-label="edit"
                    title="Editar"
                    size="large">
                    <EditIcon />
                  </IconButton>
                </Grid>
              </PermissionsGate>
              <PermissionsGate scopes={['can_remove_user_role']}>
                <Grid item xs={6}>
                  <IconButton
                    onClick={() => onDelete(value)}
                    aria-label="delete"
                    title="Excluir"
                    size="large">
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </PermissionsGate>
            </Grid>
          );
        },
      },
    });
  }
  return columns;
};
