export const errorsMessages = {
    "Document Number": {
      type: "documentNumber",
      message: "CPF já cadastrado",
    },
    "Document": {
      type: "document",
      message: "CNPJ já cadastrado",
    },
    "Phone Number": {
      type: "phone",
      message: "Telefone já cadastrado",
    },
    "Email": {
      type: "email",
      message: "Email já cadastrado",
    },
    "Name": {
      type: "name",
      message: "Nome já cadastrado",
    },
    "vehicle associated": {
      type: "vehicle",
      message: "Existem veículos associados a esse perfil.",
    }
  };