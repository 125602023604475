import React from "react";
import PropTypes from "prop-types";

import "./title.css";

const Title = ({ value = "Gobrax Title" }) => {
  return (
    <div>
      <span className={`Title-container`}>{value}</span>
    </div>
  );
};

export default Title;

Title.propTypes = {
  /**
   * Props descrição
   */
  value: PropTypes.string.isRequired,
};