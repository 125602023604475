import { format, lastDayOfMonth } from 'date-fns';

import Utils from "./utils";

export function generateRange(startDay, finishDay, period, year) {
  if (
    Utils.isNullOrUndefined(startDay) ||
    Utils.isNullOrUndefined(finishDay) || 
    Utils.isNullOrUndefined(year) ||
    Utils.isNullOrUndefined(period)
  ) {
    return [];
  }

  const options = [];

  function formatDateString(date) {
    return format(date, 'dd/MM/yyyy');
  }

  if (period === 0) {
    for (let month = 0; month < 12; month++) {
      let totalDaysMonth = lastDayOfMonth(new Date(year, month)).getDate();
      let verifiedFinishDay = Math.min(finishDay, totalDaysMonth);

      const startDate = new Date(year, month, startDay); 
      const finishDate = new Date(year, month, verifiedFinishDay, 23, 59, 59); 

      const option = {
        label: `${formatDateString(startDate)} a ${formatDateString(finishDate)}`,
        startDate: startDate,
        finishDate: finishDate
      };

      options.push(option);
    }

    return options;
  }

  if (period === 1) {
    let prevYear = year - 1;

    for (let month = 0; month < 12; month++) {
      const startDate = month === 0 
        ? new Date(prevYear, 11, startDay)
        : new Date(year, month - 1, startDay);
      const finishDate = new Date(year, month, finishDay, 23, 59, 59); 

      const option = {
        label: `${formatDateString(startDate)} a ${formatDateString(finishDate)}`,
        startDate: startDate,
        finishDate: finishDate
      };

      options.push(option);
    }

    const lastStartDate = new Date(year, 11, startDay); 
    const lastFinishDate = new Date(year + 1, 0, finishDay, 23, 59, 59);

    const lastOption = {
      label: `${formatDateString(lastStartDate)} a ${formatDateString(lastFinishDate)}`,
      startDate: lastStartDate,
      finishDate: lastFinishDate
    };
    options.push(lastOption);
    return options;
  }

  return options;
}



export const formatVehicleGroups = (list) => {
  let formatedList = [];
  list.map((item) => {
    return formatedList.push({
      ...item,
      label: item.name
    });
  })
  return formatedList;
}


export const allSelectedsVehicles = (data) => {
  const listVehicles = [];
  data.forEach((op) => {
    if (op.vehicles) {
      for (let truck of op.vehicles) {
        listVehicles.push(truck);
      }
    }
  });
  return listVehicles;
};

export const getYearsOptions = () => {
  const today = new Date();
  const yearOptions = [];
  const currentYear = today.getFullYear();
  const lastYearToShow = today.getMonth() > 10 ? currentYear+1 : currentYear;
  for (let i = 2020; i <= lastYearToShow; i++) {
    yearOptions.push({ value: i, label: i });
  }
  return yearOptions;
}
