import { alpha } from "@mui/material";
import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  spacingContainer: {
    [theme.breakpoints.down('lg')]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
  title: {
    fontSize: 24,
    fontWeight: '500',
    marginBottom: 20,
    marginLeft: 10,
  },
  flex: {
      display: 'flex',
      alignItems: 'center'
  },
  paper: {
    padding: 12, 
    borderRadius: 20
  },
  wrapperContent: {
    justifyContent: 'space-between', 
    alignItems: 'center'
  },
  icon: {
      marginRight: 4,
  },
  wrapperButton: {
    justifyContent: 'flex-end', 
    alignItems: 'center',
  },
  backButton: {
    borderRadius: 20,
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: alpha(theme.palette.secondary.main, 0.7),
    },
  }
}));
