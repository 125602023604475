import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import colors from '../../themes/gobrax';

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

const CustomOption = (props) => {
  const { data, innerRef, innerProps } = props;

  if (data.value === 'selectAll') {
    return (
      <div ref={innerRef} {...innerProps} style={{ padding: '10px', cursor: 'pointer' }}>
        <strong>{data.label}</strong>
      </div>
    );
  }

  return <components.Option {...props} />;
};

const VehicleSelect = ({ options, onChange, selectedValues }) => {
  const [selectedOptions, setSelectedOptions] = useState(selectedValues || []);

  useEffect(() => {
    setSelectedOptions(selectedValues || []);
  }, [selectedValues]);

  const handleSelectChange = (selected) => {
    if (selected && selected.some(option => option.value === 'selectAll')) {
      const allOptions = options.flatMap(group => group.options);
      setSelectedOptions(allOptions);
      onChange(allOptions);
    } else {
      setSelectedOptions(selected);
      onChange(selected);
    }
  };

  const handleGroupSelect = (group) => {
    if (group.label === 'Selecionar Todos') {
      const allOptions = options.flatMap(group => group.options);
      setSelectedOptions(allOptions);
      onChange(allOptions);
    } else {
      const groupOptions = group.options;
      const isGroupSelected = groupOptions.every(option => selectedOptions.some(sel => sel.value === option.value));

      const updatedOptions = isGroupSelected
        ? selectedOptions.filter(option => !groupOptions.some(groupOption => groupOption.value === option.value))
        : [...selectedOptions, ...groupOptions];

      setSelectedOptions(updatedOptions);
      onChange(updatedOptions);
    }
  };

  const formatGroupLabel = (data) => (
    <div style={groupStyles} onClick={() => handleGroupSelect(data)}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );

  const customStyles = {
    control: (provided) => ({
      ...provided,
      maxHeight: '180px',
      borderRadius: '20px',
      overflowY: 'auto',
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '20px',
      overflowY: 'auto',
    }),
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: colors.palette.background.background2,
      borderRadius: '20px',
      padding: '3px 10px',
      fontSize: 12,
      fontWeight: 800,
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    }),
  };
  
  return (
    <Select
      isMulti
      options={[
        { label: 'Selecionar Todos', options: [{ value: 'selectAll', label: 'Selecionar Todos' }] },
        ...options,
      ]}
      value={selectedOptions}
      onChange={handleSelectChange}
      formatGroupLabel={formatGroupLabel}
      components={{ Option: CustomOption }}
      placeholder="Selecione veículos..."
      getOptionLabel={(option) => option.label}
      getOptionValue={(option) => option.value}
      styles={customStyles}
    />
  );
};

export default VehicleSelect;
