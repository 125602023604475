import fields from 'domain/forms/standardRewardGroup';
import makeDefaultValues from 'helpers/makeDefaultValues';

export const defaultValues = makeDefaultValues({
  fields,
  data: {
    highRequirementList: [
      {
        conduction: '',
        idealPercentage: '',
        acceptablePercentage: '',
        weight: '',
        id: 'highRequirementList',
      },
    ],
    highPenalizingRequirementList: [
      {
        conduction: '',
        idealPercentage: '',
        acceptablePercentage: '',
        weight: '',
        id: 'highPenalizingRequirementList',
        penalizing: true
      },
    ],
    mediumRequirementList: [
      {
        conduction: '',
        idealPercentage: '',
        acceptablePercentage: '',
        weight: '',
        id: 'mediumRequirementList',
      },
    ],
    mediumPenalizingRequirementList: [
      {
        conduction: '',
        idealPercentage: '',
        acceptablePercentage: '',
        weight: '',
        id: 'mediumPenalizingRequirementList',
        penalizing: true
      },
    ],
    lowRequirementList: [
      {
        conduction: '',
        idealPercentage: '',
        acceptablePercentage: '',
        weight: '',
        id: 'lowRequirementList',
      },
    ],
    lowPenalizingRequirementList: [
      {
        conduction: '',
        idealPercentage: '',
        acceptablePercentage: '',
        weight: '',
        id: 'lowPenalizingRequirementList',
        penalizing: true
      },
    ],
    highControll: [],
    mediumControll: [],
    lowControll: [],
    penalizingHighControll: [],
    penalizingMediumControll: [],
    penalizingLowControll: [],
    penalty_score: ''
  },
});