export default [
  /* "address", */
  "document",
  /* "email", */
  "name",
  "note",
  "operations",
  "period",
  "startingDay",
  "finishingDay",
  /* "password",
  "phone", */
];
