import { Grid } from '@mui/material';
import React from 'react';
import {
  Cell,
  Label,
  Pie,
  PieChart,
  ResponsiveContainer,
} from "recharts";

function FollowComponent({data, percentage}) {
  return (
    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} data-cy="divGrafico">
      <ResponsiveContainer height={130}>
        <PieChart>
          <Pie
            data={data}
            innerRadius={50}
            outerRadius={60}
            startAngle={-270}
            dataKey="value"
          >
            {data?.map((entry, index) => (
              <Cell key={index} fill={entry.color} />
            ))}
            <Label 
              value={`${percentage}%`} 
              position="center" 
              style={{
                fill: "#666666",
                fontSize: 30,
                fontWeight: "bold",
              }} 
            />
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </Grid>
  )
}

export default FollowComponent;
