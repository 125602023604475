import React from "react";
import { Box, Grid, Skeleton } from "@mui/material";

const IndicatorSkeleton = () => {
  const ItemSkeleton = () => (
    <Skeleton
      variant="rectangular"
      width={170}
      height={200}
      style={{
        borderRadius: "20px",
      }}
    />
  );

  return (
    <Grid container gap={2}>
      <Grid container item>
        <Grid
          container
          justifyContent="flex-end"
          alignItems="flex-end"
          direction="row"
          spacing={0}
        >
          <Grid item>
            <Skeleton
              variant="rectangular"
              width={115}
              height={40}
              style={{
                borderRadius: "20px",
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container item justifyContent="space-between">
        {Array.from({ length: 6 }, (_, index) => (
          <Box key={index} component={Grid}>
            <ItemSkeleton />
          </Box>
        ))}
      </Grid>
    </Grid>
  );
};

export default IndicatorSkeleton;
