import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React from 'react'

export function ModalReloadWebSocket(props){
    return  <Dialog
    open={props.modal}
    PaperProps={{
      style: { borderRadius: 20 }
    }}
    onClose={()=>{props.setModal(false)}}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle 
    style={{padding: '24px 24px 4px 24px',fontSize: '1rem'}} id="alert-dialog-title">
        <Typography variant="h4">
        Não foi possível carregar o mapa ao vivo
        </Typography>
      </DialogTitle>
    <DialogContent style={{padding: '0px 24px'}}>
      <DialogContentText  id="alert-dialog-description">
      Seu navegador está sem memória disponível e por isso não conseguimos carregar o mapa ao vivo, atualize a página e se persistir tente selecionar menos veículos.
      </DialogContentText>
    </DialogContent>
    <DialogActions style={{padding:20}}>
      <Button
      variant="contained"
        onClick={() => {
          window.location.reload()
        }}
        style={{borderRadius:20,width: 150}}
        color="secondary"
        autoFocus
      >
        Atualizar
      </Button>
    </DialogActions>
  </Dialog>
}