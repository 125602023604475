import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  title: {
    fontSize: 16,
    fontWeight: "bold",
  },
  formHelperText: {
    marginLeft: 15
  },
  input: {
    borderRadius: "22px",
  }
}));
