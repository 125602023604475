import { Grid, Skeleton } from "@mui/material";

import Aux from "hoc/auxiliar";

const PressureSkeleton = () => {
  return (
    <Aux>
      <Grid container spacing={2}>
        <Skeleton variant="rectangular" width="100%" height={200} style={{
          borderRadius: "20px", margin: "10px"
        }} />
      </Grid>
    </Aux>
  );
};

export default PressureSkeleton;
