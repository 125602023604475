import React from "react";
import { Link } from "react-router-dom";
import { IconButton, Grid } from "@mui/material";
import { DeleteOutline as DeleteIcon, EditOutlined as EditIcon, RemoveRedEyeOutlined as EyeIcon } from "@mui/icons-material";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";

export const makeColumns = ({ onDelete, onEdit, size }) => {
  let columns = [
    {
      name: "name",
      label: size.mobile ? " " : "Empresa",
    },
    {
      name: "document",
      label: "CNPJ",
    },
  ];
  if (!size.mobile && hasPermission({ scopes: ['can_edit_customers', 'can_remove_customers', 'can_view_customers_without_edit'] })) {
    columns.push({
      name: "id",
      label: "Ações",
      options: {
        setCellProps: () => ({
          style: {
            width: 200
          }
        }),
        print: false,
        filter: false,
        viewColumns: false,
        customBodyRender: (value) => {
          return (
            <Grid container>
              <PermissionsGate scopes={['can_edit_customers']}>
                <Grid item xs={4}>
                  <IconButton
                    onClick={() => onEdit(value)}
                    aria-label="edit"
                    title="Editar"
                    size="large">
                    <EditIcon />
                  </IconButton>
                </Grid>
              </PermissionsGate>
              <PermissionsGate scopes={['can_remove_customers']}>
                <Grid item xs={4}>
                  <IconButton
                    onClick={() => onDelete(value)}
                    aria-label="delete"
                    title="Excluir"
                    size="large">
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </PermissionsGate>
              <PermissionsGate scopes={['can_view_customers_without_edit']}>
                <Grid item container xs={4}>
                  <IconButton
                    aria-label="view-customer"
                    title="Visualizar cliente"
                    component={Link}
                    to={`/gofurther/customers/view-customer/${value}`}
                    size="large">
                    <EyeIcon />
                  </IconButton>
                </Grid>
              </PermissionsGate>
            </Grid>
          );
        },
      },
    });
  }
  return columns;
};
