export const makeSelectOptions = ({ data, keyValue, keyLabel }) => {
  if (!data || !keyValue || !keyLabel) {
    return data;
  }
  const options = data.map((item) => ({
    label: item[keyLabel],
    value: item[keyValue],
  }));

  return options;
};
