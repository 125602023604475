import React, { useState } from "react";
import { useTheme } from "@mui/styles";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useHistory, useParams } from "react-router-dom";
import MassImportTemplate from "components/MassImportTemplate";
import { downloadFile, getBase64 } from 'helpers/functions';
import { importTemplateMassLinkDrivers, createMassLinkDrivers } from "services/driver";
import Utils from "helpers/utils";

const MassLinkChassisToLogin = () => {
  const theme = useTheme();
  const [uppingFile, setUppingFile] = useState(false)
  const history = useHistory()
  const { selectedEmail } = useParams();

  const handleClickDownloadTemplate = async () => {
    try {
      const response = await importTemplateMassLinkDrivers();
      downloadFile(response.data.data, "vnd.ms-excel", 'anexar-chassis-ao-login.xlsx');
    } catch (error) {
      toast.error(
        "Erro ao fazer download do arquivo. Tente novamente dentro de alguns instantes.",
      );
    }
  };

  const handleClickLinkChassisToLogin = async (e) => {
    const file = e.target.files[0];
    const base64 = await getBase64(file);
    const fileName = Utils.getFileNameTrunc(file.name, 23);

    Swal.fire({
      title: "Importação em massa",
      text: `Você selecionou o arquivo "${fileName}".\nDeseja iniciar a importação?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: theme.palette.primary.main,
      cancelButtonColor: theme.palette.error.main,
      confirmButtonText: "Iniciar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setUppingFile(true);
        // send email at request
        // console.log(selectedEmail, '###')
        try {
          const response = await createMassLinkDrivers({
            "b64": base64
          });
          if (response.data.success) {
            toast.success(
              "Vinculo de chassis feito com sucesso!",
            );
            history.push("/gofurther/user/list-users");
          } else {
            toast.error("Erro ao vincular chassis. Entre em contato com o suporte.");
          }
        } catch (error) {
          toast.error(
            "Erro ao vincular chassis em massa. Tente novamente dentro de alguns instantes.",
          );
        }
        setUppingFile(false);
      }
    });
  };

  return (
    <MassImportTemplate
      title={'Cadastro em massa de chassis ao login'}
      handleClickDownloadTemplate={handleClickDownloadTemplate}
      handleClickMassImport={handleClickLinkChassisToLogin}
      uppingFile={uppingFile}
      permission={'can_import_link_chassis_to_login'}
    />
  );
};

export default MassLinkChassisToLogin;
