import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  profile: null
};

export const customerProfileSlice = createSlice({
  name: 'customerProfile',
  initialState,
  reducers: {
    saveCustomerProfile: (state, action) => {
      state.profile = action.payload
    },
    clearCustomerProfile: (state, action) => {
      state.profile = initialState.profile
    } 
  },
});

// Action creators are generated for each case reducer function
export const { saveCustomerProfile, clearCustomerProfile } = customerProfileSlice.actions;

export default customerProfileSlice.reducer;
