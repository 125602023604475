import { Grid, Paper, Skeleton } from "@mui/material";

import Aux from "hoc/auxiliar";

const RewardGroupSkeleton = () => {
  return (
    <Aux>
      <Paper elevation={2} style={{ padding: 20 }}>
        <Grid container spacing={2}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Skeleton variant="rectangular" width={300} height={40} />
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <Skeleton variant="rectangular" width="100%" height={40} />
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <Skeleton variant="rectangular" width="100%" height={40} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                <Skeleton variant="rectangular" width="100%" height={400} />
              </Grid>
              <Grid item xl={9} lg={9} md={6} sm={12} xs={12}>
                <Skeleton variant="rectangular" width="100%" height={400} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Aux>
  );
};

export default RewardGroupSkeleton;
