import { StatusVehicle } from 'helpers/statusVehicle';
import { useEffect, useState, useRef } from 'react';

export const useVehicleWebSocket = ({ selectedVehicles, sessionId, singleVehicle = false }) => {
  const [status, setStatus] = useState(null);
  const [message, setMessage] = useState(null);
  const [points, setPoints] = useState([]);
  const socketRef = useRef(null);
  const currentData = useRef(null);

  const socketUrl = singleVehicle
    ? `${process.env.REACT_APP_LINK_WSS}/api/v1/livemap/ws/vehicles?vehicle_id=${selectedVehicles.id}&refresh=1&session_id=${sessionId}`
    : `${process.env.REACT_APP_LINK_WSS}/api/v1/livemap/ws/group/vehicles?vehicle_ids=${selectedVehicles.map(v => v.id).join(',')}&refresh=1&session_id=${sessionId}`;

  useEffect(() => {
    if (selectedVehicles.length > 0 || singleVehicle) {
      if (socketRef.current) {
        socketRef.current.close();
      }

      socketRef.current = new WebSocket(socketUrl);

      socketRef.current.onopen = () => {
        setStatus('connected');
      };

      socketRef.current.onmessage = (event) => {
        try {
          const response = JSON.parse(event.data);
          if (response?.data) {
            if (singleVehicle) {
              const saveMessage = singleVehicle ? [response.data] : response.data;

              setMessage(saveMessage);
              setPoints([{
                vehicleId: response.data.vehicleId,
                color: StatusVehicle([response.data])[0]?.color,
                title: response.data.plate,
                lng: response.data.point.lon,
                lat: response.data.point.lat,
              }]);
            } else {
              setMessage(response.data);
              const orderedPoints = response.data.sort((a, b) => a.vehicleId - b.vehicleId);

              if (JSON.stringify(currentData.current) !== JSON.stringify(orderedPoints)) {
                currentData.current = orderedPoints;
                const pointsNew = StatusVehicle(orderedPoints);
                setPoints(pointsNew);
                setStatus(pointsNew.length === 0 ? 'empty' : null);
              }
            }
          } else {
            setStatus("empty");
            socketRef.current.close();
          }
        } catch (err) {
          console.error('WebSocket Error: ', err);
        }
      };

      socketRef.current.onerror = (error) => {
        console.error('WebSocket Error: ', error);
        setStatus('error');
      };

      socketRef.current.onclose = () => {
        setStatus('disconnected');
      };

      return () => {
        if (socketRef.current) {
          socketRef.current.close();
        }
      };
    }
  }, [socketUrl, sessionId, singleVehicle]); 

  return { status, message, points };
};
