import api from "./api";

export const getOperationTypesByCustomers = async (customers) => {
  try {
    const response = await api.get(`/operation-types?customers=${customers}`);
    if (response.status === 200 || response.ok === "ok") {
      return response.data;
    }
  } catch (err) {
    return err;
  }
};

export const getOperationTypes = async () => {
  try {
    const response = await api.get(`/operation-types`);
    if (response.status === 200 || response.ok === "ok") {
      return response.data;
    }
  } catch (err) {
    return err;
  }
};

export const createOperationType = async (data) => {
  try {
    const response = await api.post(`/operation-types`, data);
    if (response.status === 200 || response.ok === "ok") {
      return response.data;
    }
  } catch (err) {
    return err;
  }
};

export const getOperationTypeById = async (id) => {
  try {
    const response = await api.get(`/operation-types/${id}`);
    if (response.status === 200 || response.ok === "ok") {
      return response.data;
    }
  } catch (err) {
    return err;
  }
};

export const updateOperationType = async ({ id, data }) => {
  try {
    const response = await api.put(`/operation-types/${id}`, data);
    if (response.status === 200 || response.ok === "ok") {
      return response.data;
    }
  } catch (err) {
    return err;
  }
};

export const deleteOperationType = async (id, customer) => {
  try {
    const response = await api.delete(`/operation-types/${id}?customer_id=${customer}`);
    if (response.status === 200 || response.ok === "ok") {
      return response.data;
    }
  } catch (err) {
    return err;
  }
};
