import React from "react";
export const svgBackSmall = (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.21928 5.33327H11.3337V6.6666H3.21928L6.79526 10.2425L5.85246 11.1853L0.666992 5.99993L5.85246 0.814453L6.79526 1.75726L3.21928 5.33327Z"
      fill="#181814"
    />
  </svg>
);

export const svgNextSmall = (
  <svg
    width="11"
    height="12"
    viewBox="0 0 11 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.09638 5.33327L4.91049 1.75726L5.75044 0.814453L10.3702 5.99993L5.75044 11.1853L4.91049 10.2425L8.09638 6.6666H0.867188V5.33327H8.09638Z"
      fill="#181814"
    />
  </svg>
);

export const svgBack = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.82843 6.9999H16V8.9999H3.82843L9.1924 14.3638L7.7782 15.778L0 7.9999L7.7782 0.22168L9.1924 1.63589L3.82843 6.9999Z"
      fill="#181814"
    />
  </svg>
);

export const svgNext = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.1716 6.9999L6.8076 1.63589L8.2218 0.22168L16 7.9999L8.2218 15.778L6.8076 14.3638L12.1716 8.9999H0V6.9999H12.1716Z"
      fill="#181814"
    />
  </svg>
);
