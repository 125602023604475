
import React from 'react';

// components
import { Button } from "@mui/material";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

//styles
import useStyles from './styles';
import { handleAmplitudeEvent } from 'services/amplitude';

function HelpButton() {
  const classes = useStyles();
  const openHelpLink = () => {
    handleAmplitudeEvent('Help Clicked');
    window.open("https://drive.google.com/file/d/1gwotWzSGXFbltnptkZ_4dWz2kd9TGrqm/view", "_blank");
  };

  return (

    <Button onClick={openHelpLink} className={classes.helpButton} data-cy="buttonHelp">
      Ajuda <HelpOutlineIcon className={classes.helpIcon} />
    </Button>
  )
}

export default HelpButton;