import { SUPER_ADMIN, DIRECTOR } from "constants/profiles";

export const getFilteredPermissions = (permissions, hasViewPermission) => {
  const filteredPermissions = hasViewPermission
    ? permissions
    : permissions.filter(item => item.name !== SUPER_ADMIN && item.name !== DIRECTOR);

  const permissionsTableData = [...filteredPermissions];
  const accessTypes = filteredPermissions.map(item => ({
    label: item.name,
    value: item.role
  }));

  return { permissionsTableData, accessTypes };
}