import { customersUrl } from "helpers/customerUrl";
import api from "./api";

export const getOperations = async (customers) => {
  const partialUrl = customersUrl(customers);
  let params = `customers=${partialUrl}`;
  try {
    const response = await api.get(`/operations?${params}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getOperationById = async (id) => {
  try {
    const response = await api.get(`/operations/${id}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getOperationHistory = async (id) => {
  try {
    const response = await api.get(`/operation-histories/${id}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const createOperation = async (data) => {
  try {
    const response = await api.post(`/operations`, data);
    return response;
  } catch (err) {
    return err;
  }
};

export const updateOperation = async (data) => {
  try {
    const response = await api.put(`/operations-prfc/${data.id}`, data);
    return response;
  } catch (err) {
    return err;
  }
};

export const deleteOperationById = async (id) => {
  try {
    const response = await api.delete(`/operations-prfc/${id}`);
    return response;
  } catch (err) {
    return err;
  }
};

export const deleteIndicatorByName = async (id, name) => {
  try {
    const response = await api.delete(
      `/operations-prfc/${id}/indicators/${name}`,
    );
    return response;
  } catch (err) {
    return err;
  }
};

export const createIndicator = async (id, data) => {
  try {
    const response = await api.post(`/operations-prfc/${id}/indicators`, data);
    return response;
  } catch (err) {
    return err;
  }
};

export const updateIndicatorByName = async (id, name, data) => {
  try {
    const response = await api.put(
      `/operations-prfc/${id}/indicators/${name}`,
      data,
    );
    return response;
  } catch (err) {
    return err;
  }
};
