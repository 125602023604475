import React from 'react';

export const hasPermission = ({ scopes }) => {
  if (!localStorage.getItem('roles')) {
    return false;
  }
  // decode buffer as UTF-8
  const roles = JSON.parse(Buffer.from(localStorage.getItem('roles'), 'base64').toString('utf-8'));
  return scopes.some((scope) => {
    let objAux = roles.find(o => o.permission === scope);
    return (objAux);
  });
};

export const getUserPermissions = () => {
  if (!localStorage.getItem('roles')) {
    return false;
  }
  // decode buffer as UTF-8
  return JSON.parse(Buffer.from(localStorage.getItem('roles'), 'base64').toString('utf-8'));
};

export default function PermissionsGate({
  children,
  scopes = [],
  disabledGate = false
}) {
  const permissionGranted = hasPermission({ scopes }) || disabledGate;

  if (!permissionGranted) return <></>

  return <>{children}</>;
}

