import React, { useCallback, useEffect, useRef, useState, useMemo } from "react";
import { useTheme } from "@mui/material";
import { createTheme, ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { useDebouncedCallback } from "use-debounce";
import MuiDataTable from "mui-datatables";

// config
import locale from "./config/mui-datatable-locale";

// store
import { useAppSelector } from "store";

//services
import { insertUserSelection, updateUserSelection } from "services/userSelection";
import { handleAmplitudeEvent } from 'services/amplitude';

// components
import { CustomCheckbox } from "./components/CustomCheckbox";
import CustomToolbar from "./components/CustomToolbar";
import CustomTableViewCol from './components/CustomTableViewCol';

// hooks
import { useWindowSize } from "hooks/useWindowsSize";
import useUserTableColumns from "hooks/useUserTableColumns";

// styles
import useStyles from "./styles";
import useStyles2 from "./styles2";

export default function TableResponsive({
  columns,
  options,
  pointer,
  tableName,
  tableHeight = "460",
  setRowsPerPage,
  tableBodyMaxHeight,
  toolbarNone = false,
  columnProps = false,
  ...props
}) {
  const theme = useTheme();
  const { userId } = useAppSelector((state) => state.global.user);

  const size = useWindowSize();

  locale.textLabels.pagination.rowsPerPage = size.mobile ? "Linhas:" : locale.textLabels.pagination.rowsPerPage;

  const getMuiTheme = () => createTheme({
    components: {
      MuiToolbar: {
        styleOverrides: {
          root: {
            display: toolbarNone ? "none" : "flex",
            backgroundColor: !props.tableWhite ? theme.palette.background.default : "white",
            justifyContent: "center",
            "& h6": {
              fontSize: size.mobile ? "12px" : "16px",
              color: theme.palette.text.primary,
            },
          },
        }
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            height: "44px",
          },
        }
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: '#F7DF36',
            '&.Mui-checked': {
              color: '#F7DF36'
            }
          }
        }
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            "&:hover": {
              cursor: pointer ? "pointer" : "default",
            },
          }
        }
      },
      MuiTablePagination: {
        styleOverrides: {
          selectLabel: {
            margin: 0,
          },
          displayedRows: {
            margin: 0,
          }
        }
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            alignItems: 'center',
            fontSize: "16px"
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            margin: 0,
            padding: 0,
          },
        }
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            padding: "10px",
            color: theme.palette.text.primary,
          },
        }
      },
      MUIDataTable: {
        styleOverrides: {
          paper: {
            borderBottomLeftRadius: "20px",
            borderBottomRightRadius: "20px",
            overflow: "hidden",
            backgroundColor: "transparent",
          },
          responsiveBase: {
            backgroundColor: !props.tableWhite ? theme.palette.background.default : "white",
            paddingBottom: props.paddingBottom ? props.paddingBottom : size.mobile ? 0 : "16px",
            paddingRight: props.paddingRight ? props.paddingRight : "8px",
            table: {
              paddingBottom: 16,
              "&::-webkit-scrollbar": {
                borderRadius: "10px",
                height: "16px",
                width: "16px",
              },
              "&::-webkit-scrollbar-track": {
                marginTop: "40px",
                WebkitBoxShadow: theme.customShadows.scrollBar,
                borderRadius: "10px",
                backgroundColor: theme.palette.scrollBarBack,
              },
              "&::-webkit-scrollbar-thumb ": {
                borderRadius: "10px",
                border: `4px solid ${theme.palette.scrollBarBack}`,
                backgroundColor: theme.palette.scrollBar,
              },
            },
            "&::-webkit-scrollbar": {
              borderRadius: "10px",
              height: "16px",
              width: "16px",
            },
            "&::-webkit-scrollbar-track": {
              marginTop: "40px",
              WebkitBoxShadow: theme.customShadows.scrollBar,
              borderRadius: "10px",
              backgroundColor: theme.palette.scrollBarBack,
            },
            "&::-webkit-scrollbar-thumb ": {
              borderRadius: "10px",
              border: `4px solid ${theme.palette.scrollBarBack}`,
              backgroundColor: theme.palette.scrollBar,
            },
          },
          root: {
            backgroundColor: theme.palette.background.default,
          },
        }
      },
      MUIDataTableBodyRow: {
        styleOverrides: {
          root: {
            width: "100%",
            "&:nth-of-type(odd)": {
              backgroundColor: theme.palette.white,
              display: size.mobile ? "inline-table" : "auto",
              marginBottom: size.mobile ? 15 : "auto",
            },
            "&:nth-of-type(even)": {
              backgroundColor: size.mobile ? theme.palette.white : theme.palette.background.background2,
              display: size.mobile ? "inline-table" : "auto",
              marginBottom: size.mobile ? 15 : "auto",
            },
          },
        }
      },
      MUIDataTableBodyCell: {
        styleOverrides: {
          cellHide: {
            display: 'inline-block',
            fontSize: '13px',
            fontWeight: 'bold',
            [theme.breakpoints.up('md')]: {
              display: 'none'
            }
          }
        }
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          toolButton: {
            marginLeft: 0,
            marginRight: 0,
          },
          contentWrapper: {
            justifyContent: "center",
            width: "100%",
          },
        }
      },
      MUIDataTableFooter: {
        styleOverrides: {
          root: {
            backgroundColor: !props.tableWhite ? theme.palette.background.default : "white",
            border: "none",
            "& td": {
              border: "none",
            },
          },
        }
      },
      MUIDataTablePagination: {
        styleOverrides: {
          navContainer: {
            justifyContent: "center",
          },
        }
      },
    },
  });

  const { loadingColumns, userCustomizationColumns, fetchData } = useUserTableColumns(tableName);
  
  const [customFields, setCustomFields] = useState([]);
  const [customColumns, setCustomColumns] = useState([]);
  const [missingColumns, setMissingColumns] = useState(false);

  const userColumns = useRef([]);
  const selectAll = useRef([]);
  const componentRef = useRef();

  const classes1 = useStyles(customColumns.length === 1);

  const classes2 = useStyles2(customColumns.length === 1);

  const classes = columnProps ? classes2 : classes1;

  const debounced = useDebouncedCallback(async (newFields) => {
    try {
      if (newFields.length > 0) {
        await updateUserSelection(
          {
            tableName: tableName,
            fields: newFields,
          },
          userId,
        );
      }
    } catch (err) {
      console.log(err);
    } finally {
      fetchData();
    }
  }, 1000);

  const handleChangeColumns = useCallback(
    (changedColumn, action) => {
      handleAmplitudeEvent("Table Columns Updated", { table_name: tableName, column_name: changedColumn });

      let newFields = [];

      if (!changedColumn) {
        newFields = userColumns.current.map((item) => ({
          ...item,
          name: item.name,
          display: !item.hideColumn ? selectAll.current : false,
        }));
      } else {
        newFields = userColumns.current.map((item) => {
          return item.name !== changedColumn
            ? item
            : {
              ...item,
              display: !item.hideColumn ? action === "add" : false,
            };
        });
      }
      userColumns.current = newFields;
      debounced(newFields);
    },
    [debounced],
  );

  const memorizedOptions = useMemo(() => ({
    ...locale,
    selectableRows: "none",
    enableNestedDataAccess: ".",
    elevation: 0,
    fixedHeader: true,
    tableBodyMaxHeight: tableBodyMaxHeight ? tableBodyMaxHeight : size.mobile ? "350px" : "500px",
    download: false,
    print: !size.mobile,
    customToolbar: () => <CustomToolbar download={props?.download ?? {}} email={props?.email || false} />,
    onColumnViewChange: handleChangeColumns,
    onChangeRowsPerPage: (rowsPerPage) => setRowsPerPage && setRowsPerPage(rowsPerPage),
    onFilterDialogOpen: () => {
      handleAmplitudeEvent("Table Filter Clicked", { table_name: tableName });
    },
    onSearchOpen: () => {
      handleAmplitudeEvent("Table Search Clicked", { table_name: tableName });
    },
    rowsPerPage: options?.rowsPerPage ?? 15,
    filter: !size.mobile,
    viewColumns: !size.mobile,
    ...options,
    rowStyle: {
      fontSize: 12,
    },
  }),
  [
    handleChangeColumns,
    options,
    props.download,
    setRowsPerPage,
    size.mobile,
    tableBodyMaxHeight,
  ]);

  const insertUserColumns = useCallback(async () => {
    const columnsNewFields = columns.map((item) => {
      const newFieldsOptions = {
        name: item.name,
        display: item.options && typeof item.options.display === "boolean" ? item.options.display : true,
      };

      if (item.options && typeof item.options.hideColumn === "boolean") {
        newFieldsOptions.hideColumn = item.options.hideColumn;
      }

      return newFieldsOptions;
    });

    try {
      if (columnsNewFields.length > 0) {
        const response = await insertUserSelection(
          {
            tableName: tableName,
            fields: columnsNewFields,
          },
          userId,
        );

        if (response && response.data.data?.success) {
          userColumns.current = columnsNewFields;
          setCustomFields(columnsNewFields);
          setMissingColumns(false);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }, [columns, tableName, userId]);

  const handleNewColumns = useCallback(() => {
    let newColumns = [];

    columns.forEach((item) => {
      let foundedColumn = customFields.find((i) => i.name === item.name);

      if (foundedColumn) {
        newColumns.push({
          ...item,
          options: {
            ...item.options,
            display: item.options && typeof item.options.hideColumn === "boolean" ? !item.options.hideColumn : foundedColumn.display,
          },
        });
      }
    });

    setCustomColumns(newColumns);
  }, [columns, customFields]);

  const handleSelectAll = (checked) => {
    selectAll.current = checked;
  };

  const checkUserColumns = useCallback(() => {
    const { fields, success } = userCustomizationColumns.data;

    // Check for new columns
    let notFound = false;

    columns.forEach((field) => {
      if (fields && !fields.find((o) => o.name === field.name)) {
        notFound = true;
      }
    });

    setMissingColumns(notFound);

    if (!success) {
      setCustomColumns(columns);

      return;
    }
    if (!fields) {
      insertUserColumns();
    } else {
      userColumns.current = fields;

      setCustomFields(fields);
    }
  }, [columns, insertUserColumns, userCustomizationColumns]);

  useEffect(() => {
    if (!loadingColumns) {
      checkUserColumns();
    }
  }, [checkUserColumns, loadingColumns]);

  useEffect(() => {
    if (customFields.length > 0) {
      handleNewColumns();
    }
  }, [customFields, handleNewColumns]);

  useEffect(() => {
    if (missingColumns) {
      insertUserColumns();
    }
  }, [insertUserColumns, missingColumns]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={getMuiTheme()}>
        {customColumns.length > 0 && (
          <MuiDataTable
            {...props}
            className={classes.root}
            columns={customColumns}
            options={memorizedOptions}
            ref={componentRef}
            components={{
              TableViewCol: (propsTableView) => (
                <CustomTableViewCol
                  {...propsTableView}
                  handleSelectAll={handleSelectAll}
                />
              ),
              Checkbox: CustomCheckbox,
            }}
          />
        )}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
