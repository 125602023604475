import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(5),
    borderRadius: 28
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250,
  },
  title: {
    fontSize: 24,
    fontWeight: 500,
    color: theme.palette.text.title,
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(1),
  },
}));
