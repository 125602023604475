import { createContext, useContext, useState, useMemo, useCallback } from 'react';
import { toast } from "react-toastify";

import { getBrands, getModelsByBrand } from 'services/vehicle';

import { orderByName } from 'helpers/functions';

const InputsProfileContext = createContext();

const getInitialInputsState = () => [
  { name: 'neutralRangeStart', title: 'Lenta' },
  { name: 'whiteRangeStart', title: 'Branca' },
  { name: 'extraEconomicRangeStart', title: 'Início faixa verde' },
  { name: 'economicRangeStart', title: 'Final faixa verde' },
  { name: 'powerRangeStart', title: 'Acelerando acima do verde' },
  { name: 'engineBrakeRangeStart', title: 'Freio motor' },
  { name: 'redRangeStart', title: 'Vermelha' },
  { name: 'redRangeEnd', title: 'Final vermelha' },
];

const InputsProfileProvider = ({ children }) => {
  const [models, setModels] = useState([]);
  const [brands, setBrands] = useState([]);

  const [inputs, setInputs] = useState(getInitialInputsState());

  const resetInputs = () => setInputs(getInitialInputsState());

  const isFormValid = () => {
    return inputs.every(input => input.value && !input.invalid);
  };

  const fetchBrandsList = useCallback(async () => {
    try {
      const response = await getBrands();

      const fetchedBrands = response.data.data?.brands;

      const brandsOptions = fetchedBrands.map(({ id, name }) => ({
        value: id,
        label: name
      }));

      const orderedBrandsOptions = orderByName(brandsOptions);

      setBrands(orderedBrandsOptions);

      return orderedBrandsOptions;
    } catch (err) {
      toast.error(
        'Erro ao carregar lista de marcas. Entre em contato com o suporte!',
      );
      console.log(err);
    }
  }, [getBrands, orderByName, setBrands]);

  const fetchModelsList = useCallback(async (brandId) => {
    try {
      const response = await getModelsByBrand(brandId);

      const fetchedModels = response.data.data?.models;

      const modelsOptions = fetchedModels.map(({ id, name }) => ({
        value: id,
        label: name
      }));

      setModels(modelsOptions);

      return modelsOptions;
    } catch (err) {
      toast.error(
        'Erro ao carregar lista de modelos. Entre em contato com o suporte!',
      );
      console.log(err);
    }
  }, [getModelsByBrand, setModels]);

  const value = useMemo(() => ({
    models,
    setModels,
    brands,
    setBrands,
    inputs,
    setInputs,
    resetInputs,
    isFormValid,
    fetchBrandsList,
    fetchModelsList,
  }), [
    models,
    setModels,
    brands,
    setBrands,
    inputs,
    setInputs,
    resetInputs,
    isFormValid,
    fetchBrandsList,
    fetchModelsList,
  ]);

  return (
    <InputsProfileContext.Provider value={value}>
      {children}
    </InputsProfileContext.Provider>
  );
};

function useInputsProfile() {
  const context = useContext(InputsProfileContext);
  return context;
}

export { InputsProfileProvider, useInputsProfile };
