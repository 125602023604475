import api from "./api";

export const getRankingByCustomer = async (customerId, startDate, finishDate, limit, page) => {
  try {
    const response = await api.get(`/web/v1/rankings/${customerId}?start_date=${startDate}&end_date=${finishDate}&limit=${limit}&page=${page}`);
    if(!response.ok){
      throw new Error("Api error")
    }
    return response;
  } catch (err) {
    throw err;
  }
};

export const getRankingByDriverCustomer = async (customerId, driverId, startDate, finishDate) => {
  try {
    const response = await api.get(`/web/v1/rankings/${customerId}/drivers/${driverId}?start_date=${startDate}&end_date=${finishDate}`);
    if(!response.ok){
      throw new Error("Api error")
    }
    return response;
  } catch (err) {
    throw err;
  }
};
