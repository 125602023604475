import React from 'react'
import { Grid, Typography } from '@mui/material'
import Icon from '@mdi/react'
import { 
  mdiMapMarkerDistance,
  mdiGasStation,
  mdiGaugeFull
} from '@mdi/js'

import useStyles from './styles'
import { NumberFormatBR } from "helpers/numberFormat";
/* import { valueToPositiveFloat } from "helpers/functions"; */

const ConsolidatedStatistics = ({data, selectedVehicles}) => {
  const classes = useStyles()

  return (
    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
      <Typography variant="h4" className={classes.cardTitle}>Estatísticas</Typography>
      <Grid container spacing={2} className={classes.contentBox}>
        <Grid 
          item container 
          xl={6}
          lg={6}
          md={12}
          sm={12}
          xs={12}
          className={classes.cardContent}
        >
          <Grid item xl={3} lg={3} md={2} sm={1} xs={1} className={classes.cardIcon}><Icon path={mdiMapMarkerDistance} size={1} /></Grid>
          <Grid item xl={9} lg={9} md={10} sm={11} xs={11}>
            <Grid className={classes.cardValue}>{NumberFormatBR(data?.totalMileage)} Km</Grid>
            <Grid className={classes.cardLabel}>Km rodado</Grid>
          </Grid>
        </Grid>
        <Grid 
          item container 
          xl={6}
          lg={6}
          md={12}
          sm={12}
          xs={12}
          className={classes.cardContent}
        >
          <Grid item xl={3} lg={3} md={2} sm={1} xs={1} className={classes.cardIcon}><Icon path={mdiGasStation} size={1} /></Grid>
          <Grid item xl={9} lg={9} md={10} sm={11} xs={11}>
            <Grid className={classes.cardValue}>{NumberFormatBR(data?.totalConsumption)} L</Grid>
            <Grid className={classes.cardLabel}>Litros consumidos</Grid>
          </Grid>
        </Grid>
        {/*<Grid 
          item container 
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={classes.cardContent}
        >
          <Grid className={classes.cardIcon}><Icon path={mdiFinance} size={1} /></Grid>
          <Grid>
            <Grid className={classes.cardValue}>{valueToPositiveFloat((data?.totalMileage / selectedVehicles.length), 2)} Km</Grid>
            <Grid className={classes.cardLabel}>Produtividade</Grid>
          </Grid>
        </Grid>*/}
        <Grid 
          item container 
          xl={6}
          lg={6}
          md={12}
          sm={12}
          xs={12}
          className={classes.cardContent}
        >
          <Grid item xl={3} lg={3} md={2} sm={1} xs={1} className={classes.cardIcon}><Icon path={mdiGaugeFull} size={1} /></Grid>
          <Grid item xl={9} lg={9} md={10} sm={11} xs={11}>
            <Grid className={classes.cardValue}>{NumberFormatBR(data?.consumptionAverage)} Km/L</Grid>
            <Grid className={classes.cardLabel}>Média km por litro</Grid>
          </Grid>
        </Grid> 
      </Grid>
    </Grid>
  )
}

export default ConsolidatedStatistics;