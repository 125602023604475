import React from "react";
import { Grid, Paper, Typography } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import useStyles from "./styles";


const DeviceValidationCard = ({ title, status, description }) => {
  const classes = useStyles();

  const getStatusIcon = (status) => {
    if (status) {
      return <CheckCircleIcon style={{ color: 'green' }} />;
    }

    return <ErrorIcon style={{ color: 'red' }} />
  }

  return (
    <Paper className={classes.card}>
      <Grid container direction="column" spacing={1}>
        <Grid item>
          {getStatusIcon(status)}
        </Grid>
        <Grid item>
          <Typography variant="subtitle1">
            {title}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {description}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};


export default DeviceValidationCard;
