import React from "react";
import { Typography } from "@mui/material";
import dayjs from 'dayjs';

export const makeColumns = ({ size }) => {
  return [
    {
      name: "identification",
      label: size.mobile ? ' ' : "Nome",
    },
    {
      name: "startDate",
      label: "Data inicial",
      options: {
        customBodyRender: (value) => (
          <Typography variant="body2">
            {dayjs(value).format("DD/MM/YYYY HH:mm")}
          </Typography>
        ),
      },
    },
    {
      name: "endDate",
      label: "Data final",
      options: {
        customBodyRender: (value) => {
          if(value) {
            return (
              <Typography variant="body2">{dayjs(value).format("DD/MM/YYYY HH:mm")}</Typography>
            )
          } else {
            return (
              <Typography variant="body2">Vínculo atual</Typography>
            )
          }
        },
      },
    },
  ];
};
