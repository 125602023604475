import React, {
  useEffect,
  createContext,
  useContext,
  useCallback,
} from "react";
import { useDispatch } from "react-redux";
import * as amplitude from "@amplitude/analytics-browser";

// store
import { useAppSelector } from "store";
import { setGlobalUser } from "store/globalSlice";

// services
import { getCustomersList, getProfileCustomer } from "services/customers";

const SelectCustomerContext = createContext();

const SelectCustomersProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { token, email, listCustomers, currentCustomer } = useAppSelector(
    (state) => state.global.user,
  );

  const getCustomers = useCallback(async () => {
    if (!email) {
      return;
    }
    const responseCustomer = await getCustomersList(email);

    if (responseCustomer.status === 200 || responseCustomer === 201) {
      let customerList = responseCustomer.data.data
        ? responseCustomer.data.data.sort((a, b) => a - b)
        : [];

      let firstCustomerId;

      for (const customerId of customerList) {
        if (customerId !== "0") {
          firstCustomerId = customerId;
          break;
        }
      }

      let currentCustomer = customerList && firstCustomerId;

      dispatch(setGlobalUser({ listCustomers: customerList }));
      dispatch(setGlobalUser({ currentCustomer: currentCustomer }));

    }
  }, [dispatch, email]);

  useEffect(() => {
    token && !listCustomers?.length && !currentCustomer && getCustomers();
  }, [token, getCustomers, listCustomers, currentCustomer]);

  useEffect(() => {
    if (currentCustomer && email) {
      getProfileCustomer(email, currentCustomer).then((response) => {
        if (response.status === 200 || response.status === 201) {
          
          const { company, user_permission_profile } = response.data.data

          const identify = new amplitude.Identify()
          identify
            .set("company", company)
            .set("profile", user_permission_profile)
            
          amplitude.identify(identify)
        }
      })
    }
      
  }, [currentCustomer, email])

  return (
    <SelectCustomerContext.Provider value={{}}>{children}</SelectCustomerContext.Provider>
  );
};

function useSelectCustomers() {
  const context = useContext(SelectCustomerContext);
  return context;
}

export { SelectCustomersProvider, useSelectCustomers };
