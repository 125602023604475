import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { toast } from "react-toastify";

// helpers
import { findValueByKey } from "helpers/functions";
import {
  accelerationIdentificationMethodOptions,
  fuelCalcMethodOptions,
  distanceCalcMethodOptions,
  odomTypeOptions,
} from "domain/selectOptions";

// components
import Aux from "hoc/auxiliar";
import BackButton from "components/BackButton";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";

// services
import { getVehicle } from "services/vehicle";
import { getCustomersById } from "services/customers";

import useStyles from "./styles";
import "react-datepicker/dist/react-datepicker.css";

const ViewVehicle = () => {
  const classes = useStyles();
  const { vehicleId } = useParams();
  const [ accelerationIdentificationMethod, setAccelerationIdentificationMethod ] = useState(null);
  const [ customer, setCustomer ] = useState(null);
  const [ distanceCalcMethod, setDistanceCalcMethod ] = useState(null);
  const [ fuelCalcMethod, setFuelCalcMethod ] = useState(null);
  const [ odometer, setOdometer ] = useState(null);
  const [ vehicle, setVehicle ] = useState(null);

  const fetchVehicleDetail = useCallback(async () => {
    try {
      const response = await getVehicle(vehicleId);
      const vehicleData = response?.data;
      if (!vehicleData) {
        return null;
      }
      const profile = vehicleData.profile;
      const accelerationIdentificationMethods = accelerationIdentificationMethodOptions.find((elm) => elm.id === profile.accelerationIdentificationMethod);
      const customers = await getCustomersById(vehicleData.customerId);
      const customerName = findValueByKey(customers.data, 'name');
      const distanceCalcMethods = distanceCalcMethodOptions.find((elm) => elm.id === profile.distanceCalcMethod);
      const fuelCalcMethods = fuelCalcMethodOptions.find((elm) => elm.id === profile.fuelCalcMethod);
      const odometers = odomTypeOptions.find((elm) => elm.value === vehicleData.odomType);

      setAccelerationIdentificationMethod(accelerationIdentificationMethods?.name);
      setCustomer(customerName);
      setFuelCalcMethod(fuelCalcMethods?.name);
      setDistanceCalcMethod(distanceCalcMethods?.name);
      setOdometer(odometers?.label);
      setVehicle(vehicleData);
    } catch (error) {
      console.log(error)
      toast.error("Erro ao buscar dados do veículo. Verifique com o suporte");
    }
  }, [vehicleId]);

  useEffect(() => {
    if (hasPermission({scopes: ['can_view_vehicles_without_edit']})) {
      fetchVehicleDetail();
    }
  }, [fetchVehicleDetail]);

  return (
    <Aux>
      <PermissionsGate scopes={['can_view_vehicles_without_edit']}>
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box>
              <Grid item xl={12} xs={12}>
                <Typography variant="h1" className={classes.titleMain}>Visualização de veículo</Typography>
                <Paper elevation={2} className={classes.container}>
                  <Grid container spacing={4}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Grid container>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <Typography className={classes.title}>Empresa</Typography>
                        </Grid>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <Box sx={{ m: 2 }}>
                            <Grid container spacing={2}>
                              <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                                <Typography><strong>Empresa:</strong> {customer}</Typography>
                              </Grid>
                              <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                                <Typography><strong>Grupo de veículo:</strong> {vehicle?.operationName}</Typography>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <Typography className={classes.title}>Dados do veículo</Typography>
                        </Grid>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <Box sx={{ m: 2 }}>
                            <Grid container spacing={2}>
                              <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                                <Typography><strong>Frota:</strong> {vehicle?.identification || " - "}</Typography>
                              </Grid>
                              <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                                <Typography><strong>Placa:</strong> {vehicle?.plate || " - "}</Typography>
                              </Grid>
                              <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                                <Typography><strong>Tipo de combustível:</strong> {vehicle?.fuelType || " - "}</Typography>
                              </Grid>
                              <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                                <Typography><strong>Tração:</strong> {vehicle?.wheelDriveType || " - "}</Typography>
                              </Grid>
                              <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                                <Typography><strong>Tipo de odômetro:</strong> {odometer || " - "}</Typography>
                              </Grid>
                              <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                                <Typography><strong>Km inicial:</strong> {vehicle?.initialKm || 0}  Km</Typography>
                              </Grid>
                              <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                                <Typography><strong>Limite tanque:</strong> {vehicle?.maxFuelCapacity || 0} Litros</Typography>
                              </Grid>
                              <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                                <Typography><strong>Aceleração máxima:</strong> {vehicle?.maxAcceleration || 0} m/s²</Typography>
                              </Grid>
                              <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                                <Typography><strong>Chassi:</strong> {vehicle?.chassi || " - "}</Typography>
                              </Grid>
                              <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                                <Typography><strong>Motorização:</strong> {vehicle?.motor || " - "}</Typography>
                              </Grid>
                              <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                                <Typography><strong>Ano do veículo:</strong> {vehicle?.fabricationYear || " - "}</Typography>
                              </Grid>
                              <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                                <Typography><strong>Modelo:</strong> {vehicle?.truckModel || " - "}</Typography>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <Typography className={classes.title}>Cálculo de</Typography>
                        </Grid>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <Box sx={{ m: 2 }}>
                            <Grid container spacing={2}>
                              <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                                <Typography><strong>Distância por:</strong> {distanceCalcMethod || " - "}</Typography>
                              </Grid>
                              <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                                <Typography><strong>Consumo por:</strong> {fuelCalcMethod || " - "}</Typography>
                              </Grid>
                              <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                                <Typography><strong>Aceleração por:</strong> {accelerationIdentificationMethod || " - "}</Typography>
                              </Grid>
                              <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                                <Box flexDirection="row">
                                  <Typography><strong>DAF ECO-ROLL:</strong> {vehicle?.profile?.hasDafEcoRoll ? " SIM" : " NÃO"}</Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <Typography className={classes.title}>Velocidade máxima</Typography>
                        </Grid>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <Box sx={{ m: 2 }}>
                            <Grid container spacing={2}>
                              <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                                <Typography><strong>Sem chuva:</strong> {vehicle?.maxSpeedAllowed || 0} Km/h</Typography>
                              </Grid>
                              <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                                <Typography><strong>Com chuva:</strong> {vehicle?.maxSpeedAllowedWithRain || 0} Km/h</Typography>
                              </Grid>
                              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                <Typography><strong>Pico aproveitamento de embalo:</strong> {vehicle?.leveragePeak || 0} Km/h</Typography>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <Typography className={classes.title}>Vínculo faixas de RPM</Typography>
                        </Grid>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <Box sx={{ m: 2 }}>
                            <Grid container spacing={2}>
                              <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                                <Typography><strong>Marca:</strong> {vehicle?.brand || " - "}</Typography>
                              </Grid>
                              <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                                <Typography><strong>Perfil:</strong> {vehicle?.model || " - "}</Typography>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <Typography className={classes.title}>Anotações sobre o veículo</Typography>
                        </Grid>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <Box sx={{ m: 2 }}>
                            <Typography><strong>Observações:</strong> {vehicle?.observation || " - "}</Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid justifyContent="flex-end" container spacing={2}>
                      <Grid container justifyContent="flex-end" spacing={2}>
                        <Grid item container justifyContent="flex-end" xl={12} lg={12} md={12} sm={12} xs={12}>
                          <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                            <Box sx={{ m: 2 }}>
                              <BackButton className={classes.backButton} />
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </PermissionsGate>
    </Aux>
  );
};

export default ViewVehicle;
