import React from "react";
import { Typography } from "@mui/material";
import makeStyles from "./styles";
import classnames from "classnames";

export const makeColumns = (size) => {
  const classes = makeStyles();

  const getCell = (data, textBold) => {
    return (
      <Typography
        variant="body2"
        className={classnames({
          [classes.textBold]: textBold,
        })}
        noWrap={true}
      >
        {data}
      </Typography>
    );
  };

  return [
    {
      name: "modification_type",
      label: size.mobile?' ':"Tipo de modificação",
      options: {
        customBodyRender: (value) => getCell(value, true),
      },
    },
    {
      name: "modification",
      label: "Modificação",
      options: {
        customBodyRender: (value) => getCell(value, true),
      },
    },
    {
      name: "user",
      label: "Usuário",
      options: {
        customBodyRender: (value) => getCell(value, true),
      },
    },
    {
      name: "datetime",
      label: "Data/hora",
      options: {
        customBodyRender: (value) => getCell(value, true),
      },
    },
  ];
};