import React from "react";
import { createRoot } from 'react-dom/client';
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { QueryClientProvider, QueryClient } from "react-query";
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';

// service worker
import * as serviceWorker from "serviceWorker";

// store
import { store, persistor } from "store";
import AppProvider from "context";

// theme
import Themes from "themes";

// components
import App from "components/App";

dayjs.locale('pt-br');

require("dotenv").config({ debug: process.env.DEBUG });

const appRoot = document.getElementById(`root`);
appRoot.setAttribute(`notranslate`, true);
const root = createRoot(appRoot);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      refetchOnReconnect: true,
      retry: 2,
      staleTime: 5 * 60 * 1000,
    },
  },
});

export function ProviderClient(props) {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <AppProvider>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={Themes.default}>
                <CssBaseline />
                {props.children}
              </ThemeProvider>
            </StyledEngineProvider>
          </AppProvider>
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  );
}

root.render(
  <ProviderClient>
    <App />
  </ProviderClient>
);

serviceWorker.unregister();
