import { Configuration, PublicApi } from "@ory/kratos-client";

export default class Auth {
  constructor({ basePath }) {
    this.basePath = basePath;
    this._createApi(this.basePath)
  }
  _createApi(basePath) {
    if (!basePath) {
      throw new Error("Missing Param: Base URL");
    }
    this.kratos = new PublicApi(new Configuration({ basePath }));
  }


  async getService({urlService, urlRedirectError}) {
    if (!new URL(document.location).searchParams.get("flow")) {
      window.location.href = urlService;
    }
    const flowId = new URL(document.location).searchParams.get("flow");
    const response = await this.kratos.getSelfServiceLoginFlow(flowId);
    if ([401, 403, 404].includes(response.status)) {
      return window.location.replace(urlRedirectError);
    }
    if (response.status !== 200) {
      throw new Error("Erro on request");
    }
    return response.data;
  }
}
