/* eslint-disable no-restricted-globals */
import React, { useEffect, useState, useRef, useCallback } from 'react';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { singleStyles } from './singleStyles'
import { useDebouncedCallback } from 'use-debounce';
import { Paper, Grid } from '@mui/material';
import useStyles from "./useStyles";
import colors from '../../../themes/gobrax'
import { clearDuplicated } from '../../../helpers/functions';
import { v4 as uuidv4 } from 'uuid';
import FormatList from '../utils/formatList';

const SelectQuestion = (props) => {
  const classes = useStyles();

  let initialOptions = useRef([]);
  let childOptions = useRef([]);
  let parentOptions = useRef([]);

  const { listData, handleSelectedListData, selectedData, isSingleMode, customStyles } = props;
  const [selectedOptions, setSelectedOption] = useState([])
  const [selected, setSelected] = useState([])


  const [selectedSingleMode, setSelectedSingleMode] = useState([])

  const formatData = useCallback((data) => {
    let parentFiltered = []
    let childFiltered = []
    let options = []
    let initialSelectedOptions = []


    data.forEach(parent => {
      let uuid = uuidv4();
      options.push({
        ...parent,
        value: parent.name,
        label: parent.label,
        color: colors.palette.text.primary,
        id: uuid,
        isChild: false,
        isHidden: true,
        isSelected: false
      });

      parent.questions.forEach(child => {
        const localChild = {
          ...child,
          value: child.label,
          label: child.label,
          color: colors.palette.text.hint,
          isChild: true,
          parentId: uuid,
          isHidden: true,
          isSelected: isSingleMode ? false : true,
          id: child.id,
          category: {
            id: parent.id,
            name: parent.name
          }
        }
        options.push(localChild)
        initialSelectedOptions.push(localChild);
      })
    })




    options.filter(isChild => isChild.isChild && childFiltered.push(isChild))

    options.filter(isChild => !isChild.isChild && parentFiltered.push(isChild))
    initialOptions.current = options;
    childOptions.current = childFiltered;
    parentOptions.current = parentFiltered;



    if (!isSingleMode) {
      handleClearAll();
      setSelectedOption([])
      debounced([])
    }
    if (isSingleMode) {
      childOptions.current = FormatList.fromChild(childFiltered);
      debounced([])
    }


  }, [])

  const debounced = useDebouncedCallback((value) => {
    if (isSingleMode) {
      const selected = [{ ...value }]
      handleSelectedListData(selected)
    } else {
      const selected = clearDuplicated(value)
      handleSelectedListData(selected)
    }
  }, 1000
  );


  useEffect(() => {
    if (selectedData && selectedData.length) {
      const op = selectedData.map(child => {
        return (
          {
            ...child,
            value: child.label,
            label: child.label,
            color: colors.palette.text.hint,
            isChild: true,
            isHidden: false,
            id: child.id
          }
        )
      })
      setSelectedSingleMode(op)
      debounced(op)
      formatData(listData)
    } else {
      formatData(listData)
    }
    formatData(listData)
  }, [formatData, listData])

  const handleAddedOption = (element, list, options) => {
    // check if it is parent and hide children

    let initialOptionsLocal = [...initialOptions.current];

    if (element.value === 'select-all') {

      initialOptionsLocal.forEach((item, index) => {
        const updatedElement = { ...item, isSelected: true, isHidden: true };
        initialOptionsLocal[index] = updatedElement;

        if (item.isChild) {
          options.push(updatedElement);
        }
      });

      const localList = [element];

      initialOptions.current = initialOptionsLocal;
      return [localList, options];

    }

    if (!element.isChild) {
      const id = element.id;
      initialOptionsLocal.forEach((item, index) => {
        if (item.parentId === id && item.isSelected) {
          list = list.filter(i => (i.parentId !== item.parentId))
        }
        if (item.parentId === id) {
          const updatedElement = { ...item, isHidden: true, isSelected: true };
          initialOptionsLocal[index] = updatedElement;
          if (!item.isSelected) {
            options.push(updatedElement);
          }
        }
      })

      initialOptions.current = initialOptionsLocal;
      return [list, options];
    }

    // check if all child is selected: select parent and hide children
    const parentId = element.parentId;
    const id = element.id;

    let allSelected = true;
    initialOptionsLocal.forEach((item, index) => {
      if (item.id === id) {
        const updatedElement = { ...item, isSelected: true };
        initialOptionsLocal[index] = updatedElement;
        options.push(updatedElement);
        list.push(element);
        return false;
      }

      if (item.parentId !== parentId) return false;

      if (item.parentId === parentId && !item.isSelected) allSelected = false;
    })

    if (allSelected) {
      initialOptionsLocal.forEach((item, index) => {
        if (!item.isChild && item.id === element.parentId) {
          initialOptionsLocal[index] = { ...item, isHidden: false };
          list.push(item);
        }
        if (item.parentId === parentId) {
          initialOptionsLocal[index] = { ...item, isHidden: true };
          list = list.filter(i => (i.parentId !== parentId))
        }
      })
    }

    initialOptions.current = initialOptionsLocal;
    return [list, options];
  }

  const handleRemovedOption = (element, list, options) => {
    if (element.value === 'select-all') {
      handleClearAll();
      setSelectedOption([])
      debounced([]);
      return [[], []];
    }

    if (element.isChild) {
      list = list.filter(item => (item.id !== element.id))

      const updatedSelectedOptions = options.filter(el => (element.id !== el.id))
      return [list, updatedSelectedOptions];
    }

    let updatedSelectedOptions = options;
    let initialOptionsLocal = [...initialOptions.current];
    initialOptionsLocal.forEach((item, index) => {

      if (item.parentId === element.id || item.id === element.id) {
        list = list.filter(el => (el.id !== element.id));
        initialOptionsLocal[index] = { ...item, isHidden: false, isSelected: false };
        updatedSelectedOptions = updatedSelectedOptions.filter(el => (item.id !== el.parentId))
      }
    })

    initialOptions.current = initialOptionsLocal;

    return [list, updatedSelectedOptions];
  }

  const handleClearAll = () => {
    let initialOptionsLocal = [...initialOptions.current];

    initialOptionsLocal.forEach((item, index) => {
      initialOptionsLocal[index] = { ...item, isHidden: false, isSelected: false };
    });

    initialOptions.current = initialOptionsLocal;
    setSelected([]);
  }

  const handleChange = (list, element) => {
    switch (element.action) {
      case 'select-option':
        let [addedItemList, selectOptions] = handleAddedOption(element.option, list, selectedOptions);
        setSelected(addedItemList);
        setSelectedOption(selectOptions);
        debounced(selectOptions);
        break;
      case 'remove-value':
        let [removedItemList, removeOptions] = handleRemovedOption(element.removedValue, list, selectedOptions);
        setSelected(removedItemList);
        setSelectedOption(removeOptions);
        debounced(removeOptions);
        break;
      case 'clear':
        handleClearAll();
        setSelectedOption([])
        debounced([]);
        break;
      default:
        break;
    }

  }
  const animatedComponents = makeAnimated();
  const handleChangeSingleMode = (list) => {
    setSelectedSingleMode(list)
    debounced(list)
  }

  const formatGroupLabel = (data) => (
    <div className={classes.groupStyles}>
      <span className={classes.labelStyles}>{data.label}</span>
      <span className={classes.groupBadgeStyles}>{data?.options.length}</span>
    </div>
  );

  return (
    <>
      
        <Paper elevation={0} className={classes.paper}>
          <Grid item style={{ width: '100%' }}>
            <Select
              menuPlacement='top'
              styles={singleStyles}
              placeholder="Procure sua pergunta"
              options={childOptions.current}
              onChange={(e) => handleChangeSingleMode(e)}
              components={animatedComponents}
              value={selectedSingleMode}
              formatGroupLabel={formatGroupLabel}
              isSearchable
            />
          </Grid>
        </Paper>
    </>
  )
}

export default SelectQuestion;
