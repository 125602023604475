import { Grid, Skeleton } from "@mui/material";

const OperationRegisterSkeleton = () => {
  return (
    <Grid container spacing={4}>
      <Grid container item spacing={2} lg={12} md={12} sm={12} xs={12}>
        <Grid item lg={6} md={6} sm={12} xs={12} display="flex" flexDirection="column" gap={1}>
          <Skeleton variant="rectangular" width={300} height={30} style={{ borderRadius: 10 }} />
          <Skeleton variant="rectangular" height={380} style={{ borderRadius: 10 }} />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12} display="flex" flexDirection="column" gap={1}>
          <Skeleton variant="rectangular" width={300} height={30} style={{ borderRadius: 10 }} />
          <Skeleton variant="rectangular" height={380} style={{ borderRadius: 10 }} />
        </Grid>
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Grid container gap={2} justifyContent="flex-end">
          <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
            <Skeleton variant="rectangular" width={300} height={50} style={{ borderRadius: 10 }} />
          </Grid>
          <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
            <Skeleton variant="rectangular" width={300} height={50} style={{ borderRadius: 10 }} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OperationRegisterSkeleton;
